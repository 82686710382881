import { IonGrid, IonModal, IonRow, IonText } from '@ionic/react'
import React, { ComponentProps } from 'react'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonModal from '../../../../common/components/WeaverIonWrappers/WeaverIonModal'
import { BudgetRange } from '../../../../graphql/generated'
import { BadgeLevel } from '../ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils'
import RangeBadge from './RangeBadge'

type BadgeInfoProps = {
  locked?: boolean | undefined,
  title: string | undefined,
  description: string | undefined,
  projectHistorySummary: string | undefined,
  budgetRange?: BudgetRange,
  badgeLevel: BadgeLevel | undefined,
} & ComponentProps<typeof IonModal>

export const BadgeInfoModal: React.FC<BadgeInfoProps> = ({ locked, title, description, projectHistorySummary, budgetRange, badgeLevel, ...ionModalProps }) => {
  return (
    <WeaverIonModal id='BadgeInfo' {...ionModalProps}>
      <WeaverIonContent className='ion-padding'>
        <h4>{title}</h4>
        <IonText>{description}</IonText>
        <IonText color={'primary'}><h6>{projectHistorySummary}</h6></IonText>
        <IonGrid className='ion-margin-bottom'>
          <IonRow className='ion-justify-content-center ion-margin-bottom'>
            {budgetRange && <RangeBadge budgetRange={budgetRange} badgeLevel={badgeLevel} height={'135px'} width={'130px'} locked={locked}  />}
          </IonRow>
        </IonGrid>
      </WeaverIonContent>
    </WeaverIonModal>
  )
}
