import { isSomething } from "."

/**
 * Intersperse a delimiter between each item in an array (after filtering out undefined array elements).
 * It's effectively an `Array.join` operation which returns an array rather than a string.
 * Useful for having an `Array<JSX.Element>` and wanting to delimit the array with another `JSX.Element`.
 *
 * Example:
 * ```tsx
 * const IconLister: React.FC = () => {
 *   const switcherElements: JSX.Element[] = intersperseDelimiterInArray(
 *     <>&nbsp;|&nbsp;</>,
 *     [
 *       <span>First</span>,
 *       <span>Second</span>,
 *       <span>Third</span>,
 *     ])
 *
 *   return <>{switcherElements}</>
 * }
 * ```
 *
 * This will result in the effective ReactDOM model:
 * ```tsx
 * <IconLister>
 *   <>
 *     <span>First</span>
 *     <>&nbsp;|&nbsp;</>
 *     <span>Second</span>
 *     <>&nbsp;|&nbsp;</>
 *     <span>Third</span>
 *   </>
 * </IconLister>
 * ```
 *
 * @param delimiter insert this inbetween each element in the array
 * @param array array of elements (undefined elements will be filtered out)
 * @returns a copy of the original array, with the delimiter inserted between each element
 */
export const intersperseDelimiterInArray = <T>(delimiter: T, array: (T | undefined)[]): T[] => {
  return array
    .filter(isSomething)
    .flatMap((each, _index, _array) => {
      const isLast = _array.length - 1 === _index
      return isLast ? [ each ] : [ each, delimiter ]
    })
}

/**
 * Split an array into a nested array of a given partition size.
 * If uneven, last element will have the remaining elements.
 * This function is useful for laying out a grid of elements into rows.
 */
export const partitionArray = <T>(array: T[], partitionSize: number): T[][] => {
  const clonedArray = [ ...array ]
  return clonedArray.length
    ? [ clonedArray.splice(0, partitionSize) ].concat( partitionArray(clonedArray, partitionSize) )
    : []
}
