import React from 'react'
import { IonInput, IonItem, IonLabel, IonGrid, IonRow } from '@ionic/react'
import { UseFormRegister, FieldErrors } from "react-hook-form"
import { CompanySummary } from '../../../../graphql/generated'
import Styles from "./CompanyDetailsEdit.module.scss"

type FormValues = {
  companyTradingAs: string,
}

interface CompanyDetailsProps {
  company?: CompanySummary,
  errors: FieldErrors<FormValues>,
  register: Record<keyof FormValues, ReturnType<UseFormRegister<FormValues>>>,
}

const CompanyDetailsEdit: React.FC<CompanyDetailsProps> = ({ company, register, errors }) => {
  return (
    <IonGrid className={Styles.contentContainer}>
      <IonRow style={{ marginBottom: '2rem' }}>
        <h3>Add a trading name</h3>
      </IonRow>
      <IonRow className={Styles.item}>
        <div className={Styles.itemContainer}>
          <IonItem>
            <IonLabel className={Styles.label} position="stacked">Registered Company Name*</IonLabel>
            <IonInput disabled value={company?.companyName} />
          </IonItem>
          <p className={Styles.subtext}>{`Company number: ${company?.companyNumber}`}</p>
        </div>
      </IonRow>
      <IonRow className={Styles.item}>
        <IonItem>
          <IonLabel className={Styles.label} position="stacked">Trading As*</IonLabel>
          <IonInput {...register.companyTradingAs} />
          <p className={Styles.errorMessage}>{errors.companyTradingAs?.message}</p>
        </IonItem>
      </IonRow>
    </IonGrid>
  )
}

export default CompanyDetailsEdit
