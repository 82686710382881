import React from "react"
import { IonItem, IonText } from "@ionic/react"
import Styles from './SumOfWork.module.scss'
import IndicatorBar, { IndicatorBarProps } from "../../../../../../projects/CreateProjectPage/IndicatorBar"
import { FieldError } from "react-hook-form"
import { budgetRangesConfigs } from "../../../budgetRanges"
import { BudgetRange, Currency, Money } from "../../../../../../../graphql/generated"
import ResponsiveContentWrapper from "../../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { asMoney } from "../../../../../../../common/utils/currency"
import WeaverMoneyIonInput from "../../../../../../../common/components/WeaverMoneyIonInput"

type SumOfWorkProps = {
  value: Money | undefined | null,
  setValue: (value?: Money | undefined | null) => Promise<void> | void,
  indicatorBarProps: IndicatorBarProps,
  budgetRange: BudgetRange,
  error?: FieldError | undefined,
  handleDot?: (value?: boolean | undefined | null) => void,
}

const SumOfWork: React.FC<SumOfWorkProps> = ({ value, setValue, indicatorBarProps, error, budgetRange }) => {
  const minBudgetRangeInMoney = asMoney(value?.currency ?? Currency.Gbp, budgetRangesConfigs[ budgetRange ].fromInclusive)
  const maxBudgetRangeInMoney = asMoney(value?.currency ?? Currency.Gbp, budgetRangesConfigs[ budgetRange ].toExclusive)

  return (
    <>
      <ResponsiveContentWrapper>
        <IndicatorBar {...indicatorBarProps} />
        <div className="ion-padding">
          <h3 className="ion-padding-start"><b>Enter past project&apos;s Sum of Work</b></h3>
          <IonText className={Styles.ionInputLabel}>Sum of Work excluding VAT*</IonText>
          <IonItem className={`${Styles.ionItemInput}`} lines="none">
            <WeaverMoneyIonInput
              value={value}
              setValue={setValue}
              minValue={minBudgetRangeInMoney}
              maxValue={maxBudgetRangeInMoney}
              withPennies={false}
              className={Styles.ionInput}
              step="1"
            />
          </IonItem>
          <p className={Styles.errorMessage}>{error && error.message}</p>
        </div>
      </ResponsiveContentWrapper>
    </>
  )
}

export default SumOfWork
