import React, { useMemo } from 'react'
import { AuthContext, TAuth_LoggedIn, TAuth_LoggedOut } from '..'
import useStatefulEffect from '../../../../common/hooks/useStatefulEffect'
import { useEnvironmentContext } from '../../EnvironmentProvider'
import useAuth0Client from './useAuth0Client'
import { buildLoggedInAuthState, buildLoggedOutAuthState } from '../helpers'
import useRegisterCustomURLHandler from './useRegisterCustomURLHandler'
import LoadingSpinnerPage from '../../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import SplashScreenSetReady from '../../../../common/components/SplashScreen/SplashScreenSetReady'
import { IonButton, IonContent, IonPage, IonGrid, IonRow, IonCol, IonImg, IonIcon } from '@ionic/react'
import { arrowForwardOutline } from "ionicons/icons"
import WeaverLogo from "../../../../assets/images/weaver_logo.svg"
import WeaverHardhatWithComments from "../../../../assets/images/weaver-hardhat-with-comments.png"
import Styles from "./Auth0Provider.module.scss"

export const Auth0Provider: React.FC = ({ children }) => {

  const environment = useEnvironmentContext()
  const { auth0Client, auth0ClientOptions } = useAuth0Client(environment)

  const {
    state: authState,
    setState: setAuthState,
  } = useStatefulEffect<TAuth_LoggedOut | TAuth_LoggedIn>('auth', ({ state, setState }) => {
    if (auth0Client === undefined) return
    if (auth0ClientOptions === undefined) return

    auth0Client.isAuthenticated()
      .then(isLoggedIn => {
        // TODO: This was here to stop infinite loops - test to see if this is still needed
        // Check I've changed state
        if (state?.isLoggedIn === isLoggedIn) return undefined

        return isLoggedIn
          ? buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState: setState })
            .then(setState)
          : buildLoggedOutAuthState({ auth0Client, auth0ClientOptions, setAuthState: setState })
            .then(setState)
      })
  }, [ auth0Client ])

  useRegisterCustomURLHandler({ auth0Client, auth0ClientOptions, authState, setAuthState })

  console.debug('[Auth0Provider] Current state: ', authState)

  const login = (authState: TAuth_LoggedOut) => (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    authState.login()
  }

  return authState === undefined
    ? <LoadingSpinnerPage name='authStateUndefined' />
    : authState.isLoggedIn === false
      ? ( // Extract the following into a Login Card
        <SplashScreenSetReady>
          <IonPage>
            <IonContent className={Styles.outerContainer} onClick={login(authState)}>
              <IonImg className={Styles.weaverLogo} src={WeaverLogo} />
              <IonGrid className={Styles.container}>
                <IonRow>
                  <IonCol >
                  </IonCol>
                  <IonCol size='auto'>
                    <IonImg className={`${Styles.promoImage} ${Styles.improvePromoImage}`} src={WeaverHardhatWithComments}/>
                  </IonCol>
                  <IonCol>
                  </IonCol>
                </IonRow>
                <IonRow class="ion-justify-content-center">
                  <IonCol size="8" className={Styles.buttonContainer}>
                    <h1 className={Styles.caption}>Get started with Weaver</h1>
                    <IonButton expand="block" color="light" className={Styles.loginButton} onClick={login(authState)}>
                      <IonIcon slot="end" icon={arrowForwardOutline} />
                      <p>Continue</p>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonPage>
        </SplashScreenSetReady>
      )
      : <AuthContext.Provider value={authState}>
        {children}
      </AuthContext.Provider>
}

export default Auth0Provider
