import React, { useCallback, useRef, useState } from 'react'
import { IonIcon } from '@ionic/react'
import Styles from "./UploadProjectDocumentsPage.module.scss"
import uploadDocuments from "./upload-project-documents.svg"
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { UploadableFileChip, CreateUploadedFileReturns, UpdateUploadedFileReturns } from './uploadableFileChip/UploadableFileChip'
import  BrowseFilesButton from  '../../../common/components/BrowseFilesButton'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { saveFileToDeviceStorage } from '../../../common/utils/files'
import { useParamsFromPageConfig } from '../../../routesProvider'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { useAddUploadedFileToProjectFilesMutation, useGetProjectDocumentsQuery, useIncrementProjectDocumentCountMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useQueryClient } from '@tanstack/react-query'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useRouteTo, pageConfig_UploadedProjectDocumentSuccess } from '../../../routes'
import { asyncForEach } from '../../../common/utils'
import SingleClickButton from '../../../common/components/SingleClickButton'
import { folderOutline } from 'ionicons/icons'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { Duration } from 'luxon'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

const UploadProjectDocuments: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{projectId: string}>()
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const addUploadedFileToProjectFilesMutation = useAddUploadedFileToProjectFilesMutation(gqlDataSource)
  const incrementProjectDocumentCountMutation = useIncrementProjectDocumentCountMutation(gqlDataSource)
  const getProjectDocumentsQueryKey = useGetProjectDocumentsQuery.getKey({ id: projectId })[0]
  const triggerProjectDocumentUploaded = useAnalyticsEvent("Project_Document_Uploaded")
  const goToUploadProjectDocumentSuccessPage = useRouteTo(pageConfig_UploadedProjectDocumentSuccess.path)
  const uploadedFilesIds = useRef<string[]>([])
  const [ saveFileToDeviceStorageInProgress, setSaveFileToDeviceStorageInProgress ] = useState(false)

  const { ["MW-2422-tasks-qol1-part3"]: tasksQol1Part3, ["MW-2450-upload-document-section-all-clickable"]: uploadContainerIsClickable } = useWeaverFlags()
  // Use the projectDocuments query from the previous page, which should have warm cache
  const getProjectDocuments = useGetProjectDocumentsQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false, staleTime: Duration.fromObject({ seconds: 30 }).toMillis(), enabled: tasksQol1Part3  })

  const queryClient = useQueryClient()

  if (!myTeam) return <LoadingSpinnerPage name='displayMyProfile' />

  const [ fileList, setFileList ] = useState<File []>([])

  const addFileToList = (file: File) => {
    setFileList(currentState => ([ ...currentState, file ]))
  }

  const addFilesToList = (files: File[]) => {
    setFileList(currentState => ([ ...currentState, ...files ]))
  }

  const clearFileList = () => {
    setFileList([])
  }

  const removeFileFromList = (fileName: string | undefined | null) => {
    setFileList(currentState => ([ ...currentState.filter(file => file.name !== fileName) ]))
  }

  const onFilesSelectedByUser = useCallback(async (selectedFilesByUser: File[]) => {
    setSaveFileToDeviceStorageInProgress(true)
    const filesNotAlreadyIncluded = selectedFilesByUser.filter(file => !fileList.includes(file))
    await Promise.all(filesNotAlreadyIncluded.map(file => saveFileToDeviceStorage(file)))

    addFilesToList(filesNotAlreadyIncluded)
    setSaveFileToDeviceStorageInProgress(false)
  }, [ fileList, addFileToList ])

  const invalidateGetProjectDocumentsQuery = () => {
    queryClient.invalidateQueries([ getProjectDocumentsQueryKey ])
  }

  const onUploadedFileArchivedByUser = useCallback((result: UpdateUploadedFileReturns) => {
    removeFileFromList(result.fileName)
    invalidateGetProjectDocumentsQuery()
  }, [ removeFileFromList ])

  const addUploadedFileToProjectFileList = (uploadedFileId: string) =>
    addUploadedFileToProjectFilesMutation.mutateAsync({
      projectId,
      uploadedFileId: uploadedFileId,
    })

  const onUploadedFileCreated = async (result: CreateUploadedFileReturns) => {
    uploadedFilesIds.current.push(result.id)
  }

  const navigateToSuccessPage = () => {
    goToUploadProjectDocumentSuccessPage({ projectId })()
  }

  const onConfirm = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const uploadedFileIds = uploadedFilesIds.current

    await asyncForEach(uploadedFileIds, async (uploadedFileId: string) => {
      await addUploadedFileToProjectFileList(uploadedFileId)
    })

    invalidateGetProjectDocumentsQuery()

    await incrementProjectDocumentCountMutation.mutateAsync({
      input: {
        projectId,
        documentCount: uploadedFileIds.length,
      },
    })
    clearFileList()
    navigateToSuccessPage()
  }

  return (
    <WeaverIonPage id='UploadProjectDocumentsPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Documents' pageSubtitle={tasksQol1Part3 ? getProjectDocuments.data?.getProject.title : undefined}/>
      </WeaverIonHeader>
      <WeaverIonContent className='ion-padding'>
        <h4 className={Styles.title}>Upload Documents</h4>

        {uploadContainerIsClickable
          ? <BrowseFilesButton
            onFilesSelectedByUserCallback={onFilesSelectedByUser}
            containerCssClassName={Styles.uploadPhotosButtonContainer}
            multipleSelection={true}
            renderButton={
              (openNativeFilePicker) => (
                <>
                  <div onClick={openNativeFilePicker} className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
                    <img src={uploadDocuments} className={Styles.uploadDocumentsImage} alt="Upload documents image" />
                    <h6 className={Styles.uploadYourDocumentsText}>Upload Your Documents</h6>
                    <SingleClickButton fill="clear" size="small" disabled={saveFileToDeviceStorageInProgress}>
                      {saveFileToDeviceStorageInProgress && <LoadingSpinner name={'uploadingProjectDocuments'} />}
                      <IonIcon icon={folderOutline} color="primary" /> Browse Documents
                    </SingleClickButton>

                    <div className={Styles.filesToUploadContainer}>
                      {
                        fileList.map(
                          file =>
                          {
                          // Use the file's last modified + name as the key since files don't come with an id and it's not recommended the use index in cases when items can be removed.
                            const key = `${file.lastModified}_${file.name}`
                            return <UploadableFileChip
                              key={key}
                              file={file}
                              onUploadedFileArchived={onUploadedFileArchivedByUser}
                              onUploadedFileCreated={onUploadedFileCreated}
                              trackEvent={({ fileType, fileSizeInBytes, transferDurationInMilliseconds }) => triggerProjectDocumentUploaded({ projectId, fileType, fileName: file.name, fileSizeInBytes, transferDurationInMilliseconds })}
                            />
                          },
                        )
                      }
                    </div>
                    <SingleClickButton disabled={fileList.length === 0} onClick={(event) => {onConfirm(event)}} className={Styles.confirmButton}>Confirm</SingleClickButton>
                  </div>
                </>
              )
            }
          />
          : <div className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
            <img src={uploadDocuments} className={Styles.uploadDocumentsImage} alt="Upload documents image" />
            <h6 className={Styles.uploadYourDocumentsText}>Upload Your Documents</h6>
            <BrowseFilesButton
              onFilesSelectedByUserCallback={onFilesSelectedByUser}
              containerCssClassName={Styles.uploadPhotosButtonContainer}
              multipleSelection={true}
              renderButton={
                (openNativeFilePicker) => (
                  <SingleClickButton onClick={openNativeFilePicker} fill="clear" size="small" disabled={saveFileToDeviceStorageInProgress}>
                    {saveFileToDeviceStorageInProgress && <LoadingSpinner name={'uploadingProjectDocuments'} />}
                    <IonIcon icon={folderOutline} color="primary" /> Browse documents
                  </SingleClickButton>
                )
              }
            />
            <div className={Styles.filesToUploadContainer}>
              {
                fileList.map(
                  file =>
                  {
                  // Use the file's last modified + name as the key since files don't come with an id and it's not recommended the use index in cases when items can be removed.
                    const key = `${file.lastModified}_${file.name}`
                    return <UploadableFileChip
                      key={key}
                      file={file}
                      onUploadedFileArchived={onUploadedFileArchivedByUser}
                      onUploadedFileCreated={onUploadedFileCreated}
                      trackEvent={({ fileType, fileSizeInBytes, transferDurationInMilliseconds }) => triggerProjectDocumentUploaded({ projectId, fileType, fileName: file.name, fileSizeInBytes, transferDurationInMilliseconds })}
                    />
                  },
                )
              }
            </div>
            <SingleClickButton disabled={fileList.length === 0} onClick={onConfirm} className={Styles.confirmButton}>Confirm</SingleClickButton>
          </div>
        }

      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default UploadProjectDocuments
