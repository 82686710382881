import React, { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from "react"
import { IonButton, IonCheckbox, IonIcon, IonItem, IonLabel, IonText, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { budgetRangeLabels } from "../../budgetRanges.i18n"
import { ScreenRenderProps, useScreensWithProps } from "../../../../../../common/hooks/useScreens"
import { Controller, useForm, UseFormReturn } from "react-hook-form"
import { WorkHistory, zWorkHistory } from "../workHistoryTypes"
import { BudgetRange, Currency, useCreateContractorProfileWorkHistoryMutation, WeaverAddressInput } from "../../../../../../graphql/generated"
import { zodResolver } from "@hookform/resolvers/zod"
import PickAddress from "../../../../../../common/components/PickAddress"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import ProjectTypeSelector from "./ProjectTypeSelector/ProjectTypeSelector"
import SumOfWork from "./SumOfWorkPage/SumOfWork"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import RangeBadge from "../../../ContractorBudgetReferences/RangeBadge"
import { budgetRangesConfigs } from "../../budgetRanges"
import { z } from "zod"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import SingleClickButton from "../../../../../../common/components/SingleClickButton"
import { moneyAmountAsNumberOnlyLogError, numberToStringWithThousandCommas } from "../../../../../../common/utils/currency"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"
import { useWeaverFlags } from "../../../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { ConstructionCompletedDate } from "./ConstructionDates/ConstructionCompletedDate"
import { DateTime } from "luxon"
import IndicatorBar from "../../../../../projects/CreateProjectPage/IndicatorBar"
import ScreenWorkHistoryMenu from "../ScreenWorkHistoryMenu"
import { WorkHistoryMenuItem } from "../ScreenWorkHistoryMenu/config"
import { getAllBadgeEligibleHistories } from "../ContractorProfileBudgetRangeBadge/hooks"
import { BadgeLevel, getInprogressBadgeLevel } from "../BadgeUtils"
import WeaverDatePicker from "../../../../../../common/components/WeaverDatePicker/WeaverDatePicker"
import { DateResolution } from "../../../../../../common/components/WeaverDatePicker/types"
import { ConstructionStartedDate } from "./ConstructionDates/ConstructionStartedDate"

import Styles from './NewWorkHistory.module.scss'

enum NewWorkHistoryScreens {
  UnlockNewHistoryMenu = "UnlockNewHistoryMenu",
  Splash = "Splash",
  ConstructionStartedDate = "ConstructionStartedDate",
  ConstructionCompletedDate = "ConstructionCompletedDate",
  ProjectAddress = "ProjectAddress",
  ProjectSumOfWork = "ProjectSumOfWork",
  ProjectType = "ProjectType",
  Finished = "Finished",
}

type ScreenProps = {
  budgetRange: BudgetRange,
  myContractorProfile: ReturnType<typeof useMyContractorProfile>,
  form: UseFormReturn<WorkHistory>,
  constructionIsStillOnGoing: boolean,
  setConstructionIsOnGoing: Dispatch<SetStateAction<boolean>>,
  newWorkHistoryId: MutableRefObject<string | undefined>,
  badgeLevel?: BadgeLevel,
}

type ScreenComponentProps = ScreenRenderProps<NewWorkHistoryScreens> & ScreenProps

const _UnlockNewHistoryMenu: React.FC<ScreenComponentProps> = ({ useChangeScreen, budgetRange, badgeLevel }) => {

  const goToSplash = useChangeScreen(NewWorkHistoryScreens.Splash)
  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  const currentProgressIndex = 0
  const totalPages = Object.keys(WorkHistoryMenuItem).length

  const handleClickBack = () => {
    goToUnlockContractorBudgetRange({ budgetRange }, undefined, 'back')()
  }

  return <>
    <WeaverIonContent>
      <div className={`${Styles.pastProjectHeaderContainer} ion-text-center}`}>
        { budgetRange && <RangeBadge budgetRange={budgetRange} badgeLevel={badgeLevel} />}
        <h5>Unlock {budgetRangeLabels[budgetRange]} leads</h5>
      </div>
      <div className={Styles.indicatorContainer}>
        <IndicatorBar currentPageIndex={currentProgressIndex} totalPages={totalPages} maxAvailableIndex={totalPages} onNavigate={() => null} />
      </div>
      <div className="ion-padding">
        <IonText>To unlock {budgetRangeLabels[budgetRange]} leads, you need to have all the tasks marked as done</IonText>
        <ScreenWorkHistoryMenu budgetRange={budgetRange} workHistory={undefined} goToNewHistorySplash={goToSplash} />
      </div>
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={handleClickBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _Splash: React.FC<ScreenComponentProps> = ({ useChangeScreen, budgetRange, badgeLevel }) => {

  const onClickNext = useChangeScreen(NewWorkHistoryScreens.ConstructionStartedDate)

  const goToUnlockNewHistoryMenu = useChangeScreen(NewWorkHistoryScreens.UnlockNewHistoryMenu)

  return <>
    <WeaverIonContent>
      <TitleWithDescriptionPage
        topDivContents={<RangeBadge budgetRange={budgetRange} badgeLevel={badgeLevel} />}
        title={`Add a past project in ${budgetRangeLabels[ budgetRange ]} budget range to unlock leads.`}
        description={`It doesn't have to be a Weaver project, but does need to be in the selected budget range.`}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={goToUnlockNewHistoryMenu}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _ConstructionStartedDate: React.FC<ScreenComponentProps> = ({ useChangeScreen, form }) => {
  const onClickBack = useChangeScreen(NewWorkHistoryScreens.Splash)
  const onClickNext = useChangeScreen(NewWorkHistoryScreens.ConstructionCompletedDate)
  const weaverFlags = useWeaverFlags()

  /**
   * We reset the constructionStartedDate value when the user clicks back
   * so a when a user returns to this screen, the calendar
   * does not default to showing second step, in this case month.
   *
   * This is to prevent possible UX confusion when a user is intending to
   * change the year (first step) but thinks it is the back button that controls
   * that feature.
   */
  const onClickBackAndResetConstructionStartDateValue = () => {
    form.setValue('constructionStartedDate', undefined)
    onClickBack()
  }

  const hasConstructionStartDateValue = form.watch('constructionStartedDate')
  const dateToday = DateTime.now()

  const triggerEventWorkHistoryAddedConstructionStart = useAnalyticsEvent('WorkHistory_Added_Construction_Start')
  const onClickNextIfFieldIsValid = async () => {
    const isValid = await form.trigger('constructionStartedDate')
    const constructionStartedDate = await form.getValues('constructionStartedDate')

    if (isValid && constructionStartedDate) {

      const year = DateTime.fromISO(constructionStartedDate).year
      const month =  DateTime.fromISO(constructionStartedDate).monthShort

      await triggerEventWorkHistoryAddedConstructionStart({
        month: month,
        year: year,
      })
      onClickNext()
    }
  }

  return <>
    <WeaverIonContent>
      <Controller
        control={form.control}
        name="constructionStartedDate"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
          <>
            <div className='ion-padding-top'>
              <IndicatorBar
                currentPageIndex={0}
                totalPages={4}
                maxAvailableIndex={4}
                onNavigate={() => null}/>
              <h3 className="ion-padding-vertically ion-padding-bottom"><b>When did the construction start?</b></h3>
            </div>
            <WeaverDatePicker value={value} max={dateToday} setValue={onChange} resolution={DateResolution.Month}/>
          </>
        )}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBackAndResetConstructionStartDateValue}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton
          slot="end"
          disabled={!hasConstructionStartDateValue}
          onClick={onClickNextIfFieldIsValid}
        >Next
          <IonIcon icon={arrowForwardOutline} />
        </IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _ConstructionCompletedDate: React.FC<ScreenComponentProps> = ({ useChangeScreen, form, constructionIsStillOnGoing, setConstructionIsOnGoing }) => {
  const history = useHistory()
  const onClickBack = useChangeScreen(NewWorkHistoryScreens.ConstructionStartedDate)
  const onClickNext = useChangeScreen(NewWorkHistoryScreens.ProjectAddress)

  const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined)

  const onClickBackAndResetConstructionCompletedDateValue = () => {
    form.setValue('constructionCompletedDate', undefined)
    setConstructionIsOnGoing(false)
    errorMessage?.length && setErrorMessage(undefined)
    onClickBack()
  }

  const constructionStartedDate = form.getValues('constructionStartedDate')

  //This should never happen - typescript guard
  if (!constructionStartedDate){
    throw new Error('[NewWorkHistoryPage]constructionStartedDate is not a valid date')
  }

  const dateToday = DateTime.now()

  const triggerEventWorkHistoryAddedConstructionEnd = useAnalyticsEvent('WorkHistory_Added_Construction_End')
  const triggerEventWorkHistorySelectedConstructionOnGoing = useAnalyticsEvent("WorkHistory_Selected_Construction_Ongoing")

  const onClickNextIfFieldIsValid = async () => {
    if (constructionIsStillOnGoing) {
      await triggerEventWorkHistorySelectedConstructionOnGoing()
      history.push('http://help.weaver.build/en/articles/6907932-past-projects-that-weaver-accepts')
      return
    }

    const isValid = await form.trigger('constructionCompletedDate')
    const constructionCompletedDate = await form.getValues('constructionCompletedDate')

    if (isValid && constructionCompletedDate) {
      const year = DateTime.fromISO(constructionCompletedDate).year
      const month =  DateTime.fromISO(constructionCompletedDate).monthShort

      await triggerEventWorkHistoryAddedConstructionEnd({
        month: month,
        year: year,
      })

      onClickNext()
    } else {
      setErrorMessage('Please select a date')
    }
  }

  return <>
    <WeaverIonContent>
      <Controller
        control={form.control}
        name="constructionCompletedDate"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) =>  (
          <>
            <div className='ion-padding-top'>
              <IndicatorBar
                currentPageIndex={0}
                totalPages={4}
                maxAvailableIndex={4}
                onNavigate={() => null}/>
              <h3 className="ion-padding-vertically ion-padding-bottom"><b>When did the construction complete?</b></h3>
            </div>
            <WeaverDatePicker
              value={value}
              max={dateToday}
              min={DateTime.fromISO(constructionStartedDate)}
              setValue={onChange}
              resolution={DateResolution.Month}/>
            <IonItem lines='none' className={`${Styles.constructionOngoingCheckBox}`}>
              <IonCheckbox
                slot="start"
                color='primary'
                checked={constructionIsStillOnGoing}
                onIonChange={(e) => setConstructionIsOnGoing(e.detail.checked)} />
              <IonLabel color={'primary'} >Construction is still ongoing</IonLabel>
            </IonItem>
            {errorMessage && <IonText color='danger'><p className='ion-padding-start'>{errorMessage}</p></IonText>}
          </>
        )}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBackAndResetConstructionCompletedDateValue}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _ProjectAddress: React.FC<ScreenComponentProps>  = ({ useChangeScreen, form, budgetRange }) => {
  const weaverFlags = useWeaverFlags()

  const onClickBack = useChangeScreen(NewWorkHistoryScreens.ConstructionCompletedDate)

  const onClickNext = useChangeScreen(NewWorkHistoryScreens.ProjectSumOfWork)

  const triggerEventReferencesAddedPastProjectAddress = useAnalyticsEvent("WorkHistory_References_Added_Past_Project_Address")

  const onClickNextIfFieldIsValid = async () => {
    const isValid = await form.trigger('projectAddress')
    if (isValid) {
      await triggerEventReferencesAddedPastProjectAddress()
      onClickNext()
    }
  }

  const hiddenFields: Array<keyof WeaverAddressInput> = weaverFlags["MW-2413-hide-unnecessary-fields-from-project-history-address-capture"] ? [ 'careOf', 'poBox' ] : []

  return (
    <>
      <WeaverIonHeader>
        <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
      </WeaverIonHeader>
      <WeaverIonContent className="ion-padding" fullscreen>
        <Controller
          control={form.control}
          name="projectAddress"
          render={({
            field: { onChange, value },
            fieldState: { error : fieldStateEmptyError },
            formState: { errors },
          }) => ( <> <h3><b>Enter address of a past project</b></h3>
            <IonText className="ion-margin-bottom" color='medium'>
              <p>
                The project information will not be viewable publicly and will be stored securely
              </p>
            </IonText>
            {fieldStateEmptyError ? <p className={Styles.errorMessage}>{fieldStateEmptyError.message}</p> : null}
            <PickAddress title='' value={value} setValue={onChange} hasError={errors.projectAddress} hideFields={hiddenFields} /></> )}/>
      </WeaverIonContent>
      <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
          <IonButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </>)
}

const _ProjectSumOfWork: React.FC<ScreenComponentProps> = ({ useChangeScreen, form, budgetRange }) => {

  const onClickBack = useChangeScreen(NewWorkHistoryScreens.ProjectAddress)
  const onClickNext = useChangeScreen(NewWorkHistoryScreens.ProjectType)

  const triggerEventReferencesAddedSumOfWork = useAnalyticsEvent("WorkHistory_References_Added_Sum_Of_Work")

  const onClickNextIfFieldIsValid = async () => {
    const isValid = await form.trigger('constructionValue')
    await triggerEventReferencesAddedSumOfWork({
      currency: form.getValues('constructionValue').currency ,
      amountInPence: form.getValues('constructionValue').amountInPence,
    })
    if (isValid) onClickNext()
  }

  return <>
    {/* Button to set the sum field in the work history */}
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className="ion-padding">
      <Controller
        control={form.control}
        name="constructionValue"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (  <SumOfWork
          indicatorBarProps={{
            currentPageIndex: 1,
            totalPages: 3,
            maxAvailableIndex: 3,
            onNavigate: () => null,
          }}
          value={value}
          setValue={onChange}
          error={error}
          budgetRange={budgetRange}
        /> )}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _ProjectType: React.FC<ScreenComponentProps> = ({ useChangeScreen, form, newWorkHistoryId, budgetRange }) => {
  const onClickBack = useChangeScreen(NewWorkHistoryScreens.ProjectSumOfWork)
  const onClickNext = useChangeScreen(NewWorkHistoryScreens.Finished)

  const [ isSubmitting, setSubmitting ] = useState(false)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createMutation = useCreateContractorProfileWorkHistoryMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

  const triggerEventReferencesAddedPastProjectTypes = useAnalyticsEvent("WorkHistory_References_Added_Past_Project_Types")

  const onSubmitThenClickNextIfFieldIsValid = async () => {
    const isProjectTypeValid = await form.trigger('projectTypes')
    if (isProjectTypeValid) {
      setSubmitting(true)

      if (!form.trigger()) {
        console.error(`[NewWorkHistory] Form not valid: `, form)
        return
      }

      console.debug('[NewWorkHistory] Adding the Work History: ', form.getValues())

      const { createContractorProfileWorkHistory : { id } } = await createMutation.mutateAsync({
        input: {
          projectAddress: form.getValues('projectAddress'),
          constructionValue: form.getValues('constructionValue'),
          projectTypes: form.getValues('projectTypes'),
          constructionStartedDate: form.getValues('constructionStartedDate'),
          constructionCompletedDate: form.getValues('constructionCompletedDate'),
        },
      })

      if (id) {
        newWorkHistoryId.current = id
      }

      await triggerEventReferencesAddedPastProjectTypes({
        types: form.getValues('projectTypes'),
      })

      await invalidateMyContractorProfile()
      onClickNext()
    }
    setSubmitting(false)
  }

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className="ion-padding" fullscreen>
      <Controller
        control={form.control}
        name="projectTypes"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (  <ProjectTypeSelector
          disabled={isSubmitting}
          indicatorBarProps={{
            currentPageIndex: 2,
            totalPages: 3,
            maxAvailableIndex: 3,
            onNavigate: () => null,
          }} value={value} setValue={onChange} hasError={!!error}
        />)}
      />

    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <SingleClickButton slot="end" onClick={onSubmitThenClickNextIfFieldIsValid}
        >Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _Finished: React.FC<ScreenComponentProps> = ({ budgetRange, newWorkHistoryId }) => {

  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  const handleClickContinue = goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId: newWorkHistoryId.current ?? '' }, undefined,'forward', 'push')

  const weaverFlags = useWeaverFlags()

  return <>
    <WeaverIonContent>
      { weaverFlags['MW-2410-copy-changes-march']
        ? <BrilliantScreen title='Project logged' description="We'll now help you to add some detail which will make this project look great on your Weaver profile"/>
        : <BrilliantScreen title='Amazing!!!' description='You have added a reference project'/>
      }
    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton expand="block" onClick={handleClickContinue}>Continue</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const NewWorkHistoryPage: React.FC = () => {
  const history = useHistory()
  const [ constructionIsStillOnGoing, setConstructionIsOnGoing ] = useState<boolean>(false)

  const myContractorProfile = useMyContractorProfile()
  const { budgetRange } = useParamsFromPageConfig<{ budgetRange: BudgetRange }>()
  const newWorkHistoryId = useRef<string | undefined>(undefined)
  const weaverFlags = useWeaverFlags()

  const eligibleWorkHistories = getAllBadgeEligibleHistories(myContractorProfile, budgetRange)
  const badgeLevel = getInprogressBadgeLevel(eligibleWorkHistories.length)

  const minBudgetRange = Number(budgetRangesConfigs[ budgetRange ].fromInclusive / 100)
  const maxBudgetRange = Number(budgetRangesConfigs[ budgetRange ].toExclusive / 100)

  const zRefinedWorkHistory = zWorkHistory.superRefine((validated, ctx) => {

    if (validated.constructionStartedDate == null) {
      ctx.addIssue({
        path: [ "constructionStartedDate" ],
        code: z.ZodIssueCode.custom,
        message: "Invalid Date",
      })
    }

    if (validated.constructionCompletedDate == null) {
      ctx.addIssue({
        path: [ "constructionCompletedDate" ],
        code: z.ZodIssueCode.custom,
        message: "Invalid Date",
      })

      if (validated.constructionCompletedDate && validated.constructionStartedDate) {
        const constructionStartedDate = DateTime.fromISO(validated.constructionStartedDate)
        const constructionCompletedDate = DateTime.fromISO(validated.constructionCompletedDate)
        if (constructionCompletedDate < constructionStartedDate) {
          ctx.addIssue({
            path: [ "constructionCompletedDate" ],
            code: z.ZodIssueCode.custom,
            message: 'You cannot set construction completed date before the construction started date',
          })
        }
      }

    }

    const amountInPence = moneyAmountAsNumberOnlyLogError(validated.constructionValue)
    if (amountInPence == null) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be a currency number!`,
      })
      return
    }
    if (amountInPence < budgetRangesConfigs[ budgetRange ].fromInclusive) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be greater than the minimum of £${numberToStringWithThousandCommas(minBudgetRange)}`,
      })
    }
    if (amountInPence >= budgetRangesConfigs[ budgetRange ].toExclusive) {
      ctx.addIssue({
        path: [ "constructionValue" ],
        code: z.ZodIssueCode.custom,
        message: `Value must be less than the maximum of £${numberToStringWithThousandCommas(maxBudgetRange)}`,
      })
    }

  })

  const form = useForm<WorkHistory>({
    defaultValues: {
      projectAddress: undefined,
      constructionValue: {
        currency: Currency.Gbp,
      },
      constructionStartedDate: undefined,
      constructionCompletedDate: undefined,
      projectTypes: [],
      references: [],
      photos: [],
    },
    mode: "onChange",
    resolver: zodResolver(zRefinedWorkHistory),
  })

  const [ Screen, activeScreen ] = useScreensWithProps<NewWorkHistoryScreens, ScreenProps>({
    init: () => {
      form.reset()
      return NewWorkHistoryScreens.UnlockNewHistoryMenu
    },
    resetWhenValueChanges: history.location.search,
    screenProps: {
      budgetRange,
      myContractorProfile,
      form,
      constructionIsStillOnGoing,
      setConstructionIsOnGoing,
      newWorkHistoryId,
      badgeLevel,
    },
    screens: {
      UnlockNewHistoryMenu:  { render: _UnlockNewHistoryMenu },
      Splash:  { render: _Splash },
      ConstructionStartedDate: { render: _ConstructionStartedDate },
      ConstructionCompletedDate: { render: _ConstructionCompletedDate },
      ProjectAddress: { render: _ProjectAddress },
      ProjectSumOfWork: { render: _ProjectSumOfWork },
      ProjectType: { render: _ProjectType },
      Finished: { render: _Finished },
    },
  })

  return (
    <WeaverIonPage id={`NewWorkHistoryPage.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default NewWorkHistoryPage
