import { IonButton, IonCheckbox, IonItem, IonLabel, IonModal, IonText, IonToolbar } from '@ionic/react'
import { DateTime } from 'luxon'
import React, { ComponentProps, Dispatch, ReactNode, SetStateAction } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import SingleClickButton from '../../../common/components/SingleClickButton'
import { DateResolution } from '../../../common/components/WeaverDatePicker/types'
import WeaverDatePicker from '../../../common/components/WeaverDatePicker/WeaverDatePicker'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'

import Styles from './AddConstructionDatesModal.module.scss'

type AddConstructionDatesModalProps = {
  workHistoryId?:  string,
  form: UseFormReturn<WorkHistoryConstructionDates>,
  header?: () => ReactNode,
  onSubmitOfConstructionDatesIfFieldsAreValid: () => void,
  constructionIsStillOnGoing: boolean,
  setConstructionIsStillOnGoing: Dispatch<SetStateAction<boolean>>,
  showAddConstructionModalScreen: number,
  setShowAddConstructionModalScreen: Dispatch<SetStateAction<number>>,
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
  errorMessage?: string,
}

type AddConstructionComponentDatesModalProps = AddConstructionDatesModalProps & ComponentProps<typeof IonModal>

export const zWorkHistoryConstructionDates = z.object({
  constructionStartedDate: z.string().optional(),
  constructionCompletedDate: z.string().optional(),
})

export type WorkHistoryConstructionDates = z.infer<typeof zWorkHistoryConstructionDates>

// TODO: after one month from 16/02/23 check with product if this can be removed MW-2231
export const AddConstructionDatesModal: React.FC<AddConstructionComponentDatesModalProps> = ({
  workHistoryId,
  form,
  header,
  errorMessage,
  setErrorMessage,
  onSubmitOfConstructionDatesIfFieldsAreValid,
  constructionIsStillOnGoing,
  setConstructionIsStillOnGoing,
  showAddConstructionModalScreen,
  setShowAddConstructionModalScreen,
  ...ionModalProps
}) => {
  const { formState, getValues } = form

  const constructionStartedDate = getValues('constructionStartedDate')
  const todaysDate = DateTime.now()

  const handleBackClick = () => {
    form.setValue('constructionCompletedDate', undefined)
    setErrorMessage(undefined)
    setShowAddConstructionModalScreen(1)
  }

  return (
    <WeaverIonModal
      id='AddConstructionDatesModal'
      className={Styles.addConstructionModal}
      disableDirectChildStructureChecks={true}
      {...ionModalProps}>
      {header && header()}
      <WeaverIonContent className={`${Styles.addConstructionModalContent} ion-padding-horizontal`}>
        {showAddConstructionModalScreen === 1 && <Controller
          control={form.control}
          name="constructionStartedDate"
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <>
              <h3 className="ion-padding-vertically ion-padding-bottom"><b>When did the construction start?</b></h3>
              <WeaverDatePicker
                value={value}
                setValue={onChange}
                max={todaysDate}
                resolution={DateResolution.Month}
              />
            </>
          )}
        />}

        {constructionStartedDate && showAddConstructionModalScreen === 2 && <Controller
          control={form.control}
          name="constructionCompletedDate"
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (
            <>
              <h3 className="ion-padding-vertically ion-padding-bottom ion-no-margin-top"><b>When did the construction complete?</b></h3>
              <WeaverDatePicker
                value={value}
                setValue={onChange}
                min={DateTime.fromISO(constructionStartedDate)}
                resolution={DateResolution.Month}
              />
              {errorMessage && <IonText color='danger'><p className={Styles.errorMessage}>{errorMessage}</p></IonText>}
              <IonItem lines='none' className={Styles.constructionOngoingCheckBox}>
                <IonCheckbox
                  slot="start"
                  color='primary'
                  checked={constructionIsStillOnGoing}
                  onIonChange={(e) => setConstructionIsStillOnGoing(e.detail.checked)} />
                <IonLabel color='primary' >Construction is still ongoing</IonLabel>
              </IonItem>
            </>
          )}
        />}

      </WeaverIonContent>
      <IonToolbar className={`${Styles.ionToolbar} ion-padding-horizontal ion-padding-bottom`} >
        { showAddConstructionModalScreen === 1
          ? <IonButton slot='end' disabled={!formState.isDirty} onClick={() => setShowAddConstructionModalScreen(2)}>Next</IonButton>
          : <>
            <IonButton slot='start' color='secondary' onClick={handleBackClick}>Back</IonButton>
            <SingleClickButton name='AddConstructionDatesModal' slot='end' onClick={() => onSubmitOfConstructionDatesIfFieldsAreValid()}>Submit</SingleClickButton>
          </>}
      </IonToolbar>
    </WeaverIonModal>
  )
}

