import { DateTime } from "luxon"
import { useRef } from "react"
import { useGraphQLDataSource } from "../../../api/graphql"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useGetProjectDetailsForLeadAcceptanceQuery } from "../../../graphql/generated"
import { getFlexibleProjectBudgetAsText } from "../common"
import { getWorkStartEstimateLabels } from "../workEstimate.i18n"

export const useFireAcceptLeadEvent = (leadId: string, paymentAccepted: boolean) => {

  const hasFiredAcceptLeadEvent = useRef(false)
  const myTeam = useMyIndividualActiveTeam()
  const triggerEvent_Project_Lead_Responded_Accepted = useAnalyticsEvent("Project_Lead_Responded_Accepted")
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const weaverFlags = useWeaverFlags()

  useGetProjectDetailsForLeadAcceptanceQuery(gqlDataSource, {
    id: leadId,
  }, {
    refetchOnWindowFocus: false,
    onSuccess: async (data) => {

      const { getProject } = data

      if (!paymentAccepted || hasFiredAcceptLeadEvent.current) {
        return
      }

      hasFiredAcceptLeadEvent.current = true

      await triggerEvent_Project_Lead_Responded_Accepted({
        projectId: getProject.id,
        titleOfProject: getProject.title,
        description: getProject.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(getProject, myTeam) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[getProject.workStartEstimate],
        /** @deprecated MW-2386-remove-tender-return-date -> Remove tenderReturnDate from form schema when archiving flag*/
        tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : DateTime.fromISO(getProject?.tenderReturnDate ?? '').toLocaleString(DateTime.DATE_MED),
        type: getProject.projectTypes,
        // a nonWeaverBuilder can not pass through here so we hardcode false
        isNonWeaverBuilder: false,
      })
    },
  })
}
