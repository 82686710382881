import { addIcons } from 'ionicons'
import * as icons from 'ionicons/icons'

/* Ionicons v5.5.4, Types */

export const allIonIcons = {
  accessibility: icons.accessibility,
  accessibilityOutline: icons.accessibilityOutline,
  accessibilitySharp: icons.accessibilitySharp,
  add: icons.add,
  addCircle: icons.addCircle,
  addCircleOutline: icons.addCircleOutline,
  addCircleSharp: icons.addCircleSharp,
  addOutline: icons.addOutline,
  addSharp: icons.addSharp,
  airplane: icons.airplane,
  airplaneOutline: icons.airplaneOutline,
  airplaneSharp: icons.airplaneSharp,
  alarm: icons.alarm,
  alarmOutline: icons.alarmOutline,
  alarmSharp: icons.alarmSharp,
  albums: icons.albums,
  albumsOutline: icons.albumsOutline,
  albumsSharp: icons.albumsSharp,
  alert: icons.alert,
  alertCircle: icons.alertCircle,
  alertCircleOutline: icons.alertCircleOutline,
  alertCircleSharp: icons.alertCircleSharp,
  alertOutline: icons.alertOutline,
  alertSharp: icons.alertSharp,
  americanFootball: icons.americanFootball,
  americanFootballOutline: icons.americanFootballOutline,
  americanFootballSharp: icons.americanFootballSharp,
  analytics: icons.analytics,
  analyticsOutline: icons.analyticsOutline,
  analyticsSharp: icons.analyticsSharp,
  aperture: icons.aperture,
  apertureOutline: icons.apertureOutline,
  apertureSharp: icons.apertureSharp,
  apps: icons.apps,
  appsOutline: icons.appsOutline,
  appsSharp: icons.appsSharp,
  archive: icons.archive,
  archiveOutline: icons.archiveOutline,
  archiveSharp: icons.archiveSharp,
  arrowBack: icons.arrowBack,
  arrowBackCircle: icons.arrowBackCircle,
  arrowBackCircleOutline: icons.arrowBackCircleOutline,
  arrowBackCircleSharp: icons.arrowBackCircleSharp,
  arrowBackOutline: icons.arrowBackOutline,
  arrowBackSharp: icons.arrowBackSharp,
  arrowDown: icons.arrowDown,
  arrowDownCircle: icons.arrowDownCircle,
  arrowDownCircleOutline: icons.arrowDownCircleOutline,
  arrowDownCircleSharp: icons.arrowDownCircleSharp,
  arrowDownOutline: icons.arrowDownOutline,
  arrowDownSharp: icons.arrowDownSharp,
  arrowForward: icons.arrowForward,
  arrowForwardCircle: icons.arrowForwardCircle,
  arrowForwardCircleOutline: icons.arrowForwardCircleOutline,
  arrowForwardCircleSharp: icons.arrowForwardCircleSharp,
  arrowForwardOutline: icons.arrowForwardOutline,
  arrowForwardSharp: icons.arrowForwardSharp,
  arrowRedo: icons.arrowRedo,
  arrowRedoCircle: icons.arrowRedoCircle,
  arrowRedoCircleOutline: icons.arrowRedoCircleOutline,
  arrowRedoCircleSharp: icons.arrowRedoCircleSharp,
  arrowRedoOutline: icons.arrowRedoOutline,
  arrowRedoSharp: icons.arrowRedoSharp,
  arrowUndo: icons.arrowUndo,
  arrowUndoCircle: icons.arrowUndoCircle,
  arrowUndoCircleOutline: icons.arrowUndoCircleOutline,
  arrowUndoCircleSharp: icons.arrowUndoCircleSharp,
  arrowUndoOutline: icons.arrowUndoOutline,
  arrowUndoSharp: icons.arrowUndoSharp,
  arrowUp: icons.arrowUp,
  arrowUpCircle: icons.arrowUpCircle,
  arrowUpCircleOutline: icons.arrowUpCircleOutline,
  arrowUpCircleSharp: icons.arrowUpCircleSharp,
  arrowUpOutline: icons.arrowUpOutline,
  arrowUpSharp: icons.arrowUpSharp,
  at: icons.at,
  atCircle: icons.atCircle,
  atCircleOutline: icons.atCircleOutline,
  atCircleSharp: icons.atCircleSharp,
  atOutline: icons.atOutline,
  atSharp: icons.atSharp,
  attach: icons.attach,
  attachOutline: icons.attachOutline,
  attachSharp: icons.attachSharp,
  backspace: icons.backspace,
  backspaceOutline: icons.backspaceOutline,
  backspaceSharp: icons.backspaceSharp,
  bag: icons.bag,
  bagAdd: icons.bagAdd,
  bagAddOutline: icons.bagAddOutline,
  bagAddSharp: icons.bagAddSharp,
  bagCheck: icons.bagCheck,
  bagCheckOutline: icons.bagCheckOutline,
  bagCheckSharp: icons.bagCheckSharp,
  bagHandle: icons.bagHandle,
  bagHandleOutline: icons.bagHandleOutline,
  bagHandleSharp: icons.bagHandleSharp,
  bagOutline: icons.bagOutline,
  bagRemove: icons.bagRemove,
  bagRemoveOutline: icons.bagRemoveOutline,
  bagRemoveSharp: icons.bagRemoveSharp,
  bagSharp: icons.bagSharp,
  balloon: icons.balloon,
  balloonOutline: icons.balloonOutline,
  balloonSharp: icons.balloonSharp,
  ban: icons.ban,
  banOutline: icons.banOutline,
  banSharp: icons.banSharp,
  bandage: icons.bandage,
  bandageOutline: icons.bandageOutline,
  bandageSharp: icons.bandageSharp,
  barChart: icons.barChart,
  barChartOutline: icons.barChartOutline,
  barChartSharp: icons.barChartSharp,
  barbell: icons.barbell,
  barbellOutline: icons.barbellOutline,
  barbellSharp: icons.barbellSharp,
  barcode: icons.barcode,
  barcodeOutline: icons.barcodeOutline,
  barcodeSharp: icons.barcodeSharp,
  baseball: icons.baseball,
  baseballOutline: icons.baseballOutline,
  baseballSharp: icons.baseballSharp,
  basket: icons.basket,
  basketOutline: icons.basketOutline,
  basketSharp: icons.basketSharp,
  basketball: icons.basketball,
  basketballOutline: icons.basketballOutline,
  basketballSharp: icons.basketballSharp,
  batteryCharging: icons.batteryCharging,
  batteryChargingOutline: icons.batteryChargingOutline,
  batteryChargingSharp: icons.batteryChargingSharp,
  batteryDead: icons.batteryDead,
  batteryDeadOutline: icons.batteryDeadOutline,
  batteryDeadSharp: icons.batteryDeadSharp,
  batteryFull: icons.batteryFull,
  batteryFullOutline: icons.batteryFullOutline,
  batteryFullSharp: icons.batteryFullSharp,
  batteryHalf: icons.batteryHalf,
  batteryHalfOutline: icons.batteryHalfOutline,
  batteryHalfSharp: icons.batteryHalfSharp,
  beaker: icons.beaker,
  beakerOutline: icons.beakerOutline,
  beakerSharp: icons.beakerSharp,
  bed: icons.bed,
  bedOutline: icons.bedOutline,
  bedSharp: icons.bedSharp,
  beer: icons.beer,
  beerOutline: icons.beerOutline,
  beerSharp: icons.beerSharp,
  bicycle: icons.bicycle,
  bicycleOutline: icons.bicycleOutline,
  bicycleSharp: icons.bicycleSharp,
  bluetooth: icons.bluetooth,
  bluetoothOutline: icons.bluetoothOutline,
  bluetoothSharp: icons.bluetoothSharp,
  boat: icons.boat,
  boatOutline: icons.boatOutline,
  boatSharp: icons.boatSharp,
  body: icons.body,
  bodyOutline: icons.bodyOutline,
  bodySharp: icons.bodySharp,
  bonfire: icons.bonfire,
  bonfireOutline: icons.bonfireOutline,
  bonfireSharp: icons.bonfireSharp,
  book: icons.book,
  bookOutline: icons.bookOutline,
  bookSharp: icons.bookSharp,
  bookmark: icons.bookmark,
  bookmarkOutline: icons.bookmarkOutline,
  bookmarkSharp: icons.bookmarkSharp,
  bookmarks: icons.bookmarks,
  bookmarksOutline: icons.bookmarksOutline,
  bookmarksSharp: icons.bookmarksSharp,
  bowlingBall: icons.bowlingBall,
  bowlingBallOutline: icons.bowlingBallOutline,
  bowlingBallSharp: icons.bowlingBallSharp,
  briefcase: icons.briefcase,
  briefcaseOutline: icons.briefcaseOutline,
  briefcaseSharp: icons.briefcaseSharp,
  browsers: icons.browsers,
  browsersOutline: icons.browsersOutline,
  browsersSharp: icons.browsersSharp,
  brush: icons.brush,
  brushOutline: icons.brushOutline,
  brushSharp: icons.brushSharp,
  bug: icons.bug,
  bugOutline: icons.bugOutline,
  bugSharp: icons.bugSharp,
  build: icons.build,
  buildOutline: icons.buildOutline,
  buildSharp: icons.buildSharp,
  bulb: icons.bulb,
  bulbOutline: icons.bulbOutline,
  bulbSharp: icons.bulbSharp,
  bus: icons.bus,
  busOutline: icons.busOutline,
  busSharp: icons.busSharp,
  business: icons.business,
  businessOutline: icons.businessOutline,
  businessSharp: icons.businessSharp,
  cafe: icons.cafe,
  cafeOutline: icons.cafeOutline,
  cafeSharp: icons.cafeSharp,
  calculator: icons.calculator,
  calculatorOutline: icons.calculatorOutline,
  calculatorSharp: icons.calculatorSharp,
  calendar: icons.calendar,
  calendarClear: icons.calendarClear,
  calendarClearOutline: icons.calendarClearOutline,
  calendarClearSharp: icons.calendarClearSharp,
  calendarNumber: icons.calendarNumber,
  calendarNumberOutline: icons.calendarNumberOutline,
  calendarNumberSharp: icons.calendarNumberSharp,
  calendarOutline: icons.calendarOutline,
  calendarSharp: icons.calendarSharp,
  call: icons.call,
  callOutline: icons.callOutline,
  callSharp: icons.callSharp,
  camera: icons.camera,
  cameraOutline: icons.cameraOutline,
  cameraReverse: icons.cameraReverse,
  cameraReverseOutline: icons.cameraReverseOutline,
  cameraReverseSharp: icons.cameraReverseSharp,
  cameraSharp: icons.cameraSharp,
  car: icons.car,
  carOutline: icons.carOutline,
  carSharp: icons.carSharp,
  carSport: icons.carSport,
  carSportOutline: icons.carSportOutline,
  carSportSharp: icons.carSportSharp,
  card: icons.card,
  cardOutline: icons.cardOutline,
  cardSharp: icons.cardSharp,
  caretBack: icons.caretBack,
  caretBackCircle: icons.caretBackCircle,
  caretBackCircleOutline: icons.caretBackCircleOutline,
  caretBackCircleSharp: icons.caretBackCircleSharp,
  caretBackOutline: icons.caretBackOutline,
  caretBackSharp: icons.caretBackSharp,
  caretDown: icons.caretDown,
  caretDownCircle: icons.caretDownCircle,
  caretDownCircleOutline: icons.caretDownCircleOutline,
  caretDownCircleSharp: icons.caretDownCircleSharp,
  caretDownOutline: icons.caretDownOutline,
  caretDownSharp: icons.caretDownSharp,
  caretForward: icons.caretForward,
  caretForwardCircle: icons.caretForwardCircle,
  caretForwardCircleOutline: icons.caretForwardCircleOutline,
  caretForwardCircleSharp: icons.caretForwardCircleSharp,
  caretForwardOutline: icons.caretForwardOutline,
  caretForwardSharp: icons.caretForwardSharp,
  caretUp: icons.caretUp,
  caretUpCircle: icons.caretUpCircle,
  caretUpCircleOutline: icons.caretUpCircleOutline,
  caretUpCircleSharp: icons.caretUpCircleSharp,
  caretUpOutline: icons.caretUpOutline,
  caretUpSharp: icons.caretUpSharp,
  cart: icons.cart,
  cartOutline: icons.cartOutline,
  cartSharp: icons.cartSharp,
  cash: icons.cash,
  cashOutline: icons.cashOutline,
  cashSharp: icons.cashSharp,
  cellular: icons.cellular,
  cellularOutline: icons.cellularOutline,
  cellularSharp: icons.cellularSharp,
  chatbox: icons.chatbox,
  chatboxEllipses: icons.chatboxEllipses,
  chatboxEllipsesOutline: icons.chatboxEllipsesOutline,
  chatboxEllipsesSharp: icons.chatboxEllipsesSharp,
  chatboxOutline: icons.chatboxOutline,
  chatboxSharp: icons.chatboxSharp,
  chatbubble: icons.chatbubble,
  chatbubbleEllipses: icons.chatbubbleEllipses,
  chatbubbleEllipsesOutline: icons.chatbubbleEllipsesOutline,
  chatbubbleEllipsesSharp: icons.chatbubbleEllipsesSharp,
  chatbubbleOutline: icons.chatbubbleOutline,
  chatbubbleSharp: icons.chatbubbleSharp,
  chatbubbles: icons.chatbubbles,
  chatbubblesOutline: icons.chatbubblesOutline,
  chatbubblesSharp: icons.chatbubblesSharp,
  checkbox: icons.checkbox,
  checkboxOutline: icons.checkboxOutline,
  checkboxSharp: icons.checkboxSharp,
  checkmark: icons.checkmark,
  checkmarkCircle: icons.checkmarkCircle,
  checkmarkCircleOutline: icons.checkmarkCircleOutline,
  checkmarkCircleSharp: icons.checkmarkCircleSharp,
  checkmarkDone: icons.checkmarkDone,
  checkmarkDoneCircle: icons.checkmarkDoneCircle,
  checkmarkDoneCircleOutline: icons.checkmarkDoneCircleOutline,
  checkmarkDoneCircleSharp: icons.checkmarkDoneCircleSharp,
  checkmarkDoneOutline: icons.checkmarkDoneOutline,
  checkmarkDoneSharp: icons.checkmarkDoneSharp,
  checkmarkOutline: icons.checkmarkOutline,
  checkmarkSharp: icons.checkmarkSharp,
  chevronBack: icons.chevronBack,
  chevronBackCircle: icons.chevronBackCircle,
  chevronBackCircleOutline: icons.chevronBackCircleOutline,
  chevronBackCircleSharp: icons.chevronBackCircleSharp,
  chevronBackOutline: icons.chevronBackOutline,
  chevronBackSharp: icons.chevronBackSharp,
  chevronDown: icons.chevronDown,
  chevronDownCircle: icons.chevronDownCircle,
  chevronDownCircleOutline: icons.chevronDownCircleOutline,
  chevronDownCircleSharp: icons.chevronDownCircleSharp,
  chevronDownOutline: icons.chevronDownOutline,
  chevronDownSharp: icons.chevronDownSharp,
  chevronForward: icons.chevronForward,
  chevronForwardCircle: icons.chevronForwardCircle,
  chevronForwardCircleOutline: icons.chevronForwardCircleOutline,
  chevronForwardCircleSharp: icons.chevronForwardCircleSharp,
  chevronForwardOutline: icons.chevronForwardOutline,
  chevronForwardSharp: icons.chevronForwardSharp,
  chevronUp: icons.chevronUp,
  chevronUpCircle: icons.chevronUpCircle,
  chevronUpCircleOutline: icons.chevronUpCircleOutline,
  chevronUpCircleSharp: icons.chevronUpCircleSharp,
  chevronUpOutline: icons.chevronUpOutline,
  chevronUpSharp: icons.chevronUpSharp,
  clipboard: icons.clipboard,
  clipboardOutline: icons.clipboardOutline,
  clipboardSharp: icons.clipboardSharp,
  close: icons.close,
  closeCircle: icons.closeCircle,
  closeCircleOutline: icons.closeCircleOutline,
  closeCircleSharp: icons.closeCircleSharp,
  closeOutline: icons.closeOutline,
  closeSharp: icons.closeSharp,
  cloud: icons.cloud,
  cloudCircle: icons.cloudCircle,
  cloudCircleOutline: icons.cloudCircleOutline,
  cloudCircleSharp: icons.cloudCircleSharp,
  cloudDone: icons.cloudDone,
  cloudDoneOutline: icons.cloudDoneOutline,
  cloudDoneSharp: icons.cloudDoneSharp,
  cloudDownload: icons.cloudDownload,
  cloudDownloadOutline: icons.cloudDownloadOutline,
  cloudDownloadSharp: icons.cloudDownloadSharp,
  cloudOffline: icons.cloudOffline,
  cloudOfflineOutline: icons.cloudOfflineOutline,
  cloudOfflineSharp: icons.cloudOfflineSharp,
  cloudOutline: icons.cloudOutline,
  cloudSharp: icons.cloudSharp,
  cloudUpload: icons.cloudUpload,
  cloudUploadOutline: icons.cloudUploadOutline,
  cloudUploadSharp: icons.cloudUploadSharp,
  cloudy: icons.cloudy,
  cloudyNight: icons.cloudyNight,
  cloudyNightOutline: icons.cloudyNightOutline,
  cloudyNightSharp: icons.cloudyNightSharp,
  cloudyOutline: icons.cloudyOutline,
  cloudySharp: icons.cloudySharp,
  code: icons.code,
  codeDownload: icons.codeDownload,
  codeDownloadOutline: icons.codeDownloadOutline,
  codeDownloadSharp: icons.codeDownloadSharp,
  codeOutline: icons.codeOutline,
  codeSharp: icons.codeSharp,
  codeSlash: icons.codeSlash,
  codeSlashOutline: icons.codeSlashOutline,
  codeSlashSharp: icons.codeSlashSharp,
  codeWorking: icons.codeWorking,
  codeWorkingOutline: icons.codeWorkingOutline,
  codeWorkingSharp: icons.codeWorkingSharp,
  cog: icons.cog,
  cogOutline: icons.cogOutline,
  cogSharp: icons.cogSharp,
  colorFill: icons.colorFill,
  colorFillOutline: icons.colorFillOutline,
  colorFillSharp: icons.colorFillSharp,
  colorFilter: icons.colorFilter,
  colorFilterOutline: icons.colorFilterOutline,
  colorFilterSharp: icons.colorFilterSharp,
  colorPalette: icons.colorPalette,
  colorPaletteOutline: icons.colorPaletteOutline,
  colorPaletteSharp: icons.colorPaletteSharp,
  colorWand: icons.colorWand,
  colorWandOutline: icons.colorWandOutline,
  colorWandSharp: icons.colorWandSharp,
  compass: icons.compass,
  compassOutline: icons.compassOutline,
  compassSharp: icons.compassSharp,
  construct: icons.construct,
  constructOutline: icons.constructOutline,
  constructSharp: icons.constructSharp,
  contract: icons.contract,
  contractOutline: icons.contractOutline,
  contractSharp: icons.contractSharp,
  contrast: icons.contrast,
  contrastOutline: icons.contrastOutline,
  contrastSharp: icons.contrastSharp,
  copy: icons.copy,
  copyOutline: icons.copyOutline,
  copySharp: icons.copySharp,
  create: icons.create,
  createOutline: icons.createOutline,
  createSharp: icons.createSharp,
  crop: icons.crop,
  cropOutline: icons.cropOutline,
  cropSharp: icons.cropSharp,
  cube: icons.cube,
  cubeOutline: icons.cubeOutline,
  cubeSharp: icons.cubeSharp,
  cut: icons.cut,
  cutOutline: icons.cutOutline,
  cutSharp: icons.cutSharp,
  desktop: icons.desktop,
  desktopOutline: icons.desktopOutline,
  desktopSharp: icons.desktopSharp,
  diamond: icons.diamond,
  diamondOutline: icons.diamondOutline,
  diamondSharp: icons.diamondSharp,
  dice: icons.dice,
  diceOutline: icons.diceOutline,
  diceSharp: icons.diceSharp,
  disc: icons.disc,
  discOutline: icons.discOutline,
  discSharp: icons.discSharp,
  document: icons.document,
  documentAttach: icons.documentAttach,
  documentAttachOutline: icons.documentAttachOutline,
  documentAttachSharp: icons.documentAttachSharp,
  documentLock: icons.documentLock,
  documentLockOutline: icons.documentLockOutline,
  documentLockSharp: icons.documentLockSharp,
  documentOutline: icons.documentOutline,
  documentSharp: icons.documentSharp,
  documentText: icons.documentText,
  documentTextOutline: icons.documentTextOutline,
  documentTextSharp: icons.documentTextSharp,
  documents: icons.documents,
  documentsOutline: icons.documentsOutline,
  documentsSharp: icons.documentsSharp,
  download: icons.download,
  downloadOutline: icons.downloadOutline,
  downloadSharp: icons.downloadSharp,
  duplicate: icons.duplicate,
  duplicateOutline: icons.duplicateOutline,
  duplicateSharp: icons.duplicateSharp,
  ear: icons.ear,
  earOutline: icons.earOutline,
  earSharp: icons.earSharp,
  earth: icons.earth,
  earthOutline: icons.earthOutline,
  earthSharp: icons.earthSharp,
  easel: icons.easel,
  easelOutline: icons.easelOutline,
  easelSharp: icons.easelSharp,
  egg: icons.egg,
  eggOutline: icons.eggOutline,
  eggSharp: icons.eggSharp,
  ellipse: icons.ellipse,
  ellipseOutline: icons.ellipseOutline,
  ellipseSharp: icons.ellipseSharp,
  ellipsisHorizontal: icons.ellipsisHorizontal,
  ellipsisHorizontalCircle: icons.ellipsisHorizontalCircle,
  ellipsisHorizontalCircleOutline: icons.ellipsisHorizontalCircleOutline,
  ellipsisHorizontalCircleSharp: icons.ellipsisHorizontalCircleSharp,
  ellipsisHorizontalOutline: icons.ellipsisHorizontalOutline,
  ellipsisHorizontalSharp: icons.ellipsisHorizontalSharp,
  ellipsisVertical: icons.ellipsisVertical,
  ellipsisVerticalCircle: icons.ellipsisVerticalCircle,
  ellipsisVerticalCircleOutline: icons.ellipsisVerticalCircleOutline,
  ellipsisVerticalCircleSharp: icons.ellipsisVerticalCircleSharp,
  ellipsisVerticalOutline: icons.ellipsisVerticalOutline,
  ellipsisVerticalSharp: icons.ellipsisVerticalSharp,
  enter: icons.enter,
  enterOutline: icons.enterOutline,
  enterSharp: icons.enterSharp,
  exit: icons.exit,
  exitOutline: icons.exitOutline,
  exitSharp: icons.exitSharp,
  expand: icons.expand,
  expandOutline: icons.expandOutline,
  expandSharp: icons.expandSharp,
  extensionPuzzle: icons.extensionPuzzle,
  extensionPuzzleOutline: icons.extensionPuzzleOutline,
  extensionPuzzleSharp: icons.extensionPuzzleSharp,
  eye: icons.eye,
  eyeOff: icons.eyeOff,
  eyeOffOutline: icons.eyeOffOutline,
  eyeOffSharp: icons.eyeOffSharp,
  eyeOutline: icons.eyeOutline,
  eyeSharp: icons.eyeSharp,
  eyedrop: icons.eyedrop,
  eyedropOutline: icons.eyedropOutline,
  eyedropSharp: icons.eyedropSharp,
  fastFood: icons.fastFood,
  fastFoodOutline: icons.fastFoodOutline,
  fastFoodSharp: icons.fastFoodSharp,
  female: icons.female,
  femaleOutline: icons.femaleOutline,
  femaleSharp: icons.femaleSharp,
  fileTray: icons.fileTray,
  fileTrayFull: icons.fileTrayFull,
  fileTrayFullOutline: icons.fileTrayFullOutline,
  fileTrayFullSharp: icons.fileTrayFullSharp,
  fileTrayOutline: icons.fileTrayOutline,
  fileTraySharp: icons.fileTraySharp,
  fileTrayStacked: icons.fileTrayStacked,
  fileTrayStackedOutline: icons.fileTrayStackedOutline,
  fileTrayStackedSharp: icons.fileTrayStackedSharp,
  film: icons.film,
  filmOutline: icons.filmOutline,
  filmSharp: icons.filmSharp,
  filter: icons.filter,
  filterCircle: icons.filterCircle,
  filterCircleOutline: icons.filterCircleOutline,
  filterCircleSharp: icons.filterCircleSharp,
  filterOutline: icons.filterOutline,
  filterSharp: icons.filterSharp,
  fingerPrint: icons.fingerPrint,
  fingerPrintOutline: icons.fingerPrintOutline,
  fingerPrintSharp: icons.fingerPrintSharp,
  fish: icons.fish,
  fishOutline: icons.fishOutline,
  fishSharp: icons.fishSharp,
  fitness: icons.fitness,
  fitnessOutline: icons.fitnessOutline,
  fitnessSharp: icons.fitnessSharp,
  flag: icons.flag,
  flagOutline: icons.flagOutline,
  flagSharp: icons.flagSharp,
  flame: icons.flame,
  flameOutline: icons.flameOutline,
  flameSharp: icons.flameSharp,
  flash: icons.flash,
  flashOff: icons.flashOff,
  flashOffOutline: icons.flashOffOutline,
  flashOffSharp: icons.flashOffSharp,
  flashOutline: icons.flashOutline,
  flashSharp: icons.flashSharp,
  flashlight: icons.flashlight,
  flashlightOutline: icons.flashlightOutline,
  flashlightSharp: icons.flashlightSharp,
  flask: icons.flask,
  flaskOutline: icons.flaskOutline,
  flaskSharp: icons.flaskSharp,
  flower: icons.flower,
  flowerOutline: icons.flowerOutline,
  flowerSharp: icons.flowerSharp,
  folder: icons.folder,
  folderOpen: icons.folderOpen,
  folderOpenOutline: icons.folderOpenOutline,
  folderOpenSharp: icons.folderOpenSharp,
  folderOutline: icons.folderOutline,
  folderSharp: icons.folderSharp,
  football: icons.football,
  footballOutline: icons.footballOutline,
  footballSharp: icons.footballSharp,
  footsteps: icons.footsteps,
  footstepsOutline: icons.footstepsOutline,
  footstepsSharp: icons.footstepsSharp,
  funnel: icons.funnel,
  funnelOutline: icons.funnelOutline,
  funnelSharp: icons.funnelSharp,
  gameController: icons.gameController,
  gameControllerOutline: icons.gameControllerOutline,
  gameControllerSharp: icons.gameControllerSharp,
  gift: icons.gift,
  giftOutline: icons.giftOutline,
  giftSharp: icons.giftSharp,
  gitBranch: icons.gitBranch,
  gitBranchOutline: icons.gitBranchOutline,
  gitBranchSharp: icons.gitBranchSharp,
  gitCommit: icons.gitCommit,
  gitCommitOutline: icons.gitCommitOutline,
  gitCommitSharp: icons.gitCommitSharp,
  gitCompare: icons.gitCompare,
  gitCompareOutline: icons.gitCompareOutline,
  gitCompareSharp: icons.gitCompareSharp,
  gitMerge: icons.gitMerge,
  gitMergeOutline: icons.gitMergeOutline,
  gitMergeSharp: icons.gitMergeSharp,
  gitNetwork: icons.gitNetwork,
  gitNetworkOutline: icons.gitNetworkOutline,
  gitNetworkSharp: icons.gitNetworkSharp,
  gitPullRequest: icons.gitPullRequest,
  gitPullRequestOutline: icons.gitPullRequestOutline,
  gitPullRequestSharp: icons.gitPullRequestSharp,
  glasses: icons.glasses,
  glassesOutline: icons.glassesOutline,
  glassesSharp: icons.glassesSharp,
  globe: icons.globe,
  globeOutline: icons.globeOutline,
  globeSharp: icons.globeSharp,
  golf: icons.golf,
  golfOutline: icons.golfOutline,
  golfSharp: icons.golfSharp,
  grid: icons.grid,
  gridOutline: icons.gridOutline,
  gridSharp: icons.gridSharp,
  hammer: icons.hammer,
  hammerOutline: icons.hammerOutline,
  hammerSharp: icons.hammerSharp,
  handLeft: icons.handLeft,
  handLeftOutline: icons.handLeftOutline,
  handLeftSharp: icons.handLeftSharp,
  handRight: icons.handRight,
  handRightOutline: icons.handRightOutline,
  handRightSharp: icons.handRightSharp,
  happy: icons.happy,
  happyOutline: icons.happyOutline,
  happySharp: icons.happySharp,
  hardwareChip: icons.hardwareChip,
  hardwareChipOutline: icons.hardwareChipOutline,
  hardwareChipSharp: icons.hardwareChipSharp,
  headset: icons.headset,
  headsetOutline: icons.headsetOutline,
  headsetSharp: icons.headsetSharp,
  heart: icons.heart,
  heartCircle: icons.heartCircle,
  heartCircleOutline: icons.heartCircleOutline,
  heartCircleSharp: icons.heartCircleSharp,
  heartDislike: icons.heartDislike,
  heartDislikeCircle: icons.heartDislikeCircle,
  heartDislikeCircleOutline: icons.heartDislikeCircleOutline,
  heartDislikeCircleSharp: icons.heartDislikeCircleSharp,
  heartDislikeOutline: icons.heartDislikeOutline,
  heartDislikeSharp: icons.heartDislikeSharp,
  heartHalf: icons.heartHalf,
  heartHalfOutline: icons.heartHalfOutline,
  heartHalfSharp: icons.heartHalfSharp,
  heartOutline: icons.heartOutline,
  heartSharp: icons.heartSharp,
  help: icons.help,
  helpBuoy: icons.helpBuoy,
  helpBuoyOutline: icons.helpBuoyOutline,
  helpBuoySharp: icons.helpBuoySharp,
  helpCircle: icons.helpCircle,
  helpCircleOutline: icons.helpCircleOutline,
  helpCircleSharp: icons.helpCircleSharp,
  helpOutline: icons.helpOutline,
  helpSharp: icons.helpSharp,
  home: icons.home,
  homeOutline: icons.homeOutline,
  homeSharp: icons.homeSharp,
  hourglass: icons.hourglass,
  hourglassOutline: icons.hourglassOutline,
  hourglassSharp: icons.hourglassSharp,
  iceCream: icons.iceCream,
  iceCreamOutline: icons.iceCreamOutline,
  iceCreamSharp: icons.iceCreamSharp,
  idCard: icons.idCard,
  idCardOutline: icons.idCardOutline,
  idCardSharp: icons.idCardSharp,
  image: icons.image,
  imageOutline: icons.imageOutline,
  imageSharp: icons.imageSharp,
  images: icons.images,
  imagesOutline: icons.imagesOutline,
  imagesSharp: icons.imagesSharp,
  infinite: icons.infinite,
  infiniteOutline: icons.infiniteOutline,
  infiniteSharp: icons.infiniteSharp,
  information: icons.information,
  informationCircle: icons.informationCircle,
  informationCircleOutline: icons.informationCircleOutline,
  informationCircleSharp: icons.informationCircleSharp,
  informationOutline: icons.informationOutline,
  informationSharp: icons.informationSharp,
  invertMode: icons.invertMode,
  invertModeOutline: icons.invertModeOutline,
  invertModeSharp: icons.invertModeSharp,
  journal: icons.journal,
  journalOutline: icons.journalOutline,
  journalSharp: icons.journalSharp,
  key: icons.key,
  keyOutline: icons.keyOutline,
  keySharp: icons.keySharp,
  keypad: icons.keypad,
  keypadOutline: icons.keypadOutline,
  keypadSharp: icons.keypadSharp,
  language: icons.language,
  languageOutline: icons.languageOutline,
  languageSharp: icons.languageSharp,
  laptop: icons.laptop,
  laptopOutline: icons.laptopOutline,
  laptopSharp: icons.laptopSharp,
  layers: icons.layers,
  layersOutline: icons.layersOutline,
  layersSharp: icons.layersSharp,
  leaf: icons.leaf,
  leafOutline: icons.leafOutline,
  leafSharp: icons.leafSharp,
  library: icons.library,
  libraryOutline: icons.libraryOutline,
  librarySharp: icons.librarySharp,
  link: icons.link,
  linkOutline: icons.linkOutline,
  linkSharp: icons.linkSharp,
  list: icons.list,
  listCircle: icons.listCircle,
  listCircleOutline: icons.listCircleOutline,
  listCircleSharp: icons.listCircleSharp,
  listOutline: icons.listOutline,
  listSharp: icons.listSharp,
  locate: icons.locate,
  locateOutline: icons.locateOutline,
  locateSharp: icons.locateSharp,
  location: icons.location,
  locationOutline: icons.locationOutline,
  locationSharp: icons.locationSharp,
  lockClosed: icons.lockClosed,
  lockClosedOutline: icons.lockClosedOutline,
  lockClosedSharp: icons.lockClosedSharp,
  lockOpen: icons.lockOpen,
  lockOpenOutline: icons.lockOpenOutline,
  lockOpenSharp: icons.lockOpenSharp,
  logIn: icons.logIn,
  logInOutline: icons.logInOutline,
  logInSharp: icons.logInSharp,
  logOut: icons.logOut,
  logOutOutline: icons.logOutOutline,
  logOutSharp: icons.logOutSharp,
  logoAlipay: icons.logoAlipay,
  logoAmazon: icons.logoAmazon,
  logoAmplify: icons.logoAmplify,
  logoAndroid: icons.logoAndroid,
  logoAngular: icons.logoAngular,
  logoApple: icons.logoApple,
  logoAppleAppstore: icons.logoAppleAppstore,
  logoAppleAr: icons.logoAppleAr,
  logoBehance: icons.logoBehance,
  logoBitbucket: icons.logoBitbucket,
  logoBitcoin: icons.logoBitcoin,
  logoBuffer: icons.logoBuffer,
  logoCapacitor: icons.logoCapacitor,
  logoChrome: icons.logoChrome,
  logoClosedCaptioning: icons.logoClosedCaptioning,
  logoCodepen: icons.logoCodepen,
  logoCss3: icons.logoCss3,
  logoDesignernews: icons.logoDesignernews,
  logoDeviantart: icons.logoDeviantart,
  logoDiscord: icons.logoDiscord,
  logoDocker: icons.logoDocker,
  logoDribbble: icons.logoDribbble,
  logoDropbox: icons.logoDropbox,
  logoEdge: icons.logoEdge,
  logoElectron: icons.logoElectron,
  logoEuro: icons.logoEuro,
  logoFacebook: icons.logoFacebook,
  logoFigma: icons.logoFigma,
  logoFirebase: icons.logoFirebase,
  logoFirefox: icons.logoFirefox,
  logoFlickr: icons.logoFlickr,
  logoFoursquare: icons.logoFoursquare,
  logoGithub: icons.logoGithub,
  logoGitlab: icons.logoGitlab,
  logoGoogle: icons.logoGoogle,
  logoGooglePlaystore: icons.logoGooglePlaystore,
  logoHackernews: icons.logoHackernews,
  logoHtml5: icons.logoHtml5,
  logoInstagram: icons.logoInstagram,
  logoIonic: icons.logoIonic,
  logoIonitron: icons.logoIonitron,
  logoJavascript: icons.logoJavascript,
  logoLaravel: icons.logoLaravel,
  logoLinkedin: icons.logoLinkedin,
  logoMarkdown: icons.logoMarkdown,
  logoMastodon: icons.logoMastodon,
  logoMedium: icons.logoMedium,
  logoMicrosoft: icons.logoMicrosoft,
  logoNoSmoking: icons.logoNoSmoking,
  logoNodejs: icons.logoNodejs,
  logoNpm: icons.logoNpm,
  logoOctocat: icons.logoOctocat,
  logoPaypal: icons.logoPaypal,
  logoPinterest: icons.logoPinterest,
  logoPlaystation: icons.logoPlaystation,
  logoPwa: icons.logoPwa,
  logoPython: icons.logoPython,
  logoReact: icons.logoReact,
  logoReddit: icons.logoReddit,
  logoRss: icons.logoRss,
  logoSass: icons.logoSass,
  logoSkype: icons.logoSkype,
  logoSlack: icons.logoSlack,
  logoSnapchat: icons.logoSnapchat,
  logoSoundcloud: icons.logoSoundcloud,
  logoStackoverflow: icons.logoStackoverflow,
  logoSteam: icons.logoSteam,
  logoStencil: icons.logoStencil,
  logoTableau: icons.logoTableau,
  logoTiktok: icons.logoTiktok,
  logoTumblr: icons.logoTumblr,
  logoTux: icons.logoTux,
  logoTwitch: icons.logoTwitch,
  logoTwitter: icons.logoTwitter,
  logoUsd: icons.logoUsd,
  logoVenmo: icons.logoVenmo,
  logoVercel: icons.logoVercel,
  logoVimeo: icons.logoVimeo,
  logoVk: icons.logoVk,
  logoVue: icons.logoVue,
  logoWebComponent: icons.logoWebComponent,
  logoWechat: icons.logoWechat,
  logoWhatsapp: icons.logoWhatsapp,
  logoWindows: icons.logoWindows,
  logoWordpress: icons.logoWordpress,
  logoXbox: icons.logoXbox,
  logoXing: icons.logoXing,
  logoYahoo: icons.logoYahoo,
  logoYen: icons.logoYen,
  logoYoutube: icons.logoYoutube,
  magnet: icons.magnet,
  magnetOutline: icons.magnetOutline,
  magnetSharp: icons.magnetSharp,
  mail: icons.mail,
  mailOpen: icons.mailOpen,
  mailOpenOutline: icons.mailOpenOutline,
  mailOpenSharp: icons.mailOpenSharp,
  mailOutline: icons.mailOutline,
  mailSharp: icons.mailSharp,
  mailUnread: icons.mailUnread,
  mailUnreadOutline: icons.mailUnreadOutline,
  mailUnreadSharp: icons.mailUnreadSharp,
  male: icons.male,
  maleFemale: icons.maleFemale,
  maleFemaleOutline: icons.maleFemaleOutline,
  maleFemaleSharp: icons.maleFemaleSharp,
  maleOutline: icons.maleOutline,
  maleSharp: icons.maleSharp,
  man: icons.man,
  manOutline: icons.manOutline,
  manSharp: icons.manSharp,
  map: icons.map,
  mapOutline: icons.mapOutline,
  mapSharp: icons.mapSharp,
  medal: icons.medal,
  medalOutline: icons.medalOutline,
  medalSharp: icons.medalSharp,
  medical: icons.medical,
  medicalOutline: icons.medicalOutline,
  medicalSharp: icons.medicalSharp,
  medkit: icons.medkit,
  medkitOutline: icons.medkitOutline,
  medkitSharp: icons.medkitSharp,
  megaphone: icons.megaphone,
  megaphoneOutline: icons.megaphoneOutline,
  megaphoneSharp: icons.megaphoneSharp,
  menu: icons.menu,
  menuOutline: icons.menuOutline,
  menuSharp: icons.menuSharp,
  mic: icons.mic,
  micCircle: icons.micCircle,
  micCircleOutline: icons.micCircleOutline,
  micCircleSharp: icons.micCircleSharp,
  micOff: icons.micOff,
  micOffCircle: icons.micOffCircle,
  micOffCircleOutline: icons.micOffCircleOutline,
  micOffCircleSharp: icons.micOffCircleSharp,
  micOffOutline: icons.micOffOutline,
  micOffSharp: icons.micOffSharp,
  micOutline: icons.micOutline,
  micSharp: icons.micSharp,
  moon: icons.moon,
  moonOutline: icons.moonOutline,
  moonSharp: icons.moonSharp,
  move: icons.move,
  moveOutline: icons.moveOutline,
  moveSharp: icons.moveSharp,
  musicalNote: icons.musicalNote,
  musicalNoteOutline: icons.musicalNoteOutline,
  musicalNoteSharp: icons.musicalNoteSharp,
  musicalNotes: icons.musicalNotes,
  musicalNotesOutline: icons.musicalNotesOutline,
  musicalNotesSharp: icons.musicalNotesSharp,
  navigate: icons.navigate,
  navigateCircle: icons.navigateCircle,
  navigateCircleOutline: icons.navigateCircleOutline,
  navigateCircleSharp: icons.navigateCircleSharp,
  navigateOutline: icons.navigateOutline,
  navigateSharp: icons.navigateSharp,
  newspaper: icons.newspaper,
  newspaperOutline: icons.newspaperOutline,
  newspaperSharp: icons.newspaperSharp,
  notifications: icons.notifications,
  notificationsCircle: icons.notificationsCircle,
  notificationsCircleOutline: icons.notificationsCircleOutline,
  notificationsCircleSharp: icons.notificationsCircleSharp,
  notificationsOff: icons.notificationsOff,
  notificationsOffCircle: icons.notificationsOffCircle,
  notificationsOffCircleOutline: icons.notificationsOffCircleOutline,
  notificationsOffCircleSharp: icons.notificationsOffCircleSharp,
  notificationsOffOutline: icons.notificationsOffOutline,
  notificationsOffSharp: icons.notificationsOffSharp,
  notificationsOutline: icons.notificationsOutline,
  notificationsSharp: icons.notificationsSharp,
  nuclear: icons.nuclear,
  nuclearOutline: icons.nuclearOutline,
  nuclearSharp: icons.nuclearSharp,
  nutrition: icons.nutrition,
  nutritionOutline: icons.nutritionOutline,
  nutritionSharp: icons.nutritionSharp,
  open: icons.open,
  openOutline: icons.openOutline,
  openSharp: icons.openSharp,
  options: icons.options,
  optionsOutline: icons.optionsOutline,
  optionsSharp: icons.optionsSharp,
  paperPlane: icons.paperPlane,
  paperPlaneOutline: icons.paperPlaneOutline,
  paperPlaneSharp: icons.paperPlaneSharp,
  partlySunny: icons.partlySunny,
  partlySunnyOutline: icons.partlySunnyOutline,
  partlySunnySharp: icons.partlySunnySharp,
  pause: icons.pause,
  pauseCircle: icons.pauseCircle,
  pauseCircleOutline: icons.pauseCircleOutline,
  pauseCircleSharp: icons.pauseCircleSharp,
  pauseOutline: icons.pauseOutline,
  pauseSharp: icons.pauseSharp,
  paw: icons.paw,
  pawOutline: icons.pawOutline,
  pawSharp: icons.pawSharp,
  pencil: icons.pencil,
  pencilOutline: icons.pencilOutline,
  pencilSharp: icons.pencilSharp,
  people: icons.people,
  peopleCircle: icons.peopleCircle,
  peopleCircleOutline: icons.peopleCircleOutline,
  peopleCircleSharp: icons.peopleCircleSharp,
  peopleOutline: icons.peopleOutline,
  peopleSharp: icons.peopleSharp,
  person: icons.person,
  personAdd: icons.personAdd,
  personAddOutline: icons.personAddOutline,
  personAddSharp: icons.personAddSharp,
  personCircle: icons.personCircle,
  personCircleOutline: icons.personCircleOutline,
  personCircleSharp: icons.personCircleSharp,
  personOutline: icons.personOutline,
  personRemove: icons.personRemove,
  personRemoveOutline: icons.personRemoveOutline,
  personRemoveSharp: icons.personRemoveSharp,
  personSharp: icons.personSharp,
  phoneLandscape: icons.phoneLandscape,
  phoneLandscapeOutline: icons.phoneLandscapeOutline,
  phoneLandscapeSharp: icons.phoneLandscapeSharp,
  phonePortrait: icons.phonePortrait,
  phonePortraitOutline: icons.phonePortraitOutline,
  phonePortraitSharp: icons.phonePortraitSharp,
  pieChart: icons.pieChart,
  pieChartOutline: icons.pieChartOutline,
  pieChartSharp: icons.pieChartSharp,
  pin: icons.pin,
  pinOutline: icons.pinOutline,
  pinSharp: icons.pinSharp,
  pint: icons.pint,
  pintOutline: icons.pintOutline,
  pintSharp: icons.pintSharp,
  pizza: icons.pizza,
  pizzaOutline: icons.pizzaOutline,
  pizzaSharp: icons.pizzaSharp,
  planet: icons.planet,
  planetOutline: icons.planetOutline,
  planetSharp: icons.planetSharp,
  play: icons.play,
  playBack: icons.playBack,
  playBackCircle: icons.playBackCircle,
  playBackCircleOutline: icons.playBackCircleOutline,
  playBackCircleSharp: icons.playBackCircleSharp,
  playBackOutline: icons.playBackOutline,
  playBackSharp: icons.playBackSharp,
  playCircle: icons.playCircle,
  playCircleOutline: icons.playCircleOutline,
  playCircleSharp: icons.playCircleSharp,
  playForward: icons.playForward,
  playForwardCircle: icons.playForwardCircle,
  playForwardCircleOutline: icons.playForwardCircleOutline,
  playForwardCircleSharp: icons.playForwardCircleSharp,
  playForwardOutline: icons.playForwardOutline,
  playForwardSharp: icons.playForwardSharp,
  playOutline: icons.playOutline,
  playSharp: icons.playSharp,
  playSkipBack: icons.playSkipBack,
  playSkipBackCircle: icons.playSkipBackCircle,
  playSkipBackCircleOutline: icons.playSkipBackCircleOutline,
  playSkipBackCircleSharp: icons.playSkipBackCircleSharp,
  playSkipBackOutline: icons.playSkipBackOutline,
  playSkipBackSharp: icons.playSkipBackSharp,
  playSkipForward: icons.playSkipForward,
  playSkipForwardCircle: icons.playSkipForwardCircle,
  playSkipForwardCircleOutline: icons.playSkipForwardCircleOutline,
  playSkipForwardCircleSharp: icons.playSkipForwardCircleSharp,
  playSkipForwardOutline: icons.playSkipForwardOutline,
  playSkipForwardSharp: icons.playSkipForwardSharp,
  podium: icons.podium,
  podiumOutline: icons.podiumOutline,
  podiumSharp: icons.podiumSharp,
  power: icons.power,
  powerOutline: icons.powerOutline,
  powerSharp: icons.powerSharp,
  pricetag: icons.pricetag,
  pricetagOutline: icons.pricetagOutline,
  pricetagSharp: icons.pricetagSharp,
  pricetags: icons.pricetags,
  pricetagsOutline: icons.pricetagsOutline,
  pricetagsSharp: icons.pricetagsSharp,
  print: icons.print,
  printOutline: icons.printOutline,
  printSharp: icons.printSharp,
  prism: icons.prism,
  prismOutline: icons.prismOutline,
  prismSharp: icons.prismSharp,
  pulse: icons.pulse,
  pulseOutline: icons.pulseOutline,
  pulseSharp: icons.pulseSharp,
  push: icons.push,
  pushOutline: icons.pushOutline,
  pushSharp: icons.pushSharp,
  qrCode: icons.qrCode,
  qrCodeOutline: icons.qrCodeOutline,
  qrCodeSharp: icons.qrCodeSharp,
  radio: icons.radio,
  radioButtonOff: icons.radioButtonOff,
  radioButtonOffOutline: icons.radioButtonOffOutline,
  radioButtonOffSharp: icons.radioButtonOffSharp,
  radioButtonOn: icons.radioButtonOn,
  radioButtonOnOutline: icons.radioButtonOnOutline,
  radioButtonOnSharp: icons.radioButtonOnSharp,
  radioOutline: icons.radioOutline,
  radioSharp: icons.radioSharp,
  rainy: icons.rainy,
  rainyOutline: icons.rainyOutline,
  rainySharp: icons.rainySharp,
  reader: icons.reader,
  readerOutline: icons.readerOutline,
  readerSharp: icons.readerSharp,
  receipt: icons.receipt,
  receiptOutline: icons.receiptOutline,
  receiptSharp: icons.receiptSharp,
  recording: icons.recording,
  recordingOutline: icons.recordingOutline,
  recordingSharp: icons.recordingSharp,
  refresh: icons.refresh,
  refreshCircle: icons.refreshCircle,
  refreshCircleOutline: icons.refreshCircleOutline,
  refreshCircleSharp: icons.refreshCircleSharp,
  refreshOutline: icons.refreshOutline,
  refreshSharp: icons.refreshSharp,
  reload: icons.reload,
  reloadCircle: icons.reloadCircle,
  reloadCircleOutline: icons.reloadCircleOutline,
  reloadCircleSharp: icons.reloadCircleSharp,
  reloadOutline: icons.reloadOutline,
  reloadSharp: icons.reloadSharp,
  remove: icons.remove,
  removeCircle: icons.removeCircle,
  removeCircleOutline: icons.removeCircleOutline,
  removeCircleSharp: icons.removeCircleSharp,
  removeOutline: icons.removeOutline,
  removeSharp: icons.removeSharp,
  reorderFour: icons.reorderFour,
  reorderFourOutline: icons.reorderFourOutline,
  reorderFourSharp: icons.reorderFourSharp,
  reorderThree: icons.reorderThree,
  reorderThreeOutline: icons.reorderThreeOutline,
  reorderThreeSharp: icons.reorderThreeSharp,
  reorderTwo: icons.reorderTwo,
  reorderTwoOutline: icons.reorderTwoOutline,
  reorderTwoSharp: icons.reorderTwoSharp,
  repeat: icons.repeat,
  repeatOutline: icons.repeatOutline,
  repeatSharp: icons.repeatSharp,
  resize: icons.resize,
  resizeOutline: icons.resizeOutline,
  resizeSharp: icons.resizeSharp,
  restaurant: icons.restaurant,
  restaurantOutline: icons.restaurantOutline,
  restaurantSharp: icons.restaurantSharp,
  returnDownBack: icons.returnDownBack,
  returnDownBackOutline: icons.returnDownBackOutline,
  returnDownBackSharp: icons.returnDownBackSharp,
  returnDownForward: icons.returnDownForward,
  returnDownForwardOutline: icons.returnDownForwardOutline,
  returnDownForwardSharp: icons.returnDownForwardSharp,
  returnUpBack: icons.returnUpBack,
  returnUpBackOutline: icons.returnUpBackOutline,
  returnUpBackSharp: icons.returnUpBackSharp,
  returnUpForward: icons.returnUpForward,
  returnUpForwardOutline: icons.returnUpForwardOutline,
  returnUpForwardSharp: icons.returnUpForwardSharp,
  ribbon: icons.ribbon,
  ribbonOutline: icons.ribbonOutline,
  ribbonSharp: icons.ribbonSharp,
  rocket: icons.rocket,
  rocketOutline: icons.rocketOutline,
  rocketSharp: icons.rocketSharp,
  rose: icons.rose,
  roseOutline: icons.roseOutline,
  roseSharp: icons.roseSharp,
  sad: icons.sad,
  sadOutline: icons.sadOutline,
  sadSharp: icons.sadSharp,
  save: icons.save,
  saveOutline: icons.saveOutline,
  saveSharp: icons.saveSharp,
  scale: icons.scale,
  scaleOutline: icons.scaleOutline,
  scaleSharp: icons.scaleSharp,
  scan: icons.scan,
  scanCircle: icons.scanCircle,
  scanCircleOutline: icons.scanCircleOutline,
  scanCircleSharp: icons.scanCircleSharp,
  scanOutline: icons.scanOutline,
  scanSharp: icons.scanSharp,
  school: icons.school,
  schoolOutline: icons.schoolOutline,
  schoolSharp: icons.schoolSharp,
  search: icons.search,
  searchCircle: icons.searchCircle,
  searchCircleOutline: icons.searchCircleOutline,
  searchCircleSharp: icons.searchCircleSharp,
  searchOutline: icons.searchOutline,
  searchSharp: icons.searchSharp,
  send: icons.send,
  sendOutline: icons.sendOutline,
  sendSharp: icons.sendSharp,
  server: icons.server,
  serverOutline: icons.serverOutline,
  serverSharp: icons.serverSharp,
  settings: icons.settings,
  settingsOutline: icons.settingsOutline,
  settingsSharp: icons.settingsSharp,
  shapes: icons.shapes,
  shapesOutline: icons.shapesOutline,
  shapesSharp: icons.shapesSharp,
  share: icons.share,
  shareOutline: icons.shareOutline,
  shareSharp: icons.shareSharp,
  shareSocial: icons.shareSocial,
  shareSocialOutline: icons.shareSocialOutline,
  shareSocialSharp: icons.shareSocialSharp,
  shield: icons.shield,
  shieldCheckmark: icons.shieldCheckmark,
  shieldCheckmarkOutline: icons.shieldCheckmarkOutline,
  shieldCheckmarkSharp: icons.shieldCheckmarkSharp,
  shieldHalf: icons.shieldHalf,
  shieldHalfOutline: icons.shieldHalfOutline,
  shieldHalfSharp: icons.shieldHalfSharp,
  shieldOutline: icons.shieldOutline,
  shieldSharp: icons.shieldSharp,
  shirt: icons.shirt,
  shirtOutline: icons.shirtOutline,
  shirtSharp: icons.shirtSharp,
  shuffle: icons.shuffle,
  shuffleOutline: icons.shuffleOutline,
  shuffleSharp: icons.shuffleSharp,
  skull: icons.skull,
  skullOutline: icons.skullOutline,
  skullSharp: icons.skullSharp,
  snow: icons.snow,
  snowOutline: icons.snowOutline,
  snowSharp: icons.snowSharp,
  sparkles: icons.sparkles,
  sparklesOutline: icons.sparklesOutline,
  sparklesSharp: icons.sparklesSharp,
  speedometer: icons.speedometer,
  speedometerOutline: icons.speedometerOutline,
  speedometerSharp: icons.speedometerSharp,
  square: icons.square,
  squareOutline: icons.squareOutline,
  squareSharp: icons.squareSharp,
  star: icons.star,
  starHalf: icons.starHalf,
  starHalfOutline: icons.starHalfOutline,
  starHalfSharp: icons.starHalfSharp,
  starOutline: icons.starOutline,
  starSharp: icons.starSharp,
  statsChart: icons.statsChart,
  statsChartOutline: icons.statsChartOutline,
  statsChartSharp: icons.statsChartSharp,
  stop: icons.stop,
  stopCircle: icons.stopCircle,
  stopCircleOutline: icons.stopCircleOutline,
  stopCircleSharp: icons.stopCircleSharp,
  stopOutline: icons.stopOutline,
  stopSharp: icons.stopSharp,
  stopwatch: icons.stopwatch,
  stopwatchOutline: icons.stopwatchOutline,
  stopwatchSharp: icons.stopwatchSharp,
  storefront: icons.storefront,
  storefrontOutline: icons.storefrontOutline,
  storefrontSharp: icons.storefrontSharp,
  subway: icons.subway,
  subwayOutline: icons.subwayOutline,
  subwaySharp: icons.subwaySharp,
  sunny: icons.sunny,
  sunnyOutline: icons.sunnyOutline,
  sunnySharp: icons.sunnySharp,
  swapHorizontal: icons.swapHorizontal,
  swapHorizontalOutline: icons.swapHorizontalOutline,
  swapHorizontalSharp: icons.swapHorizontalSharp,
  swapVertical: icons.swapVertical,
  swapVerticalOutline: icons.swapVerticalOutline,
  swapVerticalSharp: icons.swapVerticalSharp,
  sync: icons.sync,
  syncCircle: icons.syncCircle,
  syncCircleOutline: icons.syncCircleOutline,
  syncCircleSharp: icons.syncCircleSharp,
  syncOutline: icons.syncOutline,
  syncSharp: icons.syncSharp,
  tabletLandscape: icons.tabletLandscape,
  tabletLandscapeOutline: icons.tabletLandscapeOutline,
  tabletLandscapeSharp: icons.tabletLandscapeSharp,
  tabletPortrait: icons.tabletPortrait,
  tabletPortraitOutline: icons.tabletPortraitOutline,
  tabletPortraitSharp: icons.tabletPortraitSharp,
  telescope: icons.telescope,
  telescopeOutline: icons.telescopeOutline,
  telescopeSharp: icons.telescopeSharp,
  tennisball: icons.tennisball,
  tennisballOutline: icons.tennisballOutline,
  tennisballSharp: icons.tennisballSharp,
  terminal: icons.terminal,
  terminalOutline: icons.terminalOutline,
  terminalSharp: icons.terminalSharp,
  text: icons.text,
  textOutline: icons.textOutline,
  textSharp: icons.textSharp,
  thermometer: icons.thermometer,
  thermometerOutline: icons.thermometerOutline,
  thermometerSharp: icons.thermometerSharp,
  thumbsDown: icons.thumbsDown,
  thumbsDownOutline: icons.thumbsDownOutline,
  thumbsDownSharp: icons.thumbsDownSharp,
  thumbsUp: icons.thumbsUp,
  thumbsUpOutline: icons.thumbsUpOutline,
  thumbsUpSharp: icons.thumbsUpSharp,
  thunderstorm: icons.thunderstorm,
  thunderstormOutline: icons.thunderstormOutline,
  thunderstormSharp: icons.thunderstormSharp,
  ticket: icons.ticket,
  ticketOutline: icons.ticketOutline,
  ticketSharp: icons.ticketSharp,
  time: icons.time,
  timeOutline: icons.timeOutline,
  timeSharp: icons.timeSharp,
  timer: icons.timer,
  timerOutline: icons.timerOutline,
  timerSharp: icons.timerSharp,
  today: icons.today,
  todayOutline: icons.todayOutline,
  todaySharp: icons.todaySharp,
  toggle: icons.toggle,
  toggleOutline: icons.toggleOutline,
  toggleSharp: icons.toggleSharp,
  trailSign: icons.trailSign,
  trailSignOutline: icons.trailSignOutline,
  trailSignSharp: icons.trailSignSharp,
  train: icons.train,
  trainOutline: icons.trainOutline,
  trainSharp: icons.trainSharp,
  transgender: icons.transgender,
  transgenderOutline: icons.transgenderOutline,
  transgenderSharp: icons.transgenderSharp,
  trash: icons.trash,
  trashBin: icons.trashBin,
  trashBinOutline: icons.trashBinOutline,
  trashBinSharp: icons.trashBinSharp,
  trashOutline: icons.trashOutline,
  trashSharp: icons.trashSharp,
  trendingDown: icons.trendingDown,
  trendingDownOutline: icons.trendingDownOutline,
  trendingDownSharp: icons.trendingDownSharp,
  trendingUp: icons.trendingUp,
  trendingUpOutline: icons.trendingUpOutline,
  trendingUpSharp: icons.trendingUpSharp,
  triangle: icons.triangle,
  triangleOutline: icons.triangleOutline,
  triangleSharp: icons.triangleSharp,
  trophy: icons.trophy,
  trophyOutline: icons.trophyOutline,
  trophySharp: icons.trophySharp,
  tv: icons.tv,
  tvOutline: icons.tvOutline,
  tvSharp: icons.tvSharp,
  umbrella: icons.umbrella,
  umbrellaOutline: icons.umbrellaOutline,
  umbrellaSharp: icons.umbrellaSharp,
  unlink: icons.unlink,
  unlinkOutline: icons.unlinkOutline,
  unlinkSharp: icons.unlinkSharp,
  videocam: icons.videocam,
  videocamOff: icons.videocamOff,
  videocamOffOutline: icons.videocamOffOutline,
  videocamOffSharp: icons.videocamOffSharp,
  videocamOutline: icons.videocamOutline,
  videocamSharp: icons.videocamSharp,
  volumeHigh: icons.volumeHigh,
  volumeHighOutline: icons.volumeHighOutline,
  volumeHighSharp: icons.volumeHighSharp,
  volumeLow: icons.volumeLow,
  volumeLowOutline: icons.volumeLowOutline,
  volumeLowSharp: icons.volumeLowSharp,
  volumeMedium: icons.volumeMedium,
  volumeMediumOutline: icons.volumeMediumOutline,
  volumeMediumSharp: icons.volumeMediumSharp,
  volumeMute: icons.volumeMute,
  volumeMuteOutline: icons.volumeMuteOutline,
  volumeMuteSharp: icons.volumeMuteSharp,
  volumeOff: icons.volumeOff,
  volumeOffOutline: icons.volumeOffOutline,
  volumeOffSharp: icons.volumeOffSharp,
  walk: icons.walk,
  walkOutline: icons.walkOutline,
  walkSharp: icons.walkSharp,
  wallet: icons.wallet,
  walletOutline: icons.walletOutline,
  walletSharp: icons.walletSharp,
  warning: icons.warning,
  warningOutline: icons.warningOutline,
  warningSharp: icons.warningSharp,
  watch: icons.watch,
  watchOutline: icons.watchOutline,
  watchSharp: icons.watchSharp,
  water: icons.water,
  waterOutline: icons.waterOutline,
  waterSharp: icons.waterSharp,
  wifi: icons.wifi,
  wifiOutline: icons.wifiOutline,
  wifiSharp: icons.wifiSharp,
  wine: icons.wine,
  wineOutline: icons.wineOutline,
  wineSharp: icons.wineSharp,
  woman: icons.woman,
  womanOutline: icons.womanOutline,
  womanSharp: icons.womanSharp,
}

addIcons(allIonIcons)

export type IonIconName = keyof typeof allIonIcons
export const getIconByName = (ionIconName: IonIconName): string => allIonIcons[ionIconName]

export const parseIconName = (ionIconNameAsString: string): IonIconName => {
  if (!Object.keys(allIonIcons).includes(ionIconNameAsString)) {
    return 'squareOutline'
  }

  // We don't like AS but here we've guarded to be certain
  return ionIconNameAsString as IonIconName
}
