import { IonList, IonItem, IonToolbar, IonLabel, IonIcon, IonButtons, IonButton, IonTitle, useIonViewWillEnter, IonText } from '@ionic/react'
import React, { useCallback, useState } from 'react'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useChatRoom } from '../chatRoom/ChatRoom/services/ChatRoomProvider'
import { chevronBack } from 'ionicons/icons'
import { useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useJoinVideoChatMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { VideoRoom } from './Room'
import { Types } from "ably"
import { alwaysArray } from '../../../common/utils'

type VideoChatProps = {
  goToChatRoom: () => void,
}

/**
 * This is used to generate a unique but consistent room name for both participants.
 */
const sortAlphabeticallyIndividualIds = (individualId1: string, individualId2: string): string => {
  const individualIds = [ individualId1, individualId2 ].sort()
  return `${individualIds[0]}:${individualIds[1]}`
}

export const VideoChat: React.FC<VideoChatProps> = ({ goToChatRoom }) => {
  const [ token, setToken ] = useState<string | undefined>(undefined)
  const [ membersPresent, setMembersPresent ] = useState<Types.PresenceMessage[] | undefined>(undefined)
  const [ roomName, setRoomName ] = useState<string | undefined>(undefined)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const videoChatRoomMutation = useJoinVideoChatMutation(gqlDataSource)
  const myIndividual = useMyIndividual()

  const { getMembersPresentInChatRoom } = useChatRoom()

  const fetchMembersPresentInChatRoom = async() => {
    const membersPresentInChatRoom = await getMembersPresentInChatRoom()
    console.debug('[VideoChat.fetchMembersPresentInChatRoom]', membersPresentInChatRoom)
    setMembersPresent(membersPresentInChatRoom)
  }

  useIonViewWillEnter(() => {
    fetchMembersPresentInChatRoom()
  })

  const joinVideo = useCallback(async (individualId2: string) => {
    const roomName = sortAlphabeticallyIndividualIds(myIndividual.id, individualId2)
    setRoomName(roomName)

    await videoChatRoomMutation.mutateAsync({
      roomName,
    }, {
      onSuccess: (res) => {
        setToken(res.joinVideoChat)
        console.debug('[VideoChat.joinVideo.onSuccess]', res)
      },
      onError: (err) => {
        console.debug('[VideoChat.joinVideo.onError]', err)
      },
    })
  }, [])

  const handleLogout = useCallback(() => {
    setToken(undefined)
  }, [])

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton color="primary" onClick={() => goToChatRoom()}>
            <IonIcon icon={chevronBack}/>Back
          </IonButton>
        </IonButtons>
        <IonTitle>Video Chat</IonTitle>
      </IonToolbar>
    </WeaverIonHeader>
    <WeaverIonContent>
      {!token && (
        <IonList lines="full">
          <IonText >
            <h4 className='ion-margin-bottom'>Available Members for Video Chat</h4>
          </IonText>
          {alwaysArray(membersPresent)
            .filter(member => member.clientId !== myIndividual.id)
            .map(member => (
              <IonItem key={member.clientId}>
                <IonLabel>{member.clientId}</IonLabel>
                <IonLabel slot='end' color={'primary'} onClick={() => joinVideo(member.clientId)}>
                  Join Video
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
      )}
      {token && <VideoRoom roomName={roomName} token={token} handleLogout={handleLogout} />}
    </WeaverIonContent>
  </>
}
