import React, { useEffect, useRef, useMemo } from 'react'

import { useMyIndividual } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { ChatContentListElement, ChatContentListElementType, ChatRoomContentMessage } from "./ChatRoomTypes"
import { getIndexForNewMessageLineElement } from './utils'

import { useChatRoom } from "./services/ChatRoomProvider"
import { VirtuosoActions } from './ChatContent'

type ChatRoomMessages = ReturnType<typeof useChatRoom>['chatRoomMessages']

type UseLoadChatRoomMessageHookProps = {
  listElements: React.MutableRefObject<ChatContentListElement[]>,
  chatRoomMessages: ChatRoomMessages,
}

type UseShowMessagesNewLineHookProps = {
  listElements: React.MutableRefObject<ChatContentListElement[]>,
  chatRoomMessages: ChatRoomMessages,
  individual: ReturnType<typeof useMyIndividual>,
}

type UseScrollToLastElementWhenChatRoomElementListChangeProps = {
  listElements: ChatContentListElement[],
  scrollToIndex: VirtuosoActions['scrollToIndex'],
}

type UseShowEmptyStateHookProps = {
  listElements: React.MutableRefObject<ChatContentListElement[]>,
  chatRoomMessages: ReturnType<typeof useChatRoom>['chatRoomMessages'],
}

const useBuildChatRoomListElements = () => {
  /**
   * Returns a ChatContentListElement that represents a message to be displayed in the Chat Room Virtuoso list. Notice that the hashCode corresponds to the idFromClient of the message
  */
  const buildChatRoomMessageElement = (message: ChatRoomContentMessage) => ({ message, elementType: ChatContentListElementType.ChatRoomMessage, hashCode : () => { return `${message.idFromClient}_${message.id}_${message.readAt}` } })

  /**
  * Returns a ChatContentListElement that represents the new messages line, NewMessagesLineHashCode is used as the hashcode since there isn't anything with an ID for this element
  */
  const buildNewMessagesLineElement = () => ({ elementType: ChatContentListElementType.NewMessageLine, hashCode : () => { return "NewMessagesLineHashCode" } })

  /**
  * Returns a ChatContentListElement that represents the new empty state, EmptyStateElementHashCode is used as the hashcode since there isn't anything with an ID for this element
  */
  const buildEmptyStateElement = () => ({ elementType: ChatContentListElementType.EmptyState, hashCode : () => { return "EmptyStateElementHashCode" } })

  return { buildChatRoomMessageElement, buildNewMessagesLineElement, buildEmptyStateElement }
}

export const useLoadChatRoomMessages = ({ chatRoomMessages, listElements }: UseLoadChatRoomMessageHookProps) => {
  const { buildChatRoomMessageElement } = useBuildChatRoomListElements()

  useMemo(() => {
    if (!chatRoomMessages) return

    listElements.current = chatRoomMessages.map((message) => buildChatRoomMessageElement(message))
  }, [ chatRoomMessages ])
}

export const useShowNewMessageLineElement = ({ chatRoomMessages, listElements, individual }: UseShowMessagesNewLineHookProps) => {
  const { buildNewMessagesLineElement } = useBuildChatRoomListElements()

  const isFirstTimeShowingNewMessageLine = useRef<boolean>()
  const newMessageLineIndex = useRef<number>(-1)

  const insertNewMessageLine = (index: number) => {
    listElements.current.splice(index, 0, buildNewMessagesLineElement())
  }

  useEffect(() => {
    isFirstTimeShowingNewMessageLine.current = true
    newMessageLineIndex.current = -1
  }, [])

  useMemo(() => {
    if (!chatRoomMessages) return

    const index = getIndexForNewMessageLineElement(individual.id, listElements.current)

    // If it's the first time displaying the image then
    if (index !== -1 && isFirstTimeShowingNewMessageLine.current) {
      newMessageLineIndex.current = index
      isFirstTimeShowingNewMessageLine.current = false
      insertNewMessageLine(index)

    } else {
      // If it's not the first time we still want to render the red line even though the messages have marked as read since the red line was shown initially.
      // This is done in order to avoid having the line disappearing on the screen.
      if (newMessageLineIndex.current !== -1){
        insertNewMessageLine(newMessageLineIndex.current)
      }
    }

  }, [ chatRoomMessages ])
}

export const useScrollToLastElementWhenChatRoomElementListChange = ({ listElements, scrollToIndex }: UseScrollToLastElementWhenChatRoomElementListChangeProps) => useEffect(() => {
  const scrollToLastElement = () => {
    if (listElements !== undefined){
      scrollToIndex(listElements.length)
    }
  }
  // We need to make sure the last element has rendered on screen before we scroll
  setTimeout(scrollToLastElement, 1)
}, [ listElements?.length ])

export const useClearImagesCacheOnLoad = (emptyCache: () => void) =>
  useEffect(() => {
    // The message images cache is clear every time the chat screen is rendered for the first time
    emptyCache()
  }, [])

export const useShowEmptyState = ({ chatRoomMessages, listElements }: UseShowEmptyStateHookProps) => {
  const { buildEmptyStateElement } = useBuildChatRoomListElements()

  useMemo(() => {
    if (!listElements.current.find(e => e.elementType === ChatContentListElementType.EmptyState)) {
      // Insert EmptyState as the first element
      listElements.current.splice(0, 0, buildEmptyStateElement() )
    }

  }, [ chatRoomMessages ])

}
