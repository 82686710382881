import React from 'react'
import { IonIcon, IonItem, IonText, useIonRouter } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import { useRouteMatch } from 'react-router-dom'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ensureTrailingSlash } from '../../../common/utils'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import ProfileHeader from '../ProfileHeader'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import Styles from '../Profile.module.scss'

const MyArchitectProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(
    gqlDataSource,
    { architectTeamId: myTeam?.id ?? '' },
    {
      staleTime: 5 * 60 * 1000,
      enabled: myTeam !== undefined,
    },
  )

  const ionRouter = useIonRouter()
  const match = useRouteMatch()

  if (myTeam === undefined || isFetching) {
    return <LoadingSpinnerPage name="MyArchitectProfilePage" />
  }

  if (error) {
    console.error(`[MyArchitectProfilePage] Failed to load Architect Profile: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage' onRefresh={refetch} />
  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[MyArchitectProfilePage] Architect Profile is missing: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage'><p>Unable to find the Architect Profile.</p></ErrorBlockPage>
  }

  const profile = data.getArchitectProfile

  return (
    <WeaverIonPage id='MyArchitectProfilePage'>
      <WeaverIonHeader>
        <ProfileHeader isMyTeam={true} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={event => {
          event.preventDefault()
          const newLocation = `${ensureTrailingSlash(match.url)}edit`
          ionRouter.push(newLocation)
        }}>
          <h3>{profile.companyTradingAs}</h3>
          <IonIcon className={Styles.editIcon} color='primary' src={createOutline} ></IonIcon>
        </IonItem>

        <IonText className={Styles.lightGrey}>
          <p> Architect </p>
        </IonText>
        <IonText className={Styles.lightGrey}>
          <p>{formatAddressToSingleLine(profile.companyAddress) ?? <em>No Address</em>}</p>
        </IonText>
        <TeamMembers teamId={myTeam.id} />
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default MyArchitectProfilePage
