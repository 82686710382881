import React from "react"
import EnvironmentProvider from "./EnvironmentProvider"
import * as z from 'zod'
export * from "./context"

export const zEnvironment = z.object({
  version: z.literal('2'),
  release: z.object({
    version: z.string(),
  }),
  account: z.object({
    name: z.string(),
    type: z.union([ z.literal('dev'), z.literal('published') ]),
  }),
  app: z.object({
    // The location of the hosting for the SPA (this will likely change / be removed as we gain multiple frontends)
    domain: z.string(),
  }),
  auth0: z.object({
    domain: z.string(),
    client_id: z.string(),
    audience: z.string().optional(),
  }),
  apiGateway: z.object({
    bootstrap: z.object({
      url: z.string(),
    }),
    devSupport: z.object({
      url: z.string().optional(),
    }),
    ably: z.object({
      url: z.string(),
    }),
  }),
  graphQL: z.object({
    notes: z.object({
      url: z.string(),
      region: z.string(),
      key: z.string().optional(),
    }),
    core: z.object({
      url: z.string(),
      region: z.string(),
      key: z.string().optional(),
    }),
  }),
  integrations: z.object({
    google: z.object({
      apiKey: z.string(),
    }),
    segment: z.object({
      frontend: z.object({
        writeKey: z.string(),
      }),
    }),
    launchDarkly: z.object({
      sdkKey: z.string(),
      mobileKey: z.string(),
      clientSideId: z.string(),
    }),
    sentry: z.object({
      app: z.object({
        DSN: z.string(),
      }),
      frontend: z.object({
        DSN: z.string(),
      }),
    }),
  }),
})

export type Environment = z.infer<typeof zEnvironment>

export const ENV_DEFAULT_STRING = '__DEFAULT_VALUE__'

export const defaultEnvironment: Environment = {
  version: '2',
  release: {
    version: ENV_DEFAULT_STRING,
  },
  account: {
    name: ENV_DEFAULT_STRING,
    type: 'dev',
  },
  app: {
    domain: ENV_DEFAULT_STRING,
  },
  auth0: {
    client_id: ENV_DEFAULT_STRING,
    domain: ENV_DEFAULT_STRING,
  },
  apiGateway: {
    bootstrap: {
      url: ENV_DEFAULT_STRING,
    },
    devSupport: {
      url: ENV_DEFAULT_STRING,
    },
    ably: {
      url: ENV_DEFAULT_STRING,
    },
  },
  graphQL: {
    notes: {
      region: ENV_DEFAULT_STRING,
      url: ENV_DEFAULT_STRING,
    },
    core: {
      region: ENV_DEFAULT_STRING,
      url: ENV_DEFAULT_STRING,
    },
  },
  integrations: {
    google: {
      apiKey: ENV_DEFAULT_STRING,
    },
    segment: {
      frontend: {
        writeKey: ENV_DEFAULT_STRING,
      },
    },
    launchDarkly: {
      sdkKey: ENV_DEFAULT_STRING,
      mobileKey: ENV_DEFAULT_STRING,
      clientSideId: ENV_DEFAULT_STRING,
    },
    sentry: {
      app: {
        DSN: ENV_DEFAULT_STRING,
      },
      frontend: {
        DSN: ENV_DEFAULT_STRING,
      },
    },
  },
}

export const EnvironmentContext = React.createContext<Environment>(defaultEnvironment)

export default EnvironmentProvider
