import { IonAvatar, IonButton, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonNote, IonRow, IonTitle, IonToolbar } from '@ionic/react'
import { personAdd } from 'ionicons/icons'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { v4 as uuid } from "uuid"
import React, { useState, MouseEvent } from 'react'
import ProjectAvatar from '../ProjectAvatar'
import * as z from 'zod'

import Styles from "./AddContractorsSlide.module.scss"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'

// modal breakpoint (percent of screen)
const MODAL_OPEN = 0.92
const NaiveMatchPhoneNumber = /^0|\+44 ?\d{4} ?\d{6}$/

export const ContractorFormSchema = z.object({
  id: z.string().optional(),
  companyName: z.string().nonempty("Required"),
  givenName: z.string().nonempty("Required"),
  familyName: z.string().nonempty("Required"),
  email: z.string()
    .nonempty("Required")
    .email(),
  phone: z.string()
    .nonempty("Required")
    .refine((x) => x.match(NaiveMatchPhoneNumber), "Must be a valid phone number"),
})

type Contractor = z.infer<typeof ContractorFormSchema>

type AddContractorsSlideProps = {
  value: Contractor[],
  onChange: (contractors: Contractor[]) => unknown,
  max: number,
}

const AddContractorsSlide: React.FC<AddContractorsSlideProps> = ({ value = [], onChange, max }) => {
  const [ showModal, setShowModal ] = useState(false)

  const { register, watch, reset, control, handleSubmit, setValue, formState: { errors } } = useForm<Contractor>({ resolver: zodResolver(ContractorFormSchema) })

  const currentId = watch("id")
  const isAddingNewContractor = !currentId

  const onSubmit = (changedContractor: Contractor) => {
    const id = changedContractor.id || uuid()
    const contractorData = {
      ...changedContractor,
      id,
    }

    // if editing an existing item, replace it in the value array, otherwise append
    const existingIndex = value.findIndex(x => x.id === id)
    let nextData
    if (existingIndex >= 0) {
      nextData = [ ...value ]
      nextData.splice(existingIndex, 1, contractorData)
    } else {
      nextData = [ ...value, contractorData ]
    }

    onChange(nextData)
    setShowModal(false)
  }
  const remove = (id: string) => {
    const existingIx = value.findIndex(x => x.id === id)

    if (existingIx < 0) {
      throw new Error(`tried to remove unknown contractor: ${id}`)
    }

    const nextData = [ ...value ]
    nextData.splice(existingIx, 1)
    onChange(nextData)
    setShowModal(false)
  }
  const openAdd = () => {
    reset()
    setShowModal(true)
  }

  const openEdit = (event: MouseEvent<HTMLIonItemElement>) => {
    const id = event.currentTarget.dataset.contractorId
    if (!id) throw new Error("Corrupt form: could not locate contractor Id")

    const contractor = value.find(x => x.id === id)
    if (!contractor) throw new Error("Corrupt form: could not locate contractor id in state")

    setValue("id", contractor.id)
    setValue("companyName", contractor.companyName)
    setValue("givenName", contractor.givenName)
    setValue("familyName", contractor.familyName)
    setValue("email", contractor.email)
    setValue("phone", contractor.phone)

    setShowModal(true)
  }

  return (
    <>
      <div>
        <h2>Contractors</h2>
        <IonList>
          {
            value.map(c => (
              <IonItem key={c.id} className={Styles.contractorItem} data-contractor-id={c.id} onClick={openEdit}>
                <ProjectAvatar slot="start" title={c.companyName} />
                <IonLabel>
                  <h2>{c.companyName}</h2>
                  <p>{c.givenName} {c.familyName}</p>
                </IonLabel>
                <IonLabel>
                  <p>{c.email}</p>
                  <p>{c.phone}</p>
                </IonLabel>
              </IonItem>
            ))
          }
          {
            <IonItem disabled={value.length >= max} className={[ Styles.contractorItem, Styles.addItem ].join(" ")} onClick={openAdd}>
              <IonAvatar className={Styles.addAvatar} slot="start"><IonIcon icon={personAdd} /></IonAvatar>
              <IonLabel>
                <h2>{value.length >= max ? "Maximum Contractors Added" : "Add a Contractor"}</h2>
              </IonLabel>
            </IonItem>
          }
        </IonList>
      </div>

      <IonModal
        isOpen={showModal}
        initialBreakpoint={MODAL_OPEN}
        breakpoints={[ 0, MODAL_OPEN ]}
        onDidDismiss={() => setShowModal(false)}>
        <IonToolbar>
          <IonTitle>{isAddingNewContractor ? "Add Contractor": "Edit Contractor"}</IonTitle>
        </IonToolbar>

        <div className={Styles.modalContainer}>
          <h3>Please enter the details of the contractors you would like to invite.</h3>
          <p>Your contractors will receive an invitation to Weaver.</p>
          <IonGrid>
            <IonRow>
              <IonCol>
                <h3>Company Name</h3>
                <IonInput placeholder="Builders Ltd." {...register("companyName")}></IonInput>
                {errors.companyName ? <IonNote color='danger'>{errors.companyName.message}</IonNote> : null}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <h3>First name</h3>
                <IonInput placeholder='James' {...register("givenName")}></IonInput>
                {errors.givenName ? <IonNote color='danger'>{errors.givenName.message}</IonNote> : null}
              </IonCol>
              <IonCol>
                <h3>Last name</h3>
                <IonInput placeholder='Builder' {...register("familyName")}></IonInput>
                {errors.familyName ? <IonNote color='danger'>{errors.familyName.message}</IonNote> : null}
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size='6'>
                <h3>Email</h3>
                <IonInput placeholder='email@domain.tld' {...register("email")}></IonInput>
                {errors.email ? <IonNote color='danger'>{errors.email.message}</IonNote> : null}
              </IonCol>
              <IonCol size='6'>
                <h3>Phone</h3>
                <Controller
                  control={control}
                  name="phone"
                  render={({
                    field: { onChange, value },
                  }) => (
                    <PhoneInput
                      className={Styles.phoneInput}
                      value={value}
                      onChange={onChange}
                      international
                      defaultCountry="GB"

                    />
                  )}
                />
                <>{errors.phone ? <IonNote color='danger'>{errors.phone.message}</IonNote> : null}</>
              </IonCol>
            </IonRow>
            <IonButton expand='block' onClick={handleSubmit(onSubmit)}>{isAddingNewContractor ? "Add": "Save"} Contractor</IonButton>
            {isAddingNewContractor ? null : <IonButton expand='block' onClick={() => remove(currentId)} color='danger'>Remove Contractor</IonButton> }
          </IonGrid>
        </div>
      </IonModal>
    </>
  )
}

export default AddContractorsSlide
