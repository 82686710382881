import React from 'react'
import { parseIconName } from "../../../../../common/components/IonIconTyped/icons"
import IonIconTyped from '../../../../../common/components/IonIconTyped'
import { IonLabel, IonBadge } from '@ionic/react'
import { onboardingStepStatusLabels } from '../../onboarding.i18n'
import { OnboardingStepStatus } from '../OnboardingSignupSteps/config'
import Styles from "./OnboardingSignupSteps.module.scss"
interface OnboardingSignupStepProps {
  id: string,
  title: string,
  icon: string | null | undefined,
  status: OnboardingStepStatus,
  goToNextPage?: () => void,
}

const DEFAULT_ICON = parseIconName("checkmarkCircle")

const OnboardingSignupStep: React.FC<OnboardingSignupStepProps> = ({ id, title, icon, status, goToNextPage }) => {
  const parsedIcon = icon ? parseIconName(icon) : DEFAULT_ICON
  const statusLabel = onboardingStepStatusLabels[status].toLowerCase().replace(' ', '')
  const clickableStepId = '2'
  const isClickable = id === clickableStepId

  return <div key={`${id}`} className={[ Styles.itemContent, Styles[statusLabel], isClickable ? Styles.clickable : '' ].join(' ')} onClick={isClickable ? goToNextPage : () => null}>
    {icon && <IonIconTyped className={Styles.taskIcon} slot="start" iconName={parsedIcon} />}
    <IonLabel className={Styles.label}>{title}</IonLabel>
    <IonBadge slot="end" >{onboardingStepStatusLabels[status]}</IonBadge>
  </div>
}

export default OnboardingSignupStep
