import React, { useRef } from 'react'

import { IonIcon, useIonAlert } from '@ionic/react'
import Styles from "./BrowseFilesButton.module.scss"
import { folderOutline } from 'ionicons/icons'
import SingleClickButton from '../SingleClickButton'

type BrowseFilesButtonProps = {
  multipleSelection?: boolean,
  supportedFileTypes?: string[],
  containerCssClassName?: string,
  onFilesSelectedByUserCallback: (selectedFiles: File[]) => void,
  renderButton?: (openNativeFilePicker: () => void) => React.ReactElement,
}

const getDefaultBrowseButton = (openNativeFilePicker : () => void) =>
  <SingleClickButton onClick={openNativeFilePicker} fill="clear" size="small" >
    <IonIcon icon={folderOutline} color="primary" /> Browse documents
  </SingleClickButton>

const BrowseFilesButton: React.FC<BrowseFilesButtonProps> = ({ onFilesSelectedByUserCallback, renderButton, multipleSelection, supportedFileTypes, containerCssClassName }) => {
  const inputThatOpensNativeFilePicker = useRef<HTMLInputElement>(null)

  const [ present ] = useIonAlert()

  const openNativeFilePicker = () => {
    inputThatOpensNativeFilePicker.current?.click()
  }

  const onFileSelectedByUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target
    if (target.files && target.files.length > 0) {
      const allSelectedFilesByUser = [ ...target.files ]

      // If the file type does not need to be validated then use all of the files selected by the user.
      if (!supportedFileTypes){
        onFilesSelectedByUserCallback(allSelectedFilesByUser)
        return
      }

      const filesWithSupportedTypes = allSelectedFilesByUser.filter(file => supportedFileTypes.includes(file.type))
      const wereThereInvalidFiles = allSelectedFilesByUser.length !== filesWithSupportedTypes.length
      if (wereThereInvalidFiles) {
        present({
          header: "Warning",
          message: "Some files were excluded because their type is not supported.",
          buttons: [
            {
              text: "Ok",
              role: "cancel",
            },
          ],
        })
      }
      onFilesSelectedByUserCallback(filesWithSupportedTypes)
      return
    }
  }

  return (
    <div className={containerCssClassName || Styles.browseFilesButton}>
      <input hidden ref={inputThatOpensNativeFilePicker} type="file" id="nativeFilePicker" onChange={onFileSelectedByUser} multiple={multipleSelection}/>
      {/* If render button was specified then use it, otherwise use the default one. */}
      {!renderButton && getDefaultBrowseButton(openNativeFilePicker)}
      {renderButton && renderButton(openNativeFilePicker)}
    </div>
  )
}

export default BrowseFilesButton
