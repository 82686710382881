
export const getSupportedImageFileTypes = () => (
  [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/tiff",
    "image/tif",
    "image/webp",
    "image/bmp",
    "image/heic",
  ])
