import React from 'react'
import { checkmarkCircle, warning } from "ionicons/icons"
import { IonAvatar, IonIcon } from '@ionic/react'
import Styles from './ChatRoomMemberBubble.module.scss'
import { getInitials } from '../../../../common/utils'

type ChatRoomMemberBubbleProps =  {
  isInvitationPending: boolean,
  chatRoomMemberFullName: string | null | undefined,
}

const ChatRoomMemberBubble: React.FC<ChatRoomMemberBubbleProps> = ({ isInvitationPending, chatRoomMemberFullName }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.avatarContainer}>
        {
          chatRoomMemberFullName && <IonAvatar className={Styles.avatar}>
            <div className={Styles.label}><div>{getInitials(chatRoomMemberFullName).slice(0, 2)}</div></div>
          </IonAvatar>
        }
        {
          isInvitationPending
            ? <IonIcon className={Styles.warning} icon={warning}/>
            : <IonIcon className={Styles.checkMark} icon={checkmarkCircle}/>
        }
      </div>
      <div className={`${Styles.chatMemberName} ion-text-wrap ion-text-center`}>
        {`${chatRoomMemberFullName}`}
      </div>
    </div>
  )
}

export default ChatRoomMemberBubble
