import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import ScreenSimpleAction from "./common/ScreenSimpleAction"
import { pageConfig_ShowProject, useRouteTo } from "../../../routes"

const ScreenInviteHomeowner: React.FC<ScreenComponentProps> = (props) => {
  const { getActionableTaskQuery } = props
  const routeToContractorProfile = useRouteTo(pageConfig_ShowProject.path)

  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ScreenInviteHomeowner" />
  const task = getActionableTaskQuery.data.getTask

  return <>
    <ScreenSimpleAction {...props} onClick={routeToContractorProfile({ id: task.projectId }, "invite=HOMEOWNER")} name="ScreenInviteHomeowner" buttonText="Invite Homeowner" />
  </>
}

export default ScreenInviteHomeowner
