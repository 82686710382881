import { ChatRoomMessage, GetChatRoomTeamConnectionsQuery, Individual, SignedUrl, UploadedFile } from "../../../../graphql/generated"
import { useChatRoom } from "./services/ChatRoomProvider"

export type TChatRoomMessageDetailsProps = {
  sentByIndividual: Pick<Individual, 'id' | 'givenName' | 'familyName'>,
}

export type TChatRoomMessageSentByCurrentUserProp = {
  sentByCurrentUser: boolean,
}

export type TChatRoomMessageDocument = Pick<UploadedFile, 'id' | 'status' | 'fileName' | 'fileContentType' | 'fileSizeInBytes' | 'updatedAt'> & { signedUrlForUpload: SignedUrl } & { signedUrlForDownload: SignedUrl }

export type TChatRoomMessage = {
  id?: ChatRoomMessage['id'],
  idFromClient: ChatRoomMessage['idFromClient'],
  documentCount?: ChatRoomMessage['documentCount'],
  documents?: TChatRoomMessageDocument[] | null,
  content: ChatRoomMessage['content'],
  sentAt: ChatRoomMessage['sentAt'],
  readAt?: ChatRoomMessage['readAt'],
}

export type ChatRoomMessageHasBeenSeenCallback = {
  messageHasBeenSeenCallback : (chatRoomMessageId: string | undefined, chatContent?: string) => void,
}

export type ChatRoomContentMessage = NonNullable<ReturnType<typeof useChatRoom>['chatRoomMessages']>[number] & Pick<TChatRoomMessage, 'id' >

export type ChatRoomTeam = NonNullable<GetChatRoomTeamConnectionsQuery["getChatRoom"]>["teamConnections"][number]["team"]

// The different types of elements that can be rendered in the list

export type ChatContentListElement = {
  hashCode: () => string,
  elementType: ChatContentListElementType,
}

export type ChatRoomMessageElement = ChatContentListElement & { message: ChatRoomContentMessage }

export type NewMessagesLineElement = ChatContentListElement

export type EmptyStateElement = ChatContentListElement

export enum ChatContentListElementType {
  EmptyState = "EmptyState",
  ChatRoomMessage = "ChatRoomMessage",
  NewMessageLine = "NewMessageLine"
}

