import { Filesystem, ReadFileOptions } from "@capacitor/filesystem"

/**
 * Weaver's wrapper for Capacitor's Filesystem readFile. This was introduced in order to encapsulate the casting 'as unknown as File'.
 * This is necessary because write_blob's with fast_mode writes Files into the Device's Storage, we know it's a File but
 * Typescript doesn't.
 */
export const readFile = async (options: ReadFileOptions): Promise<File> => {
  const result = await Filesystem.readFile(options)
  const file = result.data as unknown as File
  return file
}
