import { Duration, DurationLikeObject } from "luxon"
import { useState } from "react"
import useAbortableEffect from "./useAbortableEffect"

const COUNTDOWN_INCREMENT = Duration.fromObject({ seconds: 1 })

export type CountDownTimerProps = {
  /** The duration to count down from */
  duration: DurationLikeObject,
  /** The amount to count down by each increment */
  increment?: Duration,
  /** The format to display the duration in, as per the Luxon `Duration.toFormat` method */
  format?: Parameters<Duration['toFormat']>[0],
}

/**
 * A countdown timer that starts with a `duration` and counts down by `increment` until it reaches 0.
 * When it reaches 0, it returns null.
 * @param duration The duration to count down from
 * @param increment The amount to count down by each increment
 * @param format The format to display the duration in, as per the Luxon `Duration.toFormat` method
 * @returns The current duration in the format specified by `format`, or null if the countdown has reached 0.
 */
export const useCountDown = ({ duration, increment = COUNTDOWN_INCREMENT, format = 'hh:mm:ss' }: CountDownTimerProps): string | null => {
  const [ durationDisplayed, setDurationDisplayed ] = useState<Duration>(Duration.fromDurationLike(duration))

  useAbortableEffect(status => {
    const afterEachIncrement = () => {
      // Abort if we've been unmounted
      if (status.aborted) return
      // If we have enough time to remove the next increment
      if (durationDisplayed.toMillis() > increment.as('milliseconds')) {
        setDurationDisplayed(currentDuration => currentDuration.minus(increment).shiftTo('hours', 'minutes', 'seconds'))
        setTimeout(afterEachIncrement, increment.as('milliseconds'))
      } else {
        // We're out of time!
        setDurationDisplayed(Duration.fromMillis(0))
      }
    }
    // Start the countdown
    setTimeout(afterEachIncrement, increment.as('milliseconds'))
  }, [])

  // Out of time!
  if (durationDisplayed.toMillis() < increment.as('milliseconds')) return null

  return durationDisplayed.toFormat(format)
}
