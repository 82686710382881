import React from 'react'
import { Lead, ReferralType, useAcceptLeadMutation, useListMyProjectsAndLeadsQuery } from '../../../graphql/generated'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'
import { getWorkStartEstimateLabels } from '../workEstimate.i18n'
import { DateTime } from 'luxon'
import { getFlexibleProjectBudgetAsText } from '../common'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { pageConfig_ShowProject, useRouteTo } from '../../../routes'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useQueryClient } from '@tanstack/react-query'

type FreeLeadAcceptancePageProps = {
  lead: Pick<Lead, 'id' | 'workStartEstimate' | 'title' | 'description' | 'tenderReturnDate' | 'projectTypes' | 'budgetCategory' | 'budgetValue' | 'referral'>,
}

const FreeLeadAcceptancePage: React.FC<FreeLeadAcceptancePageProps> = ({ lead }) => {
  const queryClient = useQueryClient()
  const goToShowProject = useRouteTo(pageConfig_ShowProject.path)
  const myTeam = useMyIndividualActiveTeam()
  const triggerEvent_Project_Lead_Responded_Accepted = useAnalyticsEvent("Project_Lead_Responded_Accepted")
  const weaverFlags = useWeaverFlags()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const acceptLead = useAcceptLeadMutation(gqlDataSource, { onSuccess: async () => {
    if (weaverFlags["MW-2415-lead-cache-busting"]) {
      await queryClient.invalidateQueries(useListMyProjectsAndLeadsQuery.getKey())
    }
  } })
  const isProjectOwnerReferral = lead.referral?.type === ReferralType.ProjectOwner

  return (
    <WeaverIonPage id='LeadBrilliantScreen'>
      <WeaverIonContent>
        <BrilliantScreen
          title='Amazing!'
          subtitle='You have accepted a lead'
          actionButtonProps={{
            onClick: async () => {
              // We only accept the lead when Continue is clicked
              await acceptLead.mutateAsync({ id: lead.id })

              await triggerEvent_Project_Lead_Responded_Accepted({
                projectId: lead.id,
                titleOfProject: lead.title,
                description: lead.description,
                budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
                workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
                /** @deprecated MW-2386-remove-tender-return-date -> Remove tenderReturnDate from form schema when archiving flag*/
                tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : DateTime.fromISO(lead?.tenderReturnDate ??  '').toLocaleString(DateTime.DATE_MED),
                type: lead.projectTypes,
                isNonWeaverBuilder: isProjectOwnerReferral,
              })

              // NOTE: Leads are virtual representations of Projects and share the same ID
              goToShowProject({ id: lead.id })()
            },
            children: <>Continue</>,
            expand: 'block',
          }}
        />
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default FreeLeadAcceptancePage
