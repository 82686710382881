import React from "react"
import { IonTitle, IonToolbar } from "@ionic/react"
import Styles from './ProfileHeader.module.scss'
import { usePageTitleOnEnter } from "../../common/hooks/usePageTitle"

type PublicProfileHeaderProps = {
  teamName?: string | null | undefined,
}

const PublicProfileHeader: React.FC<PublicProfileHeaderProps> = ({ teamName }) => {
  usePageTitleOnEnter(teamName || undefined)
  return (
    <IonToolbar className={Styles.ionToolbar}>
      {teamName && <IonTitle>{teamName}</IonTitle>}
    </IonToolbar>
  )
}

export default PublicProfileHeader
