import React from 'react'
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import { asDayNumbers, getDaysInMonth } from '../../utils/date'
import { ScreenComponentProps } from './WeaverDatePicker'

import Styles from './WeaverDatePicker.module.scss'

/**
 * `GridPickDay` displays a grid of buttons which cover the number of days
 * in the specified month & year (leap years).
 *
 * When a day is selected, it calls the `setYear(number)` function passed.
 */
const GridPickDay: React.FC<ScreenComponentProps> = ({ selectedYear, selectedMonth, selectedDay, setDay, min, max }) => {
  if (selectedYear === undefined) throw new Error('[GridPickDay] Undefined selectedYear! This should never happen in the real world.')
  if (selectedMonth === undefined) throw new Error('[GridPickDay] Undefined selectedMonth! This should never happen in the real world.')

  const daysInMonth = getDaysInMonth(selectedYear, selectedMonth)

  const buttons = Array.from({ length: daysInMonth }, (_, index) => {
    const displayDay = asDayNumbers(index + 1)
    const isSelected = selectedDay === displayDay

    const isBelowMax = max ? max.year > selectedYear || max.month > selectedMonth || max.day >= displayDay : true
    const isAboveMin = min ? min.year < selectedYear || min.month < selectedMonth || min.day <= displayDay : true
    const isSelectable = isBelowMax && isAboveMin

    return (
      <IonButton
        fill={isSelected ? 'solid' : 'outline'}
        disabled={!isSelectable}
        onClick={() => setDay(displayDay)}
        color={isSelected ? 'primary' : undefined}
      >
        {displayDay}
      </IonButton>
    )
  })

  return (
    <IonGrid className={Styles.datePickerGridContainer}>
      <IonRow className={Styles.datePickerGridRow}>
        {buttons.map((rowButton, colIndex) => (
          <IonCol size='2' key={colIndex}>{rowButton}</IonCol>
        ))}
      </IonRow>
    </IonGrid>
  )
}

export default GridPickDay
