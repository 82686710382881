import React from 'react'
import { IonRow, IonGrid, IonLabel, IonItem } from '@ionic/react'
import PhoneInput,  { Value as E164Number } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Styles from "./MobileNumberCapture.module.scss"
import { FieldError } from 'react-hook-form'

export type MobileNumberCaptureProps = {
  value: E164Number | undefined,
  onChange: (value: E164Number | undefined) => unknown,
  disabled?: boolean,
  error: FieldError | undefined,
  errorMessage?: string,
  textHeading?: string,
  textFieldPhoneNumberTitle?: string,
  textFieldPhoneNumberPlaceholder?: string,
  customClassName?: string,
}

export const MobileNumberCapture: React.FC<MobileNumberCaptureProps> = (props) => {

  return (
    <IonGrid className={Styles.contentContainer}>
      <h3>{props.textFieldPhoneNumberTitle ?? 'Enter Mobile Number'}</h3>
      <IonLabel className={Styles.label} position='stacked'>
        {props.textHeading ?? 'Phone Number'}
      </IonLabel>
      <IonRow className={Styles.item}>
        <IonItem lines='none' className={Styles.inputContainer}>
          <PhoneInput
            className={Styles.phoneInput}
            placeholder={props.textFieldPhoneNumberPlaceholder ?? 'Please Enter Your Phone Number'}
            international
            defaultCountry="GB"
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            error={props.error?.message}
            data-testid="MobileNumberCapture.Input"
          />
          <p className={Styles.errorMessage}>{props.error && ( props.errorMessage ?? 'An error occured.')}</p>
        </IonItem>

      </IonRow>
    </IonGrid>
  )
}
