import { IonCard, IonTitle, IonToolbar, useIonAlert, useIonModal } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import SingleClickButton from '../../../common/components/SingleClickButton'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'
import { TaskStatus, useListProjectTasksQuery, useSetTaskStatusMutation } from '../../../graphql/generated'
import Task from './Task'
import { AnyLevelTask } from './types'

const MODAL_BREAKPOINT = 0.4

const useUncompleteTaskModal = ({ task }: { task: AnyLevelTask }) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false)
  const [ present ] = useIonAlert()
  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const Task_Status_Changed = useAnalyticsEvent("Task_Status_Uncompleted")
  const setTaskStatus = useSetTaskStatusMutation(gqlDataSource, { onSuccess: async () => {
    await Task_Status_Changed({
      projectId: task.projectId,
      taskId: task.id,
      taskPreviousStatus: task.status,
      taskTitle: task.title,
    })
    await queryClient.invalidateQueries(useListProjectTasksQuery.getKey({ id: task.projectId }))
    setIsOpen(false)
  },
  onError: (error) => {
    const message = error instanceof Error ? error.message : String(error)
    present({
      header: "Unable to update Task",
      message,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
        },
      ],
    })

  },
  })

  const handleDismiss = useCallback(() => {
    setIsOpen(false)
  }, [ setIsOpen ])

  const handleConfirm = useCallback(async () => {
    await setTaskStatus.mutateAsync({ newStatus: TaskStatus.NotStarted, taskId: task.id })
  }, [ task.id ])

  const render = useCallback(() => {
    return (
      <WeaverIonModal id={`UncompleteTaskModal.${task.id}`} isOpen={isOpen} onDidDismiss={handleDismiss} initialBreakpoint={MODAL_BREAKPOINT} breakpoints={[ MODAL_BREAKPOINT ]}>
        <WeaverIonHeader>
          <IonToolbar>
            <IonTitle>Mark this Task as Incomplete?</IonTitle>
          </IonToolbar>
        </WeaverIonHeader>

        <WeaverIonContent>
          <IonCard>
            <Task task={task} variant="display" />
          </IonCard>

          <IonToolbar>
            <SingleClickButton slot='end' onClick={handleConfirm}>Mark incomplete</SingleClickButton>
          </IonToolbar>
        </WeaverIonContent>
      </WeaverIonModal>
    )
  }, [ isOpen, task.id, handleDismiss, handleConfirm ])

  const open = () => {
    setIsOpen(true)
  }

  return {
    render,
    open,
  }
}

export default useUncompleteTaskModal
