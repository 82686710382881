import React, { Dispatch, SetStateAction, useContext, useState } from 'react'

type WorkHistoryItemIdState = string | undefined

type MyContractorProfileContextState = {
  /** The response from useState signifying the selected WorkHistory ID */
  workHistoryItemIdState: [WorkHistoryItemIdState, Dispatch<SetStateAction<WorkHistoryItemIdState>>],
}

const MyContractorProfileContext = React.createContext<MyContractorProfileContextState | undefined>(undefined)

const MyContractorProfileScreenState: React.FC = ({ children }) => {
  const value: MyContractorProfileContextState = {
    workHistoryItemIdState: useState<WorkHistoryItemIdState>(),
  }

  return (
    <MyContractorProfileContext.Provider value={value}>
      {children}
    </MyContractorProfileContext.Provider>
  )
}

export const useMyContractorProfileScreenState = (): MyContractorProfileContextState => {
  const state = useContext(MyContractorProfileContext)
  if (state === undefined) {
    throw new Error('[useMyContractorProfileScreenState] State is not ready to be used.')
  }

  return state
}

export default MyContractorProfileScreenState
