import React from 'react'
import { IonButton } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"

type ThumbnailPhotoUnstartedProps = {
  photoSizeSmall?: boolean,
  uploadedFileId?: string,
}

const ThumbnailPhotoUnstarted: React.FC<ThumbnailPhotoUnstartedProps> = ({ uploadedFileId, photoSizeSmall = false }) =>
  <IonButton data-uploadedfile={uploadedFileId} className={`${photoSizeSmall ? Styles.thumbnailPhotoContainerSmall : Styles.thumbnailPhotoContainer}`} fill="clear" size="small"/>

export default ThumbnailPhotoUnstarted

