import React, { useEffect } from 'react'
import { isFunction } from '../../../common/utils'
import { useMyIndividual } from '../MyIndividualProvider/MyIndividualProvider'
import { useAuthContext } from '../AuthProvider'

export const CustomerIOProvider: React.FC= ({ children }) => {
  const myIndividual = useMyIndividual()
  const auth = useAuthContext()

  // eslint-disable-next-line -- cio is loaded in the index.html, may be undefined
  const cio = (window as any)._cio

  // We want this to only run once, after the dependencies are available
  useEffect(() => {
    // Check we have our dependencies
    if (!cio || !auth || !myIndividual) return

    // Fingerprint the library
    if (!isFunction(cio?.identify)) {
      console.error('[CustomerIOProvider] Library does not have the identify function.')
      return
    }

    // Identify to Customer IO
    const details = {
      // We have to URL encode the values, as they are passed directly into the URL by the CIO library
      id: myIndividual.id,
      email: auth.userData?.email,
    }

    cio.identify(details)
    console.debug('[CustomerIOProvider] Identifying: ', details)
  }, [ cio, myIndividual, auth ])

  return <>{children}</>
}

export default CustomerIOProvider
