import React, { useRef, useState } from 'react'
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import { DateTime } from 'luxon'
import { ScreenComponentProps } from './WeaverDatePicker'

import Styles from './WeaverDatePicker.module.scss'

const currentYear = DateTime.now().year

const gridSize = 24 // the total number of buttons to display in the grid

const firstPageYearButtonCount = gridSize - 1 // the first page has one ... button (at the end)
const otherPageYearButtonCount = gridSize - 2 // all other pages have two ... buttons (at the start and end)

/**
 * `GridPickYear` displays a grid of buttons which start with the current year and enables the user
 * to page backwards and forwards in time (but not beyond the current year).
 *
 * When a year is selected, it calls the `setYear(number)` function passed.
 */
const GridPickYear: React.FC<ScreenComponentProps> = ({ setYear, selectedYear, min, max }) => {
  const [ fromYear, setFromYear ] = useState<number>(currentYear)
  const page = useRef<number>(0)
  const isFirstPage = page.current === 0
  const isSecondPage = page.current === 1

  const onClickPreviousPage = () => {
    // The number of years to add is however many years we are going to show on the page we are targetting
    const yearsOnTargetPage = isSecondPage // going to the first
      ? firstPageYearButtonCount
      : otherPageYearButtonCount

    page.current--
    setFromYear(fromYear + yearsOnTargetPage)
  }

  const onClickNextPage = () => {
    // The number of years to remove is how ever many years we are showing on the current page
    const yearsOnThisPage = isFirstPage
      ? firstPageYearButtonCount
      : otherPageYearButtonCount

    page.current++
    setFromYear(fromYear - yearsOnThisPage)
  }

  const buttons = Array.from({ length: gridSize }, (_, index) => {
    const isFirstButton = index === 0
    if (isFirstButton && !isFirstPage) {
      // This ... button is at the start and changes the year options forward in time
      return <IonButton onClick={onClickPreviousPage}>...</IonButton>
    }

    const isLastButton = index === gridSize - 1
    if (isLastButton) {
      // This ... button is at the end and changes the year options backwards in time
      return <IonButton onClick={onClickNextPage}>...</IonButton>
    }

    // Count the years down, offsetting by the ... button we might have first
    const offset = isFirstPage ? index : index - 1
    const displayYear = fromYear - offset
    const isSelected = selectedYear === displayYear

    const isBelowMax = max ? max.year >= displayYear : true
    const isAboveMin = min ? min.year <= displayYear : true
    const isSelectable = isBelowMax && isAboveMin

    return (
      <IonButton
        fill={isSelected ? 'solid' : 'outline'}
        disabled={!isSelectable}
        onClick={() => setYear(displayYear)}
        color={isSelected ? 'primary' : undefined}
      >
        {displayYear}
      </IonButton>
    )
  })

  return (
    <IonGrid className={Styles.datePickerGridContainer}>
      <IonRow className={Styles.datePickerGridRow}>
        {buttons.map((rowButton, colIndex) => (
          <IonCol size='3' key={colIndex}>{rowButton}</IonCol>
        ))}
      </IonRow>
    </IonGrid>
  )
}

export default GridPickYear
