import React from 'react'
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/react'
import { ScreenComponentProps } from './WeaverDatePicker'
import { asMonthNumbers } from '../../utils/date'

import Styles from './WeaverDatePicker.module.scss'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const gridColumnCount = 3

/**
 * `GridPickMonth` displays a grid of buttons of all 12 months.
 *
 * When a month is selected, it calls the `setMonth(number)` function passed.
 */
const GridPickMonth: React.FC<ScreenComponentProps> = ({ setMonth, selectedMonth, selectedYear, min, max }) => {
  if (selectedYear === undefined) throw new Error('[GridPickMonth] Undefined selectedYear! This should never happen in the real world.')

  const buttons = Array.from({ length: months.length }, (_, index) => {
    const displayMonth = months[ index ]
    const numericMonth = asMonthNumbers(index + 1)
    const isSelected = selectedMonth === numericMonth

    const isBelowMax = max ? max.year > selectedYear || max.month >= numericMonth : true
    const isAboveMin = min ? min.year < selectedYear || min.month <= numericMonth : true
    const isSelectable = isBelowMax && isAboveMin

    return (
      <IonButton
        fill={isSelected ? 'solid' : 'outline'}
        disabled={!isSelectable}
        onClick={() => setMonth(numericMonth)}
        color={isSelected ? 'primary' : undefined}
      >
        {displayMonth}
      </IonButton>
    )
  })

  return (
    <IonGrid className={Styles.datePickerGridContainer}>
      <IonRow className={Styles.datePickerGridRow}>
        {buttons.map((rowButton, colIndex) => (
          <IonCol size='4' key={colIndex}>{rowButton}</IonCol>
        ))}
      </IonRow>
    </IonGrid>
  )
}

export default GridPickMonth
