import { alwaysArray } from "."
import { WeaverAddressInput } from "../../graphql/generated"

type AddressComponent = {
  short_name: string,
  long_name: string,
}

export const getAddressComponent = (place: google.maps.places.PlaceResult, type: string): AddressComponent | undefined =>
  alwaysArray(place.address_components).find(each => alwaysArray(each.types).includes(type))

export const getRequiredAddressComponent = (place: google.maps.places.PlaceResult, type: string): AddressComponent => {
  const result = getAddressComponent(place, type)
  if (!result) {
    console.error(`[convertGoogleMapsPlaceResultToWeaverAddressInput] Place is missing the required address component: ${type}`, place)
    throw new Error(`Place is missing the required address component: ${type}`)
  }
  return result
}

/**
 * addressLine1 will literally return 'UNDEFINED' for street number if we don't guard against it.
 */
export const convertGoogleMapsPlaceResultToWeaverAddressInput = (place: google.maps.places.PlaceResult): WeaverAddressInput => {
  return {
    addressLine1: `${getAddressComponent(place, 'street_number')?.short_name ?? ''} ${getAddressComponent(place, 'route')?.short_name}`,
    postTown: getAddressComponent(place, 'postal_town')?.short_name,
    country: getAddressComponent(place, 'country')?.long_name,
    postCode: getAddressComponent(place, 'postal_code')?.short_name ?? '',
  }
}
