import { Duration } from 'luxon'
import React from 'react'
import { useGraphQLDataSource } from '../../api/graphql'
import { sortBy, multiSort } from '../../common/sort'
import { useListProjectTasksQuery } from '../../graphql/generated'
import { getCurrentTopLevelTask } from './taskUtils'

type NextTopLevelTaskDisplayProps = {
  projectId: string,
}

const PROJECT_TASKS_STALETIME = Duration.fromObject({ minutes: 5 }).as('milliseconds')

const NextTopLevelTaskDisplay: React.FC<NextTopLevelTaskDisplayProps> = ({ projectId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const currentTopLevelTask = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    staleTime: PROJECT_TASKS_STALETIME,
    select: data => getCurrentTopLevelTask( multiSort(data.listProjectTasks, [ sortBy("order"), sortBy("title") ]) ),
  })
  const nextTask = currentTopLevelTask.data

  return <>{nextTask?.title}</>
}

export default NextTopLevelTaskDisplay
