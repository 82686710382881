import { useEnvironmentContext } from "../../api/providers/EnvironmentProvider"

export const useDevThrowErrorTestConsoleError = () => {
  const env = useEnvironmentContext()

  return (message: string, ...extraConsoleLogParams: unknown[]) => {
    // Throw error in developer's environments
    if (env.account.type === 'dev') {
      console.error(message, ...extraConsoleLogParams)
      throw new Error(message)
    }

    // Console log error in the published test environment
    if (env.account.type === 'published' && env.account.name === 'test') {
      console.error(message, ...extraConsoleLogParams)
    }

    // Ignore in all other casees
  }
}
