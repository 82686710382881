import React, { ComponentProps } from 'react'
import { IonModal } from '@ionic/react'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'
import UnclaimedInviteFakeProfile from './UnclaimedInviteFakeProfile'
import { ShowProjectInvite } from '../ShowProjectTypes'

type UnclaimedInviteFakeProfileModalProps = {
  invite: ShowProjectInvite,
} & ComponentProps<typeof IonModal>

/**
* `UnclaimedInviteFakeProfileModal` has been created specifically
* to offer a minimal profile for invited members who are yet to onboard and join a project.
*
* The decision was made to create a new Modal/Page instead of adapting the existing `PublicProfileModal` & `ScreenHome` page.
* By doing this, components `ListWorkHistoryItem` and `RangeBadges` present in `ScreenHome` and `PublicProfileModal`
* can remain unaffected as this data does not exist for a member yet to onboard in our system.
*/

const UnclaimedInviteFakeProfileModal: React.FC<UnclaimedInviteFakeProfileModalProps> = ({ invite, ...ionModalProps } ) => <WeaverIonModal
  id='UnclaimedInviteFakeProfileModal'
  {...ionModalProps}
  disableDirectChildStructureChecks={true}>
  <UnclaimedInviteFakeProfile {...invite} />
</WeaverIonModal>

export default UnclaimedInviteFakeProfileModal
