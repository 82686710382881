import React from 'react'
import Styles from './ResponsiveContentWrapper.module.scss'

type  ResponsiveContentWrapperProps = {
  children?: React.ReactNode,
  useFullHeight?: boolean,
};

const ResponsiveContentWrapper: React.FC<ResponsiveContentWrapperProps> = ({ children, useFullHeight }) => {

  return (<div className={`${Styles.responsiveContentWrapper} ${useFullHeight ? Styles.fullHeight : null}`}>
    {children}
  </div>)
}

export default ResponsiveContentWrapper
