import React from "react"
import { IonInput } from "@ionic/react"
import { Currency, Money } from "../../../graphql/generated"
import { asMoney, moneyAmountAsNumber } from "../../utils/currency"
import CurrencyInput from 'react-currency-input-field'
import Styles from "./WeaverMoneyIonInput.module.scss"

type WeaverMoneyIonInputProps = {
  value: Money | undefined | null,
  setValue: (value?: Money | undefined | null) => Promise<void> | void,
  minValue?: Money,
  maxValue?: Money,
  /** @deprecated -- pennies will only be shown if there are pennies to show (like a decimal number) */
  withPennies: boolean,
} &
/** @deprecated */
Omit<React.ComponentProps<typeof IonInput>, 'type' | 'value' | 'placeholder' | 'min' | 'max' | 'onIonChange' | 'onKeyPress'>

/**
 * This component is built on top of `react-currency-input-field`.
 * It uses the currency of the value passed in.
 * It allows any positive number to be entered directly to 2 d.p. - including numbers outside the minValue =>= maxValue range
 * Keyboard controls: up/down change the value by £1,000 (unless the new value would be outside the minValue =>= maxValue range)
 * Typing `m` will make the number a million, `k` will make the number a thousand
 */
const WeaverMoneyIonInput: React.FC<WeaverMoneyIonInputProps> = ({ value, setValue, minValue, maxValue }) => {
  const currency = value?.currency ?? Currency.Gbp
  const valueInPence = value == null ? value : moneyAmountAsNumber(value)
  const valueInPounds = valueInPence == null ? undefined : valueInPence / 100

  const minValueInPounds = minValue == null ? undefined : (moneyAmountAsNumber(minValue) ?? 0) / 100    // NOTE: `minValue` should never be undefined/null because of the guard
  const maxValueInPounds = maxValue == null ? undefined : (moneyAmountAsNumber(maxValue) ?? Number.MAX_VALUE) / 100    // NOTE: `maxValue` should never be undefined/null because of the guard

  const handleOnIonChange = (changedValue: string | undefined) => {
    const updatedAmountInPounds = Number(changedValue ?? '0')
    const updatedAmountInPence = updatedAmountInPounds * 100
    const updatedValue = asMoney(currency, updatedAmountInPence)
    setValue(updatedValue)
  }

  return (
    <CurrencyInput
      role="spinbutton" // We use this role for our E2E tests. DO NOT REMOVE.
      className={Styles.currencyInput}
      value={valueInPounds}
      defaultValue={0}
      onValueChange={handleOnIonChange}
      intlConfig={{
        locale: navigator.language,
        currency,
      }}
      decimalsLimit={2}
      step={10000}
      min={minValueInPounds}
      max={maxValueInPounds}
    />
  )
}

export default WeaverMoneyIonInput
