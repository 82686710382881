import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { WeaverAddress, useShowProjectGoogleMapLatLngQuery } from '../../../graphql/generated'
import ErrorBlock from '../ErrorBlock'
import GoogleMap from '../GoogleMap/GoogleMap'
import Marker from '../GoogleMap/Marker'
import LoadingSpinner from '../LoadingSpinner'
type GoogleMapFromWeaverAddressProps = {
  isExact?: boolean,
  address: WeaverAddress,
  zoomLevel?: number,
}

const GoogleMapFromWeaverAddress: React.FC<GoogleMapFromWeaverAddressProps> = ({ address, zoomLevel, isExact= false }) => {

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectGoogleMapLatLangQuery = useShowProjectGoogleMapLatLngQuery(gqlDataSource, { address, isExact })

  if (showProjectGoogleMapLatLangQuery.isFetching && !showProjectGoogleMapLatLangQuery.data) {
    return <LoadingSpinner name="GoogleMapFromWeaverAddress"/>
  }

  if (showProjectGoogleMapLatLangQuery.error || !showProjectGoogleMapLatLangQuery.data) {
    return <ErrorBlock name='GoogleMapFromWeaverAddress' onRefresh={showProjectGoogleMapLatLangQuery.refetch} />
  }

  const exactCoOrdinates = showProjectGoogleMapLatLangQuery.data.getCoordinatesForWeaverAddress

  return (
    <GoogleMap zoomLevel={zoomLevel} isExact={isExact} coords={showProjectGoogleMapLatLangQuery.data.getCoordinatesForWeaverAddress}>
      {isExact && <Marker coords={exactCoOrdinates} />}
    </GoogleMap>
  )
}
export default GoogleMapFromWeaverAddress
