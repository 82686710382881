import React from 'react'
import { IonButton } from '@ionic/react'
import { TNote, useNotes } from '../../../api/services/forgetMeNote/useNotes'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

const PACKAGE = 'TechDemoPage.ForgetMeNotePage'

function arrayElement<T>(list: T[]) {
  return list[Math.floor(Math.random() * list.length)]
}
const NAMES = [ "Diego", "Dan", "Rowan", "Tom", "James", "Bill", "Damien", "Chris" ]
const LOREMIPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. `

const ForgetMeNotePage: React.FC = () => {
  const [ notes, addNote, updateNote, deleteNote ] = useNotes()

  console.debug(`[${PACKAGE}] Render: `, { notes })

  const addNoteWithRandomData = () =>
    addNote({
      title: arrayElement(NAMES),
      text: LOREMIPSUM,
    })

  const updateNoteWithRandomData = (note: TNote) =>
    updateNote({
      ...note,
      title: arrayElement(NAMES),
      text: LOREMIPSUM,
    })

  return (
    <WeaverIonPage id='ForgetMeNotePage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <h1>Tech Demo: Forget Me Note</h1>
        <div style={{ marginLeft: '1rem' }}>
          {notes === undefined
            ? <LoadingSpinner name='ForgetMeNotePage.useNotes' />
            : notes === null
              ? <p>There is an error loading the notes!</p>
              : notes.map(note =>
                <Note
                  key={note.id}
                  note={note}
                  updateNoteButtonClickFn={() => updateNoteWithRandomData(note)}
                  deleteNoteButtonClickFn={() => { deleteNote(note) }}
                />)}
          <IonButton onClick={addNoteWithRandomData}>New note</IonButton>
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

type TNoteProps = {
  note: TNote,
  updateNoteButtonClickFn: () => void,
  deleteNoteButtonClickFn: () => void,
}
const Note: React.FC<TNoteProps> = ({ note, updateNoteButtonClickFn, deleteNoteButtonClickFn }) => {
  type TFieldProps = {
    title: string,
    value: string,
  }
  const Field: React.FC<TFieldProps> = ({ title, value }) => <div><strong>{title}: </strong><span>{value}</span></div>
  return <div style={{ marginBottom: '2rem' }}>
    <h3>Note</h3>
    <Field title="ID" value={note.id} />
    <Field title="Title" value={note.title} />
    <Field title="Text" value={note.text} />
    <IonButton color='warning' onClick={updateNoteButtonClickFn}>Update note</IonButton>
    <IonButton color='danger' onClick={deleteNoteButtonClickFn}>Delete note</IonButton>
  </div>
}

export default ForgetMeNotePage
