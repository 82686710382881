import React, { MutableRefObject, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { IonAlert, IonButton, IonButtons, IonHeader, IonIcon, IonToolbar } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { getRootQueryKey } from '../../../common/hooks/query'
import { useGetContractorProfileQuery, useUpdateContractorProfileWorkHistoryMutation } from '../../../graphql/generated'
import { WorkHistoryConstructionDates, zWorkHistoryConstructionDates, AddConstructionDatesModal } from './AddConstructionDatesModal'

// Exported context for controlling this modal (could be extracted into a context.ts or similar)

export type MissingConstructionDatesModalContext = {
  displayMissingConstructionDatesModal: (workHistoryId: string) => void,
}

export const MissingConstructionDatesModalContext = React.createContext<MissingConstructionDatesModalContext>({
  displayMissingConstructionDatesModal: () => {
    throw new Error('incorrect-mount: Context can only be used within the children of a MissingConstructionDates component')
  },
})

// Component

type MissingConstructionDatesProps = {
  weaverPageRef?: MutableRefObject<HTMLElement | undefined>,
}

// TODO: after one month from 16/02/23 check with product if MissingConstructionDates can be removed MW-2231
export const MissingConstructionDates: React.FC<MissingConstructionDatesProps> = ({ weaverPageRef, children }) => {
  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorProfileWorkHistory = useUpdateContractorProfileWorkHistoryMutation(gqlDataSource)
  const history = useHistory()

  const [ constructionIsStillOnGoing, setConstructionIsStillOnGoing ] = useState(false)
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const [ workHistoryId, setWorkHistoryId ] = useState<string | undefined>(undefined)
  const [ showAddConstructionDatesModalScreen, setShowAddConstructionDatesModalScreen ] = useState(1)
  const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined)

  const triggerEventWorkHistoryAddedConstructionEnd = useAnalyticsEvent('WorkHistory_Added_Construction_End')
  const triggerEventWorkHistorySelectedConstructionOnGoing = useAnalyticsEvent("WorkHistory_Selected_Construction_Ongoing")

  const form = useForm<WorkHistoryConstructionDates>({
    defaultValues:{
      constructionStartedDate: undefined,
      constructionCompletedDate: undefined,
    },
    mode: "onChange",
    resolver: zodResolver(zWorkHistoryConstructionDates),
  })

  const onSubmitOfConstructionDatesIfFieldsAreValid = async () => {
    if (constructionIsStillOnGoing) {
      await triggerEventWorkHistorySelectedConstructionOnGoing()
      history.push('http://help.weaver.build/en/articles/6907932-past-projects-that-weaver-accepts')
      return
    }

    // FIX-ME: This guard wouldn't be necessary if this function was in the right place!
    if (!workHistoryId) return

    const isValid = await form.trigger('constructionCompletedDate')
    const constructionCompletedDate = form.getValues('constructionCompletedDate')
    const constructionStartedDate = form.getValues('constructionStartedDate')

    if (isValid && constructionCompletedDate) {
      const year = DateTime.fromISO(constructionCompletedDate).year
      const month =  DateTime.fromISO(constructionCompletedDate).monthShort

      await triggerEventWorkHistoryAddedConstructionEnd({
        month: month,
        year: year,
      })

      await updateContractorProfileWorkHistory.mutateAsync({ input: { id: workHistoryId, constructionStartedDate: constructionStartedDate, constructionCompletedDate: constructionCompletedDate } },{
        onSuccess: () => {
          const allGetContractorProfileQueryKeys = getRootQueryKey(useGetContractorProfileQuery.getKey)
          queryClient.invalidateQueries(allGetContractorProfileQueryKeys)
          setWorkHistoryId(undefined)
          setShowAddConstructionDatesModalScreen(1)
        },
        onError: () => {
          console.error(`[AddConstructionDatesModal] Failed to add construction dates ${workHistoryId}`)
          setShowUnsuccessfulAlert(true)
          setWorkHistoryId(undefined)
          setShowAddConstructionDatesModalScreen(1)
        },
      })
    } else {
      setErrorMessage('Please enter a date')
    }
  }

  /** Trigger function to display the modal for a given workHistoryId */
  const context: MissingConstructionDatesModalContext = {
    displayMissingConstructionDatesModal: (workHistoryId: string) => {
      setWorkHistoryId(workHistoryId)
    },
  }

  return <MissingConstructionDatesModalContext.Provider value={context}>
    {children}
    <div>
      <AddConstructionDatesModal
        header={ () => (<IonHeader className='ion-no-border'>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setWorkHistoryId(undefined)}>
                <IonIcon icon={allIonIcons.close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>)}
        form={form}
        workHistoryId={workHistoryId}
        showAddConstructionModalScreen={showAddConstructionDatesModalScreen}
        setShowAddConstructionModalScreen={setShowAddConstructionDatesModalScreen}
        constructionIsStillOnGoing={constructionIsStillOnGoing}
        setConstructionIsStillOnGoing={setConstructionIsStillOnGoing}
        onSubmitOfConstructionDatesIfFieldsAreValid={onSubmitOfConstructionDatesIfFieldsAreValid}
        isOpen={!!workHistoryId}
        canDismiss={true}
        onDidDismiss={() => { setWorkHistoryId(undefined), form.reset(), setShowAddConstructionDatesModalScreen(1) }}
        presentingElement={weaverPageRef?.current}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />

      <IonAlert
        isOpen={showUnsuccessfulAlert}
        onDidDismiss={() => setShowUnsuccessfulAlert(false)}
        header={'Oops! Something has gone wrong.'}
        buttons={[ 'Dismiss' ]}
      />
    </div>
  </MissingConstructionDatesModalContext.Provider>
}
