import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import { IonButton, IonFooter, IonText } from "@ionic/react"
import { pageConfig_Profile_Public, useRouteTo } from "../../../routes"
import GlobalHeader from "../../../common/components/GlobalHeader"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import Styles from '../TaskActionerPage/TaskActionerPage.module.scss'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from "./commonActions"
import { TeamType } from "../../../graphql/generated"

const ScreenOpenContractorProfile: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const routeToContractorProfile = useRouteTo(pageConfig_Profile_Public.path)

  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ScreenOpenContractorProfile" />
  const task = getActionableTaskQuery.data.getTask

  if (!task.actionablePayload) throw new Error(`Missing actionable payload for OpenContractorProfile task ${task.id}`)

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h2 className={`${Styles.title} ion-margin-top`}>{task.title}</h2>
      {task.actionSummary && <IonText className={Styles.ionText} color='medium'>{task.actionSummary}</IonText>}
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border'>
      <IonButton expand="block" onClick={routeToContractorProfile({ teamId: task.actionablePayload, teamType: TeamType.Contractor })}>View contractor profile</IonButton>
      <IonButton className={Styles.ionOutlineButton} expand="block" fill="outline" onClick={useMarkTaskCompleteMutation(getActionableTaskQuery)}>Mark task as complete</IonButton>
      <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
    </IonFooter>
  </>
}

export default ScreenOpenContractorProfile
