import React from 'react'

import { IonSpinner, IonIcon, IonButton } from "@ionic/react"
import { imageOutline, refreshOutline } from "ionicons/icons"
import { useAutoRetryGetUploadedFileQuery } from "../../hooks/useAutoRetryGetUploadedFileQuery"
import Styles from "./OpenUploadedFileModal.module.scss"
import { UseQueryResult } from '@tanstack/react-query'
import { GetUploadedFileQuery } from '../../../graphql/generated'

const TryAgainButton: React.FC<{refetch: () => void}> = ({ refetch }) => (
  <IonButton onClick={() => refetch()} size="small" color="danger">
    <IonIcon icon={refreshOutline} /> Try Again
  </IonButton>
)

type OpenUploadedFileModalProps = {
  uploadedFileQuery: UseQueryResult<GetUploadedFileQuery, unknown>,
  action: (url: string) => void,
}

const OpenUploadedFileModal: React.FC<OpenUploadedFileModalProps> = ({ uploadedFileQuery, action }) => {
  const maxAttemptsReached = useAutoRetryGetUploadedFileQuery(uploadedFileQuery)

  const { refetch } = uploadedFileQuery
  const url = uploadedFileQuery.data?.getUploadedFile.signedUrlForDownload.url

  if (uploadedFileQuery.isLoading || !url ){
    return <div className={Styles.container}>
      <IonSpinner /> <span className={Styles.statusDescription}>Processing the file.</span>
    </div>
  }

  if (maxAttemptsReached){
    return <TryAgainButton refetch={refetch} />
  }

  return (
    <div className={Styles.container} onClick={() => action(url)} >
      <IonIcon icon={imageOutline} className={Styles.downloadIcon} />
      <span className={Styles.statusDescription}>Click to open image.</span>
    </div>
  )
}

export default OpenUploadedFileModal
