import { IonItem, IonText } from '@ionic/react'
import React from 'react'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'

type PublicWeaverProfileProps = {
  id: string,
}

const PublicWeaverProfile: React.FC<PublicWeaverProfileProps> = ({ id }) => {
  console.debug(`[PublicWeaverProfile] Render: `, { id })

  return <>
    <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
      <h3>Weaver</h3>
    </IonItem>
    <IonText className={Styles.lightGrey}>
      <p>Here to help.</p>
    </IonText>
    <TeamMembers teamId={id} />
  </>
}

export default PublicWeaverProfile
