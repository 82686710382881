import React from "react"
import buildersPng from '../../../assets/images/builders.png'
import SupportPhoto from '../../../assets/images/support.jpg'
import TitleWithBulletsPage from "../../../domains/onboarding/team/pages/TitleWithBulletsPage"
import { useWeaverFlags } from "../../thirdParty/launchDarkly/useWeaverFlags"

const ContactSupportScreen = () => {
  const { ["MW-2483-updated-brand-illustrations"]: updatedIllustrations } = useWeaverFlags()

  return <TitleWithBulletsPage
    title="You are about to be transferred to our Live Support Team "
    imgProps={{
      src: updatedIllustrations ? SupportPhoto : buildersPng,
    }}
    bullets={[]}
  />
}

export default ContactSupportScreen
