import React from 'react'

import FsLightbox from 'fslightbox-react'
import Styles from "./ImageLightbox.module.scss"

const BUTTON_CUSTOM_HEIGHT = "30px"
const BUTTON_CUSTOM_WIDTH = "30px"

type ImageLightboxProps = {
  componentKey: string | undefined,
  toggler: boolean,
  imageUrls: string [],
  selectedImageUrl: string | undefined,
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({ componentKey, toggler, imageUrls, selectedImageUrl }) => {

  const selectedElementIndex = imageUrls.findIndex(e => e === selectedImageUrl)

  if (imageUrls.length > 0 &&
      selectedImageUrl !== undefined &&
      selectedElementIndex !== -1 &&
      componentKey !== undefined) {

    return (
      <FsLightbox
        key={componentKey}
        toggler={toggler}
        type="image"
        sourceIndex={selectedElementIndex}
        sources={imageUrls}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore next-line
        // HACK: FsLightbox does not provider for the Pro version which accepts a svg parameter to customise the tool bar.
        svg={{
          toolbarButtons: {
            zoomIn: {
              width: BUTTON_CUSTOM_WIDTH,
              height: BUTTON_CUSTOM_HEIGHT,
            },
            zoomOut: {
              width: BUTTON_CUSTOM_WIDTH,
              height: BUTTON_CUSTOM_HEIGHT,
            },
            slideshow: {
              start: {
                width: BUTTON_CUSTOM_WIDTH,
                height: BUTTON_CUSTOM_HEIGHT,
              },
              pause: {
                width: BUTTON_CUSTOM_WIDTH,
                height: BUTTON_CUSTOM_HEIGHT,
              },
            },
            fullscreen: {
              enter: {
                width: BUTTON_CUSTOM_WIDTH,
                height: BUTTON_CUSTOM_HEIGHT,
              },
              exit: {
                width: BUTTON_CUSTOM_WIDTH,
                height: BUTTON_CUSTOM_HEIGHT,
              },
            },
            close: {
              width: BUTTON_CUSTOM_WIDTH,
              height: BUTTON_CUSTOM_HEIGHT,
            },
          },
          slideButtons: {
            previous: {
              width: BUTTON_CUSTOM_WIDTH,
              height: BUTTON_CUSTOM_HEIGHT,
            },
            next: {
              width: BUTTON_CUSTOM_WIDTH,
              height: BUTTON_CUSTOM_HEIGHT,
            },
          },
        }}
        slideChangeAnimation={Styles.customSlideAnimation}
      />
    )
  }

  return <></>

}

export default ImageLightbox

