import React, { useState } from "react"
import { downloadUploadedFile, fileIconSelector, openFile } from "../../common/utils/files"
import { DateTime } from 'luxon'
import { formatBytes } from "../../common/utils"
import { IonButton, IonCard, IonIcon, IonImg, IonItem, IonLabel, IonThumbnail, useIonModal } from "@ionic/react"
import { ContentDisposition, SignedUrlStatus, UploadedFileStatus, useGetUploadedFileQuery, useUpdateUploadedFileMutation } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"
import LoadingSpinner from "../../common/components/LoadingSpinner"

import Styles from "./ShowProjectPage.module.scss"

import { fullSizeTransformation } from "../../common/utils/imageTransformations"
import { ProjectDocument } from "./ProjectDocumentTypes"
import { trashBinOutline } from "ionicons/icons"
import { useIsWeaverTeamMember } from "../../api/providers/AuthProvider"
import { DownloadUploadedFileModal, DownloadUploadedFileModalStyles } from "../../common/components/DownloadUploadedFileModal"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"

type ProjectDocumentRowProps = {
  document: ProjectDocument,
  isDownloadingAll: boolean,
  onDownload: (document: ProjectDocument) => void | unknown,
  onArchived: () => void | unknown,
}

const ProjectDocumentRow: React.FC<ProjectDocumentRowProps> = ({ document, isDownloadingAll = false, onDownload, onArchived }) => {
  const { id: projectDocumentId } = document
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const [ , setIsDownloadingInProgress ] = useState<boolean>(isDownloadingAll)

  const weaverFlags = useWeaverFlags()

  const isWeaverTeamMember = useIsWeaverTeamMember()

  const [ present ] = useIonModal(DownloadUploadedFileModal, {
    uploadedFileId: projectDocumentId,
  })

  const updateUploadFileMutation = useUpdateUploadedFileMutation(gqlDataSource)
  const { isLoading, data } = useGetUploadedFileQuery(gqlDataSource, { id: projectDocumentId, config: { disposition: ContentDisposition.Attachment, transformation: fullSizeTransformation  } })

  if (isLoading) return <LoadingSpinner name={"ProjectDocumentRow"} />

  const uploadedFile = data?.getUploadedFile

  if (!uploadedFile) return <></>

  const fileName = uploadedFile.fileName

  if (!fileName) return <></>

  const urlStatus = uploadedFile?.signedUrlForDownload.status

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const openFullSizeImageModal = () => {
    present({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const handleDownload = () => {
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openFullSizeImageModal()
    } else {
      const saveToDownloadsFolder = true
      downloadUploadedFile(uploadedFile, onProgressUpdate, saveToDownloadsFolder)
      onDownload(document)
    }
  }

  const archiveUploadedFile = async (event: React.MouseEvent) => {
    event.stopPropagation()
    await updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadedFile.id,
        status: UploadedFileStatus.Archived,
      },
    }, {
      onSuccess: () => {
        onArchived()
      },
    })
  }

  return (
    <IonCard className={`${Styles.projectDocumentCard} ion-no-margin ion-margin-vertical`} onClick={handleDownload}>
      <IonItem>
        <IonThumbnail onClick={() => openFile(fileName, uploadedFile.fileContentType || "")}>
          <IonImg src={fileIconSelector(fileName)} />
        </IonThumbnail>
        <IonLabel>
          { uploadedFile.uploadedByTeam && <p>Author: {uploadedFile.uploadedByTeam?.name}</p> }
          <h3>{uploadedFile.fileName}</h3>
          { uploadedFile.fileSizeInBytes && <p>{formatBytes(uploadedFile.fileSizeInBytes)}</p>}
        </IonLabel>
        <div className={Styles.iconDateContainer}>
          <IonLabel>
            <p>{DateTime.fromISO(uploadedFile.updatedAt || "").toLocaleString(DateTime.DATE_SHORT)}</p>
          </IonLabel>
          <IonButton color='weaver-component' hidden={!isWeaverTeamMember} onClick={(e) => archiveUploadedFile(e)}><IonIcon icon={trashBinOutline}/></IonButton>
        </div>
      </IonItem>
    </IonCard>
  )
}
export default ProjectDocumentRow

