import { InputChangeEventDetail, IonButton, IonInput, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react'
import React, { useState } from 'react'
import JwtList from '../../../../domains/devSettings/ManageJwts/ListJwts'
import { useJwtManagerGetDecryptedJwt } from '../../../services/jwtManager/useJwtManagerGetDecryptedJwt'
import { JwtListItem } from '../../../services/jwtManager/useJwtManagerListItems'
import useCachedAuth from './useCachedAuth'

export type SetCachedJwtsInProps = {
  setCachedJwts: ReturnType<typeof useCachedAuth>[1],
}

export const CachingJwtSelector: React.FC<SetCachedJwtsInProps> = ({ setCachedJwts }) =>
  <>
    <h2>Offline Login</h2>
    <PickJwtFromList setCachedJwts={setCachedJwts} />
  </>

const PickJwtFromList: React.FC<SetCachedJwtsInProps> = ({ setCachedJwts }) => {
  const [ selectedJwt, setSelectedJwt ] = useState<JwtListItem>()

  return selectedJwt === undefined
    ?<>
      <p>Select a Stored JWT from the list below:</p>
      <JwtList withSelectButtonProps={{ onClick: jwt => setSelectedJwt(jwt) }}/>
    </>
    : <UnlockSelectedJwtListItem jwtListItem={selectedJwt} setCachedJwts={setCachedJwts} />
}

type JwtListItemInProps = {
  jwtListItem: JwtListItem,
}

const UnlockSelectedJwtListItem: React.FC<JwtListItemInProps & SetCachedJwtsInProps> = ({ jwtListItem, setCachedJwts }) => {
  const getDecryptedJwt = useJwtManagerGetDecryptedJwt()

  const [ email, setEmail ] = useState('my@email.com')
  const [ passcode, setPasscode ] = useState('123456')
  const [ hasError, setHasError ] = useState(false)

  return  <form onSubmit={event => {
    event.preventDefault()
    getDecryptedJwt.mutateAsync({ jwtListItem, email, passcode })
      .then(decryptedJwt => {
        console.log('Decrypted JWT', decryptedJwt)
        if ('errorCode' in decryptedJwt) {
          console.error('[UnlockSelectedJwtListItem] Error when decrypting JWT. ', { jwtListItem, email, decryptedJwt })
          setHasError(true)
        } else {
          setHasError(false)
          setCachedJwts(decryptedJwt)
        }
      })
  }}>
    <IonList>
      <IonListHeader>
        <h2>Decrypt Selected JWT</h2>
      </IonListHeader>
      <IonItem>
        <IonLabel>Login Email</IonLabel>
        <IonInput name='email' type='email' value={email} onIonChange={(event: CustomEvent<InputChangeEventDetail>) => setEmail(event.detail.value ?? '')} />
      </IonItem>
      <IonItem>
        <IonLabel>Encryption Passcode</IonLabel>
        <IonInput name='passcode' type='number' value={passcode} onIonChange={(event: CustomEvent<InputChangeEventDetail>) => setPasscode(event.detail.value ?? '')} />
      </IonItem>
    </IonList>
    <IonButton color={hasError ? 'danger' :''} type="submit">Unlocked selected JWT</IonButton>
  </form>
}

export default CachingJwtSelector
