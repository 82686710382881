import { useCallback, useEffect, useMemo, useState } from "react"
import useAbortableEffect from "./useAbortableEffect"
import qs, { IParseOptions } from "qs"
import z, { ZodSchema } from "zod"
import { useIonRouter } from "@ionic/react"

type TUsePageStateInternalInitial<T> = {
  data: T,
  initialized: false,
}
type TUsePageStateInternalReady<T> = {
  data: T,
  initialized: true,
  routerOptions: {
    action: "push" | "replace",
  },
}

type TUsePageStateInternal<T> = TUsePageStateInternalReady<T> | TUsePageStateInternalInitial<T>

type MergeSearchStateFn<T> = (nextData: Partial<T>, action?: "push" | "replace") => void

type TUsePageStateReturnsInitial<T> = {
  searchData: T,
  mergeSearchState: MergeSearchStateFn<T>,
  initialized: false,
}
type TUsePageStateReturnsReady<T> = {
  searchData: T,
  mergeSearchState: MergeSearchStateFn<T>,
  initialized: true,
}

type TUsePageStateReturns<T> = TUsePageStateReturnsReady<T> | TUsePageStateReturnsInitial<T>

type SearchStateOptions<TSchema> = {
  schema?: TSchema,
  parseOptions?: IParseOptions,
}
const delay = (ms: number) => new Promise( (resolve) => setTimeout(resolve, ms))

const anyRecord = z.record(z.any())

/**
 * 2-way bind a state object to IonRouter, so that modifications to state result in changes to the URL, and vice-versa
 * Supports simple immutable merging of state with minimal validation
 * Keys set to undefined are not propagated to the URL. (see buildSantizedSearchParams)
 */
export function useSearchState<TSchema extends ZodSchema>(initialData: z.infer<TSchema>, options: SearchStateOptions<TSchema> = {}): TUsePageStateReturns<z.infer<TSchema>> {
  const ionRouter = useIonRouter()

  const [ searchState, setSearchState ] = useState<TUsePageStateInternal<z.infer<TSchema>>>({ initialized: false, data: initialData })

  const schemaParse = options.schema || anyRecord

  const mergeSearchState = useCallback( (nextData: Partial<z.infer<TSchema>>, action: "push" | "replace" = "push") => {
    const nextInternalState = {
      ...searchState,
      // If the change is coming from a component, we have to assume it's been initialized, otherwise we'll be waiting forever
      initialized: true as const,
      data: {
        ...(searchState.initialized ? searchState.data : {}),
        ...nextData,
      },
      routerOptions: {
        action,
      },
    }

    setSearchState(nextInternalState)
  }, [ setSearchState, searchState ])

  // bind URL => state
  useAbortableEffect((status) => {
    async function setStateFromUrl() {
      const incomingData = qs.parse(ionRouter.routeInfo?.search, { ignoreQueryPrefix: true, ...options.parseOptions })

      const incomingUrl = ionRouter.routeInfo.pathname + qs.stringify(schemaParse.parse(incomingData), { addQueryPrefix: true })
      const currentUrl = ionRouter.routeInfo.pathname + qs.stringify(searchState.data, { addQueryPrefix: true })

      if (incomingUrl === currentUrl) {
        console.debug("[useSearchState] from url: ignoring duplicate:", JSON.stringify(currentUrl))
        return
      }

      let nextState = initialData

      // build a valid next state (missing fields should be set to their initial values if first run)
      const parsed = schemaParse.safeParse({
        ...initialData,
        ...incomingData,
      })

      if (parsed.success) {
        nextState = parsed.data
      } else {
        console.warn("[useSearchState] failed to parse search params, resetting to initial data", parsed.error)
        nextState = initialData
      }

      // some ionic components(IonAccordion) don't render correctly when value is set the first time,
      // booting the state update into an async call seems to fix this.
      await delay(1)
      if (status.aborted) {
        return
      }
      console.debug("[useSearchState] from url:", nextState)
      setSearchState({ data: nextState, initialized: true, routerOptions: { action: "replace" } })
    }

    setStateFromUrl()
  },[ ionRouter.routeInfo?.id ])

  // bind state => URL
  useEffect(() => {
    if (!searchState.initialized) return

    // if the zod schema uses preprocessing, (e.g. object preprocessing for case-insensitive)
    // parsing it will normalize these fields, so when we check for duplicates, we include key re-orderings or valid cases
    const incomingData = qs.parse(location.search, { ignoreQueryPrefix: true, ...options.parseOptions })
    const incomingUrl = ionRouter.routeInfo.pathname + "?" + qs.stringify(schemaParse.parse(incomingData))
    const nextUrl = ionRouter.routeInfo.pathname + "?" + qs.stringify(searchState.data)

    if (incomingUrl === nextUrl) {
      console.debug("[useSearchState] from state: ignoring duplicate:", JSON.stringify(nextUrl))
      return
    }

    console.debug("[useSearchState] from state:", nextUrl)
    ionRouter.push(nextUrl, undefined, searchState.routerOptions.action)

  }, [ searchState ])

  const api = useMemo(() => ({
    searchData: searchState.data,
    mergeSearchState,
    initialized: true,
  }), [ searchState, mergeSearchState ])

  return api
}
