import React from 'react'
import { IonItem } from '@ionic/react'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { NavElement } from '../../../common/components/GlobalHeader/GlobalHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { ShowProjectInvite } from '../ShowProjectTypes'
import Styles from '../../profile/PublicContractorProfile/PublicContractorProfilePage.module.scss'

const UnclaimedInviteFakeProfile: React.FC<ShowProjectInvite> = (props) => <>
  <WeaverIonHeader>
    <GlobalHeader
      navElement={NavElement.None}
      pageTitle='Public Profile'
    />
  </WeaverIonHeader>
  <WeaverIonContent className={Styles.displayContractorProfileContainer}>
    <section className={Styles.companyInfoContainer}>
      <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
        <h3>{props.companyName}</h3>
      </IonItem>
      <p className={Styles.contractorTeamType}>{props.requiredTeamType}</p>
      <p>Team has not yet registered</p>
    </section>
  </WeaverIonContent>
</>

export default UnclaimedInviteFakeProfile
