import { useRef, useEffect } from "react"
import { Duration, DurationObjectUnits } from "luxon"

type DelayedTriggerFn = (durationObject: DurationObjectUnits, callback: () => unknown) => void

/**
 * Returns a function that can be used to trigger another function every certain number of seconds.
 */
export const useCallFunctionWithDelayedTrigger = (): DelayedTriggerFn => {
  const isMounted = useRef<boolean | undefined>(true)
  useEffect(() => () => { isMounted.current = false }, [])

  return (durationObject: DurationObjectUnits, callback: () => unknown) => {
    const duration = Duration.fromObject(durationObject)
    window.setTimeout(() => {
      // If I am still mounted, after the duration has passed, call the function
      if (isMounted.current) callback()
    }, duration.as("milliseconds"))
  }
}

export default useCallFunctionWithDelayedTrigger
