import React, { useEffect, useRef, useState } from 'react'
import { IonIcon, IonSpinner, IonButton, useIonModal } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import { formatBytes } from '../../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { openFile, readFileFromDeviceStorageDeprecated, downloadUploadedFile, readFileFromDeviceStorage } from '../../../../common/utils/files'
import { ContentDisposition, SignedUrlStatus, useGetUploadedFileQuery } from '../../../../graphql/generated'
import { fullSizeTransformation } from '../../../../common/utils/imageTransformations'
import useAbortableEffect from '../../../../common/hooks/useAbortableEffect'
import { useChatRoom } from './services/ChatRoomProvider'
import { useGraphQLDataSource } from '../../../../api/graphql'
import Styles from "./Chat.module.scss"
import { isMobile } from '../../../../common/utils/ionic'
import { Browser } from '@capacitor/browser'
import { DownloadUploadedFileModal, DownloadUploadedFileModalStyles } from '../../../../common/components/DownloadUploadedFileModal'
import { OpenUploadedFileModal, OpenUploadedFileModalStyles } from '../../../../common/components/OpenUploadedFileModal'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { UseImageLightboxReturns } from './services/useImageLightbox'

type LightboxProps = Pick<UseImageLightboxReturns, 'openLightbox' | 'addImageToLightbox'>

const ChatMessageImageItem: React.FC<TChatRoomMessageDocument & LightboxProps> = ({ id, fileName, fileSizeInBytes, fileContentType, updatedAt, openLightbox, addImageToLightbox }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const weaverFlags = useWeaverFlags()
  const downloadUploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment, transformation: fullSizeTransformation } })

  const viewFullSizeUploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Inline, transformation: fullSizeTransformation } }, {
    onSuccess: (data) => {
      const status = data.getUploadedFile.signedUrlForDownload.status

      if (status !== SignedUrlStatus.TransformationMissing) {
        const url = data.getUploadedFile.signedUrlForDownload.url
        if (!url) return

        if (!fileName) return

        addImageToLightbox({ id, url, fileName, updatedAt })
      }
    },
  })

  const { isLoading, data } = downloadUploadedFileQuery

  const uploadedFile = data?.getUploadedFile
  const status = uploadedFile?.signedUrlForDownload.status

  const [ presentDownloadModal ] = useIonModal(DownloadUploadedFileModal, {
    uploadedFileQuery: downloadUploadedFileQuery,
  })

  const openImageInLightboxAfterFileUploaded = (url: string) => {
    if (!fileName) return

    // HACK: This hack is needed for the Lightbox to be opened with the new image. These two functions need to be called until the next render.
    // It seems this has to do with the non-reactive nature of the FsLightbox component. https://fslightbox.com/react/documentation/updating-props
    setTimeout(() => {
      addImageToLightbox({ id, url, fileName, updatedAt })
      openLightbox(id)
    }, 100)
  }

  const [ presentFullSizeModal, dismissFullSizeModal ] = useIonModal(OpenUploadedFileModal, {
    uploadedFileQuery: viewFullSizeUploadedFileQuery,
    action: (url: string) => {
      if (!fileName) return

      dismissFullSizeModal()
      openImageInLightboxAfterFileUploaded(url)

    },
  })

  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)
  const { readFromCache, saveToCache } = useChatRoom()
  const [ imageBlob, setImageBlob ] = useState<Blob>()
  const imgRef = useRef<HTMLImageElement>(null)

  useAbortableEffect(status => {

    const setImageBlobIfComponentIsStillMounted = (blob: Blob) => {
      if (!status.aborted){
        setImageBlob(blob)
      }
    }

    const readImageBlobFromFS = async () => {
      const result = await (readFileFromDeviceStorage(fileName))

      if (result) {
        setImageBlobIfComponentIsStillMounted(result)
        saveToCache(fileName, result)
      } else {
        if (uploadedFile && fileName && !isLoading){
          const saveToDownloadsFolder = false
          const file = await downloadUploadedFile(uploadedFile, () => undefined, saveToDownloadsFolder)
          if (file) setImageBlob(file)
        }
      }
    }

    const readImageBlobFromCache = () => {
      const blob = readFromCache(fileName)
      if (blob) {
        setImageBlobIfComponentIsStillMounted(blob)
      } else {
        readImageBlobFromFS()
      }
    }

    readImageBlobFromCache()
  }, [ fileName, readFileFromDeviceStorageDeprecated, readFromCache, setImageBlob, saveToCache, isLoading, status  ])

  useEffect(() => {
    const updateImageSrc = () => {
      if (imageBlob) {
        const url = URL.createObjectURL(imageBlob)
        if (imgRef && imgRef.current) {
          imgRef.current.src = url
        }
      }
    }
    updateImageSrc()
  }, [ imageBlob, setImageBlob ])

  const openDownloadImageModal = () => {
    presentDownloadModal({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const downloadFile =  async (event: React.MouseEvent<HTMLIonButtonElement>) => {
    event.stopPropagation()
    if (!uploadedFile)
      return

    const urlStatus = uploadedFile?.signedUrlForDownload.status
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openDownloadImageModal()
    } else {
      const saveToDownloadsFolder = true
      const file = await downloadUploadedFile(uploadedFile, onProgressUpdate, saveToDownloadsFolder)
      if (file){
        setImageBlob(file)
      }
    }
  }

  const onChatMessageImageClicked = (event: React.MouseEvent<HTMLDivElement>) => {

    event.stopPropagation()

    if (isMobile) {
      return openFile(fileName, fileContentType)
    } else {
      const { isLoading, data } = viewFullSizeUploadedFileQuery
      const urlStatus = data?.getUploadedFile.signedUrlForDownload.status

      if (isLoading || urlStatus === SignedUrlStatus.TransformationMissing) {
        presentFullSizeModal({ cssClass: OpenUploadedFileModalStyles.fullSizeImageModal })
      } else {
        const url = data?.getUploadedFile.signedUrlForDownload.url

        if (!url) return
        if (!fileName) return

        openLightbox(id)
      }
    }
  }
  return (
    <div className={Styles.chatMessageDocumentContainer}>
      {
        !isLoading && <img ref={imgRef} className={Styles.chatMessageImage} onClick={(e) => onChatMessageImageClicked(e)} />
      }

      {
        isLoading && <div className={`${Styles.thumbnailPhotoContainer} ${Styles.success}`}>
          <IonSpinner className={Styles.spinner} color="black" />
        </div>
      }

      <div className={Styles.chatMessageImageBottomContainer}>
        <div className={Styles.chatMessageImageDetailsContainer}>
          <div className={Styles.chatMessageDocumentName}>{fileName}</div>
          <div className={Styles.chatMessageDocumentSize}>{fileSizeInBytes ? formatBytes(fileSizeInBytes) : ""}</div>
        </div>

        <div className={Styles.chatMessageImageDownloadContainer}>
          {
            isDownloadingInProgress
              ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
              : <IonButton className={Styles.downloadButton} fill='clear' size='small' onClick={(e) => downloadFile(e)}>
                <IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} />
              </IonButton>
          }
        </div>
      </div>
    </div>
  )
}

export default ChatMessageImageItem
