import React from 'react'
import { useEnvironmentContext } from '../../../api/providers/EnvironmentProvider'
import Styles from "./DevAccountOnly.module.scss"

const DevAccountOnly: React.FC = ({ children }) => {
  const env = useEnvironmentContext()

  return env.account.type === 'dev'
    ? (
      <div className={Styles.devAccountOnly}>
        <div className={Styles.devAccountOnlyHeading}>Dev Account Only</div>
        {children}
      </div>
    )
    : null
}

export default DevAccountOnly
