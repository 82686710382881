import { useContext } from "react"
import { AuthContext, TAuth_LoggedIn, WeaverIdTokenClaims } from "."

export const useAuthContext = (): TAuth_LoggedIn => {
  const auth = useContext(AuthContext)

  if (auth === undefined) {
    throw new Error('[useAuthContext] Use it not logged in! Guard more appropriately.')
  }

  return auth
}

export const useMaybeAuthContext = (): TAuth_LoggedIn | undefined =>
  useContext(AuthContext)

export const useIsWeaverTeamMember = (): boolean => {
  const auth = useAuthContext()

  const isWeaverEmailDomain = auth.userData?.email?.endsWith('@weaver.build') ?? false
  const isLoggedInThroughGoogle = auth.idToken?.sub?.startsWith("google-oauth2|") ?? false
  const isTestWeaverUser = auth.userData?.[WeaverIdTokenClaims.WeaverIsTestWeaverUser] === true ?? false

  return isWeaverEmailDomain && (isLoggedInThroughGoogle || isTestWeaverUser)
}
