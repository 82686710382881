import React from 'react'
import { IonBackButton } from '@ionic/react'
import TeamMembers from '../TeamMembers/TeamMembers'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { PublicContractorProfileScreenProps, PublicContractorScreenNames, RenderAs } from './PublicContractorProfile'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader, { NavElement } from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { PublicContractorProfileDetailsCard } from './PublicContractorProfileDetailsCard'
import { PublicWorkHistoryTimeline } from './PublicWorkHistoryTimeline'
import { BadgeLevel, Team, TeamType } from '../../../graphql/generated'
import RangeBadge from '../ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge'
import { useOpenBadgeInfoModal } from '../ContractorLeadPreferences/ContractorBudgetReferences/useOpenBadgeInfoModal'
import { alwaysArray } from '../../../common/utils'

import Styles from './PublicContractorProfilePage.module.scss'

const ScreenHome: React.FC<PublicContractorProfileScreenProps & ScreenRenderProps<PublicContractorScreenNames>> = (props) => {
  const weaverFlags = useWeaverFlags()

  const team: Pick<Team, 'id' | 'type'> = {
    id: props.profile.id,
    type: TeamType.Contractor,
  }

  const { openBadgeInfoModal, displayBadgeInfoModal } = useOpenBadgeInfoModal(true, team)
  const shouldApplyStyle = weaverFlags['tab-nav-bar'].enabled

  const onlyAwardedBadgeLevels = alwaysArray(props?.profile?.budgetRangeBadges).filter((item) => item?.badgeLevel !== null)

  return <>
    <WeaverIonHeader className={shouldApplyStyle ? GlobalHeaderStyles.globalHeader : undefined}>
      <GlobalHeader
        navElement={props.renderProps.as === RenderAs.Page ? <IonBackButton className={Styles.backButton} /> : NavElement.None}
        pageTitle='Public Profile'
      />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>

      <>
        <PublicContractorProfileDetailsCard contractorProfile={props.profile} />
        <section className={`${Styles.tieredBadgeRangeContainer} ion-padding-horizontal`}>
          {onlyAwardedBadgeLevels.length > 0 && (
            <>
              <h5 className={Styles.badgeTitle}>Badges</h5>
              {onlyAwardedBadgeLevels?.map((budgetRangeBadge, index) => (
                <RangeBadge key={index} badgeLevel={budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze} budgetRange={budgetRangeBadge.budgetRange} width="90" height="90"
                  onClick={() => openBadgeInfoModal(budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze, budgetRangeBadge.budgetRange, false)}  />))}
            </>
          )}
        </section>
      </>

      <section className={Styles.listWorkHistoryContainer}>
        <h3 className={Styles.pastProjectsTitle}>Past projects</h3>
        {!props.profile.workHistory && <p className="ion-padding" >No work history has been added</p>}
        <PublicWorkHistoryTimeline {...props} />
      </section>

      <section className={Styles.teamMemberListContainer}>
        <TeamMembers teamId={props.profile.id} />
      </section>

      {displayBadgeInfoModal()}
    </WeaverIonContent>
  </>
}

export default ScreenHome
