import { DateTime } from "luxon/src/datetime"

export const INVITE_PARAM_KEY = "claimInviteId"
export const STORED_INVITE_KEY = "pendingClaimInviteId"

export type EmptyInvite = {
  inviteId: undefined,
  addedAt: undefined,
}

export type PresentInvite = {
  inviteId: string,
  addedAt: DateTime,
}
export type StoredInvite = PresentInvite | EmptyInvite

export const EMPTY_INVITE: EmptyInvite = {
  inviteId: undefined,
  addedAt: undefined,
}
