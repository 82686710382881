import React, { SVGAttributes } from 'react'
import Styles from './PadLock.module.scss'

type PadLockProps = {
  isBadgeRowElement?: boolean,
} & SVGAttributes<SVGSVGElement>

export const PadLock: React.FC<PadLockProps> = ({ isBadgeRowElement, ...svnAttrs }) => {
  return (
    <svg className={`${isBadgeRowElement ? Styles.badgeRowSvg: undefined}`} width="48" height="42" viewBox="0 0 48 42" fill="none" {...svnAttrs} xmlns="http://www.w3.org/2000/svg" >
      <path className={Styles.background} d="M30.5522 6.59204H16.7431C15.8678 6.59204 15.0591 7.05853 14.6214 7.81679L7.71595 19.7761C7.27828 20.5344 7.27828 21.4674 7.71595 22.2256L14.6214
      34.185C15.0591 34.9433 15.8678 35.4097 16.7431 35.4097H30.5522C31.4275 35.4097 32.2362 34.9433 32.6739 34.185L39.5793 22.2256C40.017 21.4674 40.017 20.5344
      39.5793 19.7761L32.6739 7.81679C32.2362 7.05853 31.4275 6.59204 30.5522 6.59204Z" fill={'#99c7c2'}/>
      <path className={Styles.background} d="M30.1649 34.918H17.1303C16.1937 34.918 15.322 34.4137 14.8537 33.6032L8.3373 22.3157C7.86901 21.5052 7.86901 20.4966 8.3373 19.6861L14.8537 8.39855C15.322
      7.58805 16.1937 7.08374 17.1303 7.08374H30.1649C31.1015 7.08374 31.9732 7.58805 32.4415 8.39855L38.9579 19.6861C39.4262 20.4966 39.4262 21.5052 38.9579 22.3157L32.4415
      33.6032C31.9732 34.4137 31.1015 34.918 30.1649 34.918V34.918ZM17.1303 7.44396C16.3216 7.44396 15.5687 7.87803 15.1653 8.57866L8.64889 19.8662C8.24544 20.5668 8.24544 21.435
      8.64889 22.1356L15.1653 33.4231C15.5687 34.1238 16.3216 34.5578 17.1303 34.5578H30.1649C30.9736 34.5578 31.7265 34.1238 32.1299 33.4231L38.6463 22.1356C39.0498 21.435 39.0498 20.5668
      38.6463 19.8662L32.1299 8.57866C31.7265 7.87803 30.9736 7.44396 30.1649 7.44396H17.1303V7.44396Z" fill={'#99c7c2'}/>
      <path className={Styles.background}
        d="M27.0202 28.7078C26.4204 28.7078 25.8801 28.6304 25.401 28.4773C24.9201 28.3224 24.4788 28.1351 24.0772 27.9117C23.6737 27.6884 23.2883 27.4669 22.9209 27.2435C22.5516 27.0202 22.1878 26.8329 21.8276 26.678C21.4674 26.5231 21.0909 26.4474 20.6965 26.4474C20.0787 26.4474 19.6429 26.5969 19.3853 26.8977C19.1277 27.1985 18.9386 27.6056 18.8197 28.1189C18.7855 28.1873 18.7387 28.2738 18.6775 28.3764C18.618 28.4791 18.501 28.5313 18.3298
      28.5313H16.3756C16.2388 28.5313 16.1217 28.4791 16.028 28.3764C15.9344 28.2738 15.8857 28.1621 15.8857 28.0414C15.92 26.9103 16.2063 25.9882 16.7467 25.2767C17.287 24.5653 17.9678 24.0736 18.7909 23.798C18.7909 23.3694
      18.7567 22.9371 18.6883 22.4994C18.6198 22.0617 18.5514 21.5953 18.4829 21.0982H16.5035C16.3324 21.0982 16.1865 21.0423 16.0659 20.9307C15.9452 20.819 15.8857 20.6695 15.8857 20.4804V19.0665C15.8857 18.8954 15.9452 18.7495 16.0659
      18.6288C16.1865 18.5082 16.3306 18.4487 16.5035 18.4487H17.9426C17.9246 18.2092 17.912 17.9606 17.9048 17.7031C17.8958 17.4455 17.8922 17.1807 17.8922 16.907C17.8922 15.5706 18.1371 14.4052 18.6252 13.411C19.1133 12.4168 19.85
      11.6423 20.837 11.084C21.8222 10.5275 23.0433 10.2483 24.5004 10.2483C25.9575 10.2483 27.0886 10.5022 28.099 11.0066C29.1095 11.5127 29.8893 12.2061 30.4387 13.0886C30.9862 13.9712 31.269 14.978 31.287 16.1091C31.287 16.264 31.2348 16.3955
      31.1321 16.5071C31.0294 16.6188 30.9016 16.6746 30.7467 16.6746H28.3296C28.1405 16.6746 27.982 16.6224 27.8541 16.5197C27.7262 16.4171 27.6272 16.2622 27.5587 16.0569C27.3534 15.1311 27.0058 14.4251 26.5177 13.9352C26.0296 13.4471
      25.347 13.2021 24.4734 13.2021C23.4972 13.2021 22.7678 13.5407 22.2887 14.2179C21.8078 14.8951 21.5682 15.7993 21.5682 16.9304C21.5682 17.2042 21.5718 17.4671 21.5808 17.7139C21.5899 17.9624 21.6115 18.2074 21.6457 18.4469H26.0422C26.2133 18.4469
       26.3592 18.5064 26.4799 18.627C26.5987 18.7477 26.66 18.8936 26.66 19.0647V20.4786C26.66 20.6677 26.6005 20.8172 26.4799 20.9289C26.3592 21.0405 26.2133 21.0964 26.0422 21.0964H21.8258C21.878 21.5592 21.9321 22.0131 21.9933 22.4598C22.0527 22.9065
       22.0743 23.3766 22.0581 23.8737C22.5733 23.9763 23.0181 24.1258 23.3946 24.3239C23.771 24.5203 24.1186 24.7346 24.4356 24.9669C24.7526 25.1975 25.0822 25.392 25.4262 25.5451C25.7684 25.7 26.1539 25.7756 26.5825 25.7756C27.2345 25.7756 27.71
       25.6045 28.009 25.2623C28.308 24.9201 28.5367 24.4914 28.6898 23.9763C28.706 23.8899 28.7583 23.8052 28.8447 23.7188C28.9312 23.6323 29.0338 23.5909 29.1527 23.5909H30.9016C31.0565 23.5909 31.1753 23.6377 31.2618 23.7332C31.3482 23.8268 31.3897
       23.9439 31.3897 24.0808C31.3717 25.075 31.1573 25.9197 30.7467 26.6131C30.336 27.3066 29.8029 27.8307 29.1527 28.1819C28.5007 28.5331 27.7893 28.7096 27.0184 28.7096L27.0202 28.7078Z" fill={'#99c7c2'}/>
      <path className={Styles.padLockIcon} d="M27.853 19H27.353V16.5C27.353 15.7044 27.037 14.9413 26.4743 14.3787C25.9117 13.8161 25.1487 13.5 24.353 13.5C23.5574 13.5 22.7943 13.8161 22.2317 14.3787C21.6691 14.9413 21.353 15.7044 21.353 16.5V19H20.853C20.3228 19.0006 19.8144 19.2115 19.4395 19.5864C19.0645 19.9614 18.8536 20.4697 18.853 21V26.5C18.8536 27.0303 19.0645 27.5386 19.4395 27.9136C19.8144 28.2885 20.3228 28.4994 20.853 28.5H27.853C28.3833 28.4994 28.8917 28.2885 29.2666 27.9136C29.6415 27.5386 29.8524 27.0303 29.853 26.5V21C29.8524 20.4697 29.6415 19.9614 29.2666 19.5864C28.8917 19.2115 28.3833 19.0006 27.853 19ZM26.353 19H22.353V16.5C22.353
       15.9696 22.5637 15.4609 22.9388 15.0858C23.3139 14.7107 23.8226 14.5 24.353 14.5C24.8835 14.5 25.3922 14.7107 25.7672 15.0858C26.1423 15.4609 26.353 15.9696 26.353 16.5V19Z" fill={'black'}/>
    </svg>
  )
}
