export const showIntercom = () => {
  // Intercom is installed via Segment, so we don't have certainty about it's existence
  // We have to do lots of checking to make sure it exists before calling it
  // The alternative would be to install Segment as a package within the app, but we'd have to own the configuration too
  // eslint-disable-next-line
  const anyWindow: any = window as any
  if ('Intercom' in anyWindow) {
    const Intercom = anyWindow['Intercom']
    if (typeof Intercom === 'function') {
      Intercom('show')
    } else {
      console.error('[Help.showIntercom] window.Intercom is installed but is not a function!', { window })
    }
  } else {
    console.error('[Help.showIntercom] window.Intercom is not installed!', { window })
  }
}
