import React, { useEffect } from 'react'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { pageConfig_Projects, useRouteTo } from '../../../routes'
import LoadingSpinnerPage from '../LoadingSpinner/LoadingSpinnerPage'

export const PWAStartPage: React.FC = () => {
  const triggerEventPWAAppLaunched = useAnalyticsEvent('PWA_App_Launched')
  const routeToProjects = useRouteTo(pageConfig_Projects.path)

  useEffect(() => {
    (async () => {
      await triggerEventPWAAppLaunched()
      routeToProjects({})()
    })()
  }, [ triggerEventPWAAppLaunched ])

  return <LoadingSpinnerPage name="PWAStartPage" />
}
