import React from 'react'
import IndicatorBar from "../../../../projects/CreateProjectPage/IndicatorBar"
import Styles from "./OnboardingSignupSteps.module.scss"
import OnboardingSignupStep from './OnboardingSignupStep'
import { StepType } from './config'

interface OnboardingSignupStepsProps {
  caption: string,
  message: string,
  steps: StepType[],
  currentPageIndex: number,
  goToNextPage?: () => void,
}

const OnboardingSignupSteps: React.FC<OnboardingSignupStepsProps> = ({ steps, caption, message , currentPageIndex, goToNextPage }) => {

  return (
    <div className={Styles.listContainer} data-testid="OnboardingSignupSteps">
      <IndicatorBar currentPageIndex={currentPageIndex} totalPages={2} maxAvailableIndex={2} onNavigate={(page) => console.debug(page)} />
      <div>
        <h3>{caption}</h3>
        <p>{message}</p>
        {steps.map(step => <OnboardingSignupStep key={step.id} id={step.id} title={step.title} icon={step.icon} status={step.status} goToNextPage={goToNextPage}/>)
        }
      </div>
    </div>
  )
}

export default OnboardingSignupSteps
