import React, { useEffect, useMemo, useRef, useState } from 'react'
import ChatMessageBubble from './ChatMessageBubble'
import ChatMessageWithDocumentsBubble from './ChatMessageWithDocumentsBubble'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useChatRoom } from './services/ChatRoomProvider'
import { useMyIndividual, useMyIndividualActiveTeam } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useGetChatRoomTeamConnectionsQuery, useGetChatRoomWithMyMessagesQuery, useListMyChatRoomsQuery, useMarkChatRoomMessageAsReadMutation } from '../../../../graphql/generated'
import Styles from "./Chat.module.scss"
import { getChatEmptyStateMessage, isElementChatRoomMessage, isElementEmptyState, isElementNewMessagesLine } from './utils'
import { ChatContentListElement } from './ChatRoomTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useClearImagesCacheOnLoad, useScrollToLastElementWhenChatRoomElementListChange as useScrollToLastElementWhenChatRoomElementListChange, useLoadChatRoomMessages, useShowNewMessageLineElement, useShowEmptyState } from './hooks'
import { alwaysArray } from '../../../../common/utils'
import { UseImageLightboxReturns } from './services/useImageLightbox'

const PACKAGE = 'chatContent'

export type VirtuosoActions = {
  scrollToIndex: (index: number) => void,
}

const useTeamsInTheChatExceptMine = (chatRoomId: string) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const myTeam = useMyIndividualActiveTeam()

  const resultGetChatRoomConnectionsQuery = useGetChatRoomTeamConnectionsQuery(gqlDataSource, { chatRoomId } )
  const teamConnections = resultGetChatRoomConnectionsQuery.data?.getChatRoom?.teamConnections
  const otherTeams = alwaysArray(teamConnections?.map(teamConnection => teamConnection.team).filter(team => team.id !== myTeam?.id))
  return otherTeams
}

const ChatEmptyState : React.FC<{ chatRoomId: string }> = ({ chatRoomId }) => {
  const teams = useTeamsInTheChatExceptMine(chatRoomId)
  const emptyStateMessage = getChatEmptyStateMessage(teams)
  return (
    <div className={Styles.chatEmptyState}>
      { emptyStateMessage }
    </div>
  )
}

type ChatContentProps = Pick<UseImageLightboxReturns, 'openLightbox' | 'addImageToLightbox'>

const computeHashForElementList = (list: ChatContentListElement[]) => list.map(listElement => listElement.hashCode()).join("|")

const ChatContent: React.FC<ChatContentProps> = ({ openLightbox, addImageToLightbox }) => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const { chatRoomId, chatRoomMessages, emptyCache } = useChatRoom()
  const individual = useMyIndividual()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const queryClient = useQueryClient()
  const markMessageAsReadMutation = useMarkChatRoomMessageAsReadMutation(gqlDataSource)

  /**
   * Stateful list of the elements rendered on the Virtuoso list.
   */
  const [ listElements, setListElements ] = useState<ChatContentListElement[]>([])

  /**
   * listElementsClone is a clone of the stateful list of elements. Notice how the different hooks - that add elements to the row list - modify
   * that list, they don't actually modify the stateful list (i.e. listElements). Eventually, both list are compared and the stateful list is updated
   * only when something has changed. This approach was followed to allow custom hooks to insert whichever elements to the list
   * they want and keeping things uncoupled).
   */
  const listElementsClone = useRef<ChatContentListElement[]>([ ...listElements ])

  useLoadChatRoomMessages({ chatRoomMessages, listElements: listElementsClone })

  useShowNewMessageLineElement({ chatRoomMessages, listElements: listElementsClone, individual })

  useClearImagesCacheOnLoad(emptyCache)

  useScrollToLastElementWhenChatRoomElementListChange({ listElements, scrollToIndex: (index: number) => {
    virtuosoRef.current?.scrollToIndex({ index, behavior: 'auto', align: "start" })
  } })

  useShowEmptyState({ chatRoomMessages, listElements: listElementsClone })

  const listElementsHash = useMemo(() => computeHashForElementList(listElements), [ listElements ])
  const listElementsCloneHash = computeHashForElementList(listElementsClone.current)

  useEffect(() => {
    // Only update the list of elements when there has been a change in the cloned list.
    if (listElementsHash !== listElementsCloneHash){
      setListElements(listElementsClone.current)
    }
  }, [ listElementsHash, listElementsCloneHash ])

  if (chatRoomMessages === undefined) {
    return <WeaverIonContent>
      <LoadingSpinner name={`${PACKAGE}.undefined`} />
    </WeaverIonContent>
  }

  const onMessageSeen = async (chatRoomMessageId: string | undefined) => {
    if (!chatRoomMessageId) return

    await markMessageAsReadMutation.mutateAsync({ chatRoomMessageId })
    await queryClient.invalidateQueries({ queryKey: useGetChatRoomWithMyMessagesQuery.getKey({ chatRoomId }) })

    // Invalidate the query that lists my chat rooms so the correct number of unread messages is retrieved.
    await queryClient.invalidateQueries({ queryKey: useListMyChatRoomsQuery.getKey({}) })
  }

  return (
    <WeaverIonContent useFullHeight={true}>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: "100%" }}
        data={listElements}
        itemContent={(_index, element) => {
          if (isElementChatRoomMessage(element)) {
            const message = element.message
            if (!message) return

            const wasMessageSentByCurrentUser = message.sentByIndividual.id === individual?.id
            return (
              <>
                {
                  message.documentCount
                    ? <ChatMessageWithDocumentsBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} messageHasBeenSeenCallback={onMessageSeen} openLightbox={openLightbox} addImageToLightbox={addImageToLightbox}/>
                    : <ChatMessageBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} messageHasBeenSeenCallback={onMessageSeen} />
                }
              </>
            )
          } else if (isElementNewMessagesLine(element)) {
            return (
              <div className={Styles.newMessagesContainer}>
                <div className={Styles.newMessagesLine}/>
                New
              </div>
            )
          } else if (isElementEmptyState(element)) {
            return (
              <ChatEmptyState chatRoomId={chatRoomId} />
            )
          }
        }}
      >
      </Virtuoso>
    </WeaverIonContent>
  )
}

export default ChatContent
