import React from 'react'
import { IonButton, IonIcon, IonToolbar, useIonAlert } from '@ionic/react'
import { useForm, UseFormReturn } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useOnboardHomeownerTeam } from '../../hooks'
import { useMyIndividual, useMyIndividualActiveTeam, useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import ReactHookFormSingleInputPage from '../../pages/ReactHookFormSingleInputPage'
import TitleWithBulletsPage, { Bullet } from "../../pages/TitleWithBulletsPage"
import { TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { homeownerSteps } from '../../pages/OnboardingSignupSteps/config'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import signupDetailsImg from '../../../../../assets/images/signup-details-signpost.jpg'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { ScreenRenderProps, useScreensWithProps } from '../../../../../common/hooks/useScreens'
import { arrowBackOutline, arrowForwardOutline, checkboxOutline } from 'ionicons/icons'
import Styles from "./OnboardingHomeowner.module.scss"
import { pageConfig_Projects, useRouteTo, useRouteToOnboardingForTeam } from '../../../../../routes'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import IndicatorBar from '../../../../projects/CreateProjectPage/IndicatorBar'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import BrilliantScreen from '../../../../../common/components/BrilliantScreen/BrilliantScreen'
import useTriggerOnCondition from '../../../../../common/hooks/useTriggerOnCondition'
import { useWeaverFlags } from '../../../../../api/thirdParty/launchDarkly/useWeaverFlags'

const zFormSchema = z.object({
  teamName: z.string().min(1, 'Required'),
})

type FormSchema = z.infer<typeof zFormSchema>

enum HomeownerOnboardingScreens {
  Introduction = "Introduction",
  SignupSteps = "SignupSteps",
  SignpostCompanyDetails = "SignpostCompanyDetails",
  TeamName = "TeamName",
  Finished = "Finished",
}

type ScreenProps = {
  form: UseFormReturn<FormSchema>,
}

type HomeownerOnboardingFormSubmitFn = Parameters<UseFormReturn<FormSchema>["handleSubmit"]>[0]

type ScreenComponentProps = ScreenRenderProps<HomeownerOnboardingScreens> & ScreenProps

const _Introduction: React.FC<ScreenComponentProps> = ({ useChangeScreen }) => {
  const changeToNextScreen = useChangeScreen(HomeownerOnboardingScreens.SignupSteps)
  const switchToOnboardingTeam = useRouteToOnboardingForTeam()
  const weaverFlags = useWeaverFlags()

  return <>
    <WeaverIonContent>
      <SuccessNotice
        caption={weaverFlags['MW-2410-copy-changes-march'] ?"We've got you!" : "Brilliant!"}
        message="You're almost ready to find builders for your project."
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={switchToOnboardingTeam}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        <IonButton slot="end" onClick={changeToNextScreen} data-testid="OnboardingHomeowner.Introduction.NextButton">Next <IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _SignUpSteps: React.FC<ScreenComponentProps> =({ useChangeScreen }) => {
  const changeToPreviousScreen = useChangeScreen(HomeownerOnboardingScreens.Introduction)
  const changeToNextScreen = useChangeScreen(HomeownerOnboardingScreens.SignpostCompanyDetails)

  return <>
    <WeaverIonContent>
      <OnboardingSignupSteps
        caption="Sign up to Weaver"
        message="Completing these steps will allow you to find builders"
        steps={homeownerSteps}
        currentPageIndex={1}
        goToNextPage={changeToNextScreen}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _SignpostCompanyDetails: React.FC<ScreenComponentProps> =({ useChangeScreen }) => {
  const changeToPreviousScreen = useChangeScreen(HomeownerOnboardingScreens.SignpostCompanyDetails)
  const changeToNextScreen = useChangeScreen(HomeownerOnboardingScreens.TeamName)

  const weaverFlags = useWeaverFlags()

  const bullets: Array<Bullet> =  [
    { description: 'Pick a name that builders on Weaver will see', icon: weaverFlags['MW-2410-copy-changes-march'] ? checkboxOutline : shieldCheckmark },
  ]

  return <>
    <WeaverIonContent>
      <TitleWithBulletsPage
        title={weaverFlags['MW-2410-copy-changes-march'] ? 'Now, enter your details': 'Next enter your details'}
        imgProps={{
          src: weaverFlags['MW-2410-copy-changes-march'] ? signupDetailsImg : builderPng ,
        }}
        bullets={bullets}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        <IonButton slot="end" onClick={changeToNextScreen} data-testid="OnboardingHomeowner.SignpostCompanyDetails.NextButton">Next <IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _TeamName: React.FC<ScreenComponentProps> =({ useChangeScreen, form }) => {
  const weaverFlags = useWeaverFlags()
  const changeToPreviousScreen = useChangeScreen(HomeownerOnboardingScreens.TeamName)
  const onboardHomeownerTeamFn = useOnboardHomeownerTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
  const [ present ] = useIonAlert()

  const changeToNextScreen = useChangeScreen(HomeownerOnboardingScreens.Finished)

  const onFormValid: HomeownerOnboardingFormSubmitFn = async (data) => {
    try {
      const onboardResult = await onboardHomeownerTeamFn({
        teamName: data.teamName,
      })

      await setOnboardingCompleteMutation.mutateAsync({
        teamId: onboardResult.team.id,
        isOnboardingComplete: true,
      })

      changeToNextScreen()
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Onboard Homeowner Team",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }
  }

  return <>
    <WeaverIonContent data-testid="OnboardingHomeowner.TeamName.Content">
      <IndicatorBar currentPageIndex={0} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
      <ReactHookFormSingleInputPage
        title='Give your team a name'
        inputLabel="Team name:"
        inputSubtext={weaverFlags['MW-2410-copy-changes-march']
          ? 'This is the name contractors will see on Weaver (You can change this later)'
          : 'This is the name clients will see on Weaver (You can change this later)'
        }
        inputFieldProps={form.register('teamName')}
        inputFieldError={form.formState.errors.teamName}
      />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        <SingleClickButton slot="end" onClick={form.handleSubmit(onFormValid)} data-testid="OnboardingHomeowner.TeamName.NextButton">Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _Finished: React.FC<ScreenComponentProps> = ({ form }) => {
  const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Info_Complete')
  const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()

  const myIndividualActiveTeam = useMyIndividualActiveTeam()
  const routeToProjects = useRouteTo(pageConfig_Projects.path)
  const goToProjectsAfterActiveTeam = useTriggerOnCondition(
    routeToProjects({}, undefined, 'root', 'replace'),
    !!myIndividualActiveTeam,
  )

  return <>
    <WeaverIonContent>
      <BrilliantScreen
        title="Time to find some builders!"
        description="Now you're ready to add project details"
        actionButtonProps={{
          onClick: async () => {
            const formValues = form.getValues()

            await triggerEventOnboardingCompanyComplete({
              teamType: TeamType.Homeowner,
              claimedTeamName: formValues.teamName,
              claimedTeamType: TeamType.Homeowner,
            })

            await useMyIndividualInvalidateCacheFn()
            goToProjectsAfterActiveTeam()
          },
          children: <span data-testid="OnboardingHomeowner.Finished.NextButton">Continue</span>,
        }}
      />
    </WeaverIonContent>
  </>
}

const OnboardingHomeowner: React.FC = () => {
  const myIndividual = useMyIndividual()

  const form = useForm<FormSchema>({
    defaultValues: {
      teamName: `The ${myIndividual.familyName}'s`,
    },
    resolver: zodResolver(zFormSchema),
  })

  console.debug('[OnboardingHomeowner] Render ', { formValues: form.getValues() })

  const [ Screen, activeScreen ] = useScreensWithProps<HomeownerOnboardingScreens, ScreenProps>({
    init: HomeownerOnboardingScreens.Introduction,
    resetWhenValueChanges: form.getValues,
    screenProps: {
      form,
    },
    screens: {
      Introduction: { render: _Introduction },
      SignupSteps: { render: _SignUpSteps },
      SignpostCompanyDetails: { render: _SignpostCompanyDetails },
      TeamName: { render: _TeamName },
      Finished: { render: _Finished },
    },
  })

  return (
    <WeaverIonPage id={`OnboardingHomeowner.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default OnboardingHomeowner
