import { IonButton, IonSpinner } from '@ionic/react'
import React from 'react'

import Styles from "./UploadableFileChip.module.scss"

type UploadableFileChipInProgressProps  =
{
  file: File,
  onRemoveFileClicked: (file: File) => void ,
}

const UploadableFileChipInProgress: React.FC<UploadableFileChipInProgressProps> = ({ file, onRemoveFileClicked }) => {
  return (
    <IonButton className={Styles.uploadBubble}
      fill="clear"
      size="small"
      onClick={(e) => {
        e.stopPropagation(),
        onRemoveFileClicked(file)}}>
      <span>{file.name}</span>
      <IonSpinner className={Styles.uploadingFileStatusSpinner} color="black" />
    </IonButton>
  )
}

export default UploadableFileChipInProgress
