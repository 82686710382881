import React from "react"
import { IonButton, IonCol, IonFooter, IonGrid, IonIcon, IonImg, IonItem, IonList, IonRow, IonText } from "@ionic/react"
import Styles from './TitleWithBulletsPage.module.scss'
import { ComponentProps, isPlatform } from "@ionic/core"

export interface Bullet {
  description: string,
  icon?: string,
}

interface TitleWithBulletsPageProps {
  imgProps: ComponentProps<typeof IonImg>,
  title: string,
  bullets: Bullet[],
  actionButtonProps?: ComponentProps<typeof IonButton>,
}

// TODO: This does not contain a WeaverIonPage, so shouldn't have Page in it's name
const TitleWithBulletsPage: React.FC<TitleWithBulletsPageProps> = ({ title, imgProps, bullets, actionButtonProps }) => {
  return <>
    <IonImg {...imgProps} className={`${Styles.ionImg} ${Styles.centerText} ${imgProps.className}`} />
    <div>
      <h1 className={`${Styles.title} ion-text-center ion-padding`}>
        {title}
      </h1>
      <IonGrid>
        {isPlatform('desktop')
          ? (
            <div className={Styles.centeredContainer}>
              {bullets.map(bullet =>
                <div className={Styles.centeredVertically} key={bullet.description}>
                  <IonIcon hidden={bullet.icon ? false : true} icon={bullet.icon}></IonIcon>
                  <IonText className="ion-padding">
                    <p>{bullet.description}</p>
                  </IonText>
                </div>,
              )}
            </div>
          )
          : (
            <IonList className={Styles.centeredText}>
              {bullets.map(bullet =>
                <IonItem key={bullet.description} className={Styles.ionItem} lines="none">
                  <IonIcon hidden={bullet.icon ? false : true} icon={bullet.icon}></IonIcon>
                  <IonText className="ion-padding">
                    <p>{bullet.description}</p>
                  </IonText>
                </IonItem>,
              )}
            </IonList>
          )
        }
      </IonGrid>
    </div>

    {actionButtonProps && <IonFooter className={`${Styles.ionFooter} ion-no-border`}>
      <IonGrid>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <IonCol className={Styles.centeredText}>
            <IonButton {...actionButtonProps} className={`${Styles.ionButton} ${actionButtonProps.className}`} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonFooter>}
  </>
}

export default TitleWithBulletsPage
