import React, { useCallback, useEffect, useState } from 'react'
import { IonButton, IonNote, IonTextarea, useIonAlert, IonIcon, IonDatetime, IonCol, IonGrid, IonRow, useIonViewDidLeave, IonItem } from '@ionic/react'
import Styles from "./CreateProjectPage.module.scss"

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import { useSearchState } from '../../../common/hooks/pages'
import { Swiper as SwiperType } from 'swiper/types'

import { Controller, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { CreateProjectMutation, ProjectType, BudgetCategory, useCreateProjectMutation, WorkStartEstimate, Currency } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import IndicatorBar from "./IndicatorBar"
import AddContractorsSlide, { ContractorFormSchema } from './AddContractorsSlide'
import { arrowBack } from 'ionicons/icons'
import SuccessSlide from './SuccessSlide'
import GenericSelectorList from '../../../common/components/GenericSelectorList'
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import PickAddress from '../../../common/components/PickAddress'
import { zMoney, zWeaverAddressInput } from '../../../graphql/zod'
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import { getGenericSelectorOptionsForEnum } from "../../../common/components/GenericSelectorList/helpers"
import { CREATE_PROJECT_CONTRACTORS, getEnumLabel, MINIMUM_PROJECT_VALUE_IN_PENCE } from '../../../common/utils'
import { useStoredValue } from '../../../api/providers/StorageProvider'
import { DateTime } from 'luxon'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useGetInviteUrl } from '../common'
import { getWorkStartEstimateLabels } from '../workEstimate.i18n'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverGuarantee from './WeaverGuarantee/WeaverGuarantee'
import ContractorPrice from './ContractorPrice/ContractorPrice'
import WeaverContractors from './WeaverContractors/WeaverContractors'
import WeaverMoneyIonInput from '../../../common/components/WeaverMoneyIonInput'
import { budgetCategoryLabels, budgetCategoryLabelsV2 } from './budgetCategory.i18n'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { asMoney, moneyAmountAsNumber } from '../../../common/utils/currency'
import { useRouteTo, pageConfig_ShowProject } from '../../../routes'
import SingleClickButton from '../../../common/components/SingleClickButton'

const zSearchSchema = z.object({
  page: z.coerce.number().optional(),
})

const FormSchema = z.object({
  projectTypes: z.nativeEnum(ProjectType).array().nonempty(),
  description: z.string(),
  address: zWeaverAddressInput,
  budgetCategory: z.nativeEnum(BudgetCategory),
  budgetAmount: zMoney.superRefine((val, ctx) => {
    const budgetAmountAsNumber = moneyAmountAsNumber(val)
    if (budgetAmountAsNumber == null) return // We only error on budget amounts once set
    if (budgetAmountAsNumber < MINIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects below £30,000",
        code: z.ZodIssueCode.custom,
      })
    }
  }),
  workStartEstimate: z.nativeEnum(WorkStartEstimate),
  /** @deprecated MW-2386-remove-tender-return-date -> Remove tenderReturnDate from form schema when archiving flag*/
  tenderReturnDate: z.string().optional(),
  contractors: z.array(ContractorFormSchema).default([]),
})

const FormPartial = FormSchema.partial()
type FormPartial = z.infer<typeof FormPartial>

type FormData = z.infer<typeof FormSchema>

const INITIAL_PAGE_INDEX = 0
type Draft = {
  type: "draft",
  createdAt: string,
  maxUnlockedIndex: number,
  formData: string,
}
const EMPTY_DRAFT_STATE = {
  type: "initial",
  maxUnlockedIndex: 0,
  createdAt: undefined,
  formData: "{}",
}

type DraftState = Draft | typeof EMPTY_DRAFT_STATE

const CreateProject: React.FC = () => {
  const now = DateTime.now()
  const getInviteUrl = useGetInviteUrl()

  const weaverFlags = useWeaverFlags()
  const taskTemplateIds = (weaverFlags['task-templates'].enabled && weaverFlags['task-templates'].createProject) || undefined

  const [ draftState, setDraftState ] = useStoredValue<DraftState>({ key: "createProjectDraft", initialValue: EMPTY_DRAFT_STATE })
  const [ draftInitComplete, setDraftInitComplete ] = useState(false)
  const { register, handleSubmit, trigger, formState: { errors }, control, getValues, reset, setValue } = useForm<FormData>({ resolver: zodResolver(FormSchema) })

  const { searchData, mergeSearchState } = useSearchState({ page: INITIAL_PAGE_INDEX }, { schema: zSearchSchema } )
  const page = searchData.page || INITIAL_PAGE_INDEX
  const setPage = (page: number) => mergeSearchState({ page })

  const [ present ] = useIonAlert()

  const [ swiper, setSwiper ] = useState<SwiperType | null>(null)
  const [ maxUnlockedIndex, setMaxUnlockedIndex ] = useState<number>(0)

  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ createdProject, setCreatedProject ] = useState<CreateProjectMutation["createProject"] | null>(null)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createProject = useCreateProjectMutation(gqlDataSource)
  const triggerProjectInviteCreated = useAnalyticsEvent("Project_Invite_Created")
  const triggerProjectCreated = useAnalyticsEvent("Project_Created")

  const formData = getValues()
  const formJson = JSON.stringify(formData)

  useIonViewDidLeave(() => {
    reset({})
    setMaxUnlockedIndex(0)
    setDraftState(EMPTY_DRAFT_STATE)

    if (createdProject) {
      setCreatedProject(null)
    }
  })

  // Draft Initialization
  useEffect(() => {
    if (!draftState || draftInitComplete) return

    try {
      const rawDraftData = JSON.parse(draftState.formData)
      const draftData = FormPartial.parse(rawDraftData)

      reset(draftData)
      setMaxUnlockedIndex(draftState.maxUnlockedIndex)
      setCreatedProject(null)
    } catch (e) {
      console.error("[CreateProject] Failed to restore draft, likely corrupt or parsing failure. Clearing draft...", draftState, e)
      reset({})
      setMaxUnlockedIndex(0)
      setDraftState(EMPTY_DRAFT_STATE)
    }
    setDraftInitComplete(true)

  }, [ draftState?.formData ])

  // Draft persistence
  useEffect(() => {
    if (!draftInitComplete) {
      console.debug("[CreateProject] ignoring persist: draft init has not completed yet")
      return
    }
    if (createdProject) {
      console.debug("[CreateProject] ignoring persist: success state is active. draft should be getting cleared!")
      return
    }
    const nextState: Draft = {
      type: "draft",
      createdAt: DateTime.now().toISO(),
      maxUnlockedIndex,
      formData: formJson,
    }
    setDraftState(nextState)
  }, [ formJson, maxUnlockedIndex ])

  const onSubmit = async (data: FormData) => {
    const { address, description, budgetCategory, budgetAmount, projectTypes, contractors, tenderReturnDate, workStartEstimate } = data
    try {
      setSubmitting(true)

      // transform the contractors in the form to contractor invites (remove id field)
      const contractorInvites = contractors.map(({ companyName, email, familyName, givenName, phone }) => ({
        companyName,
        email,
        familyName,
        givenName,
        phone,
      }))

      const result = await createProject.mutateAsync({
        project: {
          projectTypes,
          description,
          address,
          budgetCategory,
          budgetValue: budgetAmount,
          totalContractors: CREATE_PROJECT_CONTRACTORS.contractorCount,
          contractorInvites,
          workStartEstimate,
          /** @deprecated MW-2386-remove-tender-return-date -> Remove tenderReturnDate from form schema when archiving flag*/
          tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : tenderReturnDate,
          taskTemplateIds,
        },
      })

      // sending analytics events doesn't affect project creation, so update state/screen immediately
      setCreatedProject(result.createProject)
      reset()

      await Promise.all(contractors.map(async (contractor, ix) => {
        // invites on a project are not dependent on who's being invited, they can be joined to contractors in the frontend
        const invite = result.createProject.memberInvites[ix]

        if (!invite) {
          console.error("[CreateProject] Missing invite for corresponding contractor index", ix)
          return
        }

        try {
          await triggerProjectInviteCreated({
            source: "createProject",
            inviteId: invite.id,
            inviteUrl: getInviteUrl(invite.id),
            inviteEmail: contractor.email,
            inviteCompanyName: contractor.companyName,
            inviteFamilyName: contractor.familyName,
            inviteGivenName: contractor.givenName,
            invitePhone: contractor.phone,
            projectId: result.createProject.id,
            projectBudget: result.createProject.budgetValue,
            projectDescription: result.createProject.description,
            projectName: result.createProject.title,
            projectTypes: result.createProject.projectTypes,
          })
        } catch (e) {
          console.error("[CreateProject] failed to send invite analytics event ", invite.id, e)
        }
      }))

      reset({})
      setMaxUnlockedIndex(0)
      setCreatedProject(result.createProject)
      await triggerProjectCreated({
        projectId: result.createProject.id,
        types: data.projectTypes,
        description: data.description,
        budget: data.budgetAmount,
        budgetFlex: data.budgetCategory,
        address: data.address,
        constructionStart: data.workStartEstimate,
        /** @deprecated MW-2386-remove-tender-return-date -> Remove tenderReturnDate from form schema when archiving flag*/
        tenderReturn: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : DateTime.fromISO(data?.tenderReturnDate ?? '').toLocaleString(DateTime.DATE_MED),
        taskTemplateIds,
      })
      setDraftState(EMPTY_DRAFT_STATE)
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Create Project",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }
    setSubmitting(false)
  }

  const tryUnlockPage = async (fields: (keyof FormData)[], requestedPage: number) => {
    const validationSuccess = await trigger(fields)

    if (validationSuccess) {
      // unlock the requested page if required
      if (requestedPage > maxUnlockedIndex) {
        setMaxUnlockedIndex(requestedPage)
      }
      setPage(requestedPage)
    }
  }

  // sync swiper with query state
  useEffect(() => {
    if (!swiper || swiper.destroyed) return
    if (page > maxUnlockedIndex) {
      setPage(maxUnlockedIndex)
      swiper.slideTo(maxUnlockedIndex)
      return
    }
    swiper.slideTo(page)
  }, [ swiper, page, maxUnlockedIndex ])

  const handleNoContractorsClick = useCallback(async () => {
    await setValue("contractors", [])

    if (weaverFlags['MW-2386-remove-tender-return-date']) {
      await tryUnlockPage([], 7)
    } else {
      await tryUnlockPage([], 8)
    }

  }, [ setValue, tryUnlockPage ])

  return <WeaverIonPage id='CreateProjectPage'>
    <WeaverIonHeader className={`${GlobalHeaderStyles.globalHeader} ${createdProject ? `ion-no-border` : null}`}>
      {!createdProject && <GlobalHeader />}
    </WeaverIonHeader>
    <WeaverIonContent>
      <div className={Styles.container}>
        {isSubmitting && <div className={Styles.submitOverlay}></div>}
        {!draftInitComplete ? <LoadingSpinner name="CreateProject" /> : <>
          {createdProject ? <SuccessSlide createdProject={createdProject} /> : <>

            {weaverFlags['MW-2462-remove-onboarding-pages']
              ? <IndicatorBar currentPageIndex={page} totalPages={4} maxAvailableIndex={3} onNavigate={(page) => swiper?.slideTo(page)} />
              : <IndicatorBar currentPageIndex={page} totalPages={swiper?.slides?.length ?? 0} maxAvailableIndex={maxUnlockedIndex} onNavigate={(page) => swiper?.slideTo(page)} />
            }

            <div className={Styles.swiperContainer}>
              <Swiper
                className={Styles.swiper}
                onActiveIndexChange={(x) => setPage(x.activeIndex)}
                onSwiper={(swiper) => setSwiper(swiper)}
                allowSlideNext={page <= maxUnlockedIndex}
                simulateTouch={false}
              >

                <SwiperSlide data-testid="ProjectTypeSelector.Page">
                  <div className={Styles.slideContainer}>
                    <h2>What type of project is it?</h2>
                    <div>
                      <Controller
                        control={control}
                        name="projectTypes"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (<div className={Styles.projectList}>
                          <GenericSelectorList
                            options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
                            selected={value || []}
                            multiSelect={true}
                            onSelect={(value) => onChange(value)}
                            showItemDetail={false}
                            colSize={'6'}
                            render={({ value }) => <p><div key={value} id={value}>{getEnumLabel(value)}</div></p>}
                          />
                          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                        </div>
                        )}
                      />
                    </div>

                    <h2>Anything else to add?</h2>
                    <IonItem className={Styles.ionItemInput} lines='none'>
                      <IonTextarea autoGrow={true} placeholder='(Optional) Describe your project...' {...register("description")}></IonTextarea>
                    </IonItem>
                    {errors.description ? <IonNote color='danger'>{errors.description.message}</IonNote> : null}

                    <div className={Styles.buttonContainer}>
                      <div></div>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "projectTypes", "description" ], 1)}
                        data-testid="ProjectTypeSelector.NextButton"
                      >
                        Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide data-testid="Budget.Page">
                  <div className={Styles.slideContainer}>
                    <h2>What&apos;s your budget?</h2>
                    <h6>Budget Value (GBP, ex. VAT)</h6>
                    <Controller
                      control={control}
                      name="budgetAmount"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => <>
                        <IonItem className={Styles.ionItemInput} lines="none">
                          <WeaverMoneyIonInput
                            value={value}
                            setValue={onChange}
                            withPennies={false}
                            minValue={asMoney(value?.currency ?? Currency.Gbp, MINIMUM_PROJECT_VALUE_IN_PENCE)}
                          />
                        </IonItem>
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </>
                      }
                    />

                    <h2>How flexible is your budget?</h2>
                    <Controller
                      control={control}
                      name="budgetCategory"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<>
                        <GenericSelectorList
                          options={weaverFlags['MW-2410-copy-changes-march'] ? getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabelsV2)  : getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabels)}
                          selected={value}
                          onSelect={value => onChange(value)}
                        />
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </>
                      )}
                    />

                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(0)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "budgetCategory", "budgetAmount" ], 2)}
                        data-testid="Budget.NextButton"
                      >Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide data-testid="CreateProject.PropertyAddress.Container">
                  <div className={Styles.slideContainer}>
                    <h2>Where is your project?</h2>
                    <Controller
                      control={control}
                      name="address"
                      render={({
                        field: { onChange, value },
                        fieldState: { error: fieldStateEmptyError },
                        formState: { errors },
                      }) => (<>
                        {fieldStateEmptyError ? <p className={Styles.errorMessage}>{fieldStateEmptyError.message}</p> : null}
                        <PickAddress title='' value={value} setValue={onChange} hasError={errors.address} hideFields={[ "careOf", 'poBox', 'county', 'country' ]} />
                      </>
                      )}
                    />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(1)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton color='primary' onClick={() => tryUnlockPage([ "address" ], 3)}
                        data-testid="CreateProject.PropertyAddress.NextButton"
                      >Next</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide data-testid="ConstructionBegin.Page">
                  <div className={Styles.slideContainer}>
                    <h2>When do you want to begin construction?</h2>
                    <Controller
                      control={control}
                      name="workStartEstimate"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (<div className={Styles.projectList}>
                        <GenericSelectorList
                          options={getGenericSelectorOptionsForEnum(WorkStartEstimate, getWorkStartEstimateLabels())}
                          selected={value}
                          onSelect={(value) => onChange(value)}
                          showItemDetail={false}
                          colSize="12"
                          render={({ value }) => <p><div key={value} id={value}>{getWorkStartEstimateLabels()[value]}</div></p>}
                        />
                        {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      </div>
                      )} />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(2)}><IonIcon icon={arrowBack} />Back</IonButton>
                      {weaverFlags['MW-2462-remove-onboarding-pages']
                        ? <SingleClickButton data-testid="CreateProjectButton"
                          color='primary'
                          onClick={handleSubmit(onSubmit)}>Create project</SingleClickButton>
                        : <IonButton data-testid="ConstructionBegin.NextButton"
                          color='primary'
                          onClick={() => tryUnlockPage([ "workStartEstimate" ], 4)}>Next</IonButton>}
                    </div>
                  </div>
                </SwiperSlide>

                {/** @deprecated by MW-2462-remove-onboarding-pages  */}
                {weaverFlags['MW-2386-remove-tender-return-date']
                  ? null
                  : <SwiperSlide data-testid="TenderReturn.Page">
                    <div className={Styles.slideContainer}>
                      <h2>When will the Tender Return?</h2>
                      <p>Please select the date you would like contractors to return their quotes. We recommend 4 weeks from the date of the site visit.</p>
                      <Controller
                        control={control}
                        name="tenderReturnDate"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (<IonGrid>
                          <IonRow>
                            <IonCol className={`${Styles.flex} ion-align-items-center ion-justify-content-center`}>
                              <IonDatetime
                                id="TenderReturn.Datetime"
                                presentation='date'
                                value={value}
                                onIonChange={(e) => onChange(e.detail.value)}
                                min={now.plus({ weeks: 3 }).toISO()}
                              />
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                            </IonCol>
                          </IonRow>

                        </IonGrid>)} />
                      <div className={Styles.buttonContainer}>
                        <IonButton color='secondary' onClick={() => setPage(3)}><IonIcon icon={arrowBack} /> Back</IonButton>
                        <IonButton color='primary' onClick={() => tryUnlockPage([ "tenderReturnDate" ], 5)}
                          data-testid="TenderReturn.NextButton"
                        >Next</IonButton>
                      </div>
                    </div>
                  </SwiperSlide>}

                {/** @deprecated by MW-2462-remove-onboarding-pages  */}
                <SwiperSlide data-testid="InviteContractors.Page">
                  <div className={Styles.slideContainer}>
                    <h2>Contractors</h2>
                    <h3>Would you like to invite your own contractors?</h3>
                    <p>Adding your own contractors lets Weaver know how many additional contractors to find.</p>
                    <div className={Styles.contractorChoices}>
                      <IonButton color='light' onClick={() => weaverFlags['MW-2386-remove-tender-return-date'] ? tryUnlockPage([], 6) : tryUnlockPage([], 7)} >
                        <div>
                          <h3>Yes</h3>
                          <p>I would like to add my own contractors</p>
                        </div>
                      </IonButton>
                      <IonButton data-testid="InviteContractors.DoNotHaveAnyContractorsButton" color='light' onClick={handleNoContractorsClick}>
                        <div>
                          <h3>No</h3>
                          <p>I don&apos;t have any contractors to add</p>
                        </div>
                      </IonButton>
                    </div>

                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => weaverFlags['MW-2386-remove-tender-return-date'] ? setPage(3) : setPage(4)}><IonIcon icon={arrowBack} /> Back</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                {/** @deprecated This view is not currently used and may be removed in the B5 iteration */}
                <SwiperSlide>
                  <Controller
                    control={control}
                    name="contractors"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (<div className={Styles.slideContainer}>
                      <AddContractorsSlide value={value} onChange={onChange} max={CREATE_PROJECT_CONTRACTORS.contractorCount} />
                      {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      <div className={Styles.buttonContainer}>
                        <IonButton color='secondary' onClick={() => weaverFlags['MW-2386-remove-tender-return-date'] ? setPage(4) : setPage(5)}><IonIcon icon={arrowBack} /> Back</IonButton>
                        <IonButton color='primary' onClick={() => weaverFlags['MW-2386-remove-tender-return-date'] ? tryUnlockPage([], 6) : tryUnlockPage([], 7)}>Next</IonButton>
                      </div>
                    </div>
                    )}
                  />
                </SwiperSlide>

                {/** @deprecated by MW-2462-remove-onboarding-pages  */}
                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <WeaverContractors />
                    <ContractorPrice />
                    <WeaverGuarantee />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => weaverFlags['MW-2386-remove-tender-return-date'] ? setPage(4) : setPage(5)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton data-testid="CreateProjectButton" color='primary' onClick={handleSubmit(onSubmit)}>Create project</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
          </>}
        </>}
      </div>
    </WeaverIonContent>
  </WeaverIonPage >
}

export default CreateProject
