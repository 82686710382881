import React from "react"
import { IonAccordion, IonAccordionGroup, IonButton,  IonCard, IonCardContent, IonCardHeader, IonCardTitle,  IonChip, IonIcon, IonItem,  IonLabel,  IonList, IonText, useIonViewDidLeave } from "@ionic/react"
import { addOutline, arrowBackOutline } from "ionicons/icons"
import { TeamType, useShowProjectQuery, ContentDisposition, Task } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"
import { MyIndividualActiveTeam, useMyIndividual, useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import ProjectInviteRow from "./ProjectInviteRow"
import { getFlexibleProjectBudgetAsText } from "./common"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { DateTime } from "luxon"
import { useChatRoomFunctions } from "../../api/services/chat/useChatRoomFunctions"
import { pageConfig_AllProjectTasks, pageConfig_ChatRoomOptions, pageConfig_MyProjectTasks, pageConfig_Projects, pageConfig_Project_Documents, useRouteTo } from "../../routes"
import { formatAddressToSingleLine } from "../../common/utils/addresses"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import { ProjectMemberTeam } from "./ShowProjectTypes"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import Paperclip from "../../assets/images/paperClip.svg"
import { informationCircle, checkboxOutline, checkmarkDoneOutline, shieldCheckmarkOutline, barChartOutline } from "ionicons/icons"
import { ChildTask } from "./checklist/types"
import { ProjectIndicator } from "./ProjectIndicator"
import { useSearchState } from "../../common/hooks/pages"
import { MemberRow } from "../../common/components/MemberRow/MemberRow"
import { useParamsFromPageConfig } from "../../routesProvider"
import { projectTypeLabels } from "../onboarding/team/onboarding.i18n"
import { getLabelsFromEnumList } from "../../common/utils/enum"
import InviteMemberModal, { InviteMemberOnSuccessHandler } from "../../common/components/InviteMemberModal/InviteMemberModal"
import Styles from "./ShowProjectPage.module.scss"
import { getChatName } from "../chats/chatRoom/utils"
import GoogleMapFromWeaverAddress from "../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress"
import { filterMyProjectTasks, getCurrentTopLevelTask } from "./taskUtils"
import { useAnalyticsEvent, useFireAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import { getBudgetRangeByMoney } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { getProjectMemberOwnerTeamTypes } from "../../api/providers/SegmentProvider/helpers"
import NextTaskButton from "./NextTaskButton"
import { multiSort, sortBy } from "../../common/sort"
import { useAuthContext } from "../../api/providers/AuthProvider"
import { z } from "zod"
import { formatAddressToStreetPostcodeCity } from "../../common/utils/addresses"

const zSearchSchema = z.object({
  /**
   * ignoreScope allows a ops (weaver team) member to ignore scoping permissions
   * this will be `"true"` when an ops member is requesting access to a project they are not part of
   */
  ignoreScope: z.coerce.boolean().optional(),
  invite: z.preprocess((x) => String(x).toUpperCase(), z.union([ z.literal(TeamType.Architect), z.literal(TeamType.Contractor), z.literal(TeamType.Homeowner) ])).optional(),
  referrer: z.literal("createProject").optional(),
})

const getAllTaskSteps = (allTasks: Array<Task | ChildTask >) => {
  return allTasks.reduce((prev, task) => prev + task.childTasks.length, 0)
}

const getValidInviteTypes = (team: MyIndividualActiveTeam) => {
  if (team.type === TeamType.Architect) return [ TeamType.Contractor, TeamType.Homeowner ]
  if (team.type === TeamType.Homeowner) return [ TeamType.Contractor, TeamType.Architect ]
  if (team.type === TeamType.Weaver) return [ TeamType.Contractor, TeamType.Homeowner, TeamType.Architect ]
  return []
}

const ShowProject: React.FC = () => {
  const weaverFlags = useWeaverFlags()
  const { createChatRoom } = useChatRoomFunctions()
  const auth = useAuthContext()
  const myIndividual = useMyIndividual()
  const myTeam = useMyIndividualActiveTeam()
  const { searchData, mergeSearchState } = useSearchState({}, { schema: zSearchSchema })

  const ignoreScope = !!searchData.ignoreScope

  const triggerVetMyBuilderEvent = useAnalyticsEvent('Tools_Vet_Builder_Clicked')
  const triggerCostCalculatorEvent = useAnalyticsEvent('Tools_QS_Estimate_Clicked')

  const goToProjectList = useRouteTo(pageConfig_Projects.path)
  const goToDocumentsPage = useRouteTo(pageConfig_Project_Documents.path)
  const goToMyTasksPage = useRouteTo(pageConfig_MyProjectTasks.path)
  const goToAllTasksPage = useRouteTo(pageConfig_AllProjectTasks.path)
  const goToChatOptions = useRouteTo(pageConfig_ChatRoomOptions.path)

  const { id } = useParamsFromPageConfig<{id:string}>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectQuery = useShowProjectQuery(gqlDataSource, {
    id,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope,
  }, {
    refetchOnWindowFocus: false,
    select: ({ getProject }) => ({
      ...getProject,
      tasks: multiSort(getProject.tasks, [ sortBy("order"), sortBy("title") ]),
    }),
  })

  useIonViewDidLeave(() => {
    mergeSearchState({ ignoreScope: undefined, referrer: undefined, invite: undefined })
  })

  useFireAnalyticsEvent({
    eventName: 'Project_Viewed',
    extraData: {
      projectId: showProjectQuery.data?.id ?? '',
      types: showProjectQuery.data?.projectTypes,
      description: showProjectQuery.data?.description,
      budget: showProjectQuery.data?.budgetValue,
      budgetFlex: showProjectQuery.data?.budgetCategory,
      budgetRange: getBudgetRangeByMoney(showProjectQuery.data?.budgetValue),
      address: showProjectQuery.data?.address ?? undefined,
      constructionStart: showProjectQuery.data?.workStartEstimate,
      /** @deprecated MW-2386-remove-tender-return-date -> remove tenderReturnDate property on archive of flag */
      tenderReturn: weaverFlags['MW-2386-remove-tender-return-date']
        ? undefined
        : showProjectQuery?.data?.tenderReturnDate !== null
          ? DateTime.fromISO(showProjectQuery?.data?.tenderReturnDate ?? '').toLocaleString(DateTime.DATE_MED)
          : undefined,
      projectOwnerTeamTypes: getProjectMemberOwnerTeamTypes(showProjectQuery.data?.members),
    },
    deps: [ showProjectQuery.data?.id ],
    enabled: !!showProjectQuery.data?.id,
  })

  if (showProjectQuery.isFetching && !showProjectQuery.data) {
    return <LoadingSpinnerPage name="ShowProject"/>
  }

  if (!myTeam) {
    return <LoadingSpinnerPage name="ShowProject" />
  }

  if (showProjectQuery.error || !showProjectQuery.data) {
    return <ErrorBlockPage name='ShowProject' onRefresh={showProjectQuery.refetch} />
  }

  const project = showProjectQuery.data

  const projectHasArchitect = project.memberInvites.some(x => x.requiredTeamType === TeamType.Architect) || project.members.some(x => x.team.type === TeamType.Architect)
  const projectHasHomeowner = project.memberInvites.some(x => x.requiredTeamType === TeamType.Homeowner) || project.members.some(x => x.team.type === TeamType.Homeowner)

  const members = project.members
  const invites = project.memberInvites
  const listProjectTasks = project.tasks

  const { myTasksCount } = filterMyProjectTasks(project.tasks, myTeam)

  const unclaimedInvites = invites.filter(x => !x.team)
  const claimedInvites = invites.filter(x => x.team)

  const createChatRoomBetweenProjectTeams = (myTeam: ProjectMemberTeam, memberTeam: ProjectMemberTeam) =>
    createChatRoom({
      name: getChatName([ myTeam, memberTeam ], project.title),
      teamIds: [ myTeam.id, memberTeam.id ],
      projectId: project.id,
    })

  const handleInviteSuccess: InviteMemberOnSuccessHandler = () => {
    showProjectQuery.refetch()
    mergeSearchState({ invite: undefined }, "replace")
  }

  const handleInviteCancel = () => mergeSearchState({ invite: undefined }, "replace")
  const handleOpenInviteModal = (teamType: TeamType.Contractor | TeamType.Architect | TeamType.Homeowner) => mergeSearchState({ invite: teamType }, "replace")
  const validInviteTypes = getValidInviteTypes(myTeam)

  type InfoDetailProps = {
    projectInfoLabel: string,
    projectInfoValue: string | null | undefined,
  }

  const InfoDetail: React.FC <InfoDetailProps> = ({ projectInfoLabel, projectInfoValue }) => {
    return (
      (projectInfoValue && projectInfoValue.length)
        ? <div className={Styles.infoDetail}>
          <IonText color="medium" className={Styles.infoDetailLabel}>{projectInfoLabel}</IonText>
          {projectInfoLabel === "Project status"
            ? <IonChip>{projectInfoValue}</IonChip>
            : <IonText className={Styles.infoDetailValue}><p>{projectInfoValue}</p></IonText>}
        </div>
        : null
    )
  }

  const navigateToProjectDocumentsPage = () => goToDocumentsPage({ id })()

  const navigateToMyTasksPage = () => {
    goToMyTasksPage({ projectId: project.id })()
  }

  const navigateToAllTasksPage = () => {
    goToAllTasksPage({ projectId: project.id })()
  }

  const onProjectMemberChatBubbleClicked = (selectedTeam: ProjectMemberTeam, goToChatRoomCreateIfNecessary: () => void) => {
    const amIAContractor = myTeam.type === TeamType.Contractor
    const doIWantToSpeakToAContractor = selectedTeam.type === TeamType.Contractor

    // The Chat Room options page only displays when the current user's a contractor or the current user just clicked on the chat icon for a Contractor
    if (amIAContractor || doIWantToSpeakToAContractor){
      goToChatOptions({ projectId: project.id, teamId: selectedTeam.id })()
    } else {
      goToChatRoomCreateIfNecessary()
    }
  }

  const goToCostCalculatorTypeform = () => {
    // Handle optional data fields
    if (!auth.userData?.email || !myTeam.name || !project?.address) return console.error('[ShowProjectPage]: Missing required data to open Cost Calculator Typeform')

    const BASE_URL_COST_CALCULATOR_TYPEFORM = 'https://weavertech.typeform.com/to/SZWQW2SW'
    const url = new URL(BASE_URL_COST_CALCULATOR_TYPEFORM)
    url.searchParams.append('email', `${auth.userData?.email}`)
    url.searchParams.append('team_name', `${myTeam.name}`)
    url.searchParams.append('team_id', `${myTeam.id}`)
    url.searchParams.append('team_type', `${myTeam.type}`)
    url.searchParams.append('individual_id', `${myIndividual.id}`)
    url.searchParams.append('project_id', `${project.id}`)
    url.searchParams.append('address', ` ${formatAddressToStreetPostcodeCity(project.address)}`)

    triggerCostCalculatorEvent({
      budgetRange: getBudgetRangeByMoney(showProjectQuery.data?.budgetValue),
      projectId: project.id,
      teamId: myTeam.id,
      address: project.address,
    })

    window.open(url, '_blank')
  }

  const goToVetMyBuilderTypeform = () => {
    // Handle optional data fields
    if (!auth.userData?.email || !myTeam.name || !project?.address) return console.error('[ShowProjectPage]: Missing required data to open Vet My Builder Typeform')

    const BASE_URL_VET_MY_BUILDER = 'https://weavertech.typeform.com/to/aWfc58lI'
    const url = new URL(BASE_URL_VET_MY_BUILDER)
    url.searchParams.append('email', `${auth.userData?.email}`)
    url.searchParams.append('team_name', `${myTeam.name}`)
    url.searchParams.append('team_id', `${myTeam.id}`)
    url.searchParams.append('team_type', `${myTeam.type}`)
    url.searchParams.append('individual_id', `${myIndividual.id}`)
    url.searchParams.append('project_id', `${project.id}`)
    url.searchParams.append('address', ` ${formatAddressToStreetPostcodeCity(project.address)}`)

    triggerVetMyBuilderEvent({
      budgetRange: getBudgetRangeByMoney(showProjectQuery.data?.budgetValue),
      projectId: project.id,
      teamId: myTeam.id,
      address: project.address,
    })

    window.open(url, '_blank')
  }

  return (
    <WeaverIonPage id='ShowProjectPage' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={project.title}
          navElement={searchData.referrer === "createProject" ? <IonButton onClick={goToProjectList({})}>
            <IonIcon slot="start" icon={arrowBackOutline} />
          </IonButton> : undefined}
        />
      </WeaverIonHeader>
      <WeaverIonContent className={Styles.showProjectContent}>
        <IonCard className={Styles.projectCard}>
          {project.address && <GoogleMapFromWeaverAddress zoomLevel={15} isExact={true} address={project.address}/>}
          <IonCardHeader>
            <IonCardTitle><p className={Styles.address}>{formatAddressToSingleLine(project.address)}</p></IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={Styles.showProjectHeader}>
            {/** // TODO: Add navigation to current task and display status in button (https://weaver.atlassian.net/browse/MW-1897) **/}
            {weaverFlags.tasks.enabled && (
              <>
                <div onClick={navigateToMyTasksPage}>
                  <ProjectIndicator taskListData={listProjectTasks} />
                </div>
                <NextTaskButton projectId={id} />
              </>
            )}
          </IonCardContent>
        </IonCard>

        {/** Architect only menu */}
        {(myTeam.type === TeamType.Architect || myTeam.type === TeamType.Weaver)  && weaverFlags['MW-2493-architect-menu-project-page'] && <section className={Styles.showProjectSection}>
          <IonText><p className={Styles.projectHeadings}>Architect pro tools <span className={Styles.newNotice}>NEW</span></p></IonText>
          <IonList>
            {weaverFlags['vet-my-builder-architect-menu'] &&
            <IonItem button slot="header" lines="full" detail={true} onClick={goToVetMyBuilderTypeform}>
              <div className={Styles.iconContainer}>
                <IonIcon src={shieldCheckmarkOutline} />
              </div>
              <p className={Styles.projectItemName}>Vet my builder</p>
            </IonItem>}

            {weaverFlags['cost-calculator-architect-menu'] &&
            <IonItem button slot="header" lines="full" detail={true} onClick={goToCostCalculatorTypeform}>
              <div className={Styles.iconContainer}>
                <IonIcon src={barChartOutline} />
              </div>
              <p className={Styles.projectItemName}>QS cost estimate</p>
            </IonItem>
            }
          </IonList>
        </section>}

        <section className={Styles.showProjectSection}>
          <IonText><p className={Styles.projectHeadings}>Project details</p></IonText>
          <IonList lines="full">
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" lines="full">
                  <div className={Styles.iconContainer}>
                    <IonIcon icon={informationCircle} />
                  </div>
                  <p className={Styles.projectItemName}>Info</p>
                </IonItem>
                <div slot="content">
                  <InfoDetail projectInfoLabel={"Project type"} projectInfoValue={getLabelsFromEnumList(projectTypeLabels, project.projectTypes)} />
                  <InfoDetail projectInfoLabel={"Address"} projectInfoValue={formatAddressToSingleLine(project.address)} />
                  <InfoDetail projectInfoLabel={"Budget"}projectInfoValue={getFlexibleProjectBudgetAsText(project, myTeam)} />
                  <InfoDetail projectInfoLabel={"Work start estimate"} projectInfoValue={project.workStartEstimate && getWorkStartEstimateLabels()[project.workStartEstimate]} />
                  {!weaverFlags['MW-2386-remove-tender-return-date'] && <InfoDetail projectInfoLabel={"Tender return date"} projectInfoValue={project.tenderReturnDate && DateTime.fromISO(project.tenderReturnDate).toLocaleString(DateTime.DATE_MED)} />}
                  {weaverFlags.tasks.enabled && (
                    <InfoDetail projectInfoLabel={"Project status"} projectInfoValue={getCurrentTopLevelTask(listProjectTasks)?.title} />
                  )}
                  <InfoDetail projectInfoLabel={"Description"} projectInfoValue={project.description} />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
            <IonItem button slot="header" lines="full" detail={true} onClick={navigateToProjectDocumentsPage}>
              <div className={Styles.iconContainer}>
                <IonIcon src={Paperclip} />
              </div>
              <p className={Styles.projectItemName}>Documents</p>
              <IonLabel slot="end"><p>{project?.documents?.length ?? ''}</p></IonLabel>
            </IonItem>
          </IonList>
        </section>

        {(weaverFlags.tasks.enabled && weaverFlags.tasks.showProjectList) && (
          <section className={Styles.showProjectSection}>
            <IonText><p  className={Styles.projectHeadings}>Tasks</p></IonText>
            <IonList>
              <IonItem button slot="header" lines="full" detail={true} onClick={navigateToMyTasksPage}>
                <div className={`${Styles.iconContainer} ${Styles.iconYourTasks}`}>
                  <IonIcon icon={checkboxOutline} color="secondary" />
                </div>
                <p className={Styles.projectItemName}>Your tasks</p>
                <IonLabel slot="end"><p>{myTasksCount}</p></IonLabel>
              </IonItem>
              <IonItem button slot="header" lines="full" detail={true} onClick={navigateToAllTasksPage}>
                <div className={Styles.iconContainer}>
                  <IonIcon icon={checkmarkDoneOutline}/>
                </div>
                <p className={Styles.projectItemName}>All tasks</p>
                <IonLabel slot="end">
                  <p>{listProjectTasks !== undefined && getAllTaskSteps(listProjectTasks)}</p>
                </IonLabel>
              </IonItem>
            </IonList>
          </section>
        )}

        <section className={Styles.showProjectSection}>
          <IonText><p className={Styles.projectHeadings}>Members</p></IonText>

          { validInviteTypes.includes(TeamType.Contractor) && <IonButton onClick={() => handleOpenInviteModal( TeamType.Contractor )}><IonIcon icon={addOutline} slot="start"/>{weaverFlags['MW-2410-copy-changes-march'] ? 'Invite My Own Contractor' :'Invite Contractor'}</IonButton> }
          { validInviteTypes.includes(TeamType.Architect) && (
            <IonButton onClick={() => handleOpenInviteModal(TeamType.Architect)} disabled={projectHasArchitect}><IonIcon icon={addOutline} slot="start"/>Invite Architect</IonButton>
          )}
          { validInviteTypes.includes(TeamType.Homeowner) && (
            <IonButton onClick={() => handleOpenInviteModal(TeamType.Homeowner)} disabled={projectHasHomeowner}><IonIcon icon={addOutline} slot="start"/>Invite Homeowner</IonButton>
          ) }
          <IonList className={Styles.membersListBottomMargin}>
            { unclaimedInvites.map(unclaimed => <ProjectInviteRow key={unclaimed.id} invite={unclaimed} />) }
            { claimedInvites.map(claimed => <ProjectInviteRow key={claimed.id} invite={claimed} />) }
            { members.map(member => <MemberRow key={member.id} members={members} budgetRange={getBudgetRangeByMoney(showProjectQuery.data?.budgetValue)} team={member.team} projectAddress={project.address} projectId={project.id} createChatRoom={createChatRoomBetweenProjectTeams} onChatBubbleClickedAction={onProjectMemberChatBubbleClicked}/>) }
          </IonList>
        </section>

      </WeaverIonContent>
      <InviteMemberModal analyticsSource={"showProject"} isOpen={(!!searchData.invite) && validInviteTypes.includes(searchData.invite)} projectId={project.id} requiredTeamType={searchData.invite} onDidDismiss={handleInviteCancel} onSuccess={handleInviteSuccess} />
    </WeaverIonPage>
  )
}

export default ShowProject
