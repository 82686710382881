import React from 'react'
import { IonSpinner } from '@ionic/react'
import { useTickingTimer } from '../../hooks/useTickingTimer'
import Styles from './LoadingSpinner.module.scss'

export type TLoadingSpinnerProps = {
  name: string,
  logMessage?: string,
  className?: string,
};

/**
 * A Loading spinner. Only display a loading indicator after a short delay, to make short loads seem instantaneous.
 */
const LoadingSpinner: React.FC<TLoadingSpinnerProps> = (props) =>
  useTickingTimer({ seconds: 1 })
    ? <DisplayedLoadingSpinner {...props} />
    : null

const DisplayedLoadingSpinner: React.FC<TLoadingSpinnerProps> = ({ name, logMessage }) => {
  if (logMessage) console.debug(`[LoadingSpinner#${name}] Spinner displayed: ${logMessage}`)

  return (
    <IonSpinner className={Styles.spinner} name="crescent" />
  )
}

export default LoadingSpinner
