import { z } from "zod"
import { Currency } from "./generated"

/**
 * These are currently manually created to map onto the `generated.ts` types
 */

export const zMoney = z.object({
  currency: z.nativeEnum(Currency),
  amountInPence: z.string(),
})

/** HACK: Nullish should be enough but this was broken when updated Zod 3.14 to 3.21 */
export const zWeaverAddressInput = z.object({
  careOf: z.string().optional().nullish(),
  poBox: z.string().optional().nullish(),
  addressLine1: z.string().optional().nullish(),
  addressLine2: z.string().optional().nullish(),
  postTown: z.string().optional().nullish(),
  county: z.string().optional().nullish(),
  country: z.string().optional().nullish(),
  postCode: z.string().min(2, 'Postcode required'),
})
