import React, { useCallback } from 'react'
import { IonToolbar, IonTitle, IonButton } from '@ionic/react'
import { useDevRemoveIndividualFromTeamMutation } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { getActiveTeam, useMyIndividual, useMyIndividualInvalidateCache } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useRouteToOnboardingForTeam } from '../../../routes'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

const ManageTeams: React.FC = () => {
  const myIndividual = useMyIndividual()
  const individualId = myIndividual.id

  const activeTeam = getActiveTeam(myIndividual)
  const teamId = activeTeam?.id

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const removeIndividualFromTeam = useDevRemoveIndividualFromTeamMutation(gqlDataSource)
  const individualCacheBuster = useMyIndividualInvalidateCache()

  const routeToOnboarding = useRouteToOnboardingForTeam()

  const onClickRemoveIndividualFromTeam = useCallback(async () => {
    if (!teamId) {
      console.error('[devSettings.ManageTeams] Cannot remove individual from no teams.', { myIndividual, activeTeam })
      return
    }

    await removeIndividualFromTeam.mutateAsync({
      individualId,
      teamId,
    })

    await individualCacheBuster()

    routeToOnboarding()
  }, [ removeIndividualFromTeam, individualId, teamId ])

  return (
    <WeaverIonPage id='ManageTeams' key="ManageTeams">
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <IonToolbar>
          <IonTitle>Developer Settings: Manage Teams</IonTitle>
        </IonToolbar>
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonButton onClick={onClickRemoveIndividualFromTeam}>Dettach my team</IonButton>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default ManageTeams
