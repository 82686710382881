import React from 'react'
import { IonText } from "@ionic/react"
import GenericSelectorList, { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import { BudgetRange, ContractorProfileBudgetRangeBadge, ProgressionStatus } from '../../../../graphql/generated'

export type ListBudgetRangeOption = {
  budgetRange: BudgetRange,
  lockedBudgetRangeStatus: ProgressionStatus,
  budgetRangeBadgeData?: Partial<ContractorProfileBudgetRangeBadge>[] | undefined | null,
}

type ListBudgetRangesProps = {
  heading?: string,
  options: GenericSelectorOption<ListBudgetRangeOption>[],
  onSelect: (option?: ListBudgetRangeOption) => Promise<void> | void,
  render?: (option: GenericSelectorOption<ListBudgetRangeOption>) => React.ReactNode | JSX.Element,
}

const ListBudgetRanges: React.FC<ListBudgetRangesProps> = ({ heading, options, onSelect, render }) =>
  options.length === 0
    ? null
    : <>
      {heading && <IonText className='ion-padding-start'>{heading}</IonText>}
      <GenericSelectorList
        options={options}
        render={render}
        multiSelect={false}
        selected={undefined}
        onSelect={onSelect}
        showItemDetail={false}
      />
    </>

export default ListBudgetRanges
