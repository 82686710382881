import React, { ComponentProps, useState } from 'react'
import classnames from 'classnames'
import { AnyLevelTask } from "./types"
import { IonButton, IonItem, IonLabel } from '@ionic/react'
import Styles from "./Task.module.scss"
import IonIconTyped from '../../../common/components/IonIconTyped'
import { TaskStatus } from '../../../graphql/generated'
import { getTaskIcon, useActionableTaskFilter, useMyTasksFilter } from '../taskUtils'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import useUncompleteTaskModal from './useUncompleteTaskModal'

export const getStatusData = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.Completed:
      return { color: "success", label: "Complete" }
    case TaskStatus.Overdue:
      return { color: "danger", label: "Overdue" }
    case TaskStatus.InProgress:
      return { color: "tertiary", label: "In Progress" }
    case TaskStatus.Disabled:
      return { color: 'light', label: 'Locked' }
    case TaskStatus.NotStarted:
    default:
      return { color: "light", label: "Not started" }
  }
}

const isUndoable = (task: AnyLevelTask) => [ TaskStatus.Completed, TaskStatus.Skipped ].includes(task.status)

type HandleClick = (option: { id: string, parentId: string | undefined, event: React.MouseEvent<unknown, MouseEvent>}) => unknown | void

type TaskProps = {
  onAction?: HandleClick,
  task: AnyLevelTask,
  parentId?: string | undefined,
  isNextTask?: boolean,
  refetchTasks?: any,
  ionItemProps?: ComponentProps<typeof IonItem>,
  variant?: "interactive" | "display",
}

const Task: React.FC<TaskProps> = ({ task, parentId, onAction, ionItemProps, isNextTask = false, variant = "interactive"  }) => {
  const isInteractive = variant === "interactive"
  const {
    ["MW-2388-tasks-qol1-part2"]: tasksQuol1Part2,
  } = useWeaverFlags()
  const taskModal = useUncompleteTaskModal({ task })

  const { id, icon, title, status, assignedTeam } = task

  const filterIsMyTask = useMyTasksFilter()
  const filterIsActionable = useActionableTaskFilter()

  const handleClick = onAction && ((...params: Parameters<typeof onAction>) => {
    if (isActionable) return onAction(...params)
  })

  const isMyTask = filterIsMyTask(task)
  const isActionable = isInteractive ? filterIsActionable(task): false
  const showDetail = isActionable
  const iconName = getTaskIcon(icon, task)

  return <IonItem lines='full' className={`${classnames({ [Styles.taskItem]: true,
    [Styles.actionable]: isActionable,
    [Styles.completed]: status === TaskStatus.Completed,
    [Styles.notStarted]: status === TaskStatus.NotStarted,
    [Styles.locked]: status === TaskStatus.Disabled,
    [Styles.nextStep]: tasksQuol1Part2 && isNextTask,
    [Styles.skipped]: status === TaskStatus.Skipped })}`}
  key={id}
  onClick={(event) => handleClick && handleClick({ id, parentId, event })}
  detail={showDetail} {...ionItemProps}>
    <section className={Styles.iconContainer}>
      <IonIconTyped className={`${Styles.taskIcon}`} iconName={iconName} />
    </section>
    <div className={Styles.itemContentContainer}>
      <IonLabel>
        {title}
      </IonLabel>
      <div className={Styles.subtextContainer}>
        {assignedTeam && (
          <div>
            <span>{assignedTeam?.name}</span>
          </div>
        )}
      </div>
    </div>
    {isInteractive && (
      // The modal contains another <Task>, so this must not be rendered in variant='display' contexts, otherwise it will be an infinite recursion
      <>
        {isMyTask && isUndoable(task) ? <IonButton fill='clear' onClick={() => taskModal.open()}><IonIconTyped size='small' slot="start" iconName="arrowUndo" /></IonButton> : null }
        {taskModal.render()}
      </>
    )}
  </IonItem>
}

export default Task
