import React from "react"
import { IdToken } from "@auth0/auth0-spa-js"
import AuthProvider from "./AuthProvider"

export * from "./context"
export * from "./guards"
export * from "./helpers"
export * from "./useAuthAccessToken"
export * from "./useAuthorizedHeader"

export type TAuthEngineType = 'auth0' | 'auth0ReadOnlyCache'

// TODO: We generate this type for Mecha, we could do it for the Frontend too. See: graphql/codegen/plugins/lambda-events.ts
export enum WeaverIdTokenClaims {
  WeaverPhoneNumber = 'https://app.weaver.build/phone_number',
  WeaverIsTestWeaverUser = 'https://app.weaver.build/isTestWeaverUser',
}

export type TBasicUser = {
  // idToken claims managed by Auth0
  email?: string,
  given_name?: string,
  family_name?: string,
  picture?: string,
  // idToken claims added by Weaver in Auth0 Post Login Rules
  [WeaverIdTokenClaims.WeaverPhoneNumber]?: string,
  [WeaverIdTokenClaims.WeaverIsTestWeaverUser]?: boolean,
}

export type TAuth_LoggedOut = {
  engine: TAuthEngineType,
  isLoggedIn: false,
  login: () => Promise<void>,
}
export type TAuth_LoggedIn = {
  engine: TAuthEngineType,
  isLoggedIn: true,
  logout: () => Promise<void>,
  forceRefresh: () => Promise<void>,
  accessToken: string,
  idToken?: IdToken,
  gravatar?: string,
  userData?: TBasicUser,
}
export type TAuth = TAuth_LoggedOut | TAuth_LoggedIn

export const AuthContext = React.createContext<TAuth_LoggedIn | undefined>(undefined)

export default AuthProvider
