import React from 'react'
import { IonIcon, IonItem } from "@ionic/react"
import Styles from "./SingleLeadPaymentPage.module.scss"
import { checkmarkCircle } from 'ionicons/icons'
import { BudgetRange, GetStripeProductConfigsQuery } from '../../../graphql/generated'
import { FeeOption } from './ContractorLeadAcceptorPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import SingleClickButton from '../../../common/components/SingleClickButton'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import NavBackButton from '../../../common/components/GlobalHeader/NavBackButton'
import { getMonthlyPrice, moneyToText } from '../../../common/utils/currency'

type SingleLeadPaymentPageProps = {
  budgetRange: BudgetRange,
  budgetRangeLabel: string,
  stripeProductLeadFee: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  stripeProductMonthlySubscription: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  handleNextClick: (selected: FeeOption) => Promise<void> | void,
}

const _SingleLeadPayment2: React.FC<SingleLeadPaymentPageProps> = ({ stripeProductLeadFee: leadProductConfig, stripeProductMonthlySubscription: subscriptionProductConfig, handleNextClick }) => {
  const budgetRangeLeadFee = moneyToText(leadProductConfig.price)
  const budgetRangeMonthlySubscriptionFee = moneyToText(getMonthlyPrice(subscriptionProductConfig))
  return (
    <WeaverIonContent className='ion-padding-none'>
      <div className={`${Styles.selectMembershipBody} ion-padding`}>
        <div className={Styles.topText}>
          <div className={Styles.headerText}>
            <div className={Styles.titleClassName}>
              <b>
                {budgetRangeLeadFee}
              </b>
            </div>
            <div className={Styles.vatSubheading}>incl. VAT</div>
          </div>
          <div className={Styles.subHeading}>Single Lead Package</div>
        </div>

        <IonItem lines='none'>
          <div className={Styles.tenderReadinessText}>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> High quality lead x1</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> Satisfaction money-back guarantee</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> No further fees when you win the tender</div>
          </div>
        </IonItem>

        <div className={`ion-padding ${Styles.paymentButtonsContainer}`}>
          <div className={Styles.topButtonText}>
            Get this lead for FREE when you subscribe
          </div>
          <SingleClickButton expand='block' fill='outline' className={`ion-margin-bottom ion-text-wrap ${Styles.firstButton}`} onClick={() => handleNextClick(FeeOption.Subscription)}>
            14 day FREE trial
            <br />
            {budgetRangeMonthlySubscriptionFee} per month for unlimited leads
          </SingleClickButton>
          <div>
          </div>
          <SingleClickButton expand='block' className={Styles.secondButton} onClick={() => handleNextClick(FeeOption.PerLead)}>
            Pay {budgetRangeLeadFee}
          </SingleClickButton>
        </div>
      </div>
    </WeaverIonContent>
  )
}

const SingleLeadPaymentPage: React.FC<SingleLeadPaymentPageProps> = (props) => {

  return (
    <WeaverIonPage id='SingleLeadPaymentPage' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Payment method' navElement={<NavBackButton />} />
      </WeaverIonHeader>
      <_SingleLeadPayment2 {...props}/>
    </WeaverIonPage>
  )
}

export default SingleLeadPaymentPage
