import { ReactNode } from "react"
import * as ReactIs from "react-is"

export const getTypeNameFromChildren = (children: ReactNode): string | undefined => {
  if (!ReactIs.isElement(children)) return undefined

  // This digging out is needed because of how `withPageConfig` structures it's output
  // Take the last child if the sub children is a list
  const subChildren = Array.isArray(children.props.children)
    ? children.props.children[children.props.children.length - 1] // Last child
    : children.props.children

  return subChildren.type.name
}
