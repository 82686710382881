import React, { useEffect, useState } from 'react'
import { ScreenRenderProps, useScreensWithProps } from '../../hooks/useScreens'
import { getDateTimeToResolution } from '../../utils/date'
import ScreenPickYear from './ScreenPickYear'
import ScreenPickMonth from './ScreenPickMonth'
import ScreenPickDay from './ScreenPickDay'
import { DateResolution, WeaverDatePickerScreens } from './types'
import { DateTime, DayNumbers, MonthNumbers } from 'luxon'

export const defaultDateResolution = DateResolution.Day
const defaultMin = DateTime.fromObject({ year: 1900 })

const screenResolution: Record<DateResolution, WeaverDatePickerScreens> = {
  Year: WeaverDatePickerScreens.PickYear,
  Month: WeaverDatePickerScreens.PickMonth,
  Day: WeaverDatePickerScreens.PickDay,
}

type WeaverDatePickerProps = {
  value: string | undefined,
  setValue: (value: string) => Promise<void> | void,
  min?: DateTime,
  max?: DateTime,
  resolution?: DateResolution,
}

type ScreenProps = Omit<WeaverDatePickerProps, 'resolution'> & {
  /** The defaulted resolution */
  resolution: DateResolution,
  /** Realistically between now and the last 50 years */
  selectedYear?: number,
  /** 1-12 */
  selectedMonth?: MonthNumbers,
  /** 1-31, but should be valid for the `selectedYear` and `selectedMonth` */
  selectedDay?: DayNumbers,
  /** Select the year */
  setYear: (selectedYear: number) => void,
  /** Select the month between 1-12 */
  setMonth: (selectedMonth: MonthNumbers) => void,
  /** Selected the day  between 1-31, but should be valid for the `selectedYear` and `selectedMonth` */
  setDay: (selectedDay: DayNumbers) => void,
  /** Return to the Year screen (usable at any time) */
  returnToYearScreen: () => void,
  /** Return to the Month screen (requires selectedYear to be set) */
  returnToMonthScreen: () => void,
}

/**
 * The Weaver Date Picker which presents the user with a series of selection grids of options
 * in the order of Year > Month > Day. There is a heading which shows the current status of the internal data.
 * Clicking on a completed component of the header will reset the data back to that screen, to allow corrections.
 *
 * It has options to provide an ISO-8601 initial value, as well as Luxon DateTime min / max constraints.
 * Changing the initial value after mounting will cause as to alter the internal year / month / date will cause a reset.
 *
 * The resolution determines how many of the 3 screens are shown.
 * The setValue callback always gets a full ISO-8601 datetime object.
 *
 * The year options always start with the current year and cannot (currently) go to future years.
 * It pages back and forth with the use of elipsis at the start/end of each grid.
 * You can page outside of the min / max constraints, but will not be able to select a date.
 *
 * All the month options are shown, but those you can't select those outside of the min / max date constraints.
 *
 * All the day options appropriate for the selected year & month are shown,
 * but those you can't select those outside of the min / max date constraints.
 *
 * It is expected that this is used within a `<WeaverIonContent>` somewhere.
 *
 * @see WeaverDatePicker.stories.tsx
 */
const WeaverDatePicker: React.FC<WeaverDatePickerProps> = ({ value, setValue, min = defaultMin, max, resolution = defaultDateResolution }) => {
  const valueDateTime = value === undefined ? undefined : DateTime.fromISO(value)

  // Initialise the internal data with the value passed in, if a valid one has been provided (`undefined` if not)
  // We reinitialise if the a new valid value is passed in after first mount in the `useEffect` below
  const [ internalYear, setInternalYear ] = useState<number | undefined>(valueDateTime?.year)
  const [ internalMonth, setInternalMonth ] = useState<MonthNumbers | undefined>(valueDateTime?.month)
  const [ internalDay, setInternalDay ] = useState<DayNumbers | undefined>(valueDateTime?.day)

  const getInitialScreen = (): WeaverDatePickerScreens => {
    // If I've got some initial internal data, show the highest resolution we allow
    if (internalDay !== undefined && resolution === DateResolution.Day) return WeaverDatePickerScreens.PickDay
    if (internalMonth !== undefined && resolution === DateResolution.Month) return WeaverDatePickerScreens.PickMonth
    // In all other cases, start with the year
    return WeaverDatePickerScreens.PickYear
  }

  const [ Screen, activeScreen, screenController ] = useScreensWithProps<WeaverDatePickerScreens, ScreenProps>({
    init: getInitialScreen,
    screenProps: {
      value,
      setValue,
      min,
      max,
      resolution,
      selectedYear: internalYear,
      selectedMonth: internalMonth,
      selectedDay: internalDay,
      setYear: selectedYear => {
        console.debug('[WeaverDatePicker.setYear] Setting year: ', { selectedYear, activeScreen })
        setInternalYear(selectedYear)

        if (resolution === DateResolution.Year) {
          const dateTime = getDateTimeToResolution(DateResolution.Year, selectedYear)
          setValue(dateTime.toISO())
        } else {
          screenController.changeScreen(WeaverDatePickerScreens.PickMonth)
        }
      },
      setMonth: selectedMonth => {
        console.debug('[WeaverDatePicker.setMonth] Setting month: ', { selectedMonth, activeScreen })
        setInternalMonth(selectedMonth)

        if (internalYear === undefined) throw new Error('[WeaverDatePicker.setMonth] Undefined internalYear! This should never happen in the real world.')

        if (resolution === DateResolution.Month) {
          const dateTime = getDateTimeToResolution(DateResolution.Month, internalYear, selectedMonth)
          setValue(dateTime.toISO())
        } else {
          screenController.changeScreen(WeaverDatePickerScreens.PickDay)
        }
      },
      setDay: selectedDay => {
        console.debug('[WeaverDatePicker.setDay] Setting day: ', { selectedDay, activeScreen })
        setInternalDay(selectedDay)

        if (internalYear === undefined) throw new Error('[WeaverDatePicker.setDay] Undefined internalYear! This should never happen in the real world.')
        if (internalMonth === undefined) throw new Error('[WeaverDatePicker.setDay] Undefined internalMonth! This should never happen in the real world.')

        const dateTime = getDateTimeToResolution(DateResolution.Day, internalYear, internalMonth, selectedDay)
        setValue(dateTime.toISO())
      },
      returnToYearScreen: () => {
        setInternalDay(undefined)
        setInternalMonth(undefined)
        screenController.changeScreen(WeaverDatePickerScreens.PickYear)
      },
      returnToMonthScreen: () => {
        if (internalYear === undefined) throw new Error('[WeaverDatePicker.returnToMonthScreen] Undefined internalYear! This should never happen in the real world.')
        setInternalDay(undefined)
        screenController.changeScreen(WeaverDatePickerScreens.PickMonth)
      },
    },
    screens: {
      PickYear: { render: ScreenPickYear },
      PickMonth: { render: ScreenPickMonth },
      PickDay: { render: ScreenPickDay },
    },
  })

  // When provided a valid value which differs from current state, update the internal data and switch to the appropriate screen for this resolution
  useEffect(() => {
    if (value === undefined) return

    const dateTime = DateTime.fromISO(value)

    const hasChanged = dateTime.year !== internalYear || dateTime.month !== internalMonth || dateTime.day !== internalDay
    if (!hasChanged) return

    setInternalYear(dateTime.year)
    setInternalMonth(dateTime.month)
    setInternalDay(dateTime.day)

    screenController.changeScreen(screenResolution[resolution])
  }, [ value ])

  return <>{Screen}</>
}

export type ScreenComponentProps = ScreenRenderProps<WeaverDatePickerScreens> & ScreenProps

export default WeaverDatePicker
