import React from "react"
import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from "@ionic/react"
import { arrowBack, calculatorOutline, scanOutline } from "ionicons/icons"
import { pageConfig_Profile, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges, pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations, useRouteTo } from "../../../routes"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonPage from "../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import Styles from './ContractorLeadPreferencesPage.module.scss'

const ContractorLeadPreferencesPage: React.FC = () => {
  const uniqueSearch = `${new Date().getTime()}`

  const goToProfile = useRouteTo(pageConfig_Profile.path)
  const goToContractorBudgetRanges = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path)
  const goToContractorLeadLocations = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations.path)

  return <WeaverIonPage id='ContractorLeadPreferencesPage' className={Styles.setLeadPreferencesPage}>
    <WeaverIonHeader>
      <IonToolbar>
        <IonTitle>Lead preferences</IonTitle>
        <IonButtons slot="start" className='ion-padding'>
          <IonIcon className={Styles.backIcon} icon={arrowBack} onClick={goToProfile({})}></IonIcon>
        </IonButtons>
      </IonToolbar>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h3>Add your lead preferences</h3>
      <p className={Styles.subtitle}>We want to make sure that we only send you the leads that suit your business and the direction of growth you want it to take. Tweak here your preferences to get the best projects for you</p>
      <IonButton expand="block" onClick={goToContractorLeadLocations({}, uniqueSearch, 'root', 'replace')}><span className={Styles.inlineFlex}><IonIcon className={Styles.extraMargin} slot="start" icon={scanOutline} />Set location preferences</span></IonButton>
      <IonButton expand="block" onClick={goToContractorBudgetRanges({})}><span className={Styles.inlineFlex}><IonIcon className={Styles.extraMargin} slot="start" icon={calculatorOutline} />Set budget preferences</span></IonButton>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ContractorLeadPreferencesPage
