type ParameterList = {
  [param: string]: string | number | undefined | null, 
}

export const replaceAndEncode = (str: string, parameterList: ParameterList): string => {
  let resultedString = `${str}`

  Object.keys(parameterList).forEach((paramKey) => {
    resultedString = resultedString.replaceAll('${'+paramKey+'}', encodeURIComponent(parameterList[paramKey] ?? ''))
  })
  return resultedString
}