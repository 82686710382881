import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../graphql"
import { ChatRoom, CreateChatRoom, UpdateChatRoom, useCreateChatRoomMutation, useGetChatRoomForProjectTeamsQuery, useJoinChatRoomMutation, useUpdateChatRoomMutation } from "../../../graphql/generated"
import { useMyChatRoomsQueryKey } from "./useMyChatRooms"
import { useAvailableChatRoomsQueryKey } from "./useAvailableChatRooms"

const PACKAGE = 'api.services.chat'

type TMutationCreateChatRoomFn = (input: CreateChatRoom) => Promise<Pick<ChatRoom, 'id' >>
type TMutationUpdateChatRoomFn = (input: UpdateChatRoom) => Promise<void>
type TMutationJoinChatRoomFn = (chatRoom: Pick<ChatRoom, 'id'>) => Promise<void>

type TUseChatRoomFunctionsReturns = {
  createChatRoom: TMutationCreateChatRoomFn,
  updateChatRoom: TMutationUpdateChatRoomFn,
  joinChatRoom: TMutationJoinChatRoomFn,
}

export const useChatRoomFunctions = (): TUseChatRoomFunctionsReturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useChatRoomFunctions`

  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: useMyChatRoomsQueryKey })
    queryClient.invalidateQueries({ queryKey: useAvailableChatRoomsQueryKey })
  }

  console.log(`[${LOCAL_PACKAGE}] Rendering `, { queryClient })

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const mutationCreateChatRoom = useCreateChatRoomMutation(gqlDataSource, { onSuccess })
  const mutationUpdateChatRoom = useUpdateChatRoomMutation(gqlDataSource, { onSuccess })
  const mutationJoinChatRoom = useJoinChatRoomMutation(gqlDataSource, { onSuccess })

  console.debug(`[${LOCAL_PACKAGE}] React Graph`, { mutationCreateChatRoom, mutationUpdateChatRoom, mutationJoinChatRoom })

  return {
    createChatRoom: async (input) => {
      const result = await mutationCreateChatRoom.mutateAsync({ input }, {
        onSuccess: () => {
          queryClient.invalidateQueries(useGetChatRoomForProjectTeamsQuery.getKey({ teamIds: input.teamIds, projectId: input.projectId }))
        },
      })
      return {
        id: result.createChatRoom.id,
      }
    },

    updateChatRoom: async (input) => {
      await mutationUpdateChatRoom.mutateAsync({ input })
    },
    joinChatRoom: async (chatRoom) => {
      await mutationJoinChatRoom.mutateAsync({ chatRoomId: chatRoom.id })
    },
  }
}
