import { Duration, DurationObjectUnits } from "luxon"

// We decided to go with 2 minutes as this is the time after the 
// use has logged in or signed up. It's not the time accounted from
// the moment that the user pressed "Continue" on the login screen.
const DEFAULT_CLEAR_AFTER: DurationObjectUnits = { minutes: 2 }

type StartWindowCloseTimerArgs = {
  windowProxyRef: WindowProxy | null,
  clearAfter?: DurationObjectUnits,
}
/**
 * This function is created to handle an edge case with Auth0 Centralized Universal Login.
 * When we call the `.loginWithPopup` of the auth0 spa sdk (@auth0/auth0-spa-js) we will create
 * a new window. By utilising the config argument of the `.loginWithPopup` we can create the window
 * ourselves and hold reference to it. This function uses this reference to try and close the window
 * using a `setInterval`.
 * 
 * The reason for this is that on iOS Safari (tested on Safari 14 and Safari 16) if a Save Password
 * is triggerred, iOS seems to prevent the window from closing until the user has completed the selection.
 * This interval will then keep calling close to make sure it's closed. Given that `.close()` is an async function
 * and does not block the execution of any other JavaScript code, we can safely leave it running and clear
 * the interval after a `DEFAULT_CLEAR_AFTER` time. (currently 2 minutes)
 * @param windowProxyRef 
 * @param clearAfter 
 * @returns void
 */
export default ({ windowProxyRef, clearAfter = DEFAULT_CLEAR_AFTER }: StartWindowCloseTimerArgs) => {
  if (!windowProxyRef){
    return
  }
  const duration = Duration.fromObject(clearAfter)
  const handler: TimerHandler = () => {
    windowProxyRef?.close()
  }

  const interval = window.setInterval(handler, 250)

  window.setTimeout(() => {
    window.clearInterval(interval)
  }, duration.as("milliseconds"))
}