import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TeamType, useClaimCompanyTeamMutation, useClaimNonCompanyTeamMutation, useCreateArchitectProfileMutation, useCreateContractorProfileMutation, useCreateHomeownerProfileMutation, WeaverAddressInput } from '../../../graphql/generated'

const useTeamNamePlaceholder = (team: TeamType): string => {
  const myIndividual = useMyIndividual()

  switch (team) {
    case TeamType.Architect: return `${myIndividual.familyName}'s Architect`
    case TeamType.Contractor: return `${myIndividual.familyName}'s Contractor`
    case TeamType.Homeowner: return `The ${myIndividual.familyName}'s`
    case TeamType.Weaver: return `Weaver`
  }
}

type OnboardArchitectTeamProps = {
  companyNumber: string,
  companyRegisteredName: string,
  companyTradingAs: string,
  companyAddress?: WeaverAddressInput,
}
export const useOnboardArchitectTeam = () => {
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimCompanyTeam = useClaimCompanyTeamMutation(datasource)
  const createArchitectProfile = useCreateArchitectProfileMutation(datasource)

  return async ({ companyNumber, companyRegisteredName, companyTradingAs, companyAddress }: OnboardArchitectTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimCompanyTeam.mutateAsync({
      type: TeamType.Architect,
      name: companyTradingAs,
      companyNumber,
    })

    // Build the architect profile
    await createArchitectProfile.mutate({
      input: {
        architectTeamId: claimCompanyTeamResult.claimCompanyTeam.id,
        companyRegisteredName,
        companyTradingAs,
        companyNumber,
        companyAddress,
      },
    })

    return { team: claimCompanyTeamResult.claimCompanyTeam }
  }
}

type OnboardContractorTeamProps = {
  companyNumber: string,
  companyRegisteredName: string,
  companyTradingAs: string,
  companyAddress?: WeaverAddressInput,
  companyIncorporationDate?: string,
}

export const useOnboardContractorTeam = () => {
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimCompanyTeam = useClaimCompanyTeamMutation(datasource)
  const createContractorProfile = useCreateContractorProfileMutation(datasource)

  return async ({ companyNumber, companyRegisteredName, companyTradingAs, companyAddress, companyIncorporationDate }: OnboardContractorTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimCompanyTeam.mutateAsync({
      type: TeamType.Contractor,
      name: companyTradingAs,
      companyNumber,
    })

    // Build the contractor profile
    await createContractorProfile.mutate({
      input: {
        contractorTeamId: claimCompanyTeamResult.claimCompanyTeam.id,
        companyRegisteredName,
        companyTradingAs,
        companyNumber,
        companyAddress,
        companyIncorporationDate,
      },
    })

    return { team: claimCompanyTeamResult.claimCompanyTeam }
  }
}

type OnboardHomeownerTeamProps = {
  teamName: string,
  propertyAddress?: WeaverAddressInput,
}
export const useOnboardHomeownerTeam = () => {
  const datasource = useGraphQLDataSource({ api: 'core' })
  const claimNonCompanyTeam = useClaimNonCompanyTeamMutation(datasource)
  const createHomeownerProfile = useCreateHomeownerProfileMutation(datasource)

  return async ({ teamName, propertyAddress }: OnboardHomeownerTeamProps) => {
    // Create the team first
    const claimCompanyTeamResult = await claimNonCompanyTeam.mutateAsync({
      type: TeamType.Homeowner,
      name: teamName,
    })

    // Build the contractor profile
    await createHomeownerProfile.mutate({
      input: {
        homeownerTeamId: claimCompanyTeamResult.claimNonCompanyTeam.id,
        teamName,
        propertyAddress,
      },
    })

    return { team: claimCompanyTeamResult.claimNonCompanyTeam }
  }
}
