import React from 'react'
import { IonIcon, IonItem, IonLabel, IonText } from '@ionic/react'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { bookOutline, flashOutline, helpCircleOutline, megaphoneOutline } from 'ionicons/icons'
import { showIntercom } from '../../common/utils/intercom'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import Styles from './Help.module.scss'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useMyIndividual } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useAuthContext } from '../../api/providers/AuthProvider'

const Help: React.FC = () => {

  const weaverFlags = useWeaverFlags()
  const myIndividual = useMyIndividual()
  const auth = useAuthContext()
  const feedbackBaseURL = `https://weavertech.typeform.com/to/DGLxY0FD`
  const url = new URL(feedbackBaseURL)
  url.searchParams.set('team_id', myIndividual.teamConnections[0].team.id)
  url.searchParams.set('team_name', myIndividual.teamConnections[0].team.name ?? '' )
  url.searchParams.set('user_id', myIndividual.id)
  url.searchParams.set('user_email', auth.userData?.email ?? '')
  const feedbackLink = url.toString()

  return (
    <WeaverIonPage id='Help'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Help'/>
      </WeaverIonHeader>
      <WeaverIonContent className={'ion-padding ion-padding-end'}>
        <IonText>
          <h4>Help</h4>
        </IonText>
        <a className={Styles.link} href='https://help.weaver.build/en/' target='_blank' rel="noreferrer">
          <IonItem className={Styles.helpItem} lines="full" detail={true}>
            <IonIcon color='dark' icon={bookOutline} />
            <IonLabel>
              Help center
            </IonLabel>
          </IonItem>
        </a>
        <IonItem className={Styles.helpItem} lines="full" onClick={showIntercom} detail={true}>
          <IonIcon icon={helpCircleOutline} color='dark'></IonIcon>
          <IonLabel>
            Contact Support
          </IonLabel>
        </IonItem>
        <a className={Styles.link} href='https://www.weaver.build/grow-with-weaver' target='_blank' rel="noreferrer">
          <IonItem className={Styles.helpItem} lines="full" detail={true}>
            <IonIcon icon={flashOutline} color='dark'></IonIcon>
            <IonLabel>
              Grow with Weaver
            </IonLabel>
          </IonItem>
        </a>
        {weaverFlags['MW-2397-feedback-link-on-help-page'] &&
        <a className={Styles.link} href={feedbackLink} target='_blank' rel="noreferrer">
          <IonItem className={Styles.helpItem} lines="full" detail={true}>
            <IonIcon icon={megaphoneOutline} color='dark'></IonIcon>
            <IonLabel>
              Give us feedback
            </IonLabel>
          </IonItem>
        </a>}
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default Help
