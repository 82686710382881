import { useMutation, useQueryClient } from '@tanstack/react-query'
import { z } from "zod"
import { useAuthContext, useAuthorizationHeader } from "../../providers/AuthProvider"
import { useEnvironmentContext } from "../../providers/EnvironmentProvider"
import { decryptEncryptedJWT, encryptAuth } from "./crypto"

const zJwtPersistItem = z.object({
  id: z.string(),
  savedAt: z.string(),
  email: z.string(),
  encryptedJWT: z.string(),
})

export const useJwtManagerPersistItem = () => {
  const queryClient = useQueryClient()

  // Find the API
  const environment = useEnvironmentContext()
  const auth = useAuthContext()
  const Authorization = useAuthorizationHeader()

  if (environment.apiGateway.devSupport.url === undefined) {
    throw new Error('[useJwtManager] Unable to find the devSupport API url')
  }
  const devSupportURL = environment.apiGateway.devSupport.url

  // Persist a JWT

  type PersistJwtProps = {
    passcode: string,
  }
  const persistJwt = useMutation([ 'jwtManager', 'persist' ], async ({ passcode }: PersistJwtProps) => {
    // Encrypt the Auth tokens
    const encryptedJWT = encryptAuth(auth, passcode)

    // Validate that we can decrypt them with the same postcode
    const decrypted = decryptEncryptedJWT(encryptedJWT, passcode)
    console.log(`[useJwtManager.persistJwt] Decryption check `, {
      accessTokenMatches: auth.accessToken === decrypted.accessToken,
      idTokenMatches: auth.idToken?.__raw === decrypted.idTokenRaw,
    })

    // Persist the JWT
    const response = await fetch(devSupportURL, {
      method: 'POST',
      headers: { Authorization },
      body: JSON.stringify({
        encryptedJWT,
      }),
    })

    const jsonBody = await response.json()
    if (response.status !== 200) {
      return {
        errorCode: response.status,
        jsonBody,
      }
    }

    return zJwtPersistItem.parse(jsonBody)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'jwtManager', 'list' ] })
    },
  })

  return persistJwt
}
