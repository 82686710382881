import React, { ComponentProps } from 'react'
import LoadingSpinner from '.'
import WeaverIonContent from '../WeaverIonWrappers/WeaverIonContent'

/**
 * A WeaverIonContent wrapped LoadingSpinner
 */
const LoadingSpinnerContent: React.FC<ComponentProps<typeof LoadingSpinner>> = (props) =>
  <WeaverIonContent>
    <LoadingSpinner {...props} />
  </WeaverIonContent>

export default LoadingSpinnerContent
