import React, { useEffect, useMemo, useRef, useState } from 'react'

type IsSeenInBrowserProps = {
  isSeenCallback : () => void,
  // Whether or not the callback will be called
  isActive: boolean,
  // After N seconds visible the callback will be called.
  secondsForSeen?: number,
}

export const IsSeenInBrowser: React.FC<IsSeenInBrowserProps> = ({ children, isSeenCallback, isActive, secondsForSeen = 1 }) => {

  const topRef = useRef(document.createElement("div"))
  const bodyRef = useRef<HTMLDivElement>(document.createElement("div"))
  const bottomRef = useRef(document.createElement("div"))

  const [ bodyVisible, setBodyVisible ] = useState(false)

  const [ topSeen, setTopSeen ] = useState(false)
  const [ bodySeen, setBodySeen ] = useState(false)
  const [ bottomSeen, setBottomSeen ] = useState(false)

  // Seconds the component has been visible since last time
  const [ secondsSeen, setSecondsSeen ] = useState(0)

  useEffect(() => {
    if (bodyVisible === false) {
      setSecondsSeen(0)
      setTopSeen(false)
      setBottomSeen(false)
    }
  }, [ bodyVisible ])

  useEffect(() => {
    if (bodyVisible && !bodySeen && isActive) {

      const timer = setTimeout(() => {
        if ((topSeen || bottomSeen) && isActive && (secondsSeen >= secondsForSeen)) {
          isSeenCallback()
          setBodySeen(true)
        }
        if (bodyVisible) setSecondsSeen(seconds => seconds + 1)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [ bodyVisible, bodySeen, isActive, topSeen, bottomSeen, secondsSeen, secondsForSeen, isSeenCallback ])

  const seenMeObserver = useMemo(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {

        if (entry.target === topRef.current && entry.isIntersecting) setTopSeen(true)
        if (entry.target === bottomRef.current && entry.isIntersecting) setBottomSeen(true)
        if (entry.target === bodyRef.current) setBodyVisible(entry.isIntersecting)
      })
    }
    const observer = new IntersectionObserver(callback)
    return observer

  }, [])

  useEffect(() => {
    if (!seenMeObserver) return

    seenMeObserver.observe(topRef.current)
    seenMeObserver.observe(bottomRef.current)
    seenMeObserver.observe(bodyRef.current)
    return () => { seenMeObserver.disconnect() }
  }, [ seenMeObserver ])

  return (
    <>
      <div ref={topRef}></div>
      <div ref={bodyRef}>
        {children}
      </div>
      <div ref={bottomRef}></div>
    </>
  )

}
