import React, { useCallback, useEffect, useState } from "react"
import { IonButton, IonCard, IonIcon, IonItem, IonSpinner, useIonAlert, useIonRouter } from "@ionic/react"
import { checkmarkCircle, eye } from "ionicons/icons"
import { useGraphQLDataSource } from "../../api/graphql"
import Styles from "./ShowLeadPage.module.scss"
import { useShowLeadQuery, useRejectLeadMutation, ProjectMemberRole, useListMyProjectsAndLeadsQuery } from "../../graphql/generated"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { getEnumLabel, uniqueFilter } from "../../common/utils"
import { Duration, DateTime } from "luxon"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { pageConfig_Projects_AcceptLead_PaymentPlanPicker, useRouteTo } from "../../routes"
import { useParamsFromPageConfig } from "../../routesProvider"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { getFlexibleProjectBudgetAsMoneyOrMoneyRange, getFlexibleProjectBudgetAsText } from "./common"
import { useAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import GoogleMapFromWeaverAddress from "../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress"
import { flexibleBudgetTextToSignificantFigures } from "../../common/utils/budgetTransformation"
import { getBudgetRangeByMoney } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { teamTypeLabels } from "../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n"
import { LeadErrorPage } from "./LeadErrorPage"
import { AcceptLeadButton } from "./AcceptLeadButton/AcceptLeadButton"
import { CountDownTimerProps, useCountDown } from "../../common/hooks/useCountDown"
import { useQueryClient } from "@tanstack/react-query"

const LeadCountDownTimer: React.FC<CountDownTimerProps> = props => {
  const countDown = useCountDown(props)
  return countDown === null
    ? null
    : <div>
      Lead expires in
      <span className={Styles.countDownTextColor}>{countDown}</span>
    </div>
}

const ShowLead: React.FC = () => {
  const queryClient = useQueryClient()
  const weaverFlags = useWeaverFlags()
  const triggerEvent_Project_Lead_Responded_Rejected = useAnalyticsEvent("Project_Lead_Responded_Rejected")
  const triggerEvent_Lead_Accepted_Clicked = useAnalyticsEvent("Lead_Accept_Clicked")

  const myTeam = useMyIndividualActiveTeam()

  const { id } = useParamsFromPageConfig<{ id: string }>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ present ] = useIonAlert()
  const router = useIonRouter()

  const showLeadQuery = useShowLeadQuery(gqlDataSource, { id }, { refetchOnWindowFocus: false, retry: false })
  const routeToPaymentPlanPicker = useRouteTo(pageConfig_Projects_AcceptLead_PaymentPlanPicker.path)
  const rejectLeadMutation = useRejectLeadMutation(gqlDataSource, { onSuccess: async () => {
    if (weaverFlags["MW-2415-lead-cache-busting"]) {
      await queryClient.invalidateQueries(useListMyProjectsAndLeadsQuery.getKey())
    }
  } })
  const [ rejectLoading, setRejectLoading ] = useState<boolean>(false)

  const triggerEvent_Lead_Viewed = useAnalyticsEvent("Lead_Viewed")

  useEffect(() => {
    const lead = showLeadQuery.data?.getLead
    const leadBudgetRange = getBudgetRangeByMoney(lead?.budgetValue)

    if (lead?.id) {
      triggerEvent_Lead_Viewed({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : DateTime.fromISO(lead?.tenderReturnDate ?? '').toLocaleString(DateTime.DATE_MED),
        type: lead.projectTypes,
        budgetRange: leadBudgetRange,
      })
    }
  }, [ showLeadQuery.data?.getLead.id ])

  /** @deprecated remove with flag: MW-2360-block-contractor-accepting-lead  **/
  const acceptLead = useCallback(async () => {
    const leadBudgetRange = getBudgetRangeByMoney(showLeadQuery.data?.getLead.budgetValue)
    await triggerEvent_Lead_Accepted_Clicked({
      projectId: id,
      budgetRange: leadBudgetRange,
    })

    routeToPaymentPlanPicker({ leadId: id })()
  }, [ id, showLeadQuery.data?.getLead.budgetCategory ])

  const rejectLead = useCallback(async () => {
    setRejectLoading(true)
    try {
      await rejectLeadMutation.mutateAsync({ id })
      await triggerEvent_Project_Lead_Responded_Rejected({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        tenderReturnDate: weaverFlags['MW-2386-remove-tender-return-date'] ? undefined : DateTime.fromISO(lead?.tenderReturnDate ?? '').toLocaleString(DateTime.DATE_MED),
        type: lead.projectTypes,
      })
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Unable to Reject Lead",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setRejectLoading(false)
    router.push("/projects")
  }, [ rejectLeadMutation, id, setRejectLoading, router ])

  if (showLeadQuery.isFetching && !showLeadQuery.data) {
    return <LoadingSpinnerPage name="ShowLoadPage.showLeadQuery" />
  }

  if (showLeadQuery.error || !showLeadQuery.data) {
    return !weaverFlags["MW-2359-full-seats-ui"] ? <ErrorBlockPage name='ShowLeadPage.showLeadQuery' onRefresh={showLeadQuery.refetch} /> : <LeadErrorPage name='ShowLeadPage.showLeadQuery' />
  }

  const { data: { getLead: lead } } = showLeadQuery

  const loading = rejectLoading

  const randomisedContractorNumber = Math.floor(Math.random() * 14) + 6

  /** Calculates the Leftover Time from the lead against the FOMO time needed  */
  const FOMO_TIMEOUT_IN_SECS =  Duration.fromObject({ milliseconds: 43200000 })
  const timeSinceLeadSentInSeconds = DateTime.fromISO(lead.createdAt).diff(DateTime.now())
  const timeLeftInTimer = FOMO_TIMEOUT_IN_SECS.plus(timeSinceLeadSentInSeconds)
  const isInFomoTimeOut = (timeLeftInTimer.milliseconds > 0) ? true : false

  return <WeaverIonPage id='ShowLeadPage'>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={`Lead - ${lead.projectTypes.map(x => {return  ' ' + getEnumLabel(x)})}`} />
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonCard className={Styles.card}>
        {lead.address && <GoogleMapFromWeaverAddress address={lead.address} />}

        { weaverFlags['MW-2387-fomo-lead-acceptance']
          ? <IonItem className={`${Styles.fomoContractors}`}>
            <IonIcon className="ion-padding" color="danger" icon={eye}/>
            <div>{randomisedContractorNumber} contractors are looking at this lead for 3 tender places</div>
          </IonItem>
          :<></>
        }

        <IonItem className={`ion-margin-top`}>
          <div className={Styles.row}>
            <div>Project Type</div>
          </div>
          <div className={Styles.row}>
            {lead.projectTypes.map(getEnumLabel).join(', ')}
          </div>
        </IonItem>

        <IonItem>
          <div className={Styles.row}>
            <div>Budget</div>
          </div>
          <div className={Styles.row}>
            {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(lead, myTeam))}
          </div>
        </IonItem>

        <IonItem>
          <div className={Styles.row}>
            <div>Work Start Estimate</div>
          </div>
          <div className={Styles.row}>
            {getWorkStartEstimateLabels()[lead.workStartEstimate]}
          </div>
        </IonItem>

        {weaverFlags['MW-2386-remove-tender-return-date']
          ? null
          : <IonItem>
            <div className={Styles.row}>
              <div>Tender Return Date</div>
            </div>
            <div className={Styles.row}>
              {lead.tenderReturnDate && DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
            </div>
          </IonItem>
        }

        <IonItem>
          <div className={Styles.row}>
            <div>Project Led By</div>
          </div>
          <div className={Styles.row}>
            {
              lead.members
                .filter(member => member.projectRole === ProjectMemberRole.Owner)
                .filter(uniqueFilter)
                .map(member => teamTypeLabels[member.team.type])
                .join(', ')
            }
          </div>
        </IonItem>

        <IonItem>
          <div>Further Description</div>
        </IonItem>
        <div className={`${Styles.descriptionText} ion-padding-start`}>
          {lead.description || "none"}
        </div>

        <IonItem>
          <div className={Styles.tenderReadinessTitle}>Tender Readiness</div>
        </IonItem>
        <IonItem>
          <div className={Styles.tenderReadinessText}>
            <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Local authority permissions</div>
            <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Detailed existing & proposed drawings</div>
            <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Weaver tenderers limited to 3</div>
          </div>
        </IonItem>

        { weaverFlags['MW-2387-fomo-lead-acceptance']
          ? <IonItem>
            {isInFomoTimeOut ? <LeadCountDownTimer duration={timeLeftInTimer}/> : null}
          </IonItem>
          :<></>
        }

        <div className={Styles.acceptRejectButtons}>
          {weaverFlags['MW-2360-block-contractor-accepting-lead']
            ? <AcceptLeadButton lead={lead} loading={loading} source="ShowLeadPage" />
            : <IonButton expand='block' disabled={loading} onClick={acceptLead} color="primary">
              Accept lead
            </IonButton>}
          <IonButton expand='block' fill="outline" disabled={loading} onClick={rejectLead} color="primary">
            {rejectLoading ? <IonSpinner /> : <>Reject</>}
          </IonButton>
        </div>

      </IonCard>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ShowLead
