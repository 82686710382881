import React from 'react'
import { ScreenComponentProps } from './WeaverDatePicker'
import WeaverDatePickerHeading from './WeaverDatePickerHeading'
import GridPickDay from './GridPickDay'

import Styles from './WeaverDatePicker.module.scss'

const ScreenPickYear: React.FC<ScreenComponentProps> = props =>
  <div className={Styles.weaverDatePickerContainer}>
    <WeaverDatePickerHeading {...props} />
    <GridPickDay {...props} />
  </div>

export default ScreenPickYear
