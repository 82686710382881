import React, { ComponentProps } from 'react'
import WeaverIonPage from '../WeaverIonWrappers/WeaverIonPage'
import LoadingSpinnerContent from './LoadingSpinnerContent'
import LoadingSpinner from '.'

/**
 * A full page loading spinner
 */
const LoadingSpinnerPage: React.FC<ComponentProps<typeof LoadingSpinner>> = (props) =>
  <WeaverIonPage id={`LoadingSpinnerPage.${props.name}`} disableDirectChildStructureChecks={true}>
    <LoadingSpinnerContent {...props} />
  </WeaverIonPage>

export default LoadingSpinnerPage
