export enum WeaverDatePickerScreens {
  PickYear = "PickYear",
  PickMonth = "PickMonth",
  PickDay = "PickDay",
}

export enum DateResolution {
  /** Pick a valid year > month > day with the result being an ISO-8601 date as picked */
  Day = "Day",
  /** Pick a valid year > month with the result being an ISO-8601 date on the 1st day of the picked month */
  Month = "Month",
  /** Pick a valid year with the result being an ISO-8601 date on the 1st Jan of the picked year */
  Year = "Year",
}
