import React from 'react'
import { IonButton, IonText, useIonRouter } from '@ionic/react'
import checkMarkCircleSharp from "./checkmark-circle-sharp.svg"
import Styles from "./SuccessPage.module.scss"
import WeaverIonPage from '../WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../WeaverIonWrappers/WeaverIonContent'

type SuccessPageProps = {
  routeToPage?: () => void,
  header?: string,
  text?: string,
  buttonText?: string,
}

const SuccessPage: React.FC<SuccessPageProps> = ({ routeToPage, header, text, buttonText }) => {
  const ionRouter = useIonRouter()

  /**
   * When the continue button is clicked take the user to the specified router link or to the root route.
   */
  const onContinueClicked = () => {
    if (routeToPage){
      routeToPage()
    } else {
      ionRouter.push('/')
    }
  }

  return (
    <WeaverIonPage id='SuccessPage'>
      <WeaverIonContent>
        <div className={`${Styles.mainContainer} ion-padding ion-align-items-center`}>
          <img src={checkMarkCircleSharp} className={Styles.checkMarkCircleSharpImage} alt="Green Tick" />
          {header && <h3 className={Styles.uploadYourDocumentsText}>{header}</h3>}
          {text && <IonText className={Styles.supportedFormatsText}>{text}</IonText>}
          { (routeToPage && buttonText) &&
            <IonButton className={Styles.continueToRouterLinkButton} onClick={() => onContinueClicked()}>
              {buttonText}
            </IonButton>
          }
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}
export default SuccessPage
