import { cubeOutline } from "ionicons/icons"
import React, { useEffect, useState } from "react"
import { Redirect } from "react-router"
import { useGraphQLDataSource } from "../../api/graphql"
import { useCreateChatRoomMutation, useJoinChatRoomForIndividualMutation, useListMyChatRoomsQuery, useShowProjectQuery } from "../../graphql/generated"
import { useParamsFromPageConfig } from "../../routesProvider"

const ProjectChat: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{ projectId: string }>()
  const [ chatroomId, setChatroomId ] = useState<string | undefined>(undefined)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const projectQuery = useShowProjectQuery(gqlDataSource, { id: projectId })
  const myChatRooms = useListMyChatRoomsQuery(gqlDataSource)
  const createChat = useCreateChatRoomMutation(gqlDataSource)
  const joinChatRoomForIndividual = useJoinChatRoomForIndividualMutation(gqlDataSource)

  useEffect(() => {
    async function findProjectChat() {
      const chatrooms = myChatRooms.data?.listMyChatRooms
      const project = projectQuery.data?.getProject
      if (chatrooms && project) {
        let projectChatroomId = chatrooms.find(x => x?.chatRoom.name === project.title)?.chatRoom.id

        if (!projectChatroomId) {
          const created = await createChat.mutateAsync({ input: {
            icon: cubeOutline,
            name: project.title,
            teamIds: project.members.map( m => m.team.id),
          } })
          if (!created.createChatRoom) {
            throw new Error("failed to create new chatroom for project")
          }
          projectChatroomId = created.createChatRoom?.id
        }

        // check everyone's in the group chat
        await Promise.all(project.members.map(async x => {
          // HACK: teamIds are also individualIds, so we can just feed them to joinChatRoomForIndividual
          const teamId = x.team.id
          if (!teamId || !projectChatroomId) {
            throw new Error("Missing Team/project ID during add to new chatroom")
          }
          console.log(`adding ${teamId} to project ${projectChatroomId}`)
          await joinChatRoomForIndividual.mutateAsync({ chatRoomId: projectChatroomId, individualId: teamId })
        }))

        setChatroomId(projectChatroomId)
      }
    }

    findProjectChat()
  }, [ projectQuery.data?.getProject, myChatRooms.data?.listMyChatRooms ])

  if (chatroomId) {
    return <Redirect to={`/chats/${chatroomId}`} />
  }

  return <div></div>
}

export default ProjectChat
