import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'

import Styles from "./UploadableFileChip.module.scss"
import { reloadCircleOutline } from 'ionicons/icons'

type UploadableFileChipErroredProps  =
{
  file: File,
  onRemoveFileClicked: (file: File) => void ,
  retryUpload: () => void,
}

const UploadableFileChipErrored: React.FC<UploadableFileChipErroredProps> = ({ file, retryUpload, onRemoveFileClicked }) => {
  const onRetryClicked = (event: React.MouseEvent) => {
    // Avoid the outer button receive the event.
    event.stopPropagation()
    retryUpload()
  }

  return (
    <IonButton className={`${Styles.uploadBubble} ${Styles.error}`}
      fill="clear"
      size="small"
      onClick={(e) => {
        e.stopPropagation(),
        onRemoveFileClicked(file)}}>
      <span>{file.name}</span>
      <IonIcon icon={reloadCircleOutline} onClick={onRetryClicked} />
    </IonButton>
  )
}

export default UploadableFileChipErrored
