import { moneyAmountAsNumber, MoneyRange } from "../../../../common/utils/currency"
import { objectEntries } from "../../../../common/utils/objects"
import { BudgetRange, Money, StripeProduct } from "../../../../graphql/generated"

/**
 * Product talk about budgets in "kilo-pounds" (a non-SI unit) and the product processes currency in pence.
 * This function converts from marketing speak into data for storage / comparision.
 *
 * e.g. £150k > 150_000_00
 *
 * @param {number} kPounds The amount to convert in thousands of pounds (e.g. `£150k` or `£150,000` would be `150`)
 * @returns kPounds in pence
 */
const kPoundsToPence = (kPounds: number) => kPounds * 1_000_00

type BudgetRangeConfig = {
  fromInclusive: number,
  toExclusive: number,
}

export const budgetRangesConfigs: Record<BudgetRange, BudgetRangeConfig> = {
  F30T100: {
    fromInclusive: kPoundsToPence(30),
    toExclusive: kPoundsToPence(100),
  },
  F100T500: {
    fromInclusive: kPoundsToPence(100),
    toExclusive: kPoundsToPence(500),
  },
  F500T1000: {
    fromInclusive: kPoundsToPence(500),
    toExclusive: kPoundsToPence(1000),
  },
  F1000T3000: {
    fromInclusive: kPoundsToPence(1000),
    toExclusive: kPoundsToPence(3000),
  },
}

export const budgetRangeMonthlySubscriptionStripeProduct: Record<BudgetRange, StripeProduct> = {
  F30T100: StripeProduct.UnlimitedLeadSubscriptionF30T100,
  F100T500: StripeProduct.UnlimitedLeadSubscriptionF100T500,
  F500T1000: StripeProduct.UnlimitedLeadSubscriptionF500T1000,
  F1000T3000: StripeProduct.UnlimitedLeadSubscriptionF1000T3000,
}

export const budgetRangeLeadFeeStripeProduct: Record<BudgetRange, StripeProduct> = {
  F30T100: StripeProduct.SingleLeadPaymentF30T100,
  F100T500: StripeProduct.SingleLeadPaymentF100T500,
  F500T1000: StripeProduct.SingleLeadPaymentF500T1000,
  F1000T3000: StripeProduct.SingleLeadPaymentF1000T3000,
}

export const isSubscriptionStripeProduct = (product: StripeProduct) =>
  Object.values(budgetRangeMonthlySubscriptionStripeProduct).includes(product)

const budgetRangeMatchesAmountInPennies = (amountInPennies: number, config: BudgetRangeConfig) =>
  amountInPennies >= config.fromInclusive && amountInPennies < config.toExclusive

// ts-prune-ignore-next-line -- utility function, used in tests: Please remove this on first usage
export const getBudgetRangeByAmountInPennies = (amountInPennies?: number): BudgetRange | undefined => {
  if (amountInPennies == null) return amountInPennies

  const budgetRangeEntry = objectEntries(budgetRangesConfigs)
    .find(([ , budgetRangeConfig ]) => budgetRangeMatchesAmountInPennies(amountInPennies, budgetRangeConfig))

  return budgetRangeEntry === undefined
    ? undefined
    : budgetRangeEntry[0]
}

export const getBudgetRangeByMoney = (money?: Money): BudgetRange | undefined => {
  if (money == null) return money

  const amountInPennies = moneyAmountAsNumber(money)
  if (amountInPennies == null) return undefined

  return getBudgetRangeByAmountInPennies(amountInPennies)
}

export const getBudgetRangesByMoneyRange = (moneyRange?: MoneyRange): BudgetRange[] | undefined => {
  if (moneyRange == null) return moneyRange

  const moneyRangeTopPence = moneyAmountAsNumber(moneyRange.rangeTop) ?? Number.MAX_VALUE
  const moneyRangeBottomPence = moneyAmountAsNumber(moneyRange.rangeBottom) ?? 0

  return Object.values(BudgetRange)
    .filter(budgetRange => {
      const budgetRangeTop = budgetRangesConfigs[budgetRange].toExclusive
      const budgetRangeBottom = budgetRangesConfigs[budgetRange].fromInclusive

      // When the leadBudgetRange overlaps partially within a subscription budget range
      // Match if the top OR bottom of the lead is between the subscription budget range top AND bottom
      if (moneyRangeTopPence >= budgetRangeBottom && moneyRangeTopPence <= budgetRangeTop) return true
      if (moneyRangeBottomPence >= budgetRangeBottom && moneyRangeBottomPence <= budgetRangeTop) return true

      // Match if the bottom of the lead is below the bottom of the the subscription budget range AND
      // the top of the lead is also above the top of the the subscription budget range
      // This is when the subscriptions fall wholly within the leadBudgetRange
      if (moneyRangeBottomPence < budgetRangeBottom && moneyRangeTopPence > budgetRangeTop) return true

      return false
    })
}
