import React from "react"
import { IonButton, IonIcon, useIonRouter } from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"

const NavBackButton: React.FC = () => {
  const router = useIonRouter()

  return (
    <IonButton onClick={() => router.goBack()}>
      <IonIcon slot="start" icon={arrowBackOutline} />
    </IonButton>
  )
}

export default NavBackButton
