import React, { useCallback, useState } from 'react'
import { IonButton } from '@ionic/react'
import { TMyChatRoom, useMyChatRooms } from '../../../api/services/chat/useMyChatRooms'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { getRandomIconName } from '../../../common/utils/ionic'
import { TAvailableChatRoom, useAvailableChatRooms } from '../../../api/services/chat/useAvailableChatRooms'
import { useChatRoomFunctions } from '../../../api/services/chat/useChatRoomFunctions'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { useScreensWithProps } from '../../../common/hooks/useScreens'
import ChatRoomLoader from './ChatRoomLoader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import ErrorBlock from '../../../common/components/ErrorBlock'

const PACKAGE = 'TechDemoPage.ChatterPage'

enum ChatterScreen {
  ListChatRooms = "ListChatRooms",
  ShowChatRoom = "ShowChatRoom",
}

const ChatterPage: React.FC = () => {
  const { createChatRoom } = useChatRoomFunctions()
  const myTeam = useMyIndividualActiveTeam()

  const [ chatRoomId, setChatRoomId ] = useState<string | undefined>(undefined)

  type ScreenProps = {
    chatRoomId: typeof chatRoomId,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<ChatterScreen, ScreenProps>({
    init: ChatterScreen.ListChatRooms,
    screenProps: {
      chatRoomId,
    },
    screens: {
      ListChatRooms: {
        render: useCallback(({ useChangeScreen }) => {
          const goToShowChatRoomScreen = useChangeScreen(ChatterScreen.ShowChatRoom)

          return <>
            <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
              <GlobalHeader/>
            </WeaverIonHeader>
            <WeaverIonContent>
              <h1>Tech Demo: Chatter</h1>
              <div style={{ marginLeft: '1rem' }}>
                <h2>My Chat Rooms</h2>
                <MyChatRooms openChatRoom={chatRoomId => {
                  setChatRoomId(chatRoomId)
                  goToShowChatRoomScreen()
                }} />
                <h2>Available Chat Rooms</h2>
                <AvailableChatRooms />
                <h2>Chat Room Tools</h2>
                {
                  myTeam && (
                    <IonButton onClick={() => createChatRoom({
                      name: `Techdemo Chatroom ${getRandomIconName()}`,
                      icon: getRandomIconName(),
                      teamIds: [ myTeam.id ],
                    })}>New chat room</IonButton>
                  )
                }
              </div>
            </WeaverIonContent>
          </>

        }, []),
      },
      ShowChatRoom: {
        render: useCallback(({ chatRoomId }) => {
          if (!chatRoomId) {
            return (
              <WeaverIonContent>
                <ErrorBlock name='ChatterPage.ShowChatRoom' />
              </WeaverIonContent>
            )
          }

          return <ChatRoomLoader chatRoomId={chatRoomId} />
        }, []),
      },

    },
  })

  return (
    <WeaverIonPage id={`Chatter.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

type TMyChatRoomsProps = {
  openChatRoom: (chatRoomId: string) => Promise<void> | void,
}
const MyChatRooms: React.FC<TMyChatRoomsProps> = ({ openChatRoom }) => {
  const LOCAL_PACKAGE = `${PACKAGE}.MyChatRooms`
  const chatRooms = useMyChatRooms()
  console.debug(`[${PACKAGE}] Render: `, { chatRooms })

  return chatRooms === undefined
    ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useMyChatRooms`} />
    : chatRooms === null
      ? <p>There is an error loading my chatrooms!</p>
      : <>{chatRooms.map(chatRoom =>
        <MyChatRoom
          key={chatRoom.id}
          chatRoom={chatRoom}
          openChatRoom={openChatRoom}
        />)}</>
}

type TMyChatRoomProps = {
  chatRoom: TMyChatRoom,
  openChatRoom: (chatRoomId: string) => Promise<void> | void,
}
const MyChatRoom: React.FC<TMyChatRoomProps> = ({ chatRoom, openChatRoom }) => {
  type TFieldProps = {
    title: string,
    value?: string | null,
  }
  const Field: React.FC<TFieldProps> = ({ title, value }) => <div><strong>{title}: </strong><span>{value ?? <em>null / undefined</em>}</span></div>
  return <div style={{ marginBottom: '2rem' }}>
    <h3>Chat Room</h3>
    <Field title="ID" value={chatRoom.id} />
    <Field title="Name" value={chatRoom.name} />
    <Field title="Icon" value={chatRoom.icon} />
    {/* This button is broken. It will be fixed after the migration to MyWeaverThings */}
    <IonButton color='success' disabled={true} onClick={() => openChatRoom(chatRoom.id)}>Open</IonButton>
  </div>
}

const AvailableChatRooms: React.FC = () => {
  const LOCAL_PACKAGE = `${PACKAGE}.AvailableChatRooms`
  const chatRooms = useAvailableChatRooms()
  const { updateChatRoom, joinChatRoom } = useChatRoomFunctions()
  console.debug(`[${PACKAGE}] Render: `, { chatRooms })

  return chatRooms === undefined
    ? <LoadingSpinner name={`${LOCAL_PACKAGE}.useAvailableChatRooms`} />
    : chatRooms === null
      ? <p>There is an error loading the available chatrooms!</p>
      : <>{chatRooms.map(chatRoom =>
        <AvailableChatRoom
          key={chatRoom.id}
          chatRoom={chatRoom}
          updateChatRoomButtonClickFn={() => updateChatRoom({
            ...chatRoom,
            name: `Techdemo chatroom ${getRandomIconName()}`,
            icon: getRandomIconName(),
          })}
          joinChatRoomButtonClickFn={() => { joinChatRoom(chatRoom) }}
        />)}</>
}

type TAvailableChatRoomProps = {
  chatRoom: TAvailableChatRoom,
  updateChatRoomButtonClickFn: () => void,
  joinChatRoomButtonClickFn: () => void,
}
const AvailableChatRoom: React.FC<TAvailableChatRoomProps> = ({ chatRoom, updateChatRoomButtonClickFn, joinChatRoomButtonClickFn }) => {
  type TFieldProps = {
    title: string,
    value?: string | null,
  }
  const Field: React.FC<TFieldProps> = ({ title, value }) => <div><strong>{title}: </strong><span>{value ?? <em>null / undefined</em>}</span></div>
  return <div style={{ marginBottom: '2rem' }}>
    <h3>{chatRoom.name}</h3>
    <Field title="ID" value={chatRoom.id} />
    <Field title="Name" value={chatRoom.name} />
    <Field title="Icon" value={chatRoom.icon} />
    <IonButton color='warning' onClick={updateChatRoomButtonClickFn}>Update</IonButton>
    <IonButton color='success' onClick={joinChatRoomButtonClickFn}>Join</IonButton>
  </div>
}

export default ChatterPage
