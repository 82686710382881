import React from 'react'
import { useJoinWeaverTeamMutation } from '../../../../../graphql/generated'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useIsWeaverTeamMember } from '../../../../../api/providers/AuthProvider'
import { useMyIndividualActiveTeam, useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { pageConfig_Projects, useRouteTo } from '../../../../../routes'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import Styles from './OnboardingWeaver.module.scss'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import useTriggerOnCondition from '../../../../../common/hooks/useTriggerOnCondition'

const OnboardingWeaver: React.FC = () => {
  console.debug(`[OnboardingWeaver] Render: `, {})

  const isWeaverTeamMember = useIsWeaverTeamMember()

  const datasource = useGraphQLDataSource({ api: 'core' })
  const joinWeaverTeam = useJoinWeaverTeamMutation(datasource)
  const myIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()

  const myIndividualActiveTeam = useMyIndividualActiveTeam()
  const goToProjects = useRouteTo(pageConfig_Projects.path)
  const goToProjectsAfterActiveTeam = useTriggerOnCondition(
    goToProjects({}),
    !!myIndividualActiveTeam,
  )

  const onClick = async () => {
    if (!isWeaverTeamMember) {
      console.error('Cannot join the Weaver Team if you are not a Weaver Team Member')
      return
    }

    await joinWeaverTeam.mutateAsync({})
    await myIndividualInvalidateCacheFn()
    goToProjectsAfterActiveTeam()
  }

  return (
    <WeaverIonPage id='OnboardingWeaver'>
      <WeaverIonContent>
        <div className={Styles.pageContainer}>
          <h1 className={Styles.caption}>Welcome!</h1>
          <p className={Styles.notification}>
            <br/>
            We have identified you as a<br/>
            <strong>Weaver Team Member</strong>.
            <br/>
            <br/>
            To complete onboarding,<br/>
            please <strong>click the button below</strong>.
            <br/>
            <br/>
            <br/>
          </p>
          <SingleClickButton onClick={onClick} className={Styles.notification}>
            Join the Weaver team
          </SingleClickButton>
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default OnboardingWeaver
