import { IonButton, IonIcon } from '@ionic/react'
import React from 'react'

import Styles from "./UploadableFileChip.module.scss"
import { closeCircleOutline } from 'ionicons/icons'

type UploadableFileChipSucceededProps  =
{
  file: File,
  onRemoveFileClicked: (file: File) => void ,
}

const UploadableFileSucceededChip: React.FC<UploadableFileChipSucceededProps> = ({ file, onRemoveFileClicked }) => {
  return (
    <IonButton className={`${Styles.uploadBubble} ${Styles.success}`}
      fill="clear"
      size="small"
      onClick={(e) => {
        e.stopPropagation(),
        onRemoveFileClicked(file)}}>
      <span>{file.name}</span>
      <IonIcon icon={closeCircleOutline}  />
    </IonButton>
  )
}

export default UploadableFileSucceededChip
