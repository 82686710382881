import { budgetRangeLabels } from "../budgetRanges.i18n"
import { BudgetRange, ContractorProfileBudgetRangeBadge, Maybe } from "../../../../../graphql/generated"
import { alwaysArray, isSomething } from '../../../../../common/utils'
import { GetContractorProfileWorkHistory, useMyContractorProfile } from "../datasource"
import { objectEntries } from "../../../../../common/utils/objects"

export enum BadgeLevel {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
  None = "None"
}

export const badgeLevelWorkHistoryThreshold: Record<BadgeLevel, number> = {
  [BadgeLevel.None]: 0,
  [BadgeLevel.Bronze]: 1,
  [BadgeLevel.Silver]: 3,
  [BadgeLevel.Gold]: 5,
}

export type badgeInfoModalState = {
  locked: boolean,
  title: string,
  description: string | undefined,
  projectHistorySummary: string | undefined,
  badgeLevel: BadgeLevel,
  budgetRange?: BudgetRange,
}

/** Lookup the number of WorkHistory items to qualify for a given BadgeLevel */
const badgeLevelThresholds: Record<Exclude<BadgeLevel, "None">, number> = {
  Bronze: 1,
  Silver: 3,
  Gold: 5,
}

export const getBadgeInfo = (badgeLevel: BadgeLevel, budgetRange: BudgetRange, allBadgeEligibleHistoriesLength: number) => {
  if (BadgeLevel.None !== badgeLevel) {
    const badgeLevelScore  = allBadgeEligibleHistoriesLength > badgeLevelThresholds[badgeLevel] ? badgeLevelThresholds[badgeLevel] : allBadgeEligibleHistoriesLength
    return {
      badgeLevelScore: `${badgeLevelScore}/${badgeLevelThresholds[badgeLevel]}`,
      description: `Badge for submitting ${badgeLevelThresholds[badgeLevel]} past project${BadgeLevel.Bronze === badgeLevel ? '': 's'} with photos and references in the ${budgetRangeLabels[budgetRange]} budget range.`,
      projectHistorySummary:`${badgeLevelScore}/${badgeLevelThresholds[badgeLevel]} past project${BadgeLevel.Bronze === badgeLevel ? '': 's'} added`,
    }
  }
}

/**
 * Calculate by completed work histories which badge a user is
 * working towards. Displayed in the splash screens header
 * and in the work history menu while users are adding work histories to a budget range.
 *
 * If 1 or no work histories the header badge should be bronze
 *
 * If 2 or 3 show silver
 *
 * 4 or more show Gold
 */
export const getInprogressBadgeLevel = (workHistoryCount: number | undefined) => {

  if (workHistoryCount === undefined || workHistoryCount < badgeLevelWorkHistoryThreshold[BadgeLevel.Bronze] ) return BadgeLevel.Bronze

  if (workHistoryCount  <= badgeLevelWorkHistoryThreshold[BadgeLevel.Silver]) return BadgeLevel.Silver

  return BadgeLevel.Gold
}

/**
 * This function takes an input an array of unlocked badges and based on that calculates how many padlocks will be displayed on Badges Row.
 * It returns back an array of BadgeLevels.
 *
 * if the unlockBadges is an empty array then no badge has been rewarded.
 * if the unlockBadges array contains gold badge that indicates that you have been rewarded with every possible badge.
 * if the unlockBadges array contains silver badge that indicates that you have rewarded with silver and bronze.
 * if the unlockBadges array contains bronze or has at least one element badge that indicates that you have rewarded with bronze.
 *
 * @param unlockedBadges
 * @returns Array<BadgeLevel | null>>
 */

export const getLockedBadges = (unlockedBadges: Array<Maybe<BadgeLevel | undefined>>) => {
  if (!unlockedBadges.length) {
    return [ BadgeLevel.Bronze, BadgeLevel.Silver, BadgeLevel.Gold ]
  }

  if (unlockedBadges.includes(BadgeLevel.Gold)) {
    return []
  }

  if (unlockedBadges.includes(BadgeLevel.Silver)) {
    return [ BadgeLevel.Gold ]
  }

  if (unlockedBadges.includes(BadgeLevel.Bronze) || unlockedBadges.length === 1) {
    return [ BadgeLevel.Silver, BadgeLevel.Gold ]
  }
}

export const migrateBadgeLevels = (legacyAndNewBadgeLevels: (BadgeLevel | null | undefined)[]): (BadgeLevel | null | undefined)[] => {
  // If I have no badges, do nothing
  if (legacyAndNewBadgeLevels.length === 0) return legacyAndNewBadgeLevels
  // If I have new badge levels, show only those new badge levels (these supersede the old ones)
  const newBadgeLevels = legacyAndNewBadgeLevels.filter(isSomething)
  if (newBadgeLevels.length > 0) return newBadgeLevels

  // Show the legacy badge as a single bronze
  return [ BadgeLevel.Bronze ]
}

/**
 * Return all badge levels (incl. legacy) that have been awarded
 * to a user in a budget range.
 * @return BadgeLevel[]
 */
export const getAwardedBadgeLevelsInBudgetRange = (budgetRangeBadgeData: Partial<ContractorProfileBudgetRangeBadge>[] | undefined | null, budgetRange: BudgetRange) => {
  if (!budgetRangeBadgeData) return []

  const badgesInBudgetRange = budgetRangeBadgeData.filter((data) => data.budgetRange === budgetRange)
  const filteredBadgesArray = badgesInBudgetRange.map((data) => data.badgeLevel)

  return filteredBadgesArray
}

/**
 * Checks is user has an award in a given budget range.
 * It is used for legacy users before multiple work histories.
 * @param profile
 * @param budgetRange
 * @returns boolean
 */
export const hasBadgeAlready = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange): boolean =>
  alwaysArray(profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges)
    .some(badge => badge.budgetRange === budgetRange)

/**
 * Returns ALL badge levels that could be awarded depending on count of valid work histories
 */
export const getBadgeLevelsThatAreEligibleToBeAwarded = (eligibleWorkHistories: GetContractorProfileWorkHistory[]) => {
  const badgeLevelsEligibleToBeAwarded: BadgeLevel[] = []
  const countOfEligibleWorkHistories = eligibleWorkHistories.length

  objectEntries(badgeLevelWorkHistoryThreshold).forEach(([ key, value ]) => {

    // We do not want BadgeLevel.None in our array as it signifies Legacy badge and not to be awarded
    if (countOfEligibleWorkHistories >= value && key !== BadgeLevel.None) {
      badgeLevelsEligibleToBeAwarded.push(key)
    }
  })

  return badgeLevelsEligibleToBeAwarded
}
