import React, { useRef, MutableRefObject, useContext, useEffect, useLayoutEffect } from "react"
import { useDevThrowErrorTestConsoleError } from "../../hooks/useConsole"
import { getTypeNameFromChildren } from "./helpers"

export enum CheckableComponents {
  weaverIonPage = 'weaverIonPage',
  weaverIonHeader = 'weaverIonHeader',
  weaverIonContent = 'weaverIonContent',
  weaverIonFooter = 'weaverIonFooter',
  weaverIonModal = 'weaverIonModal',
}

type WeaverStructureCheckerState = undefined | (Record<CheckableComponents, MutableRefObject<number>> & {
  id: string,
  nodeName: string | undefined,
})

const getLogPrefixForState = (state: WeaverStructureCheckerState): string =>
  `WeaverStructureChecker#${state?.id}.${state?.nodeName}`

const WeaverStructureCheckerContext = React.createContext<WeaverStructureCheckerState>(undefined)

const WeaverStructureChecker: React.FC<{ id: string }> = ({ id, children }) => {
  const nodeName = getTypeNameFromChildren(children)

  const state: WeaverStructureCheckerState = {
    id,
    nodeName,
    weaverIonPage: useRef(0),
    weaverIonHeader: useRef(0),
    weaverIonContent: useRef(0),
    weaverIonFooter: useRef(0),
    weaverIonModal: useRef(0),
  }

  const logPrefix = getLogPrefixForState(state)

  // After the render, check the counts match
  const onError = useDevThrowErrorTestConsoleError()
  useEffect(() => {
    if (state.weaverIonPage.current !== 1 && state.weaverIonModal.current !== 1) onError(`[${logPrefix}] Component weaverIonPage (${state.weaverIonPage.current}) or weaverIonModal (${state.weaverIonModal.current}) must be used exactly once`)
    if (state.weaverIonPage.current == 1 && state.weaverIonModal.current == 1) onError(`[${logPrefix}] Component weaverIonPage (${state.weaverIonPage.current}) or weaverIonModal (${state.weaverIonModal.current}) cannot be used together`)
    if (state.weaverIonHeader.current > 1) onError(`[${logPrefix}] Component weaverIonHeader (${state.weaverIonHeader.current}) must be used at most once`)
    if (state.weaverIonContent.current !== 1) onError(`[${logPrefix}] Component weaverIonContent (${state.weaverIonContent.current}) must be used exactly once`)
    if (state.weaverIonFooter.current > 1) onError(`[${logPrefix}] Component weaverIonFooter (${state.weaverIonFooter.current}) must be used at most once`)
  })

  return (
    <WeaverStructureCheckerContext.Provider value={state}>
      {children}
    </WeaverStructureCheckerContext.Provider>
  )
}

export const withWeaverStructureChecker = (id: string, render: () => JSX.Element): () => JSX.Element =>
  () => (
    <WeaverStructureChecker id={id}>
      {render()}
    </WeaverStructureChecker>
  )

export const useComponentCounter = (component: CheckableComponents) => {
  const onError = useDevThrowErrorTestConsoleError()
  const state = useContext(WeaverStructureCheckerContext)

  // Check we are inside a WeaverStructureChecker, if not, we don't need to keep track of counts
  if (state === undefined) return

  // Using a LayoutEffect to make sure the counts are updated before the reconciliation in the useEffect
  useLayoutEffect(() => {
    const logPrefix = getLogPrefixForState(state)

    // Increment the counter on mount
    state[component].current = state[component].current + 1

    // Checking we're now one
    if (state[component].current !== 1) {
      onError(`[${logPrefix}] Component ${component} has been mounted more than once: ${state[component].current}`)
    }

    return () => {
      // Decrement the counter on unmount
      state[component].current = state[component].current - 1

      // Checking we're now zero
      if (state[component].current !== 0) {
        onError(`[${logPrefix}] Component ${component} has been unmounted more than once: ${state[component].current}`)
      }
    }
  }, [])
}

export const ComponentCounter: React.FC<{ component: CheckableComponents }> = ({ component }) => {
  useComponentCounter(component)
  return null
}

export default WeaverStructureChecker
