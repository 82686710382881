import React from 'react'
import { IonAvatar, IonButton, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonMenu, IonIcon } from "@ionic/react"
import { codeSlashOutline, logoCodepen } from 'ionicons/icons'
import { TAuth_LoggedIn, isAuthFunctional, useAuthContext } from '../../../api/providers/AuthProvider'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

interface UserInfoProps {
  auth: TAuth_LoggedIn,
  onSignout: () => void,
}
const UserInfo: React.FC<UserInfoProps> = ({ auth, onSignout }) => {
  return (
    <IonItem>
      <IonAvatar slot="start">
        <IonImg src={auth.userData?.picture} />
      </IonAvatar>
      <IonLabel>{auth.userData?.given_name} {auth.userData?.family_name}</IonLabel>
      {isAuthFunctional(auth) && <IonButton size='small' color='danger' fill='outline' onClick={onSignout}>Sign out</IonButton>}
    </IonItem>
  )
}

const Menu: React.FC = () => {
  const auth = useAuthContext()
  const weaverFlags = useWeaverFlags()

  return (
    <IonMenu side="start" contentId='main'>
      <IonList>
        <IonItemDivider>
          <IonLabel>
            My Profile
          </IonLabel>
        </IonItemDivider>
        <UserInfo auth={auth} onSignout={auth.logout} />
        {weaverFlags['miscellaneous-menu'].enabled && (
          <IonList lines="none">
            <IonItemDivider>
              <IonLabel>
                Miscellaneous
              </IonLabel>
            </IonItemDivider>
            <IonItem routerLink={'dev-settings'}>
              <IonIcon slot="start" icon={codeSlashOutline} />
              <IonLabel>{'Developer Settings'}</IonLabel>
            </IonItem>
            <IonItem routerLink={'tech-demo'}>
              <IonIcon slot="start" icon={logoCodepen} />
              <IonLabel>{'Tech Demo'}</IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonList>
    </IonMenu>
  )
}

export default Menu
