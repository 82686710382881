import React from 'react'
import { ScreenComponentProps } from './WeaverDatePicker'
import WeaverDatePickerHeading from './WeaverDatePickerHeading'
import GridPickYear from './GridPickYear'

import Styles from './WeaverDatePicker.module.scss'

const ScreenPickYear: React.FC<ScreenComponentProps> = props =>
  <div className={Styles.weaverDatePickerContainer}>
    <WeaverDatePickerHeading {...props} />
    <GridPickYear {...props} />
  </div>

export default ScreenPickYear
