import React from "react"
import { ScreenComponentProps } from "./TaskActionerPage"
import { pageConfig_ChatRoomOptions, useRouteTo } from "../../../routes"
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from "./commonActions"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { IonButton, IonFooter, IonText } from "@ionic/react"
import Styles from '../TaskActionerPage/TaskActionerPage.module.scss'
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"

import GlobalHeader from "../../../common/components/GlobalHeader"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'

const ScreenOpenMemberChatroom: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const routeToChatOptions = useRouteTo(pageConfig_ChatRoomOptions.path)

  if (!getActionableTaskQuery.data) {
    return <LoadingSpinnerContent name="ScreenOpenMemberChatroom" />
  }
  const task = getActionableTaskQuery.data.getTask

  const chatMemberId = task.actionablePayload
  if (!chatMemberId) throw new Error(`[ScreenOpenMemberChatroom] Task#${task.id} actionablePayload is required for tasktype openMemberChatroom.`)

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task?.actionTitle ?? task?.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h2 className={`${Styles.title} ion-margin-top`}>{task.title}</h2>
      {task.actionSummary && <IonText className={Styles.ionText} color='medium'>{task.actionSummary}</IonText>}
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border'>
      <IonButton expand="block" onClick={routeToChatOptions({ projectId: task.projectId, teamId: chatMemberId })}>Go to chat</IonButton>
      <IonButton className={Styles.ionOutlineButton} expand="block" fill="outline" onClick={useMarkTaskCompleteMutation(getActionableTaskQuery)}>Mark task as complete</IonButton>
      <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
    </IonFooter>
  </>
}

export default ScreenOpenMemberChatroom
