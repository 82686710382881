import { IonAlert, IonButton, IonIcon, IonLabel } from '@ionic/react'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import React, { useContext, useState } from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { getRootQueryKey } from '../../../common/hooks/query'
import { useGetContractorProfileQuery, useUpdateContractorProfileWorkHistoryMutation } from '../../../graphql/generated'
import { GetContractorProfileWorkHistory, getWorkHistoryTimeline } from '../ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { WorkHistoryItem } from '../../../common/components/WorkHistoryItem/WorkHistoryItem'
import { ContractorTimelineCard } from './ContractorTimelineCard'
import { MissingConstructionDatesModalContext } from './MissingConstructionDates'
import { MyContractorProfileScreenWithRenderProps, MyContractorScreenNames } from './MyContractorProfilePage'
import { useMyContractorProfileScreenState } from './MyContractorProfileScreenState'

import Styles from './WorkHistoryTimeline.module.scss'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

export const MyWorkHistoryTimeline: React.FC<MyContractorProfileScreenWithRenderProps> = (props) => {
  const {
    workHistoryItemIdState: [ , setWorkHistoryItemId ],
  } = useMyContractorProfileScreenState()

  const missingConstructionDatesModal = useContext(MissingConstructionDatesModalContext)

  const [ showArchiveWorkHistoryDialog, setShowArchiveWorkHistoryDialog ] = useState<boolean>(false)
  const [ showArchiveWorkHistoryDialogError, setShowArchiveWorkHistoryDialogError ] = useState<boolean>(false)
  const [ selectedWorkHistoryToArchive, setSelectedWorkHistoryToArchive ] = useState<undefined | GetContractorProfileWorkHistory>(undefined)

  const changeScreenToWorkHistory = props.useChangeScreen(MyContractorScreenNames.WorkHistory)
  const showContractorWorkHistoryItem = (workHistoryItemId: string) => {
    setWorkHistoryItemId(workHistoryItemId)
    changeScreenToWorkHistory()
  }

  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorProfileWorkHistory = useUpdateContractorProfileWorkHistoryMutation(gqlDataSource)
  const weaverFlags = useWeaverFlags()
  const triggerEventWorkHistoryArchived = useAnalyticsEvent("WorkHistory_Archived")
  const { sortedTimelineData, filteredWorkHistoryWithNoCompletedDate, yearsSortedByNewest } = getWorkHistoryTimeline(props.profile.workHistory)

  /**
   * Will return NaN if we do not have the date
   */
  const contractorCompanyIncorporationYear = DateTime.fromISO(props.profile.companyIncorporationDate ?? '').year

  /**
   * If no work histories are present we still want to show the company info at the top of the timeline
   */
  if (!sortedTimelineData){
    return <section>
      {contractorCompanyIncorporationYear
        ? <div className={Styles.yearContainer}>
          <h5>{Number.isNaN(contractorCompanyIncorporationYear) ? 'Company information' : contractorCompanyIncorporationYear}</h5>
        </div>
        : <></>}
      <ContractorTimelineCard {...props} />
    </section>
  }

  const handleConfirmArchiveWorkHistoryItem = async (workHistoryId: string | undefined) => {
    if (!workHistoryId) return console.debug('[MyContractorProfile-WorkHistoryTimeline]: archive work history failed. No work history ID')

    await updateContractorProfileWorkHistory.mutateAsync({ input: { id: workHistoryId, isArchived: true } }, {
      onSuccess: () => {

        if (weaverFlags["MW-2417-work-history-archived-event-should-fire-from-the-contractors-my-profile"]) {
          triggerEventWorkHistoryArchived({
            workHistoryId: workHistoryId,
          })
        }

        const allGetContractorProfileQueryKeys = getRootQueryKey(useGetContractorProfileQuery.getKey)
        queryClient.invalidateQueries(allGetContractorProfileQueryKeys)
        setShowArchiveWorkHistoryDialog(false)
      },
      onError: () => {
        console.debug(`[MyContractorProfile-WorkHistoryTimeline] Failed to archive work history item ${workHistoryId}`)
        setShowArchiveWorkHistoryDialog(false)
        setShowArchiveWorkHistoryDialogError(true)
      },
    })
  }

  const handleArchiveWorkHistoryDialogOpen = (workHistory: GetContractorProfileWorkHistory) => {
    setSelectedWorkHistoryToArchive(workHistory)
    setShowArchiveWorkHistoryDialog(true)
  }

  const openAddConstructionDatesModal = (workHistoryId: string) => {
    missingConstructionDatesModal.displayMissingConstructionDatesModal(workHistoryId)
  }

  const ARCHIVE_WORK_HISTORY_ERROR_MESSAGE = "Oops sorry there was a problem <br> Please try again"

  return (
    <>
      {/** // TODO: after one month from 16/02/23 check with product if the addConstructionDates button below and connected modal can be removed MW-2231 **/}
      <section>
        {filteredWorkHistoryWithNoCompletedDate.map((workHistoryItem) => (
          <IonButton onClick={() => openAddConstructionDatesModal(workHistoryItem.id)} expand="block" fill="outline" color='primary' className={Styles.addWorkHistoryDateButton} key={workHistoryItem.id}>
            <IonIcon slot='start' icon={allIonIcons.warningOutline} color='primary' />
            <div className={Styles.addWorkHistoryLabelContainer}>
              <IonLabel color="primary" className={Styles.addWorkHistoryLabelCopy}><h5>Add missing construction dates:</h5></IonLabel>
              <IonLabel color="primary">{workHistoryItem.projectAddress?.addressLine1}</IonLabel>
            </div>
            <IonIcon slot='end' icon={allIonIcons.chevronForward} color="primary" />
          </IonButton>
        ))}
      </section>

      {yearsSortedByNewest.map((year) => (
        <React.Fragment key={year}>
          <div className={Styles.yearContainer} >
            <h5>{year}</h5>
          </div>
          {sortedTimelineData[year].map((workHistory) => (
            <WorkHistoryItem
              key={workHistory.id}
              workHistory={workHistory}
              backgroundClassName={'profileWorkHistoryItem'}
              destructiveClickHandler={() => handleArchiveWorkHistoryDialogOpen(workHistory)}
              primaryClickHandler={() => showContractorWorkHistoryItem(workHistory.id)}
            />
          ))}
        </React.Fragment>
      ))}

      {filteredWorkHistoryWithNoCompletedDate.length > 0 &&
      <>
        <div className={Styles.yearContainer}>
          <h5>Other past projects</h5>
        </div>
        {filteredWorkHistoryWithNoCompletedDate.map((workHistory) => (
          <WorkHistoryItem
            key={workHistory.id}
            workHistory={workHistory}
            backgroundClassName={'profileWorkHistoryItem'}
            destructiveClickHandler={() => handleArchiveWorkHistoryDialogOpen(workHistory)}
            primaryClickHandler={() => showContractorWorkHistoryItem(workHistory.id)}
          />
        ))}
      </>
      }

      <section>
        {/* If date is in sortedTimelineData it must be the oldest year, if it is not found add year header */}
        {!sortedTimelineData[contractorCompanyIncorporationYear] && <div className={Styles.yearContainer}>
          <h5>{Number.isNaN(contractorCompanyIncorporationYear) ? 'Company information' : contractorCompanyIncorporationYear}</h5>
        </div>}
        <ContractorTimelineCard {...props} />
      </section>

      <IonAlert
        cssClass={Styles.archiveAlertDialogContainer}
        isOpen={showArchiveWorkHistoryDialog}
        onDidDismiss={() => setShowArchiveWorkHistoryDialog(false)}
        header="Delete work history"
        message={`Are you sure you want to delete project history "${selectedWorkHistoryToArchive?.projectAddress?.addressLine1 ?? ""}"? <br> This action cannot be undone`
        }
        buttons={[
          {
            text: 'Cancel',
            role: 'dismiss',
            handler: () => {
              setShowArchiveWorkHistoryDialog(false)
            },
          },
          {
            text: 'OK',
            role: 'destructive',
            handler: () => {
              handleConfirmArchiveWorkHistoryItem(selectedWorkHistoryToArchive?.id)
            },
          },
        ]}
      ></IonAlert>
      <IonAlert
        cssClass={Styles.archiveAlertDialogContainer}
        isOpen={showArchiveWorkHistoryDialogError}
        onDidDismiss={() => setShowArchiveWorkHistoryDialogError(false)}
        message={ARCHIVE_WORK_HISTORY_ERROR_MESSAGE}
        buttons={[
          {
            text: 'OK',
            role: 'dismiss',
            handler: () => {
              setShowArchiveWorkHistoryDialogError(false)
            },
          },
        ]}
      ></IonAlert>
    </>
  )
}
