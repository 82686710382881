import { IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import ResponsiveContentWrapper from '../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'
import { usePageTitleOnEnter } from '../../../../../common/hooks/usePageTitle'
import { BudgetRange } from '../../../../../graphql/generated'
import { budgetRangeLabels } from '../budgetRanges.i18n'

type BudgetRangeIndicatorHeaderProps = {
  budgetRange : BudgetRange,
}

export const BudgetRangeIndicatorHeader: React.FC<BudgetRangeIndicatorHeaderProps> = ({ budgetRange }) => {
  const title = `Unlock ${budgetRangeLabels[budgetRange]}`
  usePageTitleOnEnter(title)
  return (
    <ResponsiveContentWrapper>
      <IonToolbar color={'primary'}>
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </ResponsiveContentWrapper>

  )
}
