import React from "react"

import ErrorBlockContent from "../../../common/components/ErrorBlock/ErrorBlockContent"

/**
 * This screen should be unreachable, it is used to provide a default screen for tasks that can't
 * be actioned by taskActioner (e.g. Shortlisting Stage)
 *
 * For more reading, See the comment on TaskActionableType in [core.graphql](../../../../../graphql/schema/core.graphql)
*/
const ScreenUnhandled: React.FC = () => {
  return <ErrorBlockContent name='ScreenUnhandled'><p>There was an error while loading the screen!</p></ErrorBlockContent>
}

export default ScreenUnhandled
