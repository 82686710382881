import React from 'react'
import PublicProfileHeader from '../PublicProfileHeader'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { TeamPublicProfileModalComponentProps } from '../PublicProfileModal'
import PublicHomeownerProfile from './PublicHomeownerProfile'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'

const PublicHomeownerProfileModal: React.FC<TeamPublicProfileModalComponentProps> = ({ team, ...ionModalProps }) =>
  <WeaverIonModal id='PublicHomeownerProfileModal' {...ionModalProps}>
    <WeaverIonHeader>
      <PublicProfileHeader teamName={team.name} />
    </WeaverIonHeader>
    <WeaverIonContent>
      <PublicHomeownerProfile id={team.id} />
    </WeaverIonContent>
  </WeaverIonModal>

export default PublicHomeownerProfileModal
