import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ENABLE_DEVTOOLS = true

const ReactQueryProvider: React.FC = ({ children }) =>
  <QueryClientProvider client={new QueryClient()}>
    {children}
    {ENABLE_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>

export default ReactQueryProvider
