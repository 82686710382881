import { Individual } from '../../../../graphql/generated'
import { ChatContentListElement, ChatRoomMessageElement, ChatContentListElementType, NewMessagesLineElement, EmptyStateElement, ChatRoomContentMessage, ChatRoomTeam } from './ChatRoomTypes'

export const isElementEmptyState = (element: ChatContentListElement): element is EmptyStateElement => element.elementType === ChatContentListElementType.EmptyState

export const isElementChatRoomMessage = (element: ChatContentListElement): element is ChatRoomMessageElement  => element.elementType === ChatContentListElementType.ChatRoomMessage

export const isElementNewMessagesLine = (element: ChatContentListElement): element is NewMessagesLineElement  => element.elementType === ChatContentListElementType.NewMessageLine

export const getFullNameFromIndividual = (individual: Pick<Individual,  'givenName' | 'familyName'>) => `${individual.givenName} ${individual.familyName}`

const isElementAMessage = (element: ChatContentListElement): element is ChatRoomMessageElement => element.elementType === ChatContentListElementType.ChatRoomMessage

/**
 * Returns the index where the New Messages Lines element should be inserted in the specified list of Chat List elements. This element should be inserted
 * after the last unread message sent to me.
 * @param myIndividualId The individual id of the receiver.
 * @param listElements The list of Chat elements.
 * @returns the index where the New Messages Lines element should be inserted. If returns -1 if no this element shouldn't be inserted in the list of ChatContentListElement
 */
export const getIndexForNewMessageLineElement = (myIndividualId: string, listElements: ChatContentListElement[]) => {
  // Get the messages of the list of elements (elements can be anything that gets rendered as a row in the Chat page (messages and any other visual element)
  const messages = listElements.filter(isElementAMessage)

  // The list of messages sent to me are reversed in order to find the last message read (first message in the reversed list)
  const messagesSentToMeInReserveOrder = [ ... messages.filter(element => element.message.receivedByIndividual?.id === myIndividualId && element.message.sentByIndividual?.id !== myIndividualId) ].reverse()

  const lastReadMessage = messagesSentToMeInReserveOrder.find((element) => (element.message.readAt !== null))
  const lastReadMessageIndex = lastReadMessage ? messagesSentToMeInReserveOrder.indexOf(lastReadMessage): -1

  if (lastReadMessageIndex !== -1){
    // Find the next message after the last message read
    const nextMessageAfterLastReadMessage = messagesSentToMeInReserveOrder[lastReadMessageIndex - 1] // - 1, because it's reversed
    const newMessagesLinesIndex = lastReadMessage ? listElements.indexOf(nextMessageAfterLastReadMessage): -1

    return newMessagesLinesIndex
  }
  return -1
}

export const getChatEmptyStateMessage = (teams: ChatRoomTeam[]) => {
  const baseMessage =  "This is the beginning of your chat"
  if (teams.length === 0) return `${baseMessage}.`

  const teamNames = teams.map(team => team.name)
  const teamNameList = teamNames.reduce((previousValue, currentValue, currentIndex) => {
    const connector = currentIndex === teamNames.length - 1 ? " and " : ", "
    return `${previousValue}${connector}${currentValue}`
  })
  return `${baseMessage} with ${teamNameList}.`
}
