import React, { useEffect, useState } from 'react'
import markerIcon from '../../../assets/icons/marker.svg'

interface MarkerProps extends google.maps.MarkerOptions {
  coords?: {lat: string, lng: string} | undefined,
}

const Marker: React.FC<MarkerProps> = (options) => {
  const [ marker, setMarker ] = useState<google.maps.Marker>()

  useEffect(() => {
    if (!marker && window.google?.maps) {
      setMarker(new google.maps.Marker())
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [ marker, window.google?.maps ])

  useEffect(() => {
    if (marker && options.coords && window.google?.maps) {
      const { lat, lng } = options.coords
      const parsedLat = Number(lat)
      const parsedLng = Number(lng)
      const latLang: google.maps.LatLngLiteral = { lat:parsedLat, lng:parsedLng }
      marker.setOptions({ ...options, position: latLang, icon: markerIcon })
    }
  }, [ marker, options ])
  return null
}

export default Marker
