import React from 'react'
import { IonIcon, IonItem } from '@ionic/react'
import { construct, home, person } from 'ionicons/icons'
import { DateTime } from 'luxon'
import GoogleMapFromWeaverAddress from '../../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { GetContractorProfileQuery } from '../../../graphql/generated'
import { getWeaverStats } from '../ContractorLeadPreferences/ContractorBudgetRanges/datasource'

import Styles from '../MyContractorProfilePage/ContractorProfileDetailsCard.module.scss'

type PublicContractorProfileDetailsCardProps = {
  contractorProfile: NonNullable<GetContractorProfileQuery['getContractorProfile']>,
}

export const PublicContractorProfileDetailsCard:React.FC <PublicContractorProfileDetailsCardProps> = ({ contractorProfile }) => {

  const stats = getWeaverStats(contractorProfile.workHistory)

  return <section className={Styles.companyInfoContainer}>
    <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none'>
      <h3 className={Styles.companyTradingName}>{contractorProfile.companyTradingAs}</h3>
    </IonItem>

    <p>Company number: {contractorProfile.companyNumber}</p>
    {contractorProfile.companyIncorporationDate && <p>Incorporated in {DateTime.fromISO(contractorProfile.companyIncorporationDate).toFormat("MMMM yyyy")}</p>}
    <p className={Styles.contractorTeamType}>Main Contractor</p>

    <h5 className={Styles.registeredOfficeTitle}>Registered office:</h5>
    <p className={Styles.registeredOfficeTitleAddress}>{formatAddressToSingleLine(contractorProfile.companyAddress) ?? <em>No Address</em>}</p>

    <section className={Styles.mapSection}>
      {contractorProfile.companyAddress && <GoogleMapFromWeaverAddress isExact={true} address={contractorProfile.companyAddress}/>}
    </section>

    <h5>Weaver stats</h5>
    <section className={Styles.weaverStatsContainer}>
      <div className={Styles.statRowContainer}>
        <IonIcon className={Styles.statIcon} color='primary' src={construct} size="medium" />
        <p>{stats?.pastProjectsCopy}</p>
      </div>
      {stats?.recentCompletedProjectCountCopy && <div className={Styles.statRowContainer}>
        <IonIcon className={Styles.statIcon} color='primary' src={home} />
        <p>{stats?.recentCompletedProjectCountCopy}</p>
      </div>}
      {stats?.referenceCountCopy && <div className={Styles.statRowContainer}>
        <IonIcon className={Styles.statIcon} color='primary' src={person} />
        <p>{stats?.referenceCountCopy}</p>
      </div>}
    </section>

  </section>
}
