import React from 'react'
import PublicProfileHeader from '../PublicProfileHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import PublicHomeownerProfile from './PublicHomeownerProfile'
import { TeamPublicProfilePageComponentProps } from '../DisplayPublicProfile'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { IonBackButton } from '@ionic/react'
import Styles from "../../../common/components/GlobalHeader/GlobalHeader.module.scss"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

const PublicHomeownerProfilePage: React.FC<TeamPublicProfilePageComponentProps> = ({ teamId, ...ionPageProps }) => {

  const weaverFlags = useWeaverFlags()

  return (<WeaverIonPage id='PublicHomeownerProfilePage' {...ionPageProps}>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      { weaverFlags['tab-nav-bar'].enabled
        ? <GlobalHeader
          navElement={<IonBackButton className={Styles.backButton} />}
          pageTitle='Public Profile'
        />
        : <PublicProfileHeader />}
    </WeaverIonHeader>
    <WeaverIonContent>
      <PublicHomeownerProfile id={teamId} />
    </WeaverIonContent>
  </WeaverIonPage>)
}

export default PublicHomeownerProfilePage
