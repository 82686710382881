import React, { useCallback, useState } from 'react'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications'
import { LocalNotifications } from '@capacitor/local-notifications'
import { isAndroid } from '../../../common/utils/ionic'
import { usePushNotifications } from '../../services/pushNotifications/usePushNotifications'
import { useObtainNotificationPermissions } from './useObtainNotificationPermissions'
import { AppLauncher } from '@capacitor/app-launcher'
import { App } from '@capacitor/app'
import { z } from 'zod'

type MobilePushNotificationsInfo = {
  deviceToken?: string,
}

const zNewMessagePushNotification = z.object({
  title: z.string(),
  body: z.string(),
  data : z.object({
    chatRoomId: z.string(),
  }),
})

type NotificationEventHandler = {
  // Called when registration was successful
  onRegistrationSuccess: (deviceToken: Token) => void,
  // Called when there was an error during registration
  onRegistrationError: (error: unknown) => void,
  // Called when a notification - either data or pushed - is received. Push notificatons are distinguished by having a title or a body
  onNotificationReceived: (notification: PushNotificationSchema) => void,
  // Called when the notification is tapped.
  onNotificationTapped: (action: ActionPerformed) => void,
}

const PACKAGE = 'MobilePushNotificationsProvider'

export const MobilePushNotificationsContext = React.createContext<MobilePushNotificationsInfo | undefined>(undefined)

const MobilePushNotificationsProvider: React.FC = ({ children }) => {
  const [ state, setState ] = useState<MobilePushNotificationsInfo>()
  const [ wasRegistrationSuccessful, setWasRegistrationSuccessful ] = useState(false)
  const { registerDevice } = usePushNotifications()

  const onPermissionsObtained = useCallback(() => {
    setupPushNotifications()
  }, [])
  useObtainNotificationPermissions(onPermissionsObtained)

  const notificationEventsHandler: NotificationEventHandler = {
    onRegistrationSuccess: (deviceToken: Token) => {
      console.log(`[${PACKAGE}] Push Notification registration success`, deviceToken.value)
      registerDevice(deviceToken.value)
      setWasRegistrationSuccessful(true)
      setState({ deviceToken: deviceToken.value })
    },
    onRegistrationError: (error: unknown) => {
      console.log(`[${PACKAGE}] Error during registration`, { error })
      setWasRegistrationSuccessful(false)
      setState({ deviceToken: undefined })
    },
    onNotificationReceived: (notification: PushNotificationSchema) => {
      const isPushNotification = !!notification.title || !!notification.body
      console.log(`[${PACKAGE}] Push Notification received`, notification)

      // if this is a push notification received when the app is in the foreground on Android
      if (isAndroid && isPushNotification) {
        sendLocalNotification(notification)
      }
    },    
    onNotificationTapped: async (action: ActionPerformed) => {
      if (action.actionId === 'tap'){
        try {
          const notificationData = zNewMessagePushNotification.parse(action.notification)
          const { data } =  notificationData
          const info = await  App.getInfo()
          AppLauncher.openUrl({ url: `${info.id}://chats/${data.chatRoomId}` })
        } catch (error) {
          console.error(`[${PACKAGE}] Unable to decode push notification from Firebase.`)
          throw error
        }
      }
    },
  }

  const setupPushNotifications = () => {
    // Register with Apple / Google to receive push via FCM
    PushNotifications.register()

    // Add event listeners
    PushNotifications.addListener('registration', notificationEventsHandler.onRegistrationSuccess)
    PushNotifications.addListener('registrationError', notificationEventsHandler.onRegistrationError)
    PushNotifications.addListener('pushNotificationReceived', notificationEventsHandler.onNotificationReceived)
    PushNotifications.addListener('pushNotificationActionPerformed', notificationEventsHandler.onNotificationTapped)
  }

  const sendLocalNotification = (notification: PushNotificationSchema) => {
    LocalNotifications.schedule({
      notifications: [ {
        title: notification.title || "",
        body: notification.body || "",
        id: new Date().getUTCMilliseconds(),
        schedule: {
          at: new Date(Date.now() + 1000),
        },
      } ],
    })
  }

  return state === undefined
    ? <LoadingSpinnerPage name='mobilePushNotificationsSetup' />
    : wasRegistrationSuccessful
      ? <MobilePushNotificationsContext.Provider value={state}>
        {children}
      </MobilePushNotificationsContext.Provider>
      : <>{children}</>
}

export default MobilePushNotificationsProvider
