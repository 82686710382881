
import React from 'react'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import WeaverUnverifiedBadge from '../../../common/components/WeaverUnverifiedBadge/WeaverUnverifiedBadge'
import WeaverVerifiedBadge from '../../../common/components/WeaverVerifiedBadge/WeaverVerifiedBadge'
import { ContractorProfileWorkHistoryReference, ProgressionStatus } from '../../../graphql/generated'
import { selectableTeamTypeLabels } from '../../onboarding/team/onboarding.i18n'
import Styles from './ContractorWorkHistoryReference.module.scss'

export const ContractorWorkHistoryReference: React.FC<{ reference: ContractorProfileWorkHistoryReference }> = ({ reference }) => {
  const referenceVerified = reference.status === ProgressionStatus.Completed
  const weaverFlags = useWeaverFlags()

  return weaverFlags['MW-2419-tag-references-as-verified']
    ? (
      <div className={Styles.referenceContainer}>
        <span className={Styles.referenceText}>{selectableTeamTypeLabels[reference.teamType]}</span>
        {referenceVerified ? <WeaverVerifiedBadge /> : <WeaverUnverifiedBadge />}
      </div>
    )
    : (
      <p>
        <span>{reference.teamType}</span>
      </p>
    )
}
