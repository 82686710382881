import React from 'react'
import { DeviceStorageFileRow } from './DeviceStorageFileRow'
import { FilesToUpload } from './FilePicker'
import { StoredFile } from '../../../../common/hooks/useDeviceStorageFiles'
import { Virtuoso } from "react-virtuoso"
import Styles from "./FilePicker.module.scss"

type DeviceStorageFileListProps = {
  files: StoredFile[],
  multiAddState: boolean,
  selectFile: (storedFile: StoredFile) => void,
  filesToUpload: FilesToUpload,
  setSelected: (fileName: string, isChecked: boolean) => void,
}

export const DeviceStorageFileList: React.FC<DeviceStorageFileListProps> = ({ files, multiAddState, selectFile, filesToUpload, setSelected }) =>
  <Virtuoso
    className={Styles.deviceStorageFileListContainer}
    data={files}
    style={{ height: "100%" }}
    itemContent={index => {
      const fileName = files[index].name
      const selected = filesToUpload[fileName]

      return <DeviceStorageFileRow
        key={index}
        storedFile={files[index]}
        multiAddState={multiAddState}
        selectFile={selectFile}
        selected={selected}
        setSelected={isChecked => setSelected(fileName, isChecked)}
      />
    }}
  />
