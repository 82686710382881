import React, { ComponentProps } from "react"
import { IonModal } from "@ionic/react"
import WeaverStructureChecker, { CheckableComponents, ComponentCounter } from "./WeaverStructureChecker"
import { useWeaverIonThingsCorrectly } from "./hooks"

type WeaverIonModalProps = {
  /**
   * WeaverIonModal validates the structure of the children it's passed prior to rendering.
   * This should be used within a WeaverIonContent, and this resets the counts for this part of the branch.
   *
   * ```tsx
   * const Test: React.FC = () => {
   *   const [ isOpen, setIsOpen ] = useState(false)
   *   return (
   *     <WeaverIonPage id='Test'>
   *       <WeaverIonContent>
   *         <IonButton onClick={() => { setIsOpen(true) }}>Show Modal</IonButton>
   *         <WeaverIonModal id='demo' isOpen={isOpen} onDidDismiss={async () => { setIsOpen(false) }}>
   *           <WeaverIonContent>
   *             A simple demonstration.
   *           </WeaverIonContent>
   *         </WeaverIonModal>
   *       </WeaverIonContent>
   *     </WeaverIonPage>
   *   )
   * }
   * ```
   *
   * Sometimes however, the child structure checks here are not appropriate,
   * like when there are intermediary components or logic, such as `useScreens` or a `React.Context.Provider` in the way.
   * When this happens, you will need to disable the checks and validate another way.
   *
   * ```tsx
   * const MustDisableChecksPage: React.FC = () => {
   *   enum Pages { PageA = "PageA" }
   *
   *   const [ Screen, activeScreen ] = useScreens<Pages>({
   *     init: Pages.PageA,
   *     screens: {
   *       PageA: {
   *         render: () => <WeaverIonContent>Something.</WeaverIonContent>
   *       },
   *     },
   *   })
   *
   *   return (
   *     <WeaverIonModal key={`exampleScreens:${activeScreen}`} disableDirectChildStructureChecks={true}>
   *       {Screen}
   *     </WeaverIonModal>
   *   )
   * }
   * ```
   *
   *  _See Also: `WeaverIonPage.test.tsx`_
   *
   * **NOTE:** This does not disable the checks done by `WeaverStructureChecker` which are designed to always hold true.
   */
  disableDirectChildStructureChecks?: boolean,
  /** This key is passed to the WeaverStructureChecker so it's picked up by the logging */
  id: string,
} & ComponentProps<typeof IonModal>

const WeaverIonModal: React.FC<WeaverIonModalProps> = ({ disableDirectChildStructureChecks = false, id, ...ionModalProps }) => {
  useWeaverIonThingsCorrectly('WeaverIonModal', disableDirectChildStructureChecks, ionModalProps.children)

  const { children: ionModalPropsChildren, ...ionModalPropsWithoutChildren } = ionModalProps

  return (
    // This resets the WeaverStructureChecker counters for this sub-tree
    <IonModal {...ionModalPropsWithoutChildren}>
      <WeaverStructureChecker id={id}>
        <ComponentCounter component={CheckableComponents.weaverIonModal} />
        {ionModalPropsChildren}
      </WeaverStructureChecker>
    </IonModal>
  )
}

export default WeaverIonModal
