import { useGraphQLDataSource } from '../../graphql'
import { GetStripeProductConfigsQuery, useCreateStripeSessionForLeadPaymentMutation } from '../../../graphql/generated'
import { useIonRouter } from '@ionic/react'
import { useRedirectBaseURL } from '../../../common/hooks/useRedirectBaseURL'
import { useMyIndividualActiveTeam } from '../../providers/MyIndividualProvider/MyIndividualProvider'
import { useAnalyticsEvent } from '../../providers/SegmentProvider/hooks'
import { isSubscriptionStripeProduct } from '../../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { EventProperties } from '../../providers/SegmentProvider/events'

type UseRedirectToStripePaymentForLeadPaymentHandlerProps = {
  leadId: string,
  source?: EventProperties['Lead_Payment_CheckoutSession_Launched']['source'],
}

type ProductConfig = Pick<GetStripeProductConfigsQuery['getStripeProductConfigs'][number], 'product' | 'priceId'>

/** A function which, when called, creates a stripe session for the specified product and redirects the browser to that URL */
type UseRedirectToStripePaymentForLeadPaymentHandlerReturns = (productConfig: ProductConfig) => Promise<void>

/**
 * Creates a stripe session and returns a function to open the stripe window.
 *
 * The returned function is not ready immediately. Use the isReady property to determine the status of the session.
 *
 * Mounting this hook will cause a stripe session to be created. The stripe session can't be made in response to the click because otherwise, the stripe popup might be blocked by popup blockers
 * First found in safari, see https://weaver.atlassian.net/browse/MW-1884
 */
const useRedirectToStripePaymentForLeadPaymentHandler = ({
  leadId,
  source,
}: UseRedirectToStripePaymentForLeadPaymentHandlerProps): UseRedirectToStripePaymentForLeadPaymentHandlerReturns => {
  const router = useIonRouter()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const createStripeSessionForLeadPayment = useCreateStripeSessionForLeadPaymentMutation(gqlDataSource)

  const triggerLeadPaymentLoadingEvent = useAnalyticsEvent("Lead_Payment_CheckoutSession_Loading")
  const triggerLeadPaymentReadyEvent = useAnalyticsEvent("Lead_Payment_CheckoutSession_Ready")
  const triggerLeadPaymentLaunchedEvent = useAnalyticsEvent("Lead_Payment_CheckoutSession_Launched")

  const activeTeam = useMyIndividualActiveTeam()
  const redirectBaseURL = useRedirectBaseURL('/stripe/')

  return async productConfig => {
    const teamId = activeTeam?.id
    if (teamId === undefined) {
      console.debug(`[useRedirectToStripePaymentForLeadPaymentHandler.callback] No active team!`, activeTeam)
      throw new Error('[useRedirectToStripePaymentForLeadPaymentHandler] Need to be a member of a team to make payments!')
    }

    if (!redirectBaseURL) throw new Error('Cannot create Stripe session without a redirectBaseURL')

    const isSubscription = isSubscriptionStripeProduct(productConfig.product)

    await triggerLeadPaymentLoadingEvent({
      isSubscription,
      projectId: leadId,
    })

    const stripeSession = await createStripeSessionForLeadPayment.mutateAsync({ product: productConfig.product, leadId, redirectBaseURL })
    const data = stripeSession.createStripeSessionForLeadPayment
    const checkoutSessionUrl = data?.url
    const checkoutSessionId = data?.id

    if (checkoutSessionUrl == null) {
      console.debug(`[useRedirectToStripePaymentForLeadPaymentHandler.makeStripePayment] createStripeSession failed to return url`, data)
      throw new Error('[useRedirectToStripePaymentForLeadPaymentHandler] Unable to create stripe session!')
    }

    await triggerLeadPaymentReadyEvent({
      isSubscription,
      projectId: leadId,
      checkoutSessionId,
    })

    await triggerLeadPaymentLaunchedEvent({
      isSubscription,
      projectId: leadId,
      checkoutSessionId,
      stripePriceId: productConfig.priceId,
      source,
    })

    router.push(checkoutSessionUrl)
  }
}

export default useRedirectToStripePaymentForLeadPaymentHandler
