import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import { IonButton, IonFooter, IonText } from "@ionic/react"
import GlobalHeader from "../../../common/components/GlobalHeader"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import Styles from '../TaskActionerPage/TaskActionerPage.module.scss'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from "./commonActions"

const ScreenGenericManual: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ScreenGenericManual" />
  const task = getActionableTaskQuery.data.getTask

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h2 className={Styles.title}>{task.title}</h2>
      {task.actionSummary && <IonText className={Styles.ionText} color='medium'>{task.actionSummary}</IonText>}
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border'>
      <IonButton expand="block" fill="solid" onClick={useMarkTaskCompleteMutation(getActionableTaskQuery)}>Mark task as complete</IonButton>
      <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
    </IonFooter>
  </>
}

export default ScreenGenericManual
