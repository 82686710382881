import React from 'react'
import CachedJwtProvider from './CachedJwtProvider'
import Auth0Provider from './Auth0Provider'
import { isMW135WorkAroundNeeded } from '../../../common/utils/workarounds'

export const AuthProvider: React.FC = ({ children }) => {
  console.debug(`[AuthProvider] rendering: `, { isMW135WorkAroundNeeded })

  return isMW135WorkAroundNeeded
    ? <CachedJwtProvider>
      {children}
    </CachedJwtProvider>
    : <Auth0Provider>
      {children}
    </Auth0Provider>
}

export default AuthProvider
