import { IonButton, IonModal, IonText } from '@ionic/react'
import React, { ComponentProps, Dispatch, SetStateAction } from 'react'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonModal from '../../common/components/WeaverIonWrappers/WeaverIonModal'
import { pageConfig_Projects, useRouteTo } from '../../routes'
import Styles from "./ShowLeadPage.module.scss"

type LeadErrorModalProps = {
  projectId: string,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
} & ComponentProps<typeof IonModal>

export const LeadErrorModal: React.FC<LeadErrorModalProps> = ({ projectId, setIsOpen, ...ionModalProps }) => {
  const goToProjects = useRouteTo(pageConfig_Projects.path)

  const triggerLeadPlacesFullContinue = useAnalyticsEvent("Lead_Places_Full_Continue")
  const triggerLeadPlacesFullBackup = useAnalyticsEvent("Lead_Places_Full_Backup")

  const onDismiss = async () => {
    return goToProjects({})()
  }

  const onContinue = async () => {
    await triggerLeadPlacesFullContinue({ projectId })
    setIsOpen(false)
  }

  const onNotifyMe = async () => {
    await triggerLeadPlacesFullBackup({ projectId })
    setIsOpen(false)
  }

  return (
    <WeaverIonModal id='LeadErrorModal'
      initialBreakpoint={0.5}
      breakpoints={[ 0, 0.5 ]}
      canDismiss={true}
      onWillDismiss={onDismiss}
      {...ionModalProps}
    >
      <WeaverIonContent className='ion-padding'>
        <h4>Oh no, this project is full</h4>
        <IonText>
          <p>
            To promote a fairer way of tendering, we limit the number of available places per project.
            Due to high demand, the available places on this project have now been taken by other contractors.
          </p>
        </IonText>
        <IonText>
          <p>
            Remember it&apos;s first come, first served!
          </p>
        </IonText>
        <IonButton className='ion-margin-top' expand='block' color="primary" onClick={onContinue}>
          Continue
        </IonButton>
        <IonButton className={Styles.outlineButton} expand='block' fill='outline' onClick={onNotifyMe}>
          Notify me if a place becomes free
        </IonButton>
      </WeaverIonContent>
    </WeaverIonModal>
  )
}
