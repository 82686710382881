import { alwaysArray, uniqueFilter } from "../../../common/utils"
import { ProjectMember, ProjectMemberRole, Team, TeamType } from "../../../graphql/generated"

type LocalProjectMember = Pick<ProjectMember, 'projectRole'> & {
  team: Pick<Team, 'type'>,
}

export const getProjectMemberOwnerTeamTypes = (projectMembers: LocalProjectMember[] | null | undefined): TeamType[] =>
  alwaysArray(projectMembers)
    .filter(member => member.projectRole === ProjectMemberRole.Owner)
    .map(member => member.team.type)
    .filter(uniqueFilter)

