import { DateTime, DayNumbers, MonthNumbers } from 'luxon'
import { DateResolution } from '../components/WeaverDatePicker/types'

/**
 * Returns a string representation of the DateTime passed as parameter according to the format 'h:mm a'
 *  i.e.: to a string including hours, minutes and the meridiem (am or pm). The meridiem is returned in lowercase.
 * For example: with an input of 2022-03-11T08:55:20+00:00 it should return 08:55 am
 * @param date The input date in ISO 8601 format.
 * @returns A string including hours, minutes and the meridiem in lowercase (am, pm).
 */
export const formatDateToHoursAndMinutesWithMeridiem = (date: string): string => {
  const sentDate = DateTime.fromISO(date)
  const formattedDate = `${sentDate.toFormat('h:mm a').toLowerCase()}`
  return formattedDate
}

/**
 * Returns a string representation of a date into a relative form ('yesterday', '2 days ago', etc.). When the date represents a moment
 * within today it returns a string in the format 'h:mm a'.
 * @param date The input date in ISO 8601 format.
 * @returns A string including hours, minutes and the meridiem in lowercase (am, pm) if the date represents a moment within day. Otherwise returns
 * its relative form.
 */
export const formatDateAsDuration = (date: string): string => {
  const sentDate = DateTime.fromISO(date)
  const relativeDate = sentDate.toRelativeCalendar() || ''
  return relativeDate === 'today' ? formatDateToHoursAndMinutesWithMeridiem(date) : relativeDate
}

/**
 * Converts an ISO 8601 formatted date into a UNIX Epoch number.
 * Where the string passed is not a valid date, it returns undefined (quietly swallowing any errors).
 * This behaviour is to support customer.io unix epoch formats, such as in MW-1063.
 * @param iso ISO 8601 formatted date
 * @returns UNIX Epoch number if valid or undefined in all other cases
 */
export const convertIsoDateToEpochQuietly = (iso: string): number | undefined => {
  const date = DateTime.fromISO(iso)
  const epoch = date.toMillis()
  return isNaN(epoch) ? undefined : epoch
}

/**
 * Convert the date components into an ISO datetime
 * @param resolution Resolution of the resulting ISO DateTime
 * @param year Year
 * @param month Month (1-12)
 * @param day Day of Month
 * @returns Full ISO-8601 datetime
 */
export const getDateTimeToResolution = (resolution: DateResolution, year: number, month?: MonthNumbers, day?: DayNumbers): DateTime => {
  // https://moment.github.io/luxon/#/parsing?id=iso-8601
  switch (resolution) {
    case DateResolution.Year: {
      const yearAsString = String(year)
      return DateTime.fromISO(yearAsString)
    }
    case DateResolution.Month: {
      if (month === undefined) throw new Error('[getDateTimeToResolution] Month is required for DateResolution.Month')
      const yearAsString = String(year)
      const monthAsPaddedString = String(month).padStart(2, '0')
      return DateTime.fromISO(`${yearAsString}-${monthAsPaddedString}`)
    }
    case DateResolution.Day: {
      if (month === undefined) throw new Error('[getDateTimeToResolution] Month is required for DateResolution.Day')
      if (day === undefined) throw new Error('[getDateTimeToResolution] Day is required for DateResolution.Day')
      const yearAsString = String(year)
      const monthAsPaddedString = String(month).padStart(2, '0')
      const dayAsPaddedString = String(day).padStart(2, '0')
      return DateTime.fromISO(`${yearAsString}-${monthAsPaddedString}-${dayAsPaddedString}`)
    }
  }
}

/**
 * Find the number of days in a given month + year (to account for leap years)
 * @param year target year
 * @param month target month as 1-12
 * @returns 28-31
 */
export const getDaysInMonth = (year: number, month: MonthNumbers) => new Date(year, month, 0).getDate()

/**
 * Enures that the number passed is a valid type of MonthNumbers
 * @param month 1 - 12
 * @returns 1 - 12 otherwise throws an error
 */
export const asMonthNumbers = (month: number): MonthNumbers => {
  switch (month) {
    case 1: return 1
    case 2: return 2
    case 3: return 3
    case 4: return 4
    case 5: return 5
    case 6: return 6
    case 7: return 7
    case 8: return 8
    case 9: return 9
    case 10: return 10
    case 11: return 11
    case 12: return 12
    default: throw new Error(`[asMonthNumbers] ${month} is not between 1 and 12`)
  }
}

/**
 * Wraps `asMonthNumbers` to support the passthrough of undefined values
 * @param month undefined | 1 - 12
 * @returns undefined | `asMonthNumbers(month)`
 */
export const asOptionalMonthNumbers = (month?: number): MonthNumbers | undefined =>
  month === undefined
    ? undefined
    : asMonthNumbers(month)

/**
 * Enures that the number passed is a valid type of DayNumbers
 * @param day A number between 1 - 31
 * @returns 1 - 31 otherwise throws an error
 */
export const asDayNumbers = (day: number): DayNumbers => {
  switch (day) {
    case 1: return 1
    case 2: return 2
    case 3: return 3
    case 4: return 4
    case 5: return 5
    case 6: return 6
    case 7: return 7
    case 8: return 8
    case 9: return 9
    case 10: return 10
    case 11: return 11
    case 12: return 12
    case 13: return 13
    case 14: return 14
    case 15: return 15
    case 16: return 16
    case 17: return 17
    case 18: return 18
    case 19: return 19
    case 20: return 20
    case 21: return 21
    case 22: return 22
    case 23: return 23
    case 24: return 24
    case 25: return 25
    case 26: return 26
    case 27: return 27
    case 28: return 28
    case 29: return 29
    case 30: return 30
    case 31: return 31
    default: throw new Error(`[asDayNumbers] ${day} is not between 1 and 31`)
  }
}

/**
 * Wraps `asMonthNumbers` to support the passthrough of undefined values
 * @param month undefined | 1 - 31
 * @returns undefined | `asDayNumbers(month)`
 */
export const asOptionalDayNumbers = (day?: number): DayNumbers | undefined =>
  day === undefined
    ? undefined
    : asDayNumbers(day)
