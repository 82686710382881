import React, { useState } from "react"
import { IonAlert, IonButton, IonIcon, IonText, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory, useRouteTo } from "../../../../../routes"
import ThisBudgetRangeIsLockedScreen from "./ScreenThisBudgetRangeIsLocked"
import { ScreenRenderProps, useScreensWithProps } from "../../../../../common/hooks/useScreens"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import { budgetRangeLabels } from "../budgetRanges.i18n"
import { getAllNonArchivedWorkHistoriesByBudgetRange, GetContractorProfileWorkHistory, getFirstWorkHistoryByBudgetRange, useMyContractorProfile } from "../datasource"
import { BudgetRange, useGetContractorProfileQuery, useUpdateContractorProfileWorkHistoryMutation } from "../../../../../graphql/generated"
import { useAnalyticsEvent } from "../../../../../api/providers/SegmentProvider/hooks"
import { useParamsFromPageConfig } from "../../../../../routesProvider"
import WeaverIonContent from "../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonPage from "../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import { useWeaverFlags } from "../../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { PadLock } from "../../ContractorBudgetReferences/PadLock"
import { getAllBadgeEligibleHistories } from "./ContractorProfileBudgetRangeBadge/hooks"
import { BadgeLevel, getAwardedBadgeLevelsInBudgetRange, getBadgeInfo, getLockedBadges, migrateBadgeLevels } from "./BadgeUtils"
import { SkeletonWorkHistoryItem, WorkHistoryItem } from "../../../../../common/components/WorkHistoryItem/WorkHistoryItem"
import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../../../../api/graphql"
import { getRootQueryKey } from "../../../../../common/hooks/query"
import RangeBadgeLevel from "../../ContractorBudgetReferences/RangeBadgeLevel"
import { useOpenBadgeInfoModal } from "../../ContractorBudgetReferences/useOpenBadgeInfoModal"

import Styles from "./UnlockContractorBudgetRange.module.scss"

enum UnlockScreens {
  ThisBudgetRangeIsLocked = "ThisBudgetRangeIsLocked",
  WorkHistoryList = "WorkHistoryList"
}

type ScreenProps = {
  budgetRange: BudgetRange,
  myContractorProfile: ReturnType<typeof useMyContractorProfile>,
}

type ScreenComponentProps = ScreenRenderProps<UnlockScreens> & ScreenProps

const _ThisBudgetRangeIsLocked: React.FC<ScreenComponentProps> = ({ useChangeScreen, budgetRange, myContractorProfile }) => {
  const allNonArchivedWorkHistories = getAllNonArchivedWorkHistoriesByBudgetRange(myContractorProfile, budgetRange)
  const hasWorkHistories = allNonArchivedWorkHistories?.length ?? 0

  const triggerEventWorkHistoryReferencesAddedBudgetPreferences = useAnalyticsEvent('WorkHistory_References_Added_Budget_Preferences')
  const changeScreenToWorkHistoryList = useChangeScreen(UnlockScreens.WorkHistoryList)
  const goToContractorBudgetRanges = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path)
  const goToNewWorkHistory = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory.path)

  const triggerEventThenChangeScreen = async () => {
    triggerEventWorkHistoryReferencesAddedBudgetPreferences({ budgetRange })
    hasWorkHistories ? changeScreenToWorkHistoryList() : goToNewWorkHistory({ budgetRange })()
  }

  return <>
    <WeaverIonContent className="ion-padding">
      <ThisBudgetRangeIsLockedScreen budgetRange={budgetRange} />
      <br />
      <br />
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={goToContractorBudgetRanges({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        <IonButton slot="end" onClick={triggerEventThenChangeScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _WorkHistoryList: React.FC<ScreenComponentProps> = ({ budgetRange, myContractorProfile }) => {
  const allNonArchivedWorkHistories = getAllNonArchivedWorkHistoriesByBudgetRange(myContractorProfile, budgetRange)
  const goToNewWorkHistory = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory.path)
  const goToContractorBudgetRanges = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path)
  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)
  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorProfileWorkHistory = useUpdateContractorProfileWorkHistoryMutation(gqlDataSource)

  const [ showArchiveWorkHistoryDialog, setShowArchiveWorkHistoryDialog ] = useState<boolean>(false)
  const [ showArchiveWorkHistoryDialogError, setShowArchiveWorkHistoryDialogError ] = useState<boolean>(false)
  const [ selectedWorkHistoryToArchive, setSelectedWorkHistoryToArchive ] = useState<undefined | GetContractorProfileWorkHistory>(undefined)
  const { openBadgeInfoModal, displayBadgeInfoModal } = useOpenBadgeInfoModal(false)

  const isLoading = myContractorProfile.getContractorProfileQuery.isLoading
  const isFetching = myContractorProfile.getContractorProfileQuery.isFetching

  const triggerEventWorkHistoryArchived = useAnalyticsEvent("WorkHistory_Archived")
  const allBadgeEligibleHistories = getAllBadgeEligibleHistories(myContractorProfile, budgetRange)

  const unlockedBadges = getAwardedBadgeLevelsInBudgetRange(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges, budgetRange)

  const unlockedBadgesAfterMigration = migrateBadgeLevels(unlockedBadges)
  const lockedBadges = getLockedBadges(unlockedBadgesAfterMigration)

  const handleWorkHistoryClick = (workHistoryId:string) => {
    goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId } )()
  }

  const handleBackClick = () => {
    goToContractorBudgetRanges({}, undefined, 'back')()
  }

  const handleCreateNewWorkHistoryClick = () => {
    goToNewWorkHistory({ budgetRange })()
  }

  const handleArchiveWorkHistoryDialogOpen = (workHistory: GetContractorProfileWorkHistory) => {
    setSelectedWorkHistoryToArchive(workHistory)
    setShowArchiveWorkHistoryDialog(true)
  }

  const handleConfirmArchiveWorkHistoryItem = async (workHistoryId: string | undefined) => {
    if (!workHistoryId) return console.debug('[UnlockContractorBudgetRange archive work history failed]: no work history ID')

    await updateContractorProfileWorkHistory.mutateAsync({ input: { id: workHistoryId, isArchived: true } }, {
      onSuccess:  () => {
        triggerEventWorkHistoryArchived({
          workHistoryId: workHistoryId,
        })

        const allGetContractorProfileQueryKeys = getRootQueryKey(useGetContractorProfileQuery.getKey)
        queryClient.invalidateQueries(allGetContractorProfileQueryKeys)
        setShowArchiveWorkHistoryDialog(false)
      },
      onError: () => {
        console.debug(`[ListWorkHistoryItems] Failed to archive work history item ${workHistoryId}`)
        setShowArchiveWorkHistoryDialog(false)
        setShowArchiveWorkHistoryDialogError(true)
      },
    })

  }

  const ARCHIVE_WORK_HISTORY_ERROR_MESSAGE = "Oops sorry there was a problem <br> Please try again"

  return <>
    <WeaverIonContent>
      <div className={`${Styles.pastProjectHeaderContainer} ion-text-center}`}>
        <div className={Styles.badgeContainer}>
          {unlockedBadgesAfterMigration.map((badge) => (
            <div key={badge}>
              <div className={Styles.badge}>
                <RangeBadgeLevel
                  onClick={() => openBadgeInfoModal(badge ?? BadgeLevel.Bronze,budgetRange,false)}
                  {...{ budgetRange, width: '100', height: '100' }}
                  badgeLevel={badge ?? BadgeLevel.Bronze}/>
              </div>
              <IonText className="ion-text-center" color="secondary">
                <p>{getBadgeInfo(badge ?? BadgeLevel.Bronze, budgetRange, allBadgeEligibleHistories.length)?.badgeLevelScore}</p>
              </IonText>
            </div>
          ))}

          {lockedBadges?.map((badge: BadgeLevel) => (
            <div key={badge}>
              <div className={Styles.badge}>
                <PadLock {...{ width: '100', height: '100' }} onClick={() => openBadgeInfoModal(badge ?? BadgeLevel.Bronze, budgetRange, true)} />
              </div>
              <IonText className="ion-text-center" color="secondary">
                <p>{getBadgeInfo(badge ?? BadgeLevel.Bronze, budgetRange, allBadgeEligibleHistories.length)?.badgeLevelScore}</p>
              </IonText>
            </div>
          ))}
        </div>
        <h5 className="ion-no-margin ion-margin-bottom">Unlock {budgetRangeLabels[budgetRange]} leads</h5>
      </div>

      <h1>Past Projects</h1>
      <IonButton className={Styles.addPastProjectButton} expand="block" onClick={handleCreateNewWorkHistoryClick}>Add past project</IonButton>

      {isFetching || isLoading
        ? [ ...Array(3) ].map((_, i) =>  <SkeletonWorkHistoryItem key={i} />)
        : <>
          {allNonArchivedWorkHistories?.map((workHistory) => (
            <WorkHistoryItem
              key={workHistory.id}
              workHistory={workHistory}
              primaryClickHandler={() => handleWorkHistoryClick(workHistory.id)}
              destructiveClickHandler={() => handleArchiveWorkHistoryDialogOpen(workHistory)}
            />
          ))}
        </>}

      <IonAlert
        cssClass={Styles.archiveAlertDialogContainer}
        isOpen={showArchiveWorkHistoryDialog}
        onDidDismiss={() => setShowArchiveWorkHistoryDialog(false)}
        header="Delete work history"
        message={`Are you sure you want to delete project history "${selectedWorkHistoryToArchive?.projectAddress?.addressLine1 ?? ""}"? <br> This action cannot be undone`
        }
        buttons={[
          {
            text: 'Cancel',
            role: 'dismiss',
            handler: () => {
              setShowArchiveWorkHistoryDialog(false)
            },
          },
          {
            text: 'OK',
            role: 'destructive',
            handler: () => {
              handleConfirmArchiveWorkHistoryItem(selectedWorkHistoryToArchive?.id)
            },
          },
        ]}
      ></IonAlert>
      <IonAlert
        cssClass={Styles.archiveAlertDialogContainer}
        isOpen={showArchiveWorkHistoryDialogError}
        onDidDismiss={() => setShowArchiveWorkHistoryDialogError(false)}
        message={ARCHIVE_WORK_HISTORY_ERROR_MESSAGE}
        buttons={[
          {
            text: 'OK',
            role: 'dismiss',
            handler: () => {
              setShowArchiveWorkHistoryDialogError(false)
            },
          },
        ]}
      ></IonAlert>
      {displayBadgeInfoModal()}
    </WeaverIonContent>
    <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={handleBackClick}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const UnlockContractorBudgetRangePage: React.FC = () => {
  const { budgetRange } = useParamsFromPageConfig<{ budgetRange: BudgetRange }>()
  const myContractorProfile = useMyContractorProfile()
  const firstWorkHistoryByBudgetRange = getFirstWorkHistoryByBudgetRange(myContractorProfile, budgetRange)
  const isNewWorkHistory = firstWorkHistoryByBudgetRange === undefined

  const [ Screen, activeScreen ] = useScreensWithProps<UnlockScreens, ScreenProps>({
    init: isNewWorkHistory ? UnlockScreens.ThisBudgetRangeIsLocked : UnlockScreens.WorkHistoryList,
    resetWhenValueChanges: budgetRange,
    screenProps: {
      budgetRange,
      myContractorProfile,
    },
    screens: {
      ThisBudgetRangeIsLocked: { render: _ThisBudgetRangeIsLocked },
      WorkHistoryList: { render: _WorkHistoryList },
    },
  })

  return (
    <WeaverIonPage id={`UnlockContractorBudgetRangePage.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen ?? <WeaverIonContent />}
    </WeaverIonPage>
  )
}

export default UnlockContractorBudgetRangePage
