import React from 'react'
import AdBlockWarningGuard from './AdBlockWarningGuard'
import { useDetectAdBlock } from "adblock-detect-react"

export const AdBlockerContext = React.createContext<boolean | undefined>(undefined)

const AdBlockProvider: React.FC = ({ children }) => {
  const adBlockDetected = useDetectAdBlock()
  return (
    <AdBlockerContext.Provider value={adBlockDetected}>
      {/* This component can actually be moved anywhere underneath this provider. */}
      <AdBlockWarningGuard>
        {children}
      </AdBlockWarningGuard>
    </AdBlockerContext.Provider>
  )
}

export default AdBlockProvider

