import React, { useRef, useEffect, useState } from 'react'
import Styles from "./GoogleMap.module.scss"
import { Loader } from '@googlemaps/js-api-loader'
import { useEnvironmentContext } from "../../../api/providers/EnvironmentProvider"
import { WeaverCoordinates } from '../../../graphql/generated'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
type GoogleMapProps = {
  isExact?: boolean,
  zoomLevel?: number,
  coords?: WeaverCoordinates,
  children?: React.ReactNode,
}
/**
 * Component that renders Google map
 * isExact specifies if we showing the exact pinned location by adjusting the zoom level, this flag will
 * also hide circle icon on the map.
 *
 * This component can take in <Mark /> component, which will render Pin mark on the specified location
 * We can render multiple Marks.
 */
const GoogleMap: React.FC<GoogleMapProps> = ({ zoomLevel, isExact, coords, children }) => {
  const mapDivRef = useRef<HTMLDivElement| null>(null)
  const environment = useEnvironmentContext()
  const weaverFlags = useWeaverFlags()
  const defaultZoomLevel = isExact ? 18 : 11

  const [ map, setMap ] = useState<google.maps.Map | undefined>(undefined)
  useEffect(() => {
    const init = async () => {
      if ( !window.google?.maps ) {
        await new Loader({ apiKey: environment.integrations.google.apiKey, ...{ libraries: [ 'places' ] } }).load()
      }
      if (!mapDivRef.current) {
        return
      }
      if (coords){
        const { lat, lng } = coords
        const parsedLat = Number(lat)
        const parsedLng = Number(lng)
        const latLang: google.maps.LatLngLiteral = { lat:parsedLat, lng:parsedLng }
        setMap( new window.google.maps.Map(mapDivRef.current, {
          center: latLang,
          zoom: (weaverFlags['MW-2379-google-zoom']
            ? zoomLevel
              ? zoomLevel
              : defaultZoomLevel
            : defaultZoomLevel),
          fullscreenControl: isExact,
          disableDefaultUI: true,
          draggable: isExact,
          keyboardShortcuts: false,
        }) )
      }
    }
    init()
  }, [ coords, mapDivRef.current ])
  return (
    <>
      <div  className={isExact ? Styles.map : Styles.mapWithCircle} ref={mapDivRef} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child as React.ReactElement, { map })
        }
      })}
    </>
  )}
export default GoogleMap
