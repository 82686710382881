import { useIonViewDidEnter } from "@ionic/react"

/** updates the document's title with a page-specific one when this component triggers ionViewDidEnter */
export const usePageTitleOnEnter = (pageTitle: string | undefined) => {

  useIonViewDidEnter(() => {
    if (!pageTitle) {
      document.title = `Weaver`
      return
    }
    document.title = `${pageTitle} | Weaver`
  }, [ pageTitle ])
}
