import { IonButton, IonButtons, IonCol, IonGrid, IonRow, IonToolbar, useIonViewWillLeave } from "@ionic/react"
import React, { useEffect, useState } from "react"
import Video from 'twilio-video'
import Participant from "./Participant"

type RoomProps = {
  roomName?: string,
  token: string,
  handleLogout: () => void,
}

export const VideoRoom: React.FC<RoomProps> = ({ roomName, token, handleLogout }) => {
  const [ room, setRoom ] = useState<Video.Room | undefined>(undefined)
  const [ participants, setParticipants ] = useState<Video.Participant[]>([])

  useEffect(() => {
    const participantConnected = (participant: Video.RemoteParticipant) => {
      setParticipants(prevParticipants => [ ...prevParticipants, participant ])
    }

    const participantDisconnected = (participant: Video.RemoteParticipant) => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant),
      )
    }

    Video.connect(token, { name: roomName })
      .then(room => {
        setRoom(room)
        room.on('participantConnected', participantConnected)
        room.on('participantDisconnected', participantDisconnected)
        room.participants.forEach(participantConnected)
      })
  }, [ roomName, token ])

  useIonViewWillLeave(() => {
    setRoom(currentRoom => {
      if (currentRoom && currentRoom.localParticipant.state === 'connected') {
        // BUG? there's no "trackPublication.track.stop()" property
        // currentRoom.localParticipant.tracks.forEach(trackPublication => trackPublication.track.stop())
        currentRoom.disconnect()
        return undefined
      } else {
        return currentRoom
      }
    })
  })

  return <>
    <IonToolbar>
      <IonButtons slot="end">
        <IonButton color={'primary'} onClick={handleLogout}> Leave</IonButton>
      </IonButtons>
    </IonToolbar>
    <IonGrid>
      <IonRow>
        <IonCol size="auto">
          {room && <Participant key={room.localParticipant.sid} participant={room.localParticipant} />}
        </IonCol>
        {participants.map(participant => (
          <IonCol key={participant?.sid} size={'auto'}>
            <Participant participant={participant} />
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  </>
}
