import React, { useState, useEffect, useRef } from "react"
import Video, { AudioTrack, RemoteTrack, VideoTrack } from 'twilio-video'
import { isSomething } from "../../../common/utils"
import Styles from './Participant.module.scss'

type ParticipantProps = {
  participant: Video.Participant,
}

const Participant: React.FC<ParticipantProps> = ({ participant }) => {
  const [ videoTracks, setVideoTracks ] = useState<VideoTrack[]>([])
  const [ audioTracks, setAudioTracks ] = useState<AudioTrack[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const audioRef = useRef<HTMLVideoElement | null>(null)

  console.debug('[Participant] render: ', participant)

  useEffect(() => {
    setVideoTracks([ ...participant.videoTracks.values() ].map(pub => pub.track).filter(isSomething))
    setAudioTracks([ ...participant.audioTracks.values() ].map(pub => pub.track).filter(isSomething))

    const trackSubscribed = (pub: RemoteTrack) => {
      if (pub.kind === "video" && pub.mediaStreamTrack.kind === 'video') {
        const videoTrack: VideoTrack = pub
        setVideoTracks(videoTracks => [ ...videoTracks, videoTrack ])
      } else if (pub.kind === "audio" && pub.mediaStreamTrack.kind === 'audio') {
        const audioTrack: AudioTrack = pub
        setAudioTracks(audioTracks => [ ...audioTracks, audioTrack ])
      }
    }

    const trackUnsubscribed = (track: RemoteTrack) => {
      if (track.kind === "video" && track.mediaStreamTrack.kind === 'video') {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track))
      } else if (track.kind === "audio" && track.mediaStreamTrack.kind === 'audio') {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track))
      }
    }

    participant.on("trackSubscribed", trackSubscribed)
    participant.on("trackUnsubscribed", trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [ participant ])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current ?? undefined)
      return () => {
        videoTrack.detach()
      }
    }
  }, [ videoTracks ])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current ?? undefined)
      return () => {
        console.log('cleaning')
        audioTrack.detach()
      }
    }
  }, [ audioTracks ])

  return <div>
    <video className={Styles.videoElement} ref={videoRef} autoPlay={true} />
    <audio ref={audioRef} autoPlay={true} muted={false} />
  </div>
}

export default Participant
