import { Environment } from "."

export const getAccountTypeEnvVar = (varName: string): 'dev' | 'published' => {
  const value = getStringEnvVar(varName)
  if (value === 'dev' || value === 'published') return value
  throw new Error(`Invalid string value from: ${varName} (was '${value}' and should be one of: 'dev' | 'published')`)
}

export const getRequiredStringEnvVar = (varName: string): string => {
  const value = getStringEnvVar(varName)
  if (!value) throw new Error(`Missing string value from: ${varName}`)
  return value
}

export const getStringEnvVar = (varName: string): string | undefined => {
  const value = process.env[varName]
  // If the string has length, return the string else return undefined
  return value == undefined
    ? undefined
    : value.length === 0
      ? undefined
      : value
}

export const getEnvironmentFromEnvVars = (): Environment => ({
  version: "2",
  release: {
    version: getStringEnvVar('REACT_APP_RELEASE_VERSION') ?? 'unknown',
  },
  account: {
    name: getRequiredStringEnvVar('REACT_APP_WEAVER_ACCOUNT_NAME'),
    type: getAccountTypeEnvVar('REACT_APP_WEAVER_ACCOUNT_TYPE'),
  },
  app: {
    domain: getRequiredStringEnvVar('REACT_APP_WEAVER_SPA_DOMAIN'),
  },
  auth0: {
    domain: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_DOMAIN'),
    client_id: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_APP_CLIENTID'),
    audience: getRequiredStringEnvVar('REACT_APP_WEAVER_AUTH0_APP_AUDIENCE'),
  },
  apiGateway: {
    bootstrap: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_BOOTSTRAP_URL'),
    },
    devSupport: {
      url: getStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_DEVSUPPORT_URL'),
    },
    ably: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_APIGATEWAY_ABLY_URL'),
    },
  },
  graphQL: {
    notes: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_URL'),
      region: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_REGION'),
      key: getStringEnvVar('REACT_APP_WEAVER_GRAPHQL_NOTES_KEY'),
    },
    core: {
      url: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_URL'),
      region: getRequiredStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_REGION'),
      key: getStringEnvVar('REACT_APP_WEAVER_GRAPHQL_CORE_KEY'),
    },
  },
  integrations: {
    google: {
      apiKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_GOOGLE_APIKEY'),
    },
    segment: {
      frontend: {
        writeKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SEGMENT_FRONTEND_WRITEKEY'),
      },
    },
    launchDarkly: {
      sdkKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_SDKKEY'),
      mobileKey: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_MOBILEKEY'),
      clientSideId: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_LAUNCHDARKLY_CLIENTSIDEID'),
    },
    sentry: {
      app: {
        DSN: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SENTRY_APP_DSN'),
      },
      frontend: {
        DSN: getRequiredStringEnvVar('REACT_APP_WEAVER_INTEGRATION_SENTRY_FRONTEND_DSN'),
      },
    },
  },
})
