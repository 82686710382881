
import React from 'react'
import { TeamStatus } from '../../../graphql/generated'
import { useWeaverFlags } from '../../thirdParty/launchDarkly/useWeaverFlags'
import { useMyIndividualActiveTeam } from '../MyIndividualProvider/MyIndividualProvider'
import SuspensionWarningGuard from './SuspensionWarningGuard'

export const SuspensionContext = React.createContext<TeamStatus | undefined | null>(undefined)

const SuspensionProvider: React.FC = ({ children }) => {
  const weaverFlags = useWeaverFlags()

  return weaverFlags['MW-2328-suspension-guard']
    ? <LoadSuspensionProvider {...{ children }} />
    : <SuspensionContext.Provider value={undefined} {...{ children }} />
}

const LoadSuspensionProvider: React.FC = ({ children }) => {
  const myTeam = useMyIndividualActiveTeam()

  return (
    <SuspensionContext.Provider value={myTeam?.status}>
      <SuspensionWarningGuard>
        {children}
      </SuspensionWarningGuard>
    </SuspensionContext.Provider>
  )
}

export default SuspensionProvider
