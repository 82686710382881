export * from './constants'

export type ObjectOrPrimitive = object | NonNullable<string | number | bigint | boolean | null | undefined>

// Take a thing and test to see if it is something (not null / undefined)
// The `maybeThing is T` means this can be used in an Array.filter(...) to remove nulls / undefineds with type safety
export const isSomething = <T>(maybeThing: T | null | undefined): maybeThing is T => maybeThing !== null && maybeThing !== undefined

/**
 * Takes an array or undefined; returns the array or empty array of the same type
 * If it is an array, cleans the array so it isSomething, so we can return T[]
 */
export const alwaysArray = <T>(maybeArray: (T | null | undefined)[] | null | undefined): T[] => Array.isArray(maybeArray) ? maybeArray.filter(isSomething) : []

// TODO: We should replace usages of isFunction with zod function checking for better typing
/**
 * Is a thing
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IsFunction<T> = T extends (...args: any[]) => any ? T : never

export const isFunction = <T extends object>(value: T): value is IsFunction<T> => value != null && typeof value === 'function'

/**
 * Returns a light-ish (#33 - #DD range) color hashed from a given string
 */
export function getSeededColor(fromString: string) {
  const hash = fromString.split("").reduce((prev, cur) => cur.charCodeAt(0) + ((prev << 5) - prev), 0)

  const [ r,g,b ] = [ hash >> 8 * 1, hash >> 8 * 2, hash >> 8 * 3 ].map( x => ("33" + (x & 0xDD).toString(16)).slice(-2))

  return `#${r}${g}${b}`
}

/**
 * Get the initials of a string, ignoring symbols and numeric "words"
 * Designed mainly for addresses e.g. "365 North Street" => "NS"
 */
export function getInitials(title: string) {
  const sanitized = title.trim().replace(/[^\w ]/g, "")
  const nonNumericBits = sanitized.split(" ").filter(x => !x.match(/^\d+$/))
  return nonNumericBits.map(x => x[0]).join("").toUpperCase()
}

export const stripLeadingSlash = (text: string) => text.startsWith('/') ? text.substring(1) : text
export const ensureTrailingSlash = (text: string) => `${text}${text.endsWith('/') ? '' : '/'}`
export const stripTrailingSlash = (text: string) => text.endsWith('/') ? text.substring(0, text.length - 1) : text

export const pathLevelUp = (url: string) => ensureTrailingSlash(
  stripTrailingSlash(url)
    .split('/')
    .slice(0, -1)
    .join('/'),
)

export const convertNullToUndefined = <T>(value: T | undefined | null): T | undefined => value ?? undefined
export const convertUndefinedToNull = <T>(value: T | undefined | null): T | null => value ?? null

/**
 * Pass this function to `Array.filter()` to ensure the returned array is unique.
 * This is an array filter function which returns true on if the item is the first of it's occurrence in the list
 *
 * @param value First parameter of `Array<T>.filter()`
 * @param index Second parameter of `Array<T>.filter()`
 * @param self Third parameter of `Array<T>.filter()`
 * @returns A shallow copy of the array with exact duplicates removed
 */
export const uniqueFilter = <T>(value: T, index: number, self: (T | null | undefined)[]): boolean => self.indexOf(value) === index

/**
 * Formats bytes to the following units ('KB', 'MB', 'GB', 'TB', 'PB').
 * @param bytes The bytes to be formatted.
 * @returns a string representing the value in the corresponding unit rounded up to 2 decimals.
 */
export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 Bytes'

  const decimals = 2
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB', 'PB' ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

/**
 * takes enum keys and adds spaces to them
 * AKA SideExtension = Side Extension
 * Refurbishment = Refurbishment
 * 1AndAbove = 1 And Above
 */

export const getEnumLabel = (value: string) => {
  return value.replace(/([A-Z])/g, ' $1').trim()
}

/**
 * Takes an enum with string values and returns all the keys
 *
 * ```enum Colour {
 *  Red = "RED",
 *  Green = "GREEN",
 * }```
 *
 * Returns [Colour.Red, Colour.Green]
 */
export const enumKeys = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
}

export const asyncForEach = async <T, X>(array: T[], callback: (each: T, index: number, array: T[]) => Promise<X>) => {
  const results = [] as X[]

  for (let index = 0; index < array.length; index++) {
    const result = await callback(array[index], index, array)
    results.push(result)
  }

  return results
}

export const sleep = (timeInMilliseconds: number) => new Promise(resolve => setTimeout(resolve, timeInMilliseconds))
