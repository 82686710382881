import React, { ComponentProps } from "react"
import { IonContent } from "@ionic/react"
import { CheckableComponents, useComponentCounter } from "./WeaverStructureChecker"
import ResponsiveContentWrapper from "../ResponsiveContentWrapper/ResponsiveContentWrapper"

type WeaverIonContentProps = {
  withResponsiveContentWrapper?: boolean,
  useFullHeight?: boolean,
} & ComponentProps<typeof IonContent>

const WeaverIonContent: React.FC<WeaverIonContentProps> = ({ withResponsiveContentWrapper = true, useFullHeight, children, ...props }) => {
  useComponentCounter(CheckableComponents.weaverIonContent)
  return (
    <IonContent fullscreen className={`ion-padding-horizontal`} {...props}>
      {withResponsiveContentWrapper
        ? (
          <ResponsiveContentWrapper useFullHeight={useFullHeight}>
            {children}
          </ResponsiveContentWrapper>
        )
        : children}
    </IonContent>
  )
}

export default WeaverIonContent
