import { IonBackdrop, IonContent, IonImg } from '@ionic/react'
import React, { useContext } from 'react'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { AdBlockerContext } from './AdBlockProvider'
import Styles from './AdBlockWarningGuard.module.scss'
import WeaverIcon from "../../../assets/icons/weaver_icon.png"

const AdBlockWarningGuard: React.FC = ({ children }) => {
  const addBlockContext = useContext(AdBlockerContext)
  return addBlockContext === undefined
    ? <LoadingSpinnerPage name='AdBlockWarningGuard.addBlockStateUndefined' />
    : addBlockContext === true
      ? <>
        <IonBackdrop visible={true} className={Styles.ionBackDrop}></IonBackdrop>
        <div className={'ion-page'}>
          <IonContent>
          </IonContent>
        </div>
        <div className={`${Styles.customAlert} ${Styles.supportDarkMode} ion-padding`}>
          <div>
            <IonImg src={WeaverIcon} />
            <h3 className={Styles.title}>It looks like you’re using an adblocker</h3>
            <p className={Styles.description}>We do not display ads, but adblockers might block useful features of the Weaver app. For the best experience of the app, please disable your adblocker. </p>
            <a target="_blank" href="https://help.weaver.build/en/articles/6845703-turning-off-your-ad-blocker" rel="noreferrer">How to disable adblocker?</a>
          </div>
        </div>
      </>
      : <>{children}</>
}

export default AdBlockWarningGuard
