import { ObjectOrPrimitive } from "./utils"

type SortFn<T> = (a: T, b: T) => number
export enum SortDirection { NORMAL, INVERTED }

/**
 * return a comparator function for comparing objects of type `T`
 *
 * @param accessor The key of the accessed property or A function to access the property
 */
export const sortBy = <T>(accessor: keyof T | ((item: T) => ObjectOrPrimitive), sortDirection: SortDirection = SortDirection.NORMAL) => {
  const accessorFn = typeof accessor === "function" ? accessor : (item: T) => item[accessor]
  return (a: T, b: T) => {
    const aValue = accessorFn(a)
    const bValue = accessorFn(b)

    const sortOrder = aValue > bValue ? 1 : aValue < bValue ? -1 : 0
    return sortDirection === SortDirection.INVERTED
      ? 0 - sortOrder // Reverse
      : sortOrder // Default to normal
  }
}

/** returns a sorted copy of an array, sorted in the order of compare functions given
 *
 * For simple accessor sorts, you can use the sortBy function:
 * ```
 * sortedBy(data, [sortBy("order"), sortBy("title")])
 * ```
 */
export const multiSort = <T,>(originalData: T[], compareFns: SortFn<T>[]) => {
  const data = [ ...originalData ]
  data.sort((a, b) => {
    for (const compare of compareFns) {
      const compareValue = compare(a, b)
      if (compareValue !== 0) return compareValue
      continue
    }

    return 0
  })

  return data
}
