import { useAuthorizationHeader } from "../api/providers/AuthProvider"
import { useEnvironmentContext, Environment } from "../api/providers/EnvironmentProvider"

type TUseGraphQLDataSourceProps = {
  api: keyof Environment['graphQL'],
}
type TUseGraphQLDataSourceReturns = {
  endpoint: string,
  fetchParams?: RequestInit,
}
export const useGraphQLDataSource = ({ api }: TUseGraphQLDataSourceProps): TUseGraphQLDataSourceReturns => {
  const environment = useEnvironmentContext()
  const Authorization = useAuthorizationHeader()

  const url = environment.graphQL[api].url

  return {
    endpoint: url,
    fetchParams: {
      headers: {
        Authorization,
        // The Content-Type header is needed for MockServiceWorker, but not AppSync
        'Content-Type': 'application/json',
      },
    },
  }
}
