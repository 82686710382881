import React from 'react'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { useParamsFromPageConfig } from '../../../routesProvider'
import { useListProjectTasksQuery, useShowProjectQuery } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { AccordionGroupChangeEventDetail, IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonRefresher, IonRefresherContent, RefresherEventDetail, AccordionGroupCustomEvent, useIonViewDidLeave } from '@ionic/react'
import Task from './Task'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { TopLevelTask } from './TopLevelTask'
import Styles from './AllProjectTasksPage.module.scss'
import { ProjectIndicator } from '../ProjectIndicator'
import { pageConfig_TaskActioner, useRouteTo } from '../../../routes'
import { multiSort, sortBy } from '../../../common/sort'
import { useNextTaskSelector } from '../taskUtils'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { Duration } from 'luxon'
import { useSearchState } from '../../../common/hooks/pages'
import { z } from 'zod'

const zSearchSchema = z.object({
  expand: z.coerce.string().optional(),
})

const AllProjectTasksPage: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{projectId: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const listProjectTasksQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    select: (data) => multiSort(data.listProjectTasks, [ sortBy("order"), sortBy("title") ]),
  })
  const showProject = useShowProjectQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false, staleTime: Duration.fromObject({ seconds: 30 }).toMillis()  })
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)
  const { searchData, mergeSearchState } = useSearchState({}, { schema: zSearchSchema })
  const nextTaskSelector = useNextTaskSelector()
  const { ["MW-2422-tasks-qol1-part3"]: tasksQol1Part3 } = useWeaverFlags()

  useIonViewDidLeave(() => {
    mergeSearchState({ expand: undefined }, "replace")
  })

  const handleAccordionChange = (event: AccordionGroupCustomEvent<AccordionGroupChangeEventDetail<unknown>>) => {
    if (event.detail.value) {
      mergeSearchState({ expand: String(event.detail.value) }, "replace")
    } else {
      mergeSearchState({ expand: undefined }, "replace")
    }
  }

  const handleRefetchData = async () => {
    await showProject.refetch()
    await listProjectTasksQuery.refetch()
  }

  function handlePullRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      handleRefetchData()
      event.detail.complete()
    }, 2000)
  }

  if (listProjectTasksQuery.isLoading || showProject.isLoading) {
    return <LoadingSpinnerPage name="AllProjectTasksPage" />
  }

  if (listProjectTasksQuery.error || !listProjectTasksQuery.data || showProject.error || !showProject.data) {
    return <ErrorBlockPage name='AllProjectTasksPage' onRefresh={handleRefetchData} />
  }

  const projectTasks = listProjectTasksQuery.data
  const projectPostCode = showProject.data.getProject.address?.postCode
  const projectTitle = showProject.data.getProject.title

  const projectSubtitle = tasksQol1Part3 ? projectTitle : projectPostCode
  const nextTask = nextTaskSelector(projectTasks)

  return (
    <WeaverIonPage id='AllProjectTasksPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='All Tasks' pageSubtitle={projectSubtitle}/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonRefresher className={Styles.ionRefresher}  slot="fixed" onIonRefresh={handlePullRefresh}>
          <IonRefresherContent
            refreshingSpinner={'crescent'}>
          </IonRefresherContent>
        </IonRefresher>
        <IonCard className={Styles.ionCard}>
          <IonCardContent className={`${Styles.ionCardContent} ion-no-padding`}>
            <ProjectIndicator taskListData={projectTasks} />
          </IonCardContent>
        </IonCard>
        <IonAccordionGroup multiple={false} value={searchData.expand} onIonChange={handleAccordionChange } className={`${Styles.ionAccordionGroup} ion-margin-top`}>
          { projectTasks?.map((task) => {
            const { id, childTasks , title } = task
            const sortedChildren = multiSort(childTasks, [ sortBy("order"), sortBy("title") ])
            return  (
              <React.Fragment key={id}>
                <IonAccordion value={id}>
                  <TopLevelTask task={task}/>

                  <div className='ion-margin-bottom' slot="content">
                    {
                      (tasksQol1Part3 && sortedChildren.length < 1) &&
                      <div slot="content" className={Styles.accordionPlaceholder}>Nothing for you to do here yet. <br /> <br />Relevant tasks will start to appear when a contractor gets matched to your project.</div>
                    }
                    {
                      sortedChildren.map((child) => (
                        <div key={child.id}>
                          <Task task={child} parentId={id} isNextTask={nextTask.id === child.id} onAction={(actioned) => routeToTaskActioner({ taskId: actioned.id })()}  />
                        </div>
                      ))}
                  </div>
                </IonAccordion>
              </React.Fragment>
            )
          })}
        </IonAccordionGroup>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default AllProjectTasksPage
