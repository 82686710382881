import React from 'react'
import { MyChatsRow } from './MyChatsRow'
import { useMyChatRooms } from '../../../api/services/chat/useMyChatRooms'
import { Virtuoso } from 'react-virtuoso'
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from "../../../common/components/GlobalHeader/GlobalHeader.module.scss"

import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import Styles from "./MyChats.module.scss"

const PACKAGE = 'techDemo.chats'

const MyChats: React.FC = () => {
  return (
    <WeaverIonPage id='MyChats'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='My Chats'/>
      </WeaverIonHeader>

      <WeaverIonContent className={Styles.myChatsContentContainer} useFullHeight={true} >
        <MyChatRooms />
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

const MyChatRooms: React.FC = () => {
  console.debug(`[${PACKAGE}] Render:`)

  const myChatRooms = useMyChatRooms()

  return myChatRooms === undefined
    ? <LoadingSpinner name='MyChatRooms' />
    : myChatRooms === null
      ? <ErrorBlock name='MyChatRooms'>There is an error loading my chatrooms!</ErrorBlock>
      : myChatRooms.length === 0
        ? <p className='ion-text-center ion-padding-top'>You have not joined any chatrooms yet!</p>
        : <Virtuoso
          style={{ height: "100%" }}
          data={myChatRooms}
          itemContent={index => {
            const chatRoom = myChatRooms[index]

            return (
              <div>

                <MyChatsRow
                  key={chatRoom.id}
                  id={chatRoom.id}
                  name={chatRoom.name}
                  lastMessageContent={chatRoom._lastMessageContent}
                  lastMessageAt={chatRoom.lastMessageAt}
                  myChatRoomStats={chatRoom.myChatRoomStats}
                />
              </div>
            )
          }}
        />
}

export default MyChats
