import React, { ComponentProps } from "react"
import { IonFooter } from "@ionic/react"
import { CheckableComponents, useComponentCounter } from "./WeaverStructureChecker"

const WeaverIonFooter: React.FC<ComponentProps<typeof IonFooter>> = (props) => {
  useComponentCounter(CheckableComponents.weaverIonFooter)
  return <IonFooter {...props} />
}

export default WeaverIonFooter
