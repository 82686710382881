import React from 'react'
import { IonToolbar, IonTitle } from '@ionic/react'
import JwtList from './ListJwts'
import NewJwtForm from './NewJwtForm'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

const ManageJWTs: React.FC = () =>
  <WeaverIonPage id='ManageJWTs' key="ManageJWTs">
    <WeaverIonHeader>
      <IonToolbar>
        <IonTitle>Developer Settings</IonTitle>
      </IonToolbar>
    </WeaverIonHeader>
    <WeaverIonContent>
      <JwtList />
      <NewJwtForm />
    </WeaverIonContent>
  </WeaverIonPage>

export default ManageJWTs
