import React from 'react'
import { Environment, EnvironmentContext } from '.'
import { getEnvironmentFromEnvVars } from './dotenv'

const PACKAGE = 'EnvironmentProvider'

type EnvironmentProviderProps = {
  environment?: Environment,
}

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ environment, children }) => {
  const selectedEnvironment = environment === undefined
    ? getEnvironmentFromEnvVars()
    : environment

  console.log(`[${PACKAGE}] Returning `, { environment, selectedEnvironment })

  return (
    <EnvironmentContext.Provider value={selectedEnvironment}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export default EnvironmentProvider
