import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useGraphQLDataSource } from '../../api/graphql'
import { useAnalyticsEvent, useCallAnalyticsGroup } from '../../api/providers/SegmentProvider/hooks'
import ResponsiveContentWrapper from '../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import { alwaysArray } from '../../common/utils'
import { budgetRangeLabels } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { useInvalidateMyContractorProfile, useMyContractorProfile } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { badgeLevelWorkHistoryThreshold } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils'
import { getAllBadgeEligibleHistories } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/hooks'
import { calculatedDescription } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/ContractorProfileBudgetRangeBadgePage'
import { useGetAllBadgesToBeAwarded } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/hooks'
import AnimatedBudgetRangeBadgePage from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetReferences/AnimatedBudgetRangeBadgePage'
import { BudgetRange, useAwardContractorProfileBudgetRangeBadgeMutation } from '../../graphql/generated'
import { objectEntries } from '../../common/utils/objects'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'

type AwardBadgePageProps = {
  setHasAwardedAllBadges: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  myContractorProfile: ReturnType<typeof useMyContractorProfile>,
}

export const AwardBadgePage: React.FC<AwardBadgePageProps> = ({ setHasAwardedAllBadges, myContractorProfile }) => {
  const [ pageKey, setPageKey ] = useState<string | undefined>(undefined)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const callAnalyticsGroup = useCallAnalyticsGroup()
  const awardContractorProfileBudgetRangeBadgeMutation = useAwardContractorProfileBudgetRangeBadgeMutation(gqlDataSource)

  const triggerEvent_WorkHistory_References_Badge_Awarded = useAnalyticsEvent('WorkHistory_References_Badge_Awarded')

  /**
   * As of today (18/03/2023) there can only be one missing badge per budget range.
   *
   * This solution is forward thinking and will work for any number of badges
   * in a given budget range by using the award threshold as an index to get the work history id.
   *
   * If it is not found, we default to "unknown" as the work history id.
   *
   */
  const allBadgesToBeAwardedByAllBudgetRanges = useGetAllBadgesToBeAwarded()

  const badgesInBudgetRangesToBeAwarded = objectEntries(allBadgesToBeAwardedByAllBudgetRanges).flatMap(
    ([ budgetRange, badgeLevels ]) => badgeLevels.map(badgeLevel => ({ budgetRange, badgeLevel })))

  const onClick = async () => {
    const budgetRangeForCurrentAward = badgesInBudgetRangesToBeAwarded[0].budgetRange
    const currentAwardLevel = badgesInBudgetRangesToBeAwarded[0].badgeLevel
    const thresholdOfCurrentAwardLevel = badgeLevelWorkHistoryThreshold[currentAwardLevel]
    const eligibleWorkHistoriesInBudgetRange = getAllBadgeEligibleHistories(myContractorProfile, budgetRangeForCurrentAward)

    const maybeCurrentWorkHistoryId = eligibleWorkHistoriesInBudgetRange?.[thresholdOfCurrentAwardLevel - 1]?.id ?? "unknown"

    await awardContractorProfileBudgetRangeBadgeMutation.mutateAsync({
      budgetRange: budgetRangeForCurrentAward,
      awardedBadge: currentAwardLevel,
      workHistoryId: maybeCurrentWorkHistoryId })

    await triggerEvent_WorkHistory_References_Badge_Awarded({
      budgetRange: budgetRangeForCurrentAward,
      badgeLevel: currentAwardLevel,
      badgeLevelWorkHistoryThreshold: thresholdOfCurrentAwardLevel,
    })

    // We should tell Segment that this group has a new Badge by adding it to the list it already has
    const contractorTeamId = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.id
    const contractorBudgetRanges: BudgetRange[] = [
      ...alwaysArray(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges).map(badge => badge.budgetRange),
      budgetRangeForCurrentAward,
    ]

    await callAnalyticsGroup(contractorTeamId, {
      budgetRangeBadges: contractorBudgetRanges,
    })

    /**
       * By invalidating profile cache after awarding the badge we make sure our badgesToBeAwarded array gets updated
       * and also prevents any edge case of a badge being awarded twice
       */
    await invalidateMyContractorProfile()

    // causes IonPage to repaint triggering the badge animation if more than one is to be awarded
    if (badgesInBudgetRangesToBeAwarded.length > 1) {
      return setPageKey(DateTime.now().toISO())
    }
    return setHasAwardedAllBadges(true)
  }

  const description = calculatedDescription(badgesInBudgetRangesToBeAwarded[0]?.badgeLevel)

  if (!badgesInBudgetRangesToBeAwarded.length){
    setHasAwardedAllBadges(true)
    return <LoadingSpinnerPage name="AwardBadgePage" />
  }

  return <WeaverIonPage id={`AwardBadgePage.show`} key={pageKey}>
    <WeaverIonContent>
      <ResponsiveContentWrapper>
        <AnimatedBudgetRangeBadgePage
          title="You&apos;ve unlocked"
          subtitle={`${budgetRangeLabels[badgesInBudgetRangesToBeAwarded[0].budgetRange]} ${badgesInBudgetRangesToBeAwarded[0].badgeLevel}`}
          description={description}
          badgeLevel={badgesInBudgetRangesToBeAwarded[0].badgeLevel}
          actionButtonProps={{
            children: <>Continue</>,
            expand: 'block',
            size: 'large',
          }}
          budgetRange={badgesInBudgetRangesToBeAwarded[0].budgetRange}
          onClick={onClick}
        />
      </ResponsiveContentWrapper>
    </WeaverIonContent>
  </WeaverIonPage>
}

