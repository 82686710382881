import { IonGrid, IonRow } from "@ionic/react"
import React from "react"
import GenericSelectorList from "../../../../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../../../../common/components/GenericSelectorList/helpers"
import { ProjectType } from "../../../../../../../graphql/generated"
import IndicatorBar, { IndicatorBarProps } from "../../../../../../projects/CreateProjectPage/IndicatorBar"
import { projectTypeLabels } from "../../../../../../onboarding/team/onboarding.i18n"
import Styles from '../../../../../../../common/components/GenericSelector.module.scss'
import ResponsiveContentWrapper from "../../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"

type ProjectTypeSelectorProps = {
  value: ProjectType[],
  setValue: (values: ProjectType[]) => Promise<void> | void,
  hasError: boolean,
  indicatorBarProps: IndicatorBarProps,
  disabled?: boolean,
}

const ProjectTypeSelector: React.FC<ProjectTypeSelectorProps> = ({ value, setValue, hasError, indicatorBarProps, disabled }) => {
  return (
    <>
      <ResponsiveContentWrapper>
        <IndicatorBar {...indicatorBarProps} />
        <div className={Styles.container}>
          <div className={Styles.slideContainer}>
            <IonGrid className={Styles.contentContainer}>
              <h3 className="ion-text-lg-center ion-padding-start"><b> What type of project was it?</b></h3>
              <h6 className="ion-text-lg-center ion-padding-start">Select all that apply</h6>
              <IonRow className={Styles.item}>
                <GenericSelectorList
                  options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
                  multiSelect={true}
                  selected={value}
                  onSelect={setValue}
                  showItemDetail={false}
                  colSize={'6'}
                  disabled={disabled}
                />
                <p className={`${Styles.errorMessage} ion-text-lg-center ion-padding-start`}>{hasError && 'You must select a project type.'}</p>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </ResponsiveContentWrapper>
    </>
  )
}

export default ProjectTypeSelector
