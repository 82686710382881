import React, { ComponentProps } from 'react'
import ErrorBlock from './ErrorBlock'
import WeaverIonContent from '../WeaverIonWrappers/WeaverIonContent'

const ErrorBlockContent: React.FC<ComponentProps<typeof ErrorBlock>> = (props) =>
  <WeaverIonContent>
    <ErrorBlock {...props} />
  </WeaverIonContent>

export default ErrorBlockContent
