import { FetchQueryOptions, QueryFunction, useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../api/graphql"

/**
 * A type that represents the generated useBlahQuery objects with generic params
 * This can't be a generic because the typescript-react-query plugin doesnt generate one
 */
type GeneratedQuery<TData, TVariables, TQueryKey extends readonly unknown[]> = {
  getKey: (variables: TVariables) => TQueryKey,
  fetcher: (datasource: ReturnType<typeof useGraphQLDataSource>, variables: TVariables) => QueryFunction<TData, TQueryKey>,
}

/**
 * Returns a function that can be used to asyncronously run queries (just like mutations!)
 * This is designed for running queries in response to an action, or as part of a useEffect when you can't mount the useQuery hook as disabled.
 *
 * This function should return from cache as normal, see all options using the FetchQueryOptions parameter
 *
 * The function returns the output directly, and doesn't store any state or meta about the fetched data. If you need to track state, e.g. isLoading, lastFetchedAt, or other meta, use the `useQuery` hook.
 *
 * ```
 * const team = await asyncQuery(useGetTeamQuery, { teamId }, { staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds') }))
 * ```
 */
export const useAsyncQuery = (...params: Parameters<typeof useGraphQLDataSource>) => {
  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource(...params)

  return <TData, TVariables, TQueryKey extends readonly unknown[]>(rootQuery: GeneratedQuery<TData, TVariables, TQueryKey>, variables: TVariables, fetchOptions: FetchQueryOptions<TData, unknown, TData, TQueryKey> = {}) => queryClient.fetchQuery(rootQuery.getKey(variables), rootQuery.fetcher(gqlDataSource, variables), fetchOptions)
}

/**
 * ReactQuery keys are arrays which have a root key as the namespace and then variables.
 * The GraphQL generator creates `getKey` functions which take the variables and produce an array of `[ rootKey, variables ]`.
 * Unfortunately, the constant that is the `rootKey` isn't exposed in any accessible way.
 * So, we have to call `getKey` with some junk variables and then strip them off.
 *
 * This function is a helper to do this messy work for you and encapsulates the necessary casting which violates our eslinting.
 * See also: https://github.com/dotansimha/graphql-code-generator-community/pull/20 (which exposes the `rootKey`)
 *
 * @param getKey The GraphQL generated QueryKey function which takes a single required parameter and returns the query key array
 * @returns The `rootKey` for the ReactQuery in a QueryKey form
 */
export const getRootQueryKey = <TVariables, TQueryKey extends readonly unknown[]>(getKey: (variables: TVariables) => TQueryKey): readonly unknown[] => {
  // `as` casting used necessarily and in a controlled manner
  const rootQueryKey = getKey({} as TVariables)[0]
  return [ rootQueryKey ]
}
