import React, { Dispatch, SetStateAction, useContext, useState } from 'react'

type WorkHistoryItemIdState = string | undefined

type PublicContractorProfileContextState = {
  /** The response from useState signifying the selected WorkHistory ID */
  workHistoryItemIdState: [WorkHistoryItemIdState, Dispatch<SetStateAction<WorkHistoryItemIdState>>],
}

const PublicContractorProfileContext = React.createContext<PublicContractorProfileContextState | undefined>(undefined)

const PublicContractorProfileScreenState: React.FC = ({ children }) => {
  const value: PublicContractorProfileContextState = {
    workHistoryItemIdState: useState<WorkHistoryItemIdState>(),
  }

  return (
    <PublicContractorProfileContext.Provider value={value}>
      {children}
    </PublicContractorProfileContext.Provider>
  )
}

export const usePublicContractorProfileScreenState = (): PublicContractorProfileContextState => {
  const state = useContext(PublicContractorProfileContext)
  if (state === undefined) {
    throw new Error('[usePublicContractorProfileScreenState] State is not ready to be used.')
  }

  return state
}

export default PublicContractorProfileScreenState
