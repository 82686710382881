import React, { ComponentProps } from "react"
import { IonButton } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences, useRouteTo } from "../../../routes"

type IonButtonPropsWithoutOnClick = Omit<ComponentProps<typeof IonButton>, 'onClick'>
const ButtonChangeLeadPreferences: React.FC<IonButtonPropsWithoutOnClick> = (ionButtonProps) => {
  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)

  return <IonButton {...ionButtonProps} onClick={goToContractorLeadPreferences({})}>
    {ionButtonProps.children ?? "Add Lead Preferences"}
  </IonButton>
}

export default ButtonChangeLeadPreferences
