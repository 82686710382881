import { IonText } from '@ionic/react'
import React from 'react'
import { CompanySummary } from "../../../../graphql/generated"
import AutoSearchCompany from "./AutoSearchCompany"
import Styles from "./CompanyDetailsFind.module.scss"

type CompanyDetailsFindProps = {
  value?: CompanySummary,
  setValue: (value?: CompanySummary) => Promise<void> | void,
  hasError: boolean,
  clearErrors: () => void,
}

const CompanyDetailsFind: React.FC<CompanyDetailsFindProps> = ({ value, setValue, hasError, clearErrors }) =>
  <>
    <div className={Styles.contentHeader}>
      <h3>Find your Company Details</h3>
      <IonText>Search Companies House data for your company name or company number</IonText>
    </div>
    <AutoSearchCompany selectedCompany={value} companyNameSelected={setValue} hasError={hasError} clearErrors={clearErrors} />
  </>

export default CompanyDetailsFind
