import React from 'react'
import { IonSpinner } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"

type ThumbnailPhotoInProgressProps = {
  uploadedFileId?: string,
  photoSizeSmall?: boolean,
}

const ThumbnailPhotoInProgress: React.FC<ThumbnailPhotoInProgressProps> = ({ uploadedFileId, photoSizeSmall = false }) => {
  return (
    <div data-uploadedfile={uploadedFileId} className={`${photoSizeSmall ? Styles.thumbnailPhotoContainerSmall : Styles.thumbnailPhotoContainer} ${Styles.success}`}>
      <IonSpinner className={Styles.spinner} color="black" />
    </div>
  )
}

export default ThumbnailPhotoInProgress
