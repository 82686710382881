import React from 'react'
import { IonBadge, IonButton, IonIcon, IonItem } from '@ionic/react'
import { alwaysArray, uniqueFilter } from '../../../common/utils'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import Styles from './ScreenWorkHistory.module.scss'
import { DisplayUploadedFileAsPhoto } from '../ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { usePublicContractorProfileScreenState } from './PublicContractorProfileScreenState'
import { PublicContractorProfileScreenWithRenderProps, PublicContractorScreenNames } from './PublicContractorProfile'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { arrowBack } from 'ionicons/icons'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { UploadedFileStatus } from '../../../graphql/generated'
import { moneyAsTextBySignificantFigures } from '../../../common/utils/budgetTransformation'
import useImageLightbox from '../../chats/chatRoom/ChatRoom/services/useImageLightbox'
import ImageLightbox from '../../chats/chatRoom/ChatRoom/ImageLightbox'
import { DateTime } from 'luxon'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { ContractorWorkHistoryReference } from '../MyContractorProfilePage/ContractorWorkHistoryReference'

const ScreenWorkHistory: React.FC<PublicContractorProfileScreenWithRenderProps> = ({ profile, useChangeScreen }) => {

  const weaverFlags = useWeaverFlags()
  const {
    workHistoryItemIdState: [ workHistoryItemId ],
  } = usePublicContractorProfileScreenState()
  const lightboxEnabled = weaverFlags['MW-2395-lightbox-for-work-history-photos']

  const {
    lightboxToggler,
    selectedUrl,
    images,
    openLightbox,
    addImageToLightbox,
  } = useImageLightbox(lightboxEnabled)

  const changeScreenToProfileHome = useChangeScreen(PublicContractorScreenNames.Home)
  const ScreenWeaverIonHeader: React.FC = () =>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader
        pageTitle='Public Profile'
        navElement={
          <IonButton onClick={() => changeScreenToProfileHome()}>
            <IonIcon slot="start" icon={arrowBack} />
          </IonButton>
        }
      />
    </WeaverIonHeader>

  const workHistoryItem = alwaysArray(profile.workHistory).find(item => item.id === workHistoryItemId)
  console.debug(`[PublicContractorWorkHistoryPage] Render: `, { profile, workHistoryItemId, workHistoryItem })

  if (workHistoryItem === undefined) {
    return <>
      <ScreenWeaverIonHeader />
      <WeaverIonContent>
        <ErrorBlock name='ScreenWorkHistory'><p>Unable to find Work History Item</p></ErrorBlock>
      </WeaverIonContent>
    </>
  }

  return <>
    <ScreenWeaverIonHeader />
    <WeaverIonContent>
      <h3>{formatAddressToSingleLine(workHistoryItem.projectAddress) ?? 'Work History'}</h3>

      <IonItem className={Styles.costOfWorkContainer}>
        <IonIcon slot='start' icon={allIonIcons.cashOutline} />
        <span>
          <p>Cost of Work</p>
          {workHistoryItem.constructionValue && <p>{moneyAsTextBySignificantFigures(workHistoryItem.constructionValue)}</p>}
        </span>
      </IonItem>

      <IonItem className={Styles.typeOfWorkContainer}>
        <IonIcon icon={allIonIcons.hammerOutline} slot="start" />
        <span>
          <p>Type</p>
          {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
        </span>
      </IonItem>

      <h3>Reference</h3>
      {workHistoryItem?.references !== undefined
        ? weaverFlags['MW-2419-tag-references-as-verified']
          ? alwaysArray(workHistoryItem.references)
            .map(reference => <ContractorWorkHistoryReference key={reference.id} reference={reference} />)
          : alwaysArray(workHistoryItem.references)
            .map(reference => reference.teamType)
            .filter(uniqueFilter)
            .map(teamType => <p key={teamType}>{teamType}</p>)
        : <p>No references have been uploaded</p>
      }

      <h3>Photos</h3>
      <div className={`${Styles.photoContainer} ${weaverFlags['MW-2420-margin-between-photos-on-work-history'] ? Styles.gridRowGap : undefined}`}>
        {workHistoryItem.photos ? alwaysArray(workHistoryItem.photos).filter(photo => photo.status !== UploadedFileStatus.Archived)
          .map(photo =>
            <div key={photo.id} className={Styles.photoItemContainer}>
              { lightboxEnabled
                ? <DisplayUploadedFileAsPhoto openLightbox={openLightbox} addImageToLightbox={addImageToLightbox} uploadedFileId={photo.id} shouldDisplayDeleteButton={false} />
                : <DisplayUploadedFileAsPhoto uploadedFileId={photo.id} shouldDisplayDeleteButton={false} />
              }
            </div>,
          )
          : <p>No photos have been uploaded</p>
        }
      </div>
    </WeaverIonContent>
    { lightboxEnabled &&
      <ImageLightbox
        componentKey={selectedUrl}
        toggler={lightboxToggler}
        imageUrls={images.sort((a,b) => DateTime.fromISO(a.updatedAt).toMillis() - DateTime.fromISO(b.updatedAt).toMillis()).map(r => r.url)}
        selectedImageUrl={selectedUrl}
      />
    }
  </>
}

export default ScreenWorkHistory
