import React, { useContext, useEffect } from 'react'
import { TabDisplay } from '../../../routes'
import { usePageConfigAgainstCurrentRoute } from '../../../routesProvider'

type TabNavBar = {
  /** Current Tab Display setting. */
  tabDisplay: TabDisplay,
  /** Change the Tab Display setting to the value passed. Ignores if the value is the same as the current Tab Display setting.  */
  setTabDisplay: (value: TabDisplay) => void,
}

export const TabNavBarContext = React.createContext<TabNavBar | undefined>(undefined)

export const TabNavBarProvider: React.FC = ({ children }) => {
  const [ tabDisplay, setTabDisplay ] = React.useState<TabDisplay>(TabDisplay.None)

  const state: TabNavBar = {
    tabDisplay: tabDisplay,
    setTabDisplay: value => {
      // Ignore if the tabDisplay is already set
      if (tabDisplay !== value) {
        setTabDisplay(value)
      }
    },
  }

  return (
    <TabNavBarContext.Provider value={state}>
      {children}
    </TabNavBarContext.Provider>
  )
}

export const useTabNavBar = (): TabNavBar => {
  const tabNavBar = useContext(TabNavBarContext)

  if (tabNavBar === undefined) {
    const errorMessage = '[TabNavBarProvider.useTabNavBar] TabNavBar is not yet available'
    console.error(errorMessage, { tabNavBar })
    throw new Error(errorMessage)
  }

  return tabNavBar
}

type UseTabNavBarProps = {
  children: (tabNavBar: TabNavBar) => ReturnType<React.FC>,
}
/**
 * Exposes the `TabNavBar` context value to the children by executing them as a function.
 *
 * This effectively functions as React Context.Consumer, and can be used wherever you need to compose the value of a context.
 * This is especially useful where you are rendering components in the same tree as the provider, e.g. Bootstrapping
 *
 * Example:
 *
 * ```jsx
 * <UseTabNavBar>
 *   {tabNavBar => <>
 *     <p>{tabNavBar.tabDisplay}</p>
 *     {tabNavBar.tabDisplay === TabDisplay.Fixed && <IonButton onClick={() => tabNavBar.setTabDisplay(TabDisplay.None)}>Set to None</IonButton>}
 *     {tabNavBar.tabDisplay === TabDisplay.None && <IonButton onClick={() => tabNavBar.setTabDisplay(TabDisplay.Fixed)}>Set to Fixed</IonButton>}
 *   </>}
 * </UseTabNavBar>
 * ```
 */
export const UseTabNavBar: React.FC<UseTabNavBarProps> = ({ children }) => {
  const tabNavBar = useTabNavBar()
  return children(tabNavBar)
}

type SetTabDisplayProps = {
  tabDisplay: TabDisplay,
}
/**
 * Sets tha `tabDisplay` value in the `TabNavBar` context to the value passed in the props, when the router path changes.
 * Takes into account whether the current route matches this page config.
 * Please note: IonRouter caches old routes, but still renders them, so uses `usePageConfigAgainstCurrentRoute` to work that out.
 *
 * Example:
 *
 * ```jsx
 * <SetTabDisplayOnRouteChange tabDisplay={TabDisplay.Fixed} />
 * ```
 */
export const SetTabDisplayOnRouteChange: React.FC<SetTabDisplayProps> = props => {
  const tabNavBar = useTabNavBar()
  const { isCurrentRoute, routerPathname, pageConfigPath } = usePageConfigAgainstCurrentRoute()

  useEffect(() => {
    if (isCurrentRoute) {
      console.debug(`[TabNavBarProvider.SetTabDisplayOnRouteChange] Setting Tab Display for route '${routerPathname}' with params matched against '${pageConfigPath}' to: ${props.tabDisplay}`)
      tabNavBar.setTabDisplay(props.tabDisplay)
    } else {
      console.debug(`[TabNavBarProvider.SetTabDisplayOnRouteChange] NOT Setting Tab Display for route '${routerPathname}' with params matched against '${pageConfigPath}' as I am not the current route (hidden route rendering)`)
    }
  }, [ routerPathname ])

  return null
}
