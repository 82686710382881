import { IonItem, IonLabel, IonList, IonText } from '@ionic/react'
import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { useGetAllTeamsQuery } from '../../../graphql/generated'
import TeamMemberAvatar from './TeamMemberAvatar'
import Styles from './TeamMembers.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

interface TeamMembersProps {
  teamId: string,
}

const TeamMembers: React.FC<TeamMembersProps> = ({ teamId }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetAllTeamsQuery(gqlDataSource, {}, { enabled: true, refetchOnWindowFocus: false  })

  const weaverFlags = useWeaverFlags()

  if (isFetching) {
    return <LoadingSpinner name="TeamMembers" />
  }

  if (error) {
    console.error(`[TeamMembers] Failed to load Team`, { teamId, data, error })
    return <ErrorBlock name='TeamMembers.failed' onRefresh={refetch}/>
  }

  if (data?.listAllTeams == null) {
    return <ErrorBlock name='TeamMembers.missing'><p>Unable to list all the teams</p></ErrorBlock>
  }

  const team = data?.listAllTeams.filter(team => team.id === teamId)[0]
  const individualConnections = team.individualConnections

  return (
    <IonList className={Styles.ionList}>
      <IonText>
        <h3>Team members</h3>
      </IonText>
      { individualConnections.map(individualConnection => {
        const teamName = `${individualConnection.individual?.givenName}${individualConnection.individual?.familyName}`
        return (
          <>
            { weaverFlags['MW-2443-QoL1-ui-updates']
              ? <IonItem lines="none" key={individualConnection.individual?.id} className={`${Styles.individualConnectionItem} ion-no-padding`} detail={false}>
                <TeamMemberAvatar title={teamName} />
                <IonLabel><h2> {individualConnection.individual?.givenName} {individualConnection.individual?.familyName}</h2></IonLabel>
              </IonItem>
              : <IonItem key={individualConnection.individual?.id} className={`${Styles.deprecatedIndividualConnectionItem} ion-no-padding`} detail={false}>
                <TeamMemberAvatar title={teamName} />
                <IonLabel><h2> {individualConnection.individual?.givenName} {individualConnection.individual?.familyName}</h2></IonLabel>
              </IonItem>
            }
          </>
        )
      })
      }
    </IonList>
  )
}

export default TeamMembers
