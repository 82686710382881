import React from 'react'
import { IonImg } from '@ionic/react'
import Styles from "./WeaverGuarantee.module.scss"
import dollarBadgeIcon from '../../../../assets/icons/dollar-badge-icon.svg'
import { CREATE_PROJECT_CONTRACTORS } from '../../../../common/utils'

const WeaverGuarantee: React.FC = () =>
  <div className={Styles.mainContainer}>
    <div className={Styles.titleSection}>
      <h5>Weaver Guarantee</h5>
      <IonImg src={dollarBadgeIcon} />
    </div>
    <p>{CREATE_PROJECT_CONTRACTORS.guaranteeText}</p>
  </div>

export default WeaverGuarantee
