import React from 'react'
import { useMyContractorProfile } from '../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { AwardBadgePage } from './AwardBagePage'

/**
 * On init of App we want to award any badges that a contractor
 * may be eligible for and have not yet received.
 *
 * It is important that this check only ever runs once as
 * we do now want it to interfere with the standard new work history
 * awarding flow which will perform the same checks.
 */
const AwardContractorAllEligibleBadges: React.FC = ({ children }) => {
  const profileRequestResult = useMyContractorProfile()
  const [ hasAwardedAllBadges, setHasAwardedAllBadges ] = React.useState<boolean | undefined>(undefined)

  const isNotReadyToRenderView = profileRequestResult.getContractorProfileQuery.isLoading || profileRequestResult.getContractorProfileQuery.isFetching
  const isContractor = profileRequestResult.isTeamOfTypeContractor

  const shouldShowAwardBadgePage = [
    isContractor,
    !isNotReadyToRenderView,
    !hasAwardedAllBadges ].every(Boolean)

  return shouldShowAwardBadgePage
    ? <AwardBadgePage setHasAwardedAllBadges={setHasAwardedAllBadges} myContractorProfile={profileRequestResult} />
    : <>{children}</>
}

export default AwardContractorAllEligibleBadges
