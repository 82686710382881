import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useEnvironmentContext } from '../../../api/providers/EnvironmentProvider'
import { useShowProjectGoogleMapLatLngQuery, WeaverAddress } from '../../../graphql/generated'
import ErrorBlock from '../ErrorBlock'
import LoadingSpinner from '../LoadingSpinner'

// Component to return static maps using the google static maps api - https://developers.google.com/maps/documentation/maps-static/start

type StaticGoogleMapProps = {
  location?: string | WeaverAddress | null,
  size: string,
  zoom: string | number,
  scale: number,
}

const StaticGoogleMap: React.FC<StaticGoogleMapProps> = ({ location, size, zoom, scale }) => {

  const environment = useEnvironmentContext()
  const mapBaseURL = `https://maps.googleapis.com/maps/api/staticmap?`

  // This has been hardcoded to var(--ion-color-primary) if that changes CHANGE BELOW
  const markerColour = '0x007367'

  const gooleSourceUrl = (location: string, zoom : string| number, size : string, scale : string| number, markerColour? : string) => {
    const url = new URL(mapBaseURL)
    url.searchParams.set('center', location)
    url.searchParams.set('zoom', zoom.toString())
    url.searchParams.set('size', size)
    url.searchParams.set('key', environment.integrations.google.apiKey)
    url.searchParams.set('scale', scale.toString())
    if (markerColour) { url.searchParams.set('markers', `color:${markerColour}`)}
    return url
  }

  if ( typeof location === 'string') {
    return (<img src={`${gooleSourceUrl(location, zoom, size, scale)}`}/>)
  }
  if ( location && typeof location === 'object') {
    const gqlDataSource = useGraphQLDataSource({ api: 'core' })
    const showProjectGoogleMapLatLangQuery = useShowProjectGoogleMapLatLngQuery(gqlDataSource, { address: location, isExact: true })

    if (showProjectGoogleMapLatLangQuery.isFetching && !showProjectGoogleMapLatLangQuery.data) {
      return <LoadingSpinner name="StaticGoogleMap"/>
    }

    if (showProjectGoogleMapLatLangQuery.error || !showProjectGoogleMapLatLangQuery.data) {
      return <ErrorBlock name='StaticGoogleMap' onRefresh={showProjectGoogleMapLatLangQuery.refetch} />
    }

    const exactLocation = showProjectGoogleMapLatLangQuery.data.getCoordinatesForWeaverAddress

    const marker = `${markerColour}|${exactLocation.lat},${exactLocation.lng}`
    const centerOfMap = `${exactLocation.lat},${exactLocation.lng}`

    return (<img src={`${gooleSourceUrl(centerOfMap, zoom, size, scale, marker)}`}/>)
  }
  else {
    return (<img src={`${gooleSourceUrl('London', zoom, size, scale)}`}/>)
  }
}
export default StaticGoogleMap
