import { useIonRouter, useIonToast } from "@ionic/react"
import { useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../../api/graphql"
import { GetActionableTaskQuery, TaskStatus, useListProjectTasksQuery, useSetTaskStatusMutation } from "../../../graphql/generated"
import { pageConfig_MyProjectTasks, useRouteTo } from "../../../routes"
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useAchievementToast } from "../../../api/providers/AchievementToastProvider/AchievementToastProvider"

type MarkTaskStatusOptions = {
  onSetTaskSuccess?: NonNullable<Parameters<typeof useSetTaskStatusMutation>[1]>["onSuccess"],
  /** By default, the task actioner issues a navigation after complete.
   * When `false`, the mutation will do nothing after completion
   *
   * You can use this to override the default routing behaviour, for tasks that send you to places on complete.
   */
  navigateOnSuccess?: boolean,
}

const useMarkTaskStatusMutation = (
  getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>,
  newStatus: TaskStatus,
  { onSetTaskSuccess, navigateOnSuccess = true }: MarkTaskStatusOptions = {},
) => {
  const datasource = useGraphQLDataSource({ api: 'core' })
  const activeTeam = useMyIndividualActiveTeam()
  const router = useIonRouter()
  const queryClient = useQueryClient()
  const useRouteToMyProjectTasks = useRouteTo(pageConfig_MyProjectTasks.path)

  if (!activeTeam) throw new Error("useMarkTaskStatusMutation requires an active team")

  const fireTaskStatusChanged = useAnalyticsEvent("Task_Status_Changed")
  const setTaskStatusMutation = useSetTaskStatusMutation(datasource, { onSuccess: async (data, variables, context) => {
    const previous = getActionableTaskQuery.data?.getTask
    if (!previous?.id) throw new Error(`[useMarkTaskStatusMutation.${newStatus}] Missing task data`)

    await Promise.all([
      fireTaskStatusChanged({
        projectId: previous.projectId,

        taskId: variables.taskId,
        taskTitle: previous.title,
        taskDueAt: previous.dueAt,
        taskStatus: variables.newStatus,
        taskPreviousStatus: previous.status,
        taskActionableType: previous.actionableType,
        taskActionablePayload: previous.actionablePayload,
      }),
      queryClient.invalidateQueries(useListProjectTasksQuery.getKey({ id: previous.projectId })),
    ])

    onSetTaskSuccess && onSetTaskSuccess(data, variables, context)
  } })

  return async () => {
    if (!getActionableTaskQuery.data?.getTask.id) throw new Error(`[useMarkTaskStatusMutation.${newStatus}] Missing task data`)

    await setTaskStatusMutation.mutateAsync({
      taskId: getActionableTaskQuery.data.getTask.id,
      newStatus,
    })

    if (navigateOnSuccess) {
      if (router.canGoBack()) {
        // HACK: without this initial push(pop), the goBack action seems to jump backward twice
        router.push("", undefined, "pop")
        router.goBack()
      } else {
        useRouteToMyProjectTasks({ projectId: getActionableTaskQuery.data.getTask.projectId }, undefined, undefined, "pop")()
      }
    }
  }
}

export const useMarkTaskCompleteMutation = (getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>, options: Omit<MarkTaskStatusOptions, "onSetTaskSuccess"> = {} ) => {
  const { ["MW-2388-tasks-qol1-part2"]: tasksQol1Part2 } = useWeaverFlags()
  const { present: presentAchievementToast } = useAchievementToast()
  const [ presentIonToast ] = useIonToast()

  return useMarkTaskStatusMutation(getActionableTaskQuery, TaskStatus.Completed, {
    ...options,
    onSetTaskSuccess: () => {
      if (tasksQol1Part2) {
        presentAchievementToast({
          message: getActionableTaskQuery.data?.getTask.title || "Task",
        })
        return
      }

      presentIonToast({
        duration: 3000,
        position: 'bottom',
        icon: checkmarkCircleOutline,
        header: 'Task Complete',
        message: getActionableTaskQuery.data?.getTask.title,
        color: 'success',
      })

    },
  })
}

export const useMarkTaskSkippedMutation = (getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>) =>
  useMarkTaskStatusMutation(getActionableTaskQuery, TaskStatus.Skipped)
