import React, { useCallback } from 'react'
import { useChatRoom } from '../services/ChatRoomProvider'

import Styles from "./ChatFooter.module.scss"
import { TMutationArchiveChatRoomMessageDocumentFn, TMutationCreateChatRoomMessageDocumentFn } from '../../../../../api/services/chat/useRealtimeChatMessages'
import { UploadableFileChip, CreateUploadedFileReturns, UpdateUploadedFileReturns } from '../../../../projects/documents/uploadableFileChip/UploadableFileChip'

type SelectedFilesProps = {
  createChatRoomMessageAttachment: (result: CreateUploadedFileReturns) => ReturnType<TMutationCreateChatRoomMessageDocumentFn>,
  archiveChatRoomMessageDocument: (result: UpdateUploadedFileReturns) => ReturnType<TMutationArchiveChatRoomMessageDocumentFn>,
}

export const SelectedFiles: React.FC<SelectedFilesProps> = ({ createChatRoomMessageAttachment, archiveChatRoomMessageDocument }) => {
  const { newMessageFiles } = useChatRoom()

  if (newMessageFiles?.length === 0) return <></>

  const onUploadedFileCreated = useCallback(async (result: CreateUploadedFileReturns) => {
    await createChatRoomMessageAttachment(result)
  }, [ createChatRoomMessageAttachment ])

  const onUploadedFileArchived = useCallback((result: UpdateUploadedFileReturns) => {
    archiveChatRoomMessageDocument(result)
  }, [ archiveChatRoomMessageDocument ])

  return (
    <div className={Styles.selectedFilesContainer}>
      {
        newMessageFiles.map(
          file => {
            // Use the file's last modified + name as the key since files don't come with an id and it's not recommended the use index in cases when items can be removed.
            const key = `${file.lastModified}_${file.name}`
            return <UploadableFileChip
              key={key}
              file={file}
              onUploadedFileArchived={onUploadedFileArchived}
              onUploadedFileCreated={onUploadedFileCreated}
            />
          },
        )
      }
    </div>
  )
}
