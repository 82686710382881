import React, { useContext } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import SplashScreenSetReady from '../../../common/components/SplashScreen/SplashScreenSetReady'
import { alwaysArray } from '../../../common/utils'
import { firstElement } from '../../../common/utils/codeReadability'
import { GetMyIndividualQuery, useGetMyIndividualQuery } from '../../../graphql/generated'
import { useAnalyticsIdentify } from '../SegmentProvider/hooks'
import { useGraphQLDataSource } from '../../graphql'
import { useLDIdentifyUser } from '../../thirdParty/launchDarkly/useLDIdentifyUser'
import MinimalIndividualProfile from '../../../init/MinimalIndividualProfile'
import { Duration } from 'luxon'

export type MyIndividual = NonNullable<GetMyIndividualQuery['getMyIndividual']>

export const MyIndividualContext = React.createContext<MyIndividual | undefined>(undefined)

const MyIndividualProvider: React.FC = ({ children }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const getMyIndividualQuery = useGetMyIndividualQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject(
      { hour: 1 },
    ).as('milliseconds'),
    refetchOnWindowFocus: true,
    refetchInterval: Duration.fromObject({ minute: 1 }).as('milliseconds'),
    refetchIntervalInBackground: false,
  })

  const myIndividual: MyIndividual | undefined = getMyIndividualQuery.data?.getMyIndividual ?? undefined
  useAnalyticsIdentify(getMyIndividualQuery)

  useLDIdentifyUser(myIndividual)

  return (myIndividual === undefined)
    ? <LoadingSpinnerPage name='myIndividualLoading' />
    : <MyIndividualContext.Provider value={myIndividual}>
      <SplashScreenSetReady>
        <MinimalIndividualProfile>
          {children}
        </MinimalIndividualProfile>
      </SplashScreenSetReady>
    </MyIndividualContext.Provider>
}

export const useMaybeMyIndividual = (): MyIndividual | undefined => useContext(MyIndividualContext)

export const useMyIndividual = (): MyIndividual => {
  const myIndividual = useMaybeMyIndividual()

  if (myIndividual === undefined) {
    const errorMessage = '[MyIndividualProvider.useMyIndividual] MyIndividual is not yet available'
    console.error(errorMessage, { myIndividual })
    throw new Error(errorMessage)
  }

  return myIndividual
}

// NOTE: This is actually your first team, in future we might change this to selecting an active team
export const getActiveTeam = (myIndividual: MyIndividual | undefined) =>
  myIndividual === undefined
    ? myIndividual
    : alwaysArray(myIndividual.teamConnections)
      .map(teamConnection => teamConnection.team)
      .find(firstElement)

export type MyIndividualActiveTeam = NonNullable<MyIndividual['teamConnections']>[number]['team']
export const useMyIndividualActiveTeam = (): MyIndividualActiveTeam | undefined => getActiveTeam(useMyIndividual())

export const useMyIndividualInvalidateCache = () => {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries(useGetMyIndividualQuery.getKey())
}

export default MyIndividualProvider
