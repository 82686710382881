import React, { useEffect, useState } from "react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, useRouteTo } from "../../../../../../routes"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { BudgetRange, useAwardContractorProfileBudgetRangeBadgeMutation } from "../../../../../../graphql/generated"
import { useAnalyticsEvent, useCallAnalyticsGroup } from "../../../../../../api/providers/SegmentProvider/hooks"
import  AnimatedBudgetRangeBadgePage from "../../../ContractorBudgetReferences/AnimatedBudgetRangeBadgePage"
import { useGetAllBadgesToBeAwardedForBudgetRange } from "./hooks"
import { useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { BadgeLevel, badgeLevelWorkHistoryThreshold } from "../BadgeUtils"
import { alwaysArray } from "../../../../../../common/utils"
import { budgetRangeLabels } from "../../budgetRanges.i18n"
import { DateTime } from "luxon"

export const calculatedDescription = (award: BadgeLevel): string  => {

  if (award === BadgeLevel.Bronze) {
    return `You have added 1 past project in the`
  }
  return `You have added ${award === BadgeLevel.Silver ? '3' : '5' } past projects in the`
}

const ContractorProfileBudgetRangeAwardBadgePage: React.FC = () => {
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string, awardedBadge: BadgeLevel }>()

  // We use an ISO-8601 timestamp as a unique key to invalidate the ionic page and retrigger the badge animation
  const [ pageKey, setPageKey ] = useState('')

  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  const profile = useMyContractorProfile()

  const isNotReadyToRenderView = profile.getContractorProfileQuery.isLoading || profile.getContractorProfileQuery.isFetching

  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()
  const badgeLevelsToBeAwarded = useGetAllBadgesToBeAwardedForBudgetRange(budgetRange)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const awardContractorProfileBudgetRangeBadgeMutation = useAwardContractorProfileBudgetRangeBadgeMutation(gqlDataSource)

  const triggerEvent_WorkHistory_References_Badge_Awarded = useAnalyticsEvent('WorkHistory_References_Badge_Awarded')
  const callAnalyticsGroup = useCallAnalyticsGroup()

  const onClick = async () => {

    await awardContractorProfileBudgetRangeBadgeMutation.mutateAsync({ budgetRange, awardedBadge: badgeLevelsToBeAwarded[0], workHistoryId })

    await triggerEvent_WorkHistory_References_Badge_Awarded({
      budgetRange: budgetRange,
      badgeLevel: badgeLevelsToBeAwarded[0],
      badgeLevelWorkHistoryThreshold: badgeLevelWorkHistoryThreshold[badgeLevelsToBeAwarded[0]],
    })

    // We should tell Segment that this group has a new Badge by adding it to the list it already has
    const contractorTeamId = profile.getContractorProfileQuery.data?.getContractorProfile?.id
    const contractorBudgetRanges: BudgetRange[] = [
      ...alwaysArray(profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges).map(badge => badge.budgetRange),
      budgetRange,
    ]

    await callAnalyticsGroup(contractorTeamId, {
      budgetRangeBadges: contractorBudgetRanges,
    })

    /**
     * By invalidating profile cache after awarding the badge we make sure our badgesToBeAwarded array gets updated
     * and also prevents any edge case of a badge being awarded twice
     */
    await invalidateMyContractorProfile()

    // causes IonPage to repaint triggering the badge animation if more than one is to be awarded
    if (badgeLevelsToBeAwarded.length > 1) {
      return setPageKey(DateTime.now().toISO())
    }

    // USE ROOT REPLACE TO AVOID BACK CLICK BRINGING USER BACK TO AWARD SCREEN
    goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId }, undefined, 'root', 'replace')()
  }

  const description = calculatedDescription(badgeLevelsToBeAwarded[0])

  /**
   * If we don't have any badge level (incl. Legacy) to be awarded then redirect user.
   * A user manipulating the URL could be a reason we end up here.
   */
  useEffect(() => {
    if (!badgeLevelsToBeAwarded.length){
      goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId }, undefined, 'root', 'replace')()
    }
  },[])

  return isNotReadyToRenderView
    ? (
      <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.hidden`}>
        <WeaverIonContent>
          {null}
        </WeaverIonContent>
      </WeaverIonPage>
    )
    : (
      <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.show`} key={pageKey}>
        <WeaverIonContent>
          <ResponsiveContentWrapper>
            <AnimatedBudgetRangeBadgePage
              title="You&apos;ve unlocked"
              subtitle={`${budgetRangeLabels[budgetRange]} ${badgeLevelsToBeAwarded[0]}`}
              description={description}
              badgeLevel={badgeLevelsToBeAwarded[0]}
              actionButtonProps={{
                children: <>Continue</>,
                expand: 'block',
                size: 'large',
              }}
              budgetRange={budgetRange}
              onClick={onClick}
            />
          </ResponsiveContentWrapper>
        </WeaverIonContent>
      </WeaverIonPage>
    )
}

export default ContractorProfileBudgetRangeAwardBadgePage
