import { useEffect } from "react"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { useIonRouter } from "@ionic/react"

/**
 * Component that listens for url open events for the app . This handles both custom URL scheme links as well
 * as URLs your app handles - in the format appId://slug (e.g.: build.weaver.app://chats/6c90e7f5-74df-4171-9b84-b40612bd720e).
 */
const AppUrlListener: React.FC = () => {
  const IsUrlFromWeaverApp = async (url: string) => {
    const info = await App.getInfo()
    return url.startsWith(`${info.id}://`)
  }

  const ionRouter = useIonRouter()
  useEffect(() => {
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      const { url } = event
      // url follows the pattern appId://destinationUrl. For example: if build.weaver.app is the appId
      // then a valid url to take a user to a specific chat would look like build.weaver.app://chats/6c90e7f5-74df-4171-9b84-b40612bd720e.
      const isUrlValid = await IsUrlFromWeaverApp(url)
      if (isUrlValid) {
        const destinationSlug = url.split(':/').pop() // Following the previous example, at this point destinationUrl would be chats/6c90e7f5-74df-4171-9b84-b40612bd720e
        if (destinationSlug) {
          ionRouter.push(destinationSlug)
        }
      }
    })
  }, [])

  return null
}

export default AppUrlListener
