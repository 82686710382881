import { isPossiblePhoneNumber } from "react-phone-number-input"
import { z } from "zod"
import { ProgressionStatus, ProjectType, TeamType } from "../../../../../graphql/generated"
import { zWeaverAddressInput, zMoney } from "../../../../../graphql/zod"

export const zWorkHistoryReference = z.object({
  id: z.string().optional(),
  teamType: z.nativeEnum(TeamType).refine(value => [ TeamType.Architect, TeamType.Homeowner ].includes(value), {
    message: 'Work History References can only be provided by Architects and Homeowners',
  }),
  companyName: z.string().optional(),
  givenName: z.string().nonempty('First name is required'),
  familyName: z.string().nonempty('Last name is required'),
  email: z.string().email('Email is Required'),
  phoneNumber: z.string().default(''),
  status: z.nativeEnum(ProgressionStatus).optional(), // Status of the reference
}).superRefine((validated, ctx) => {
  if (validated.teamType === TeamType.Architect && !validated.companyName) {
    ctx.addIssue({
      path: [ "companyName" ],
      code: z.ZodIssueCode.custom,
      message: "Architecture studio is required",
    })
  }
  if (!isPossiblePhoneNumber(validated.phoneNumber)) {
    ctx.addIssue({
      path: [ "phoneNumber" ],
      code: z.ZodIssueCode.custom,
      message: "A valid Phone Number is required",
    })
  }})

type WorkHistoryReference = z.infer<typeof zWorkHistoryReference>

const zWorkHistoryPhoto = z.object({
  id: z.string(),
})

type WorkHistoryPhoto = z.infer<typeof zWorkHistoryPhoto>

export const zWorkHistory = z.object({
  id: z.string().optional(),
  constructionValue: zMoney,
  projectAddress: zWeaverAddressInput.required(),
  projectTypes: z.nativeEnum(ProjectType).array().min(1),
  constructionStartedDate: z.string().optional(),
  constructionCompletedDate: z.string().optional(),
  references: z.array(zWorkHistoryReference).optional(),
  photos: z.array(zWorkHistoryPhoto).optional(),
})

export type WorkHistory = z.infer<typeof zWorkHistory>
