import React, { ComponentProps } from "react"
import { IonHeader } from "@ionic/react"
import { CheckableComponents, useComponentCounter } from "./WeaverStructureChecker"

const WeaverIonHeader: React.FC<ComponentProps<typeof IonHeader>> = (props) => {
  useComponentCounter(CheckableComponents.weaverIonHeader)
  return <IonHeader {...props} />
}

export default WeaverIonHeader
