import React from 'react'
import ChatFooter from './footer/ChatFooter'
import ChatContentWithNewMessagesLines from './ChatContent'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { useChatRoom } from './services/ChatRoomProvider'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { IonButton, IonButtons, IonFooter, IonIcon, IonTitle, IonToolbar, useIonRouter } from '@ionic/react'
import { arrowBackOutline, ellipsisVertical } from 'ionicons/icons'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { pageConfig_ChatRoomMembers, useRouteTo } from '../../../../routes'

import ImageLightbox from './ImageLightbox'
import useImageLightbox from './services/useImageLightbox'
import { DateTime } from 'luxon'
import { usePageTitleOnEnter } from '../../../../common/hooks/usePageTitle'

type ChatRoomProps = {
  goToFilePickerScreen: () => void,
  goToVideoChat: () => void,
}

const ChatRoom: React.FC<ChatRoomProps> = ({ goToFilePickerScreen, goToVideoChat }) => {
  const { chatRoomName, chatRoomId } = useChatRoom()
  const weaverFlags = useWeaverFlags()
  const ionRouter = useIonRouter()

  const goToChatRoomMembersPage = useRouteTo(pageConfig_ChatRoomMembers.path)
  const title = chatRoomName || 'My Chat'
  usePageTitleOnEnter(title)

  const {
    lightboxToggler,
    selectedUrl,
    images,
    openLightbox,
    addImageToLightbox,
  } = useImageLightbox(true)

  const goBack = () => ionRouter.goBack()

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={goBack}>
              <IonIcon icon={arrowBackOutline}/>
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end" >
            <IonButton onClick={ goToChatRoomMembersPage({ chatRoomId: chatRoomId }) } >
              <IonIcon icon={ellipsisVertical}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </WeaverIonHeader>

      {weaverFlags['MW-2144-integrate-video-chat'] && (
        <IonFooter className='ion-no-border ion-padding'>
          <IonButton expand='block' onClick={goToVideoChat}>Go to video chat</IonButton>
        </IonFooter>
      )}

      <ChatContentWithNewMessagesLines openLightbox={openLightbox} addImageToLightbox={addImageToLightbox} />

      <ImageLightbox
        componentKey={selectedUrl}
        toggler={lightboxToggler}
        imageUrls={images.sort((a,b) => DateTime.fromISO(a.updatedAt).toMillis() - DateTime.fromISO(b.updatedAt).toMillis()).map(r => r.url)}
        selectedImageUrl={selectedUrl}
      />

      <ChatFooter goToFilePickerScreen={goToFilePickerScreen} />
    </>
  )
}

export default ChatRoom
