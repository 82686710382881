import React, { useState } from 'react'
import { IonToolbar, IonTitle, IonButton, IonIcon } from '@ionic/react'
import { ProjectMemberRole, TeamType, useAddProjectInviteMutation, useDevListAllProjectsForSelectQuery } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { getActiveTeam, useMyIndividual } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useAuthContext } from '../../../api/providers/AuthProvider'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import { warningOutline } from 'ionicons/icons'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { alwaysArray } from '../../../common/utils'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

const DevSettingsPage: React.FC<{ key: string }> = ({ key, children }) =>
  <WeaverIonPage id={`DevSettingsPage.${key}`} key={key}>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <IonToolbar>
        <IonTitle>Developer Settings: Manage Leads</IonTitle>
      </IonToolbar>
    </WeaverIonHeader>

    <WeaverIonContent className='ion-padding'>
      {children}
    </WeaverIonContent>
  </WeaverIonPage >

const NopeBlock: React.FC = ({ children }) =>
  <DevSettingsPage key="ManageLeadsNope">
    <CentralisedBlock>
      <IonIcon icon={warningOutline} size="large" color="danger" />
      <p>{children}</p>
    </CentralisedBlock>
  </DevSettingsPage>

const ManageLeads: React.FC = () => {
  const auth = useAuthContext()

  const myIndividual = useMyIndividual()
  const activeTeam = getActiveTeam(myIndividual)
  const activeTeamId = activeTeam?.id

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const devListAllProjects = useDevListAllProjectsForSelectQuery(gqlDataSource, {}, { staleTime: 500_000 })
  const createLead = useAddProjectInviteMutation(gqlDataSource)

  const [ selectedProjectId, setSelectedProjectId ] = useState<string | undefined>(undefined)

  // Guards
  if (!(auth.userData?.email ?? '').endsWith('@weaver.build')) {
    return <NopeBlock>You must be a Weaver user.</NopeBlock>
  }

  if (!activeTeamId || activeTeam?.type !== TeamType.Contractor) {
    return <NopeBlock>You must be a Contractor.</NopeBlock>
  }

  if (devListAllProjects.isFetching) {
    return <LoadingSpinnerPage name='ManageLeads.devListProjects' />
  }

  const projects = alwaysArray(devListAllProjects.data?.listAllProjects)
  const cannotCreateLead = !selectedProjectId
  const doCreateLead = async () => {
    if (!selectedProjectId) {
      console.warn('[ManageLeads] Cannot create a lead for a missing Project')
      return
    }

    console.log('[ManageLeads] Creating a project lead for: ', { selectedProjectId, activeTeamId })

    await createLead.mutateAsync({
      projectId: selectedProjectId,
      teamId: activeTeamId,
      projectMemberRole: ProjectMemberRole.CandidateProfessional,
    })

    console.log('[ManageLeads] Done')
  }

  return (
    <DevSettingsPage key="ManageLeads">
      <h3>Add Lead for Project</h3>
      <p>Select from the projects ({projects.length}), add a lead for the currently logged in Contractor.</p>
      <br />
      <select onChange={e => { setSelectedProjectId(e.target.value == 'none' ? undefined : e.target.value) }}>
        <option value="none">Pick a Project</option>
        {projects.map(project => <option key={project.id} value={project.id}>{project.title} 1</option>)}
      </select>
      <br />
      <br />
      <IonButton disabled={cannotCreateLead} onClick={doCreateLead}>Create lead (Add project invite)</IonButton>
    </DevSettingsPage>
  )
}

export default ManageLeads
