import React from 'react'
import { CREATE_PROJECT_CONTRACTORS } from '../../../../common/utils'

const WeaverContractors: React.FC = () =>
  <div>
    <h2>{CREATE_PROJECT_CONTRACTORS.title}</h2>
    <p>{CREATE_PROJECT_CONTRACTORS.description}</p>
    <h3>{CREATE_PROJECT_CONTRACTORS.contractorCount} Main contractors</h3>
  </div>

export default WeaverContractors
