import React from 'react'
import classnames from 'classnames'
import { getFullNameFromIndividual } from './utils'
import ChatMessageDocumentItem from './ChatMessageDocumentItem'
import Styles from "./Chat.module.scss"
import { ChatRoomMessageHasBeenSeenCallback, TChatRoomMessage, TChatRoomMessageDetailsProps, TChatRoomMessageSentByCurrentUserProp } from './ChatRoomTypes'
import ChatMessageImageItem from './ChatMessageImageItem'
import { isFilenameExtensionAnImageExtension } from '../../../../common/utils/files'
import { IsSeenInBrowser } from '../../../../common/components/IsSeenInBrowser'
import { UseImageLightboxReturns } from './services/useImageLightbox'

type LightboxProps = Pick<UseImageLightboxReturns, 'openLightbox' | 'addImageToLightbox'>

type ChatMessageWithDocumentsBubbleProps = TChatRoomMessage & TChatRoomMessageDetailsProps & TChatRoomMessageSentByCurrentUserProp & ChatRoomMessageHasBeenSeenCallback & LightboxProps

const ChatMessageWithDocumentsBubbleDetails: React.FC<TChatRoomMessageDetailsProps> = ({ sentByIndividual }) => {
  return (
    <span className={Styles.chatBottomDetails}>
      <span className={Styles.chatBottomSenderName}>{getFullNameFromIndividual(sentByIndividual)}</span>
    </span>
  )
}

const ChatMessageWithDocumentsBubble: React.FC<ChatMessageWithDocumentsBubbleProps> = ({ id, content, sentByIndividual, documents, sentByCurrentUser, readAt, messageHasBeenSeenCallback, openLightbox, addImageToLightbox }) => {
  return (
    <IsSeenInBrowser isSeenCallback={() => messageHasBeenSeenCallback(id)} isActive={readAt !== undefined}>
      <div className={classnames({ [Styles.chatBubbleParentSent]: sentByCurrentUser, [Styles.chatBubbleParentReceived]: !sentByCurrentUser })}>
        <div key={id} className={classnames({ [Styles.chatMessageWithDocumentsBubble]: true, [Styles.bubbleSent]: sentByCurrentUser, [Styles.bubbleReceived]: !sentByCurrentUser })} >
          {
            documents &&
            documents.map((document) => {

              if (isFilenameExtensionAnImageExtension(document.fileName)){
                return <ChatMessageImageItem key={document.id} {...document} openLightbox={openLightbox} addImageToLightbox={addImageToLightbox} />
              }
              return <ChatMessageDocumentItem key={document.id} {...document} />
            },
            )
          }
          {content}
          <ChatMessageWithDocumentsBubbleDetails sentByIndividual={sentByIndividual} />
        </div>
      </div>
    </IsSeenInBrowser>
  )
}

export default ChatMessageWithDocumentsBubble
