import { useEffect, useState } from "react"
import { UploadedFile } from "../../../../../graphql/generated"

export type LightboxImage = {
  id: UploadedFile['id'],
  url: NonNullable<UploadedFile['signedUrlForDownload']['url']>,
  fileName: UploadedFile['fileName'],
  updatedAt: UploadedFile['updatedAt'],
}

export type UseImageLightboxReturns = {
  lightboxToggler: boolean,
  selectedUrl: string | undefined,
  images: LightboxImage[],
  openLightbox: (imageId: LightboxImage['id']) => void,
  addImageToLightbox: (image: LightboxImage, shouldOpenLightbox?: boolean) => void,
}

const useImageLightbox = (isEnabled: boolean): UseImageLightboxReturns => {

  const [ selectedUrl, setSelectedUrl ] = useState<string | undefined>()
  const [ shouldOpenLightbox, setShouldOpenLightbox ] = useState<boolean>(false)
  const [ images, setImages ] = useState<LightboxImage[]>([])
  const [ lightboxToggler, setLightboxToggler ] = useState(false)

  useEffect(() => {
    if (!isEnabled) return

    if (!shouldOpenLightbox) return

    setLightboxToggler(toggler => !toggler)

    setShouldOpenLightbox(false)

  }, [ shouldOpenLightbox ])

  const openLightbox = (imageId: LightboxImage['id']) => {
    const image = images.find((image) => image.id === imageId)

    if (image){
      setSelectedUrl(image.url)
      setShouldOpenLightbox(true)
    } else {
      setShouldOpenLightbox(false)
      setSelectedUrl(undefined)
    }
  }

  const addImageToLightbox = (image: LightboxImage ) => {
    const existingImage = images.find(image => image.id === image.id)
    if (!existingImage){
      // Add the image
      setImages([ ...images, image ])
    } else {
      // If the image exists, remove it first and the update it
      const imagesExcludingTheSpecifiedImage = images.filter(doc => doc.id !== image.id)
      setImages([ ...imagesExcludingTheSpecifiedImage, image ])
    }
  }

  return {
    lightboxToggler,
    selectedUrl,
    images,
    openLightbox,
    addImageToLightbox,
  }

}
export default useImageLightbox
