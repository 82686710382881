import { CompanySummary, WeaverAddress } from "../../graphql/generated"
import { businessOutline, construct, help } from "ionicons/icons"

type CompanyDetailProperty = string | null | undefined

export const CONSTRUCTION_SIC_CODES = [ '41100','41201','41202','42110','42120','42130','42210','42220','42910','42990','43110','43120','43130','43210','43130','43210','43220','43290','43310','43320','43330','43341','43342','43390','43910','43991','43999' ]

export const ARCHITECT_SIC_CODES = [ '71111','23190','58110','71129' ]

/**
 * Converts the CompanySummary address from Companies House to a WeaverAddress
 * Defaults the postCode to an empty string
 *
 * @param companySummary CompanySummary containing the address fields
 * @returns The address formatted as a WeaverAddress
 */
export const getWeaverAddressFromCompanySummary = (companySummary: CompanySummary): WeaverAddress => ({
  addressLine1: companySummary.regAddress_AddressLine1,
  addressLine2: companySummary.regAddress_AddressLine2,
  county: companySummary.regAddress_County,
  postCode: companySummary.regAddress_PostCode ?? '',
})

/**
 * Extract the SIC Code from the first SIC Code text in the CompanySummary
 * This data is variable with white spacing and contains inconsistencies
 *
 * @param companySummary CompanySummary containing the SIC Code Text 1 - e.g: "34502 - Architecture and Drawing"
 * @returns SIC Code number as a String - e.g: 34502
 */
export const getSicCodeFromCompanySummary = (companySummary: CompanySummary) => companySummary.sicCode_SicText_1?.split(' ')[0]?.trim() ?? ''

export const getIconBySicCode = (sicCode: CompanyDetailProperty) => {
  if (sicCode) {
    return CONSTRUCTION_SIC_CODES.includes(sicCode)
      ? construct
      : ARCHITECT_SIC_CODES.includes(sicCode)
        ? businessOutline
        : help
  }
  return help
}
