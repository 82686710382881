import { IonIcon, IonItem, IonText, useIonRouter } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ensureTrailingSlash } from '../../../common/utils'
import { useGetHomeownerProfileQuery } from '../../../graphql/generated'
import ProfileHeader from '../ProfileHeader'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

const MyHomeownerProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetHomeownerProfileQuery(
    gqlDataSource,
    { homeownerTeamId: myTeam?.id ?? '' },
    {
      staleTime: 5 * 60 * 1000,
      enabled: myTeam !== undefined,
    },
  )

  const weaverFlags = useWeaverFlags()

  const history = useIonRouter()
  const match = useRouteMatch()
  console.debug(`[MyHomeownerProfilePage] Render: `, { myTeam, data })

  if (myTeam === undefined || isFetching) {
    return <LoadingSpinnerPage name="MyHomeownerProfilePage" />
  }

  if (error) {
    console.error(`[MyHomeownerProfilePage] Failed to load Homeowner Profile: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyHomeownerProfilePage' onRefresh={refetch} />
  }

  if (data?.getHomeownerProfile == null) {
    console.warn(`[MyHomeownerProfilePage] Homeowner Profile is missing: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyHomeownerProfilePage'><p>Unable to find the Homeowner Profile.</p></ErrorBlockPage>
  }

  const profile = data.getHomeownerProfile

  return (
    <WeaverIonPage id='MyHomeownerProfilePage'>
      <WeaverIonHeader>
        <ProfileHeader isMyTeam={true} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={event => {
          event.preventDefault()
          const newLocation = `${ensureTrailingSlash(match.url)}edit`
          history.push(newLocation)
        }}>
          <h3>{profile.teamName}</h3>
          <IonIcon className={Styles.editIcon} color='primary' src={createOutline} ></IonIcon>
        </IonItem>

        <IonText className={Styles.lightGrey}>
          <p>Homeowner</p>
        </IonText>
        {!weaverFlags['MW-2482-hide-address-from-homeowner-profile'] &&
          <IonText className={Styles.lightGrey}>
            <p>{formatAddressToSingleLine(profile.propertyAddress)}</p>
          </IonText>
        }
        <TeamMembers teamId={myTeam.id}/>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default MyHomeownerProfilePage
