import { BudgetCategory } from "../../../graphql/generated"

/** @deprecated */
export const budgetCategoryLabels: Record<BudgetCategory, string> = {
  [ BudgetCategory.NoIdea ]: "Flexible",
  [ BudgetCategory.RoughIdea ]: "I am ok with a bit of flexibility",
  [ BudgetCategory.Exact ]: "I have a precise figure in mind",
}

export const budgetCategoryLabelsV2: Record<BudgetCategory, string> = {
  [ BudgetCategory.NoIdea ]: "I am unsure as to what I want built or how much it should cost",
  [ BudgetCategory.RoughIdea ]: "I'm fairly confident about what I'll end up getting built and the final cost",
  [ BudgetCategory.Exact ]: "I know exactly what i want built and how much it should cost",
}
