import React from 'react'
import Styles from "./MyChats.module.scss"
import { ensureTrailingSlash } from '../../../common/utils'
import { useRouteMatch } from 'react-router'
import { IonIcon, IonItem, useIonRouter } from '@ionic/react'
import { ChatRoom } from '../../../graphql/generated'
import { formatDateAsDuration } from '../../../common/utils/date'
import { chatboxEllipsesOutline } from 'ionicons/icons'

const FormattedLastMessageSentAt: React.FC<{ lastMessageSentAt: string, isForChatWithUnreadMessages?: boolean }> = ({ lastMessageSentAt, isForChatWithUnreadMessages = false }) => {
  const formattedDate = formatDateAsDuration(lastMessageSentAt)
  return <div className={`${Styles.newChatDate} ${isForChatWithUnreadMessages && Styles.newChatDateForChatWithUnreadmessages}`}>{formattedDate} </div>
}

type MyChatsRowProps = {
  id: ChatRoom['id'],
  name: ChatRoom['name'],
  lastMessageAt: ChatRoom['lastMessageAt'],
  lastMessageContent?: ChatRoom['_lastMessageContent'],
  myChatRoomStats?: ChatRoom['myChatRoomStats'],
}

export const MyChatsRow: React.FC<MyChatsRowProps> = ({ id, name, lastMessageContent, lastMessageAt, myChatRoomStats }) => {
  const match = useRouteMatch()
  const ionRouter = useIonRouter()

  const goToChat = (event: React.MouseEvent) => {
    event.preventDefault()
    const newLocation = `${ensureTrailingSlash(match.url)}${id}`
    ionRouter.push(newLocation)
  }
  const areUnreadMessagesInChatRoom = myChatRoomStats?.unreadMessagesCount !== 0

  return (
    <div className={Styles.newChatRow}>
      <div className={Styles.circleForIcon}>
        <IonIcon icon={chatboxEllipsesOutline} size='large'/>
      </div>
      <IonItem lines="none" onClick={goToChat}>
        <div className={Styles.newChatContent}>
          <div className={Styles.newChatTopContent}>
            <div className={Styles.newChatName}>{name}</div>
            {lastMessageAt && <FormattedLastMessageSentAt lastMessageSentAt={lastMessageAt} isForChatWithUnreadMessages={areUnreadMessagesInChatRoom} />}
          </div>
          <div className={Styles.newChatBottomContent}>
            <div className={Styles.newChatLastMessagePreview}>{lastMessageContent}</div>
            { areUnreadMessagesInChatRoom &&
            <div className={Styles.newUnreadMessagesCircle}>{myChatRoomStats?.unreadMessagesCount}</div>
            }
          </div>
        </div>
      </IonItem>
    </div>
  )
}
