import React from "react"
import { useChatRoomMembersPresence } from "../../../../../api/services/chat/useChatRoomMembersPresence"
import { TMutationSendChatRoomMessageFn, useRealtimeChatMessages } from "../../../../../api/services/chat/useRealtimeChatMessages"
import useMessageImagesCache from "./useMessageImagesCache"
import useNewMessageDocumentFiles from "./useNewMessageFiles"

type ChatRoom = {
  chatRoomId: string,
  chatRoomName: string | null | undefined ,
  chatRoomMessages: ReturnType<typeof useRealtimeChatMessages>['chatRoomMessages'],
  projectId: string | null | undefined,
  sendChatRoomMessage: TMutationSendChatRoomMessageFn,
  newMessageFiles: File[],
  addFileToNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['addFileToNewMessage'],
  addFilesToNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['addFilesToNewMessage'],
  removeFileFromNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['removeFileFromNewMessage'],
  clearFilesFromNewMessage: ReturnType<typeof useNewMessageDocumentFiles>['clearFilesFromNewMessage'],
  readFromCache: ReturnType<typeof useMessageImagesCache>['readFromCache'],
  saveToCache: ReturnType<typeof useMessageImagesCache>['saveToCache'],
  emptyCache: ReturnType<typeof useMessageImagesCache>['emptyCache'],
  enterChatRoom: ReturnType<typeof useChatRoomMembersPresence>['enterChatRoom'],
  leaveChatRoom: ReturnType<typeof useChatRoomMembersPresence>['leaveChatRoom'],
  getMembersPresentInChatRoom: ReturnType<typeof useChatRoomMembersPresence>['getMembersPresentInChatRoom'],
  latestTypedMessage: React.MutableRefObject<string>,
}

const chatRoomContext = React.createContext<ChatRoom | undefined>(undefined)

export default chatRoomContext
