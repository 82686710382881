export enum WorkHistoryStatus {
  IN_PROGRESS,
  WAITING,
  VERIFIED
}

export const workHistoryStatusLabel: Record<WorkHistoryStatus, string> = {
  [WorkHistoryStatus.IN_PROGRESS]: "In progress",
  [WorkHistoryStatus.WAITING]: "Waiting",
  [WorkHistoryStatus.VERIFIED]: "Verified",
}
