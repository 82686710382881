import { useCallback, useEffect } from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useCreateStripeSessionForLeadPaymentMutation } from '../../../graphql/generated'
import { StripeProduct } from '../../../graphql/generated'
import { useIonAlert } from '@ionic/react'
import { Browser } from '@capacitor/browser'
import { useRedirectBaseURL } from '../../../common/hooks/useRedirectBaseURL'
import { useMyIndividualActiveTeam } from '../../providers/MyIndividualProvider/MyIndividualProvider'

type MakeStripePaymentProps = {
  product: StripeProduct,
  leadId: string,
  onPaymentLoading?: (onPaymentLoadingProps: {leadId: string}) => unknown | Promise<unknown>,
  onPaymentReady?: (onPaymentReadyProps: {leadId: string, checkoutSessionId: string}) => unknown | Promise<unknown>,
}

type UseStripePaymentForLeadPaymentHandlerReturnsReady = {
  makeStripePayment: () => Promise<void>,
  /**
   * determines whether the stripe session has been created and the function is ready to be called
   *
   * As the session is created on mount, this flag will be false for a short time, while the mutation completes in the background.
   */
  isReady: true,
  checkoutSessionId: string,
  checkoutSessionUrl: string,
}

type UseStripePaymentForLeadPaymentHandlerReturnsNotReady = {
  makeStripePayment: () => Promise<void>,
  isReady: false,
  checkoutSessionId: undefined,
  checkoutSessionUrl: undefined,
}

type UseStripePaymentForLeadPaymentHandlerReturns = UseStripePaymentForLeadPaymentHandlerReturnsReady | UseStripePaymentForLeadPaymentHandlerReturnsNotReady

/**
 * Creates a stripe session and returns a function to open the stripe window.
 *
 * The returned function is not ready immediately. Use the isReady property to determine the status of the session.
 *
 * Mounting this hook will cause a stripe session to be created. The stripe session can't be made in response to the click because otherwise, the stripe popup might be blocked by popup blockers
 * First found in safari, see https://weaver.atlassian.net/browse/MW-1884
 */
const useStripePaymentForLeadPaymentHandler = ({
  product,
  leadId,
  onPaymentLoading = () => Promise.resolve(),
  onPaymentReady = () => Promise.resolve(),
}: MakeStripePaymentProps): UseStripePaymentForLeadPaymentHandlerReturns => {
  const [ present ] = useIonAlert()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const createStripeSessionForLeadPayment = useCreateStripeSessionForLeadPaymentMutation(gqlDataSource, {
    onMutate: ({ leadId }) => { onPaymentLoading({ leadId }) },
    onSuccess: ({ createStripeSessionForLeadPayment: { id } }) => { onPaymentReady({ leadId, checkoutSessionId: id }) },
  })

  const activeTeam = useMyIndividualActiveTeam()
  const redirectBaseURL = useRedirectBaseURL('/stripe/')

  const data = createStripeSessionForLeadPayment.data?.createStripeSessionForLeadPayment
  const checkoutSessionUrl = data?.url
  const checkoutSessionId = data?.id
  const isReady = !!checkoutSessionUrl && createStripeSessionForLeadPayment.isSuccess

  useEffect(() => {
    async function createSession() {
      if (!createStripeSessionForLeadPayment.isIdle || !redirectBaseURL) return

      await createStripeSessionForLeadPayment.mutateAsync({
        product,
        leadId,
        redirectBaseURL,
      })
    }
    createSession()

  }, [ createStripeSessionForLeadPayment, redirectBaseURL ])

  const makeStripePayment = useCallback(async () => {
    const teamId = activeTeam?.id
    if (teamId === undefined) {
      console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] No active team!`, activeTeam)
      throw new Error('[useStripePaymentForLeadPaymentHandler] Need to be a member of a team to make payments!')
    }

    try {
      if (checkoutSessionUrl == null) {
        console.debug(`[useStripePaymentForLeadPaymentHandler.makeStripePayment] createStripeSession failed to return url`, data)
        throw new Error('[useStripePaymentForLeadPaymentHandleraymentHandler] Unable to create stripe session!')
      }

      await Browser.open({ url: checkoutSessionUrl })
    } catch (err) {
      if (err instanceof Error) {
        present({
          header: "Unable to take Payment at this time. Please try later.",
          message: err.message,
          buttons: [
            {
              text: "Dismiss",
              role: "cancel",
            },
          ],
        })
      }
    }

  }, [ redirectBaseURL, createStripeSessionForLeadPayment, activeTeam ])

  if (isReady) {
    if (!checkoutSessionId) throw new Error("[useStripePaymentForLeadPaymentHandler]: invalid state, transactionId was missing")
    return {
      isReady,
      makeStripePayment,
      checkoutSessionId,
      checkoutSessionUrl,
    }
  } else {
    return {
      isReady,
      makeStripePayment,
      checkoutSessionId: undefined,
      checkoutSessionUrl: undefined,
    }
  }

}

export default useStripePaymentForLeadPaymentHandler
