import { useIonRouter } from '@ionic/react'
import React from 'react'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { ChatRoom, useGetChatRoomForProjectTeamsQuery } from '../../../../graphql/generated'
import { ChatRoomOptionsTeam } from './types'

type ChatRoomOptionsChatButtonProps = {
  teams: ChatRoomOptionsTeam [],
  projectId: string,
  renderButton: (goToChatRoomCreateIfNecessary: () => void) => React.ReactElement, // use typescript utils.
  createChatRoom: (teams: ChatRoomOptionsTeam[]) => Promise<Pick<ChatRoom, 'id' >>,
}

export const ChatRoomOptionsChatButton: React.FC<ChatRoomOptionsChatButtonProps> = ({ renderButton, teams, projectId, createChatRoom }) => {
  const teamIds = teams.map(team => team.id)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const router = useIonRouter()

  const getChatRoomForProjectTeamsQuery = useGetChatRoomForProjectTeamsQuery(gqlDataSource, { teamIds, projectId }, { refetchOnWindowFocus: true })

  const goToChatRoom = (chatRoomId: string) => {
    router.push(`/chats/${chatRoomId}`)
  }

  const goToChatRoomCreateIfNecessary = async () => {
    if (!getChatRoomForProjectTeamsQuery.data) return

    const existingChatRoomId = getChatRoomForProjectTeamsQuery?.data.getChatRoomForProjectTeams?.id
    if (existingChatRoomId){
      goToChatRoom(existingChatRoomId)
    } else {
      const chatRoomCreated = await createChatRoom(teams)
      goToChatRoom(chatRoomCreated.id)
    }
  }
  return (
    <>{renderButton(goToChatRoomCreateIfNecessary)}</>
  )
}
