import React, { useEffect } from 'react'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useInvite } from './useStoredInvite'
/**
 * A component for listening to invites in storage, and claiming them
 *
 * Automatically claims a stored invite if the team is onboarded
 *
 * Needs to be mounted fairly high up so that the process is triggered early on. (after bootstrapping)
 *
 * Renders null
 */
export const InviteClaimer: React.FC = () => {
  const activeTeam = useMyIndividualActiveTeam()

  const { claimInviteMutation, invite } = useInvite()

  useEffect(() => {
    async function claim() {
      // not onboarded yet
      if (!activeTeam?.type) return

      const inviteId = invite?.inviteId
      if (!inviteId) return

      // do not restart if in progress
      if (claimInviteMutation.isLoading) return

      await claimInviteMutation.mutateAsync({ inviteId })
    }
    claim()
  }, [ activeTeam?.type, invite?.inviteId ])

  return null
}
