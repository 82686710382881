import React, { useContext, useRef } from "react"
import { useChatRoomMembersPresence } from "../../../../../api/services/chat/useChatRoomMembersPresence"
import { useRealtimeChatMessages } from "../../../../../api/services/chat/useRealtimeChatMessages"
import ChatRoomContext from "./ChatRoomContext"
import useMessageImagesCache from "./useMessageImagesCache"
import useNewMessageDocumentFiles from "./useNewMessageFiles"

export const useChatRoom = () => {
  const chatRoom = useContext(ChatRoomContext)

  if (chatRoom === undefined) {
    const errorMessage = '[ChatRoomProvider.useChatRoom] ChatRoom is not yet available'
    console.error(errorMessage, { chatRoom })
    throw new Error(errorMessage)
  }
  return chatRoom
}

type ChatRoomProviderProps = {
  chatRoomId: string,
  chatRoomName: string | null | undefined,
  projectId: string | null | undefined,
}

const ChatRoomProvider: React.FC<ChatRoomProviderProps> = ({ chatRoomId, chatRoomName, projectId, children }) => {
  const {
    chatRoomMessages,
    sendChatRoomMessage,
  } = useRealtimeChatMessages({ chatRoomId })

  const {
    enterChatRoom,
    leaveChatRoom,
    getMembersPresentInChatRoom,
  } = useChatRoomMembersPresence({ chatRoomId })

  const {
    newMessageFiles,
    addFileToNewMessage,
    addFilesToNewMessage,
    removeFileFromNewMessage,
    clearFilesFromNewMessage,
  } = useNewMessageDocumentFiles()

  const {
    readFromCache,
    saveToCache,
    emptyCache,
  } = useMessageImagesCache()

  // The latest message typed by the user. Useful in order to restore the message input field's value when the user comes back to the ChatRoom page.
  const latestTypedMessage = useRef("")

  const chatRoom = {
    /* Chat Messages */
    chatRoomId,
    chatRoomName,
    chatRoomMessages,
    projectId,
    sendChatRoomMessage,
    /*  Chat Members presence */
    enterChatRoom,
    leaveChatRoom,
    getMembersPresentInChatRoom,
    /* Chat Message Files */
    newMessageFiles,
    addFileToNewMessage,
    addFilesToNewMessage,
    removeFileFromNewMessage,
    clearFilesFromNewMessage,
    /* Chat Message Images Cache */
    readFromCache,
    saveToCache,
    emptyCache,
    latestTypedMessage,
  }

  return (
    <ChatRoomContext.Provider value={chatRoom}>
      {children}
    </ChatRoomContext.Provider>
  )
}

export default ChatRoomProvider
