import React from 'react'
import { IonButton, IonList, IonItem, IonListHeader } from '@ionic/react'
import { JwtListItem, useJwtManagerListItems } from '../../../api/services/jwtManager/useJwtManagerListItems'
import { alwaysArray } from '../../../common/utils'

type IonButtonForJwtListItem = Omit<Partial<Parameters<typeof IonButton>[0]>, 'onClick'> & { onClick: (jwtListItem: JwtListItem) => Promise<void> | void }

type JwtListButtonsInProps = {
  withSelectButtonProps?: IonButtonForJwtListItem,
}

const JwtList: React.FC<JwtListButtonsInProps> = ({ withSelectButtonProps }) => {
  const listQueryResult = useJwtManagerListItems()
  const alwaysData = alwaysArray(listQueryResult.data)

  return <>
    <IonList>
      <IonListHeader>
        <h2>Available JWTs</h2>
      </IonListHeader>
      {alwaysData.length == 0
        ? <IonItem><em>You have no saved JWTs. Go to the Developer Settings tab.</em></IonItem>
        : alwaysData.map(each => (
          <IonItem key={each.id}>
            <span style={{ marginRight: '1rem' }}><strong>{each.id}</strong></span>
            <span>{each.savedAt}</span>
            {withSelectButtonProps && <IonButton {...withSelectButtonProps} onClick={() => withSelectButtonProps.onClick(each)}>Select</IonButton>}
          </IonItem>
        ))}
    </IonList>
  </>
}

export default JwtList
