import React from 'react'
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react'
import Styles from "./FAQ.module.scss"

export const FAQ: React.FC = () => {

  return (
    <div className={`ion-padding ${Styles.faqContainer}`}>
      <h3>FAQ</h3>
      <IonAccordionGroup multiple={true}>
        <IonAccordion value="first">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What is Weaver’s involvement going forward?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            The platform allows you to self-drive the tender process, from messaging to project document downloading
          </div>
        </IonAccordion>
        <IonAccordion value="second">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What happens after I accept the lead?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You will be able to <br/>
            <ol>
              <li>Download the project documents and</li>
              <li>Message with the homeowner or architect to begin tendering</li>
            </ol>
          </div>
        </IonAccordion>
        <IonAccordion value="third">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>When can I cancel my subscription?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You can cancel your subscription at any time
          </div>
        </IonAccordion>
        <IonAccordion value="fouth">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>How do I cancel my subscription if I wanted to?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You can cancel your subscription by sending a message to support@weaver.build
          </div>
        </IonAccordion>
        <IonAccordion value="fifth">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What is the tender return date?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You will be able to request and agree one with the homeowner or architect as soon as you have accepted the lead
          </div>
        </IonAccordion>
        <IonAccordion value="sixth">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>How do I recieve leads for larger projects?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You can receive leads for 4 different bands of project sizes. All you need to do is to add past projects in the relevant band to your profiles’ past projects.
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  )
}

export default FAQ
