export const createValidCssClassname = (value: string): string =>
  [
    formatStringFromPascalToCamelCase,
    removeSpaces,
  ].reduce((currentValue, stringProcessingFn: (input: string) => string) => stringProcessingFn(currentValue), value)

export const formatStringFromPascalToCamelCase = (value: string): string =>
  value.charAt(0).toLowerCase() + value.slice(1)

export const removeSpaces = (value: string): string => value.split(' ').join('')

export const isString = (maybeString: unknown): maybeString is string => typeof maybeString === 'string' || maybeString instanceof String

export const onlyTruthyStrings = (maybeStrings: unknown[]): string[] => maybeStrings.filter(isString).filter(string => !!string)

/**
 * Takes a string and return the first character capitalized.
 * Its use case is for UPPERCASED words we receive from our API that need to be capitalized.
 * Note: If the string is already lowercased the CSS method `text-transform: Capitalize` can be used
 * @param {string}
 * @returns {string} ('CAPITALIZE THIS SENTENCE') => 'Capitalize this sentence'
 */
export const capitalizeFirstCharacter = (maybeString: string | null | undefined) => {
  if (typeof maybeString !== 'string' || !maybeString?.length) return ""

  const lowercased = maybeString.split(" ").map((word) => word.toLowerCase()).join(' ')
  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1)
}
