import React, { useCallback, useEffect, useState } from 'react'
import { IonButton,IonIcon, IonToolbar, useIonAlert } from '@ionic/react'
import { UseFormReturn, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { prettifyCompanyName } from '../../companyNameHelpers'
import { useOnboardArchitectTeam } from '../../hooks'
import { useMyIndividualActiveTeam, useMyIndividualInvalidateCache } from '../../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { zWeaverAddressInput } from '../../../../../graphql/zod'
import SuccessNotice from '../../../../../common/components/GeneralSuccessNotice'
import OnboardingSignupSteps from '../../pages/OnboardingSignupSteps'
import CompanyDetailsFind from '../../pages/CompanyDetailsFind'
import CompanyDetailsEdit from '../../pages/CompanyDetailsEdit'
import TitleWithBulletsPage from "../../pages/TitleWithBulletsPage"
import CorporateStructureSelector from '../../../CorporateStructureSelector'
import { CorporateStructureType, TeamType, useSetOnboardingCompleteMutation } from "../../../../../graphql/generated"
import { architectSteps, architectSteps_deprecated } from '../../pages/OnboardingSignupSteps/config'
import PickAddress from '../../../../../common/components/PickAddress'
import shieldCheckmark from "../../../../../assets/icons/shield-checkmark-outline.svg"
import builderPng from '../../../../../assets/images/builder.png'
import { useGraphQLDataSource } from '../../../../../api/graphql'
import { useAnalyticsEvent } from '../../../../../api/providers/SegmentProvider/hooks'
import { useScreens } from '../../../../../common/hooks/useScreens'
import { pageConfig_Projects, useRouteTo, useRouteToOnboardingForTeam } from '../../../../../routes'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import ContactSupportScreen from '../../../../../api/providers/ContactSupportProvider/ContactSupportScreen'
import { firstPartOfPostCode } from '../../../../../common/utils/addresses'
import SingleClickButton from '../../../../../common/components/SingleClickButton'
import IndicatorBar from '../../../../projects/CreateProjectPage/IndicatorBar'
import Styles from "./OnboardingArchitect.module.scss"
import { showIntercom } from '../../../../../common/utils/intercom'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import BrilliantScreen from '../../../../../common/components/BrilliantScreen/BrilliantScreen'
import useTriggerOnCondition from '../../../../../common/hooks/useTriggerOnCondition'
import { useWeaverFlags } from '../../../../../api/thirdParty/launchDarkly/useWeaverFlags'

const zFormSchema = z.object({
  corporateStructure: z.enum([ CorporateStructureType.LimitedCompany, CorporateStructureType.SoleTrader, CorporateStructureType.Partnership, CorporateStructureType.Other ]),
  company: z.object({
    companyName: z.string(),
    companyNumber: z.string(),
    regAddress_CareOf: z.string().nullish(),
    regAddress_POBox: z.string().nullish(),
    regAddress_AddressLine1: z.string().nullish(),
    regAddress_AddressLine2: z.string().nullish(),
    regAddress_PostTown: z.string().nullish(),
    regAddress_County: z.string().nullish(),
    regAddress_PostCode: z.string().nullish(),
    regAddress_Country: z.string().nullish(),
  }),
  companyTradingAs: z.string().min(1, 'Required'),
  companyAddress: zWeaverAddressInput,
})

type FormSchema = z.infer<typeof zFormSchema>
type ArchitectOnboardingFormSubmitFn = Parameters<UseFormReturn<FormSchema>["handleSubmit"]>[0]

enum ArchitectOnboardingScreens {
  /** Remove Introduction property with MW-2462-remove-onboarding-page */
  Introduction = "Introduction",
  SignupSteps = "SignupSteps",
  /** Remove SignpostCompanyDetails property with MW-2462-remove-onboarding-page */
  SignpostCompanyDetails = "SignpostCompanyDetails",
  SelectCorporateStructure = "SelectCorporateStructure",
  FindCompanyDetails = "FindCompanyDetails",
  EditCompanyDetails = "EditCompanyDetails",
  ConfirmCompanyAddress = "ConfirmCompanyAddress",
  Finished = "Finished",
  UnsupportedCorporateStructure = "UnsupportedCorporateStructure",
}

const OnboardingArchitect: React.FC = () => {
  const { register, trigger, setValue, handleSubmit, getValues, resetField, clearErrors , formState: { errors } } = useForm<FormSchema>({
    defaultValues: {},
    resolver: zodResolver(zFormSchema),
  })

  const weaverFlags = useWeaverFlags()

  console.debug('[OnboardingArchitect] Render ', { formValues: getValues() })

  const [ Screen, activeScreen ] = useScreens<ArchitectOnboardingScreens>({
    init: () => weaverFlags['MW-2462-remove-onboarding-pages'] ? ArchitectOnboardingScreens.SignupSteps : ArchitectOnboardingScreens.Introduction,
    screens: {
      Introduction: {
        render: useCallback(({ useChangeScreen, nextScreen }) => {
          const changeToNextScreen = useChangeScreen(nextScreen)
          const switchToOnboardingTeam = useRouteToOnboardingForTeam()

          return <>
            <WeaverIonContent>
              <SuccessNotice
                caption={weaverFlags['MW-2410-copy-changes-march'] ?"We've got you!":"Brilliant!"}
                message="You're almost ready to find builders for your project."
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={switchToOnboardingTeam}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" data-testid="OnboardingArchitect.Introduction.NextButton" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>

              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
      SignupSteps: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)

          /** On Flag removal MW-2462-remove-onboarding-pages: Keep useChangeScreen(nextScreen) pattern after screen removal.
           *  We will remove the screen and the property from the enum and maintain the flow as designed.
          */
          const changeToNextScreen = weaverFlags['MW-2462-remove-onboarding-pages']
            ? useChangeScreen(ArchitectOnboardingScreens.SelectCorporateStructure)
            : useChangeScreen(nextScreen)

          const switchToOnboardingTeam = useRouteToOnboardingForTeam()

          const handleBackClick = () => {
            weaverFlags['MW-2462-remove-onboarding-pages']
              ? switchToOnboardingTeam()
              : changeToPreviousScreen()
          }
          return <>
            <WeaverIonContent>
              <OnboardingSignupSteps
                caption="Sign up to Weaver"
                message="Complete all tasks and start finding builders for your projects."
                steps={weaverFlags['MW-2468-onboarding-improvements'] ? architectSteps : architectSteps_deprecated}
                currentPageIndex={1}
                goToNextPage={changeToNextScreen}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={handleBackClick}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
      SignpostCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          return <>
            <WeaverIonContent>
              <TitleWithBulletsPage
                title='Next enter your company details'
                imgProps={{
                  src: builderPng,
                }}
                bullets={[
                  { description: 'Find your company name on Companies House Services', icon: shieldCheckmark },
                  { description: 'Confirm a trading name that clients on Weaver will see', icon: shieldCheckmark },
                ]}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={changeToNextScreen}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
      SelectCorporateStructure: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const changeToContactSupport = useChangeScreen(ArchitectOnboardingScreens.UnsupportedCorporateStructure)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger('corporateStructure')
            if (!isValid) return

            const corporateStructure = getValues('corporateStructure')
            const contactSupport = corporateStructure !== CorporateStructureType.LimitedCompany

            if (contactSupport) {
              changeToContactSupport()
            } else {
              changeToNextScreen()
            }
          }

          return <>
            <WeaverIonContent>
              <CorporateStructureSelector
                value={getValues("corporateStructure")}
                setValue={async corporateStructure => {
                  if (corporateStructure) {
                    setValue("corporateStructure", corporateStructure)
                    await trigger([ 'corporateStructure' ])
                  } else {
                    resetField('corporateStructure')
                  }
                }}
                hasError={errors.corporateStructure !== undefined}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField ]),
      },

      FindCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'company', 'companyTradingAs' ])
            if (!isValid) return

            // Overwrite the address with what we have from companies house
            // (even if there's already an address as they've gone back in the screens)
            const selectedCompany = getValues('company')
            setValue('companyAddress', {
              careOf: selectedCompany.regAddress_CareOf,
              poBox: selectedCompany.regAddress_POBox,
              addressLine1: selectedCompany.regAddress_AddressLine1,
              addressLine2: selectedCompany.regAddress_AddressLine2,
              postTown: selectedCompany.regAddress_PostTown,
              county: selectedCompany.regAddress_County,
              postCode: selectedCompany.regAddress_PostCode ?? '',
              country: selectedCompany.regAddress_Country,
            })

            changeToNextScreen()
          }

          const resetCompanyDetailsState = () => {
            resetField('company')
            resetField('companyTradingAs')
          }

          const clearCompanyDetailErrors = () => clearErrors([ 'company', 'companyTradingAs' ])

          /* To ensure when navigating between screens we reset company info
           this is so when a user clicks back a reset is performed */
          useEffect(() => {
            resetCompanyDetailsState()
          }, [])

          return <>
            <WeaverIonContent scrollY={false}>
              <IndicatorBar currentPageIndex={1} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <CompanyDetailsFind
                value={getValues("company")}
                setValue={company => {
                  if (company) {
                    setValue("company", company)
                    setValue("companyTradingAs", prettifyCompanyName(company.companyName))
                  } else {
                    resetCompanyDetailsState()
                  }
                }}
                hasError={errors.companyTradingAs !== undefined || errors.company !== undefined}
                clearErrors={clearCompanyDetailErrors}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField, errors, prettifyCompanyName ]),
      },

      EditCompanyDetails: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
          const onboardArchitectTeamFn = useOnboardArchitectTeam()

          const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('Onboarding_Company_Address_Selected')

          const [ present ] = useIonAlert()

          /** On Flag removal MW-2462-remove-onboarding-pages: Keep useChangeScreen(nextScreen) pattern after screen removal.
           *  We will remove the screen and the property from the enum and maintain the flow as designed.
          */
          const changeToNextScreen = weaverFlags['MW-2462-remove-onboarding-pages']
            ? useChangeScreen(ArchitectOnboardingScreens.Finished)
            : useChangeScreen(nextScreen)

          const clickNextIfFieldIsValid = async () => {
            const isValid = await trigger([ 'companyTradingAs' ])
            if (!isValid) return

            changeToNextScreen()
          }

          const submitIfFieldIsValid: ArchitectOnboardingFormSubmitFn = async (data) => {

            const { companyTradingAs, companyAddress, company: { companyNumber, companyName }  } = data

            await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
              postCode: companyAddress.postCode,
              city: companyAddress.postTown ?? undefined,
              firstPartOfPostCode: firstPartOfPostCode(companyAddress.postCode) ?? undefined,
            })

            try {
              const onboardResult = await onboardArchitectTeamFn({
                companyNumber: companyNumber,
                companyRegisteredName: companyName,
                companyTradingAs: companyTradingAs,
                companyAddress: companyAddress,
              })

              await setOnboardingCompleteMutation.mutateAsync({
                teamId: onboardResult.team.id,
                isOnboardingComplete: true,
              })

              changeToNextScreen()
            } catch (e) {
              if (e instanceof Error) {
                present({
                  header: "Failed to Onboard Architect Team",
                  message: e.message,
                  buttons: [
                    {
                      text: "Dismiss",
                      role: 'cancel',
                    },
                  ],
                })
              }
            }
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={2} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <CompanyDetailsEdit
                company={getValues("company")}
                register={{
                  companyTradingAs: register('companyTradingAs'),
                }}
                errors={errors}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                {weaverFlags['MW-2462-remove-onboarding-pages']
                  ? <SingleClickButton slot="end" onClick={handleSubmit(submitIfFieldIsValid)}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
                  : <IonButton slot="end" onClick={clickNextIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
                }
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, getValues, register, errors ]),
      },

      ConfirmCompanyAddress: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          {/** @deprecated screen by MW-2462-remove-onboarding-pages */}

          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const [ isSubmitting, setSubmitting ] = useState(false)
          const [ currentAddress, setCurrentAddress ] = useState(getValues('companyAddress'))

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const setOnboardingCompleteMutation = useSetOnboardingCompleteMutation(gqlDataSource)
          const onboardArchitectTeamFn = useOnboardArchitectTeam()

          const triggerEventWorkHistoryReferencesAddedPastProjectAddress = useAnalyticsEvent('Onboarding_Company_Address_Selected')

          const [ present ] = useIonAlert()

          const submitIfFieldIsValid = async () => {
            const isValid = await trigger()
            if (!isValid) return

            setSubmitting(true)
            const value = getValues('companyAddress')

            await triggerEventWorkHistoryReferencesAddedPastProjectAddress({
              postCode: value.postCode,
              city: value.postTown ?? undefined,
              firstPartOfPostCode: firstPartOfPostCode(value.postCode) ?? undefined,
            })

            const data = getValues()

            try {
              const onboardResult = await onboardArchitectTeamFn({
                companyNumber: data.company.companyNumber,
                companyRegisteredName: data.company.companyName,
                companyTradingAs: data.companyTradingAs,
                companyAddress: data.companyAddress,
              })

              await setOnboardingCompleteMutation.mutateAsync({
                teamId: onboardResult.team.id,
                isOnboardingComplete: true,
              })

              changeToNextScreen()
            } catch (e) {
              if (e instanceof Error) {
                present({
                  header: "Failed to Onboard Architect Team",
                  message: e.message,
                  buttons: [
                    {
                      text: "Dismiss",
                      role: 'cancel',
                    },
                  ],
                })
              }
            }
            setSubmitting(false)
          }

          return <>
            <WeaverIonContent>
              <IndicatorBar currentPageIndex={3} totalPages={4} maxAvailableIndex={4} onNavigate={() => null} />
              <PickAddress
                title="Enter your Company Address"
                value={currentAddress}
                hideAutocomplete={true}
                hideFields={[ 'careOf', 'poBox' ]}
                setValue={companyAddress => {
                  if (companyAddress) {
                    setValue("companyAddress", companyAddress)
                    setCurrentAddress(companyAddress)
                    clearErrors('companyAddress')
                  } else {
                    resetField('companyAddress')
                  }
                }}
                hasError={errors.companyAddress}
                disabled={isSubmitting}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToPreviousScreen}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <SingleClickButton slot="end" onClick={submitIfFieldIsValid}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ trigger, getValues, setValue, resetField, errors ]),
      },

      Finished: {
        render: useCallback(() => {
          const triggerEventOnboardingCompanyComplete = useAnalyticsEvent('Onboarding_Company_Info_Complete')
          const useMyIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()

          const myIndividualActiveTeam = useMyIndividualActiveTeam()
          const routeToProjects = useRouteTo(pageConfig_Projects.path)
          const goToProjectsAfterActiveTeam = useTriggerOnCondition(
            routeToProjects({}, undefined, 'root', 'replace'),
            !!myIndividualActiveTeam,
          )

          return <>
            <WeaverIonContent className={weaverFlags['MW-2468-onboarding-improvements'] ?  Styles.onboardingFinishScreen : ""}>
              <BrilliantScreen
                title={weaverFlags['MW-2468-onboarding-improvements']
                  ? 'Start uploading your projects!'
                  : weaverFlags['MW-2410-copy-changes-march']
                    ? "Nice one"
                    : "Amazing!"}
                description={weaverFlags['MW-2468-onboarding-improvements']
                  ? ''
                  : weaverFlags['MW-2410-copy-changes-march']
                    ? "We've retrieved your company details from Companies House"
                    : "Thank you for adding your company information."
                }
                actionButtonProps={{
                  onClick: async () => {
                    const formValues = getValues()

                    await triggerEventOnboardingCompanyComplete({
                      teamType: TeamType.Architect,
                      claimedTeamNumber: formValues.company.companyNumber,
                      claimedTeamName: formValues.companyTradingAs,
                      claimedTeamType: TeamType.Architect,
                    })

                    await useMyIndividualInvalidateCacheFn()
                    goToProjectsAfterActiveTeam()
                  },
                  children: <>Continue</>,
                }}
              />
            </WeaverIonContent>
          </>
        }, []),
      },

      UnsupportedCorporateStructure: {
        render: useCallback(({ useChangeScreen }) => {
          const changeToSelectCorporateStructure = useChangeScreen(ArchitectOnboardingScreens.SelectCorporateStructure)
          return <>
            <WeaverIonContent className='ion-no-padding'>
              <ContactSupportScreen/>
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" onClick={changeToSelectCorporateStructure}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <IonButton slot="end" onClick={showIntercom}>Next <IonIcon icon={arrowForwardOutline} /></IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, []),
      },
    },
  })

  return (
    <WeaverIonPage id={`OnboardingArchitect.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default OnboardingArchitect
