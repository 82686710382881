import React from 'react'
import { intersperseDelimiterInArray } from '../../utils/arrays'
import { dateResolutionLabels } from './dateResolution.i18n'
import { DateResolution, WeaverDatePickerScreens } from './types'
import { ScreenComponentProps } from './WeaverDatePicker'
import classNames from 'classnames'

import Styles from './WeaverDatePicker.module.scss'

const screenResolution: Record<WeaverDatePickerScreens, DateResolution> = {
  PickYear: DateResolution.Year,
  PickMonth: DateResolution.Month,
  PickDay: DateResolution.Day,
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const HeaderYear: React.FC<ScreenComponentProps> = ({ returnToYearScreen, selectedYear, activeScreen }) => {
  const divClassName = classNames({
    [Styles.datePickerHeadingSelectorButtonActive]: activeScreen === WeaverDatePickerScreens.PickYear,
    [Styles.datePickerHeadingSelectorButton]: true })

  const spanClassName = classNames({
    [Styles.currentDateActiveYear]: activeScreen === WeaverDatePickerScreens.PickYear,
    [Styles.currentDateBlank]: true,
  })

  return (
    <div className={divClassName}>
      {selectedYear === undefined && <span className={spanClassName}>YYYY</span>}
      {selectedYear !== undefined && <span className={spanClassName} onClick={returnToYearScreen}>{selectedYear}</span>}
    </div>
  )
}

const HeaderMonth: React.FC<ScreenComponentProps> = ({ returnToMonthScreen, selectedMonth, activeScreen }) => {
  const divClassName = classNames({
    [Styles.datePickerHeadingSelectorButtonActive]: activeScreen === WeaverDatePickerScreens.PickMonth,
    [Styles.datePickerHeadingSelectorButton]: true })

  const spanClassName = classNames({
    [Styles.currentDateActiveYear]: activeScreen === WeaverDatePickerScreens.PickMonth,
    [Styles.currentDateBlank]: true,
  })

  return (
    <div className={divClassName}>
      {selectedMonth === undefined && <span className={spanClassName}>MMM</span>}
      {selectedMonth !== undefined && <span className={spanClassName} onClick={returnToMonthScreen}>{months[selectedMonth - 1]}</span>}
    </div>
  )
}

const HeaderDay: React.FC<ScreenComponentProps> = ({ selectedDay, activeScreen }) => {
  const divClassName = classNames({
    [Styles.datePickerHeadingSelectorButtonActive]: activeScreen === WeaverDatePickerScreens.PickDay,
    [Styles.datePickerHeadingSelectorButton]: true })

  const spanClassName = classNames({
    [Styles.currentDateActiveYear]: activeScreen === WeaverDatePickerScreens.PickDay,
    [Styles.currentDateBlank]: true,
  })

  return (
    <div className={divClassName}>
      {selectedDay === undefined && <span className={spanClassName}>DD</span>}
      {selectedDay !== undefined && <span className={spanClassName}>{String(selectedDay).padStart(2, '0')}</span>}
    </div>
  )
}

const WeaverDatePickerHeading: React.FC<ScreenComponentProps> = props => {
  const switcherElements: JSX.Element[] = intersperseDelimiterInArray(
    <span className={Styles.delimiter}>/</span>,
    [
      [ DateResolution.Day, DateResolution.Month, DateResolution.Year ].includes(props.resolution)
        ? <HeaderYear {...props}/>
        : undefined,
      [ DateResolution.Day, DateResolution.Month ].includes(props.resolution)
        ? <HeaderMonth {...props} />
        : undefined,
      [ DateResolution.Day ].includes(props.resolution)
        ? <HeaderDay {...props} />
        : undefined,
    ])

  return <div className={Styles.datePickerHeading}>
    <p className={Styles.datePickerHeadingPrompt}>Select a {dateResolutionLabels[screenResolution[props.activeScreen]]}</p>
    <div className={Styles.datePickerHeadingLine} />
    <div className={Styles.datePickerHeadingSelector}>
      {switcherElements}
    </div>
  </div>
}

export default WeaverDatePickerHeading
