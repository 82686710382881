import { Directory, Filesystem } from "@capacitor/filesystem"
import { useEffect, useState } from "react"

// Folder within the app's internal directory, for now we use the root folder
const DEFAULT_FOLDER = ''
// The app's documents directory
const APP_DOCUMENT_DIRECTORY = Directory.Documents

export type FileName = {
  name: string,
}

export type FileMetaInformation = {
  type: string,
  size: number,
  modifiedTime: number,
  creationTime?: number,
}

export type StoredFile = FileName & FileMetaInformation

type UseDeviceStorageReturns = {
  files: StoredFile[],
}

/**
 * Custom hook to access the files in the app's internal folder.
 */
const useDeviceStorage = (): UseDeviceStorageReturns => {
  const [ files, setFiles ] = useState<StoredFile[]>([])

  useEffect(() => {
    const loadFilesFromDeviceInternalDirectory = async () => {
      const folderContents = await Filesystem.readdir({
        directory: APP_DOCUMENT_DIRECTORY,
        path: DEFAULT_FOLDER,
      })

      const storedFilePromises = folderContents.files.map(async name => {
        const fileMetaInformation: FileMetaInformation = await Filesystem.stat({
          directory: APP_DOCUMENT_DIRECTORY,
          path: name,
        }).then(statResult => ({
          size: statResult.size,
          type: statResult.type,
          modifiedTime: statResult.mtime,
          creationTime: statResult.ctime,
        }))

        return {
          name,
          ...fileMetaInformation,
        }
      })

      setFiles(await Promise.all(storedFilePromises))
    }

    loadFilesFromDeviceInternalDirectory()
  }, [])

  return {
    files,
  }
}

export default useDeviceStorage
