import { alwaysArray, isSomething } from "../../../../../../common/utils"
import { BudgetRange } from "../../../../../../graphql/generated"
import { getAllNonArchivedWorkHistoriesByBudgetRange, getWorkHistoryById, useMyContractorProfile } from "../../datasource"
import { BadgeLevel, getAwardedBadgeLevelsInBudgetRange, getBadgeLevelsThatAreEligibleToBeAwarded } from "../BadgeUtils"

type maybeWorkHistoryId = string | undefined

/**
 * Checks if in budget range the legacy or current work history
 * has photos and a reference. If yes then return true else false
 */
const doesProfileQualifyForBadge = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange, workHistoryId: maybeWorkHistoryId = ""): boolean => {
  console.debug(`[doesProfileQualifyForBadge] Checking: `, { profile, budgetRange })

  const workHistory = getWorkHistoryById(profile, workHistoryId)

  if (workHistory === undefined) {
    console.debug(`[doesProfileQualifyForBadge] No work history, no badge`)
    return false
  }

  const hasReferences = alwaysArray(workHistory.references).length > 0
  const hasPhotos = alwaysArray(workHistory.photos).length > 0

  console.debug(`[doesProfileQualifyForBadge] Result === ${hasReferences && hasPhotos}: `, { workHistory, hasReferences, hasPhotos })
  return hasReferences && hasPhotos
}

/**
 * This prevents us from awarding badges for incomplete work history
 */
export const getAllBadgeEligibleHistories = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange) => {
  const allNonArchivedHistories = getAllNonArchivedWorkHistoriesByBudgetRange(profile, budgetRange)
  return alwaysArray(allNonArchivedHistories).filter((project) => alwaysArray(project?.photos).length > 0 && alwaysArray(project?.references).length > 0)
}

export const useShouldAwardContractorProfileBudgetRangeBadge = (budgetRange: BudgetRange, workHistoryId = ""): boolean => {
  const profile = useMyContractorProfile()
  return doesProfileQualifyForBadge(profile, budgetRange, workHistoryId)
}

/**
 * Retrieve all our badges that should be awarded to the user across all budget ranges.
 */
export const useGetAllBadgesToBeAwarded = (): Record<BudgetRange, BadgeLevel[]> => {
  const profile = useMyContractorProfile()

  const allAwardedBadgeLevelsInAllBudgetRanges = profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges

  const getBadgesLevelsToBeAwarded = (budgetRange: BudgetRange) => {
    const eligibleWorkHistories = getAllBadgeEligibleHistories(profile, budgetRange)

    const awardedBadgeLevels = getAwardedBadgeLevelsInBudgetRange(allAwardedBadgeLevelsInAllBudgetRanges, budgetRange).filter(isSomething)
    const badgeLevelsThatShouldBeAwarded: BadgeLevel[] = getBadgeLevelsThatAreEligibleToBeAwarded(eligibleWorkHistories)
    return badgeLevelsThatShouldBeAwarded.filter((item) => awardedBadgeLevels.indexOf(item) == -1)
  }

  return {
    F30T100: getBadgesLevelsToBeAwarded(BudgetRange.F30T100),
    F100T500: getBadgesLevelsToBeAwarded(BudgetRange.F100T500),
    F500T1000: getBadgesLevelsToBeAwarded(BudgetRange.F500T1000),
    F1000T3000: getBadgesLevelsToBeAwarded(BudgetRange.F1000T3000),
  }
}

/**
 * A function that gets an array of badge levels that should be awarded to a user for a specific budget range.
 * Allows for backfilling if new levels are introduced.
 */
export const useGetAllBadgesToBeAwardedForBudgetRange = (budgetRange: BudgetRange) => useGetAllBadgesToBeAwarded()[budgetRange]

