import React from "react"
import { IonGrid, IonRow } from "@ionic/react"
import GenericSelectorList from "../../common/components/GenericSelectorList"
import Styles from './../../common/components/GenericSelector.module.scss'
import IndicatorBar from "../projects/CreateProjectPage/IndicatorBar"
import { corporateStructureTypeLabels } from "./team/onboarding.i18n"
import { CorporateStructureType } from "../../graphql/generated"
import { getGenericSelectorOptionsForEnum } from "../../common/components/GenericSelectorList/helpers"

type CorporateStructureSelectorProps = {
  value: CorporateStructureType,
  setValue: (value?: CorporateStructureType) => Promise<void> | void,
  hasError: boolean,
}

const CorporateStructureSelector: React.FC<CorporateStructureSelectorProps> = ({ value, setValue, hasError }) => {

  const indicatorBarProps = {
    currentPageIndex: 0,
    totalPages: 4,
    maxAvailableIndex: 5,
  }

  return (
    <>
      <IndicatorBar {...indicatorBarProps} onNavigate={() => null} />
      <div className={Styles.container}>
        <div className={Styles.slideContainer}>
          <IonGrid className={Styles.contentContainer}>
            <h3 className="ion-padding-bottom ion-text-sm-center ion-padding-start"> What&apos;s your corporate structure?</h3>
            <IonRow className={Styles.item}>
              <GenericSelectorList
                options={getGenericSelectorOptionsForEnum(CorporateStructureType, corporateStructureTypeLabels)}
                multiSelect={false}
                selected={value}
                onSelect={setValue}
              />
              <p className={`${Styles.errorMessage} ion-text-sm-center`}>{hasError && 'You must select a corporate structure.'}</p>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  )
}

export default CorporateStructureSelector
