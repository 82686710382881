import React from 'react'
import { useGetStripeProductConfigsQuery, useShowLeadQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { alwaysArray } from '../../../common/utils'
import { FeeOption } from './ContractorLeadAcceptorPage'
import { budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionStripeProduct, getBudgetRangeByMoney } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import PaymentPlanPickerPage from './PaymentPlanPickerPage'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { moneyToText } from '../../../common/utils/currency'
import { Duration } from 'luxon'
import { pageConfig_Projects_AcceptLead_SingleLeadPayment, useRouteTo } from '../../../routes'
import useRedirectToStripePaymentForLeadPaymentHandler from '../../../api/thirdParty/stripe/useRedirectToStripePaymentHandler'
import { useMyContractorProfile } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import FreeLeadAcceptancePage from './FreeLeadAcceptancePage'
import { shouldPayForLead } from '../common'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'

const DisplayPaymentPlanPicker: React.FC = () => {
  const { leadId } = useParamsFromPageConfig<{ leadId: string }>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const useShowLeadResponse = useShowLeadQuery(gqlDataSource , { id: leadId }, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
  })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
  })

  const budgetValueAsMoney = useShowLeadResponse.data?.getLead.budgetValue
  const lead = useShowLeadResponse.data?.getLead
  const leadBudgetRange = getBudgetRangeByMoney(budgetValueAsMoney)    // NOTE: `leadBudgetAmountInPennies` should never be undefined/null because of the guard

  const { getContractorProfileQuery: useGetContractorProfileResponse } = useMyContractorProfile()
  const contractorProfile = useGetContractorProfileResponse.data?.getContractorProfile

  const makeStripePayment = useRedirectToStripePaymentForLeadPaymentHandler({ leadId, source: 'DisplayPaymentPlanPicker' })
  const routeToSingleLeadPayment = useRouteTo(pageConfig_Projects_AcceptLead_SingleLeadPayment.path)

  const triggerEvent_SingleLeadPayment_Clicked = useAnalyticsEvent("Lead_SingleLeadPayment_Clicked")

  if (useShowLeadResponse.isLoading || useGetContractorProfileResponse.isLoading || getStripeProductConfigsQuery.isLoading) {
    return <LoadingSpinnerPage name="DisplayPaymentPlanPicker" />
  }

  if (useShowLeadResponse.error || useGetContractorProfileResponse.error || getStripeProductConfigsQuery.error || lead == null || contractorProfile == null || leadBudgetRange == null) {
    console.error(`[DisplayPaymentPlanPicker] Failed to load lead and Stripe product data: `, { useShowLeadResponse, useGetContractorProfileResponse, getStripeProductConfigsQuery, budgetValueAsMoney, leadBudgetRange })
    return <ErrorBlockPage name='DisplayPaymentPlanPicker' onRefresh={() => {
      useShowLeadResponse.refetch()
      useGetContractorProfileResponse.refetch()
      getStripeProductConfigsQuery.refetch()
    }} />
  }

  const stripeProductLeadFee = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeLeadFeeStripeProduct[leadBudgetRange])
  const budgetRangeLeadFee = moneyToText(stripeProductLeadFee?.price)

  const stripeProductMonthlySubscription = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeMonthlySubscriptionStripeProduct[leadBudgetRange])
  const budgetRangeMonthlySubscriptionFee = moneyToText(stripeProductMonthlySubscription?.price)

  if (stripeProductLeadFee == null|| budgetRangeLeadFee == null || stripeProductMonthlySubscription == null || budgetRangeMonthlySubscriptionFee == null) {
    console.error(`[DisplayPaymentPlanPicker] Failed to resolve fees: `, { getStripeProductConfigsQuery, stripeProductLeadFee, stripeProductMonthlySubscription })
    return <ErrorBlockPage name='DisplayPaymentPlanPicker'>
      <p>Unable to find the pricing. Please contact Weaver Support.</p>
    </ErrorBlockPage>
  }

  const handleNextClick = async (feeOption: FeeOption) => {
    switch (feeOption) {
      case FeeOption.Subscription: {
        await makeStripePayment(stripeProductMonthlySubscription)
        break
      }
      case FeeOption.PerLead: {
        await triggerEvent_SingleLeadPayment_Clicked({
          projectId: leadId,
          budgetRange: leadBudgetRange,
        })
        routeToSingleLeadPayment({ leadId })()
        break
      }
    }
  }

  console.debug(`[DisplayPaymentPlanPicker] Render: `, {
    leadId,
    useShowLeadResponse,
    getStripeProductConfigsQuery,
    stripeProductLeadFee,
    stripeProductMonthlySubscription,
  })

  return shouldPayForLead(lead, contractorProfile)
    ? (
      <PaymentPlanPickerPage
        leadId={lead.id}
        budgetRange={leadBudgetRange}
        budgetRangeLabel={budgetRangeLabels[leadBudgetRange]}
        stripeProductLeadFee={stripeProductLeadFee}
        stripeProductMonthlySubscription={stripeProductMonthlySubscription}
        handleNextClick={handleNextClick}
      />
    )
    : <FreeLeadAcceptancePage lead={lead} />
}

export default DisplayPaymentPlanPicker
