import React from 'react'
import { getFullNameFromIndividual } from './utils'
import Styles from "./Chat.module.scss"
import { ChatRoomMessageHasBeenSeenCallback, TChatRoomMessage, TChatRoomMessageDetailsProps, TChatRoomMessageSentByCurrentUserProp } from './ChatRoomTypes'
import { IsSeenInBrowser } from '../../../../common/components/IsSeenInBrowser'

type TChatRoomMessageProps = TChatRoomMessage & TChatRoomMessageDetailsProps & TChatRoomMessageSentByCurrentUserProp & ChatRoomMessageHasBeenSeenCallback

const ChatRoomMessageDetails: React.FC<TChatRoomMessageDetailsProps> = ({ sentByIndividual }) => {
  return (
    <span className={Styles.chatBottomDetails}>
      <span className={Styles.chatBottomSenderName}>{getFullNameFromIndividual(sentByIndividual)}</span>
    </span>
  )
}

const ChatMessageBubble: React.FC<TChatRoomMessageProps> = ({ id, content, sentByIndividual, sentByCurrentUser, messageHasBeenSeenCallback, readAt }) => {
  const bubbleParentClass = sentByCurrentUser ? Styles.chatBubbleParentSent : Styles.chatBubbleParentReceived
  const bubbleSentStatusClass = sentByCurrentUser ? Styles.bubbleSent : Styles.bubbleReceived
  return (
    <IsSeenInBrowser isSeenCallback={() => messageHasBeenSeenCallback(id)} isActive={!readAt}>
      <div className={`${bubbleParentClass}`}>
        <div key={id} className={`${Styles.chatBubble} ${bubbleSentStatusClass}`}>
          <div>
            {content}
          </div>
          <ChatRoomMessageDetails sentByIndividual={sentByIndividual} />
        </div>
      </div>
    </IsSeenInBrowser>
  )
}

export default ChatMessageBubble
