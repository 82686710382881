import React, { ComponentProps } from "react"
import { IonBadge, IonIcon } from "@ionic/react"
import { warning } from "ionicons/icons"
import Styles from "./WeaverUnverifiedBadge.module.scss"

type WeaverBadgePros = ComponentProps<typeof IonBadge>

const WeaverUnverifiedBadge: React.FC<WeaverBadgePros> = ({ ...ionBadgeProps }) => <IonBadge color='danger' {...ionBadgeProps}><IonIcon className={Styles.ionIcon} icon={warning} /> Unverified</IonBadge>

export default WeaverUnverifiedBadge
