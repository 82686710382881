import { useEffect, useState } from "react"

/**
 * Calls a trigger function when a condition passes, but only once the tripwire has been tripped.
 *
 * **Example:**
 *
 * You depend on something external (say myIndividual) and
 * you are waiting for it to reach a certain state (such as being in a certain team)
 * when it reaches that state, then you wan to take action (say route to a new page)
 * but only once an action has occured (say clicking a button)
 *
 * **Code:**
 * ```typescript
 * const myIndividualInvalidateCacheFn = useMyIndividualInvalidateCache()
 *
 * const myIndividualActiveTeam = useMyIndividualActiveTeam()
 * const goToProjects = useRouteTo(pageConfig_Projects.path)
 * const goToProjectsAfterActiveTeam = useTriggerOnCondition(
 *   goToProjects({}),
 *   !!myIndividualActiveTeam,
 * )
 *
 * const onClick = async () => {
 *   await myIndividualInvalidateCacheFn()
 *   goToProjectsAfterActiveTeam()
 * }
 * ```
 *
 * @param triggerFn Function to be called when tripped and the condition passes
 * @param condition The condition to wait for to pass
 * @returns Function with trips the wire and causes the condition to be rechecked
 */
const useTriggerOnCondition = (triggerFn: () => unknown, condition: boolean) => {
  const [ isTripped, setTripped ] = useState<boolean>(false) // tripping the tripwire needs to causes a rerender

  useEffect(() => {
    // When I'm both tripped AND the condition passes, then call the trigger function
    if (isTripped && condition) {
      triggerFn()
    }
  }, [ isTripped, condition ])

  // Return the tripwire trigger
  return () => setTripped(true)
}

export default useTriggerOnCondition
