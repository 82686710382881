import React, { useState } from 'react'
import { IonGrid, IonRow, IonCol, IonIcon, IonSpinner, IonButton, useIonModal } from '@ionic/react'
import { cloudDownloadOutline } from 'ionicons/icons'

import Styles from "./Chat.module.scss"
import { formatBytes } from '../../../../common/utils'
import { TChatRoomMessageDocument } from './ChatRoomTypes'
import { downloadUploadedFile, openFile } from '../../../../common/utils/files'
import { ContentDisposition, SignedUrlStatus, useGetUploadedFileQuery } from '../../../../graphql/generated'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { isMobile } from '../../../../common/utils/ionic'
import { Browser } from '@capacitor/browser'
import { DownloadUploadedFileModal, DownloadUploadedFileModalStyles } from '../../../../common/components/DownloadUploadedFileModal'
import { OpenUploadedFileModal, OpenUploadedFileModalStyles } from '../../../../common/components/OpenUploadedFileModal'

const ChatMessageDocumentItem: React.FC<TChatRoomMessageDocument> = ({ id, fileName, fileSizeInBytes, fileContentType }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const downloadUploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment } })
  const viewUploadedFileQuery = useGetUploadedFileQuery(gqlDataSource, { id, config: { disposition:  ContentDisposition.Inline } } )

  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)

  const { isLoading, data } = downloadUploadedFileQuery

  const uploadedFile = data?.getUploadedFile

  const downloadModal = useIonModal(DownloadUploadedFileModal, {
    uploadedFileId: id,
  })

  const presentDownloadModal = downloadModal[0]

  const fullsizeModal = useIonModal(OpenUploadedFileModal, {
    uploadedFileId: id,
    uploadedFileQuery: viewUploadedFileQuery,
  })

  const presentFullSizeModal = fullsizeModal[0]

  const openDownloadImageModal = () => {
    presentDownloadModal({ cssClass: DownloadUploadedFileModalStyles.downloadUploadedFileModal })
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  const downloadFile = async (event: React.MouseEvent<HTMLIonButtonElement>) => {
    event.stopPropagation()
    if (!uploadedFile)
      return

    const urlStatus = uploadedFile?.signedUrlForDownload.status
    if (urlStatus === SignedUrlStatus.TransformationMissing) {
      openDownloadImageModal()
    } else {
      await downloadUploadedFile(uploadedFile, onProgressUpdate)
    }
  }

  const openFileInNewTab = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    // For mobile the file is open using native plugin.
    if (isMobile){
      return openFile(fileName, fileContentType)
    } else {
      const { isLoading, data } = viewUploadedFileQuery
      const urlStatus = data?.getUploadedFile.signedUrlForDownload.status

      if (isLoading || urlStatus === SignedUrlStatus.TransformationMissing) {
        presentFullSizeModal({ cssClass: OpenUploadedFileModalStyles.fullSizeImageModal })
      } else {
        const url = data?.getUploadedFile.signedUrlForDownload.url
        if (!url) return
        Browser.open({ url })
      }
    }
  }

  return (
    <div className={Styles.chatDocumentItem} onClick={(e) => openFileInNewTab(e)}>
      <IonGrid>
        <IonRow>
          <IonCol size="9">
            <div className={Styles.chatMessageDocumentName}>{fileName}</div>
            {
              fileSizeInBytes &&
              <div className={Styles.chatMessageDocumentSize}><p>{formatBytes(fileSizeInBytes)}</p></div>
            }
          </IonCol>
          <IonCol size="2">
            <div>
              {
                isDownloadingInProgress || isLoading
                  ? <IonSpinner className={Styles.downloadSpinner} name="bubbles" />
                  : <IonButton fill='clear' size='small' onClick={(e) => downloadFile(e)}><IonIcon className={Styles.downloadIcon} icon={cloudDownloadOutline} /></IonButton>
              }
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default ChatMessageDocumentItem
