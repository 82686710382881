import React from 'react'
import { CreateProjectMutation } from '../../../graphql/generated'
import { pageConfig_ShowProject, populateUrlForPath } from '../../../routes'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

type SuccessSlideProps = {
  createdProject: CreateProjectMutation["createProject"],
}

const SuccessSlide: React.FC<SuccessSlideProps> = ({ createdProject: { id } }) => {

  const routeToProject = populateUrlForPath(pageConfig_ShowProject.path, { id }, "referrer=createProject")

  const weaverFlags = useWeaverFlags()

  if ( weaverFlags['MW-2410-copy-changes-march']) {
    return (
      <BrilliantScreen
        title='Nice one'
        description="We're now matching you to our best contractors. Expect to receive introduction notifications in under 3 buisness days."
        actionButtonProps={{
          href: routeToProject,
          children: <>Continue</>,
          expand: 'block',
        }}/>
    )
  } else {
    return (
      <BrilliantScreen
        title='Brilliant'
        subtitle='Your project has been created.'
        description='You will hear from us as soon as we match you with the right contractor. Weaver will update you on the progress within 3 business days.'
        actionButtonProps={{
          href: routeToProject,
          children: <>Continue</>,
          expand: 'block',
        }}/>
    )
  }
}

export default SuccessSlide
