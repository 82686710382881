import { IonAlert, IonButton, IonInput, IonItem, IonText, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetHomeownerProfileQuery, useUpdateHomeownerProfileMutation } from '../../../graphql/generated'
import Styles from '../EditProfile.module.scss'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import EditProfileHeader from '../EditProfileHeader'
import { useQueryClient } from '@tanstack/react-query'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'

type EditHomeownerProfileInputs = {
  teamName: string,
}

const EditHomeownerProfileSchema = z.object({
  teamName: z.string().nonempty('Required'),
})

type EditHomeownerProfileProps = {
  id: string,
}

const EditHomeownerProfile: React.FC<EditHomeownerProfileProps> = ({ id }) => {
  const queryClient = useQueryClient()
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetHomeownerProfileQuery(gqlDataSource, { homeownerTeamId: id })
  const updateHomeownerProfile = useUpdateHomeownerProfileMutation(gqlDataSource)
  const router = useIonRouter()

  const { register, setValue, handleSubmit, formState: { errors } } = useForm<EditHomeownerProfileInputs>({ resolver: zodResolver(EditHomeownerProfileSchema), reValidateMode: 'onChange' })

  if (isFetching) {
    return <LoadingSpinnerPage name="EditHomeownerProfilePage" />
  }

  if (error) {
    console.error(`[EditHomeownerProfilePage] Failed to load Trading As: `, { data, error })
    return  <ErrorBlockPage name='EditHomeownerProfilePage' onRefresh={refetch} />
  }

  if (data?.getHomeownerProfile?.teamName == null) {
    console.warn(`[EditHomeownerProfilePage] Team Name is missing: `, { data, error })
    return <ErrorBlockPage name='EditHomeownerProfilePage'><p>Unable to find the Homeowner Profile details.</p></ErrorBlockPage>
  }

  const { teamName } = data.getHomeownerProfile
  setValue('teamName', teamName)

  const goBack = () => router.goBack()

  const onSubmit = async (data: EditHomeownerProfileInputs) => {
    await updateHomeownerProfile.mutateAsync({ input: { id, teamName: data.teamName } }, {
      onSuccess: () => {
        queryClient.invalidateQueries(useGetHomeownerProfileQuery.getKey({ homeownerTeamId: id }))
        goBack()
      },
      onError: () => {
        setShowUnsuccessfulAlert(true)
      },
    })
  }

  return <WeaverIonPage id='EditHomeownerProfile'>
    <WeaverIonHeader>
      <EditProfileHeader title='Team Name' />
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonText>
        <h5> Change your Team Name</h5>
      </IonText>
      <IonText className={Styles.lightGrey}>
        <p> This is the name clients will see on your profile </p>
      </IonText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem className={Styles.ionItemInput} lines="none">
          <IonInput type='text' {...register("teamName", { required: true })} />
        </IonItem>

        <IonItem lines='none' className={`${Styles.ionItem} ion-no-padding`}>
          {errors.teamName && <IonText color='danger'>
            <p>This field is required</p>
          </IonText>
          }
        </IonItem>

        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <IonButton type='submit' className={`${Styles.confirmButton} ion-no-padding`}>Confirm</IonButton>
        </IonItem>

        <IonAlert
          isOpen={showUnsuccessfulAlert}
          onDidDismiss={() => setShowUnsuccessfulAlert(false)}
          header={'Oops! Something has gone wrong.'}
          buttons={[ 'Dismiss' ]}
        />
      </form>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default EditHomeownerProfile
