import React from 'react'
import { EventProperties } from '../../../../api/providers/SegmentProvider/events'
import { useAnalyticsEvent } from '../../../../api/providers/SegmentProvider/hooks'
import { BudgetRange } from '../../../../graphql/generated'
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, useRouteTo } from '../../../../routes'
import { convertToLockedBudgetRangeStatuses, useMyContractorProfile } from './datasource'

/**
 * Unlike other useRouteTo... hooks, this one fires the WorkHistory_References_Range_Selected event.
 * The pageSource argument is passed to this event as a property with the same name.
 */
export const useRouteToUnlockBudgetRange = (source: EventProperties['WorkHistory_References_Range_Selected']['source']) => {
  const fireEventWorkHistoryReferencesRangeSelected = useAnalyticsEvent('WorkHistory_References_Range_Selected')

  const myContractorProfile = useMyContractorProfile()

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  return (budgetRange: BudgetRange) => {
    const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.workHistory)

    fireEventWorkHistoryReferencesRangeSelected({
      budgetRangeSelected: budgetRange,
      rangeStatus: lockedBudgetRangeStatuses[budgetRange],
      source,
    })

    return goToUnlockContractorBudgetRange({ budgetRange })
  }
}
