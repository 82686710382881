import React from 'react'
import { IonIcon, IonItem, useIonViewDidEnter } from "@ionic/react"
import RangeBadge from "../../profile/ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge"
import Styles from "./PaymentPlanPickerPage.module.scss"
import { checkmarkCircle } from 'ionicons/icons'
import { BudgetRange, GetStripeProductConfigsQuery } from '../../../graphql/generated'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { FeeOption } from './ContractorLeadAcceptorPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import SingleClickButton from '../../../common/components/SingleClickButton'
import FAQ from '../../../common/components/FAQ'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import NavBackButton from '../../../common/components/GlobalHeader/NavBackButton'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { getMonthlyPrice, moneyToText } from '../../../common/utils/currency'

type PaymentPlanPickerPageProps = {
  leadId: string,
  budgetRange: BudgetRange,
  budgetRangeLabel: string,
  stripeProductLeadFee: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  stripeProductMonthlySubscription: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  handleNextClick: (selected: FeeOption) => Promise<void> | void,
}

const _PlanPicker2: React.FC<PaymentPlanPickerPageProps> = ({ leadId, budgetRange, stripeProductLeadFee: leadProductConfig, stripeProductMonthlySubscription: subscriptionProductConfig, handleNextClick }) => {
  const weaverFlags = useWeaverFlags()
  const budgetRangeLeadFee = moneyToText(leadProductConfig.price)
  const budgetRangeMonthlySubscriptionFee = moneyToText(getMonthlyPrice(subscriptionProductConfig))

  const triggerEvent_Lead_PaymentPlanPicker_Viewed = useAnalyticsEvent("Lead_PaymentPlanPicker_Viewed")

  useIonViewDidEnter(() => {
    triggerEvent_Lead_PaymentPlanPicker_Viewed({
      projectId: leadId,
      budgetRange: budgetRange,
    })
  }, [])

  return (
    <WeaverIonContent className='ion-padding-none'>
      <div className={`${Styles.topBackground} ion-text-center`}>
        <RangeBadge budgetRange={budgetRange} />
      </div>
      <div className={Styles.selectMembershipBody}>
        <h3 className={`ion-padding ${Styles.titleClassName}`}>
          <b>
            High quality, vetted leads in the {budgetRangeLabels[budgetRange]} range
          </b>
        </h3>

        <IonItem lines='none' className={Styles.bulletPointContainer}>
          <div className={Styles.tenderReadinessText}>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> Receive {budgetRangeLabels[budgetRange]} vetted leads</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> No further fees when you win the tender</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> Cancel at any time</div>
          </div>
        </IonItem>

        <div className={`ion-padding ${Styles.paymentButtonsContainer}`}>
          <SingleClickButton expand='block' className={Styles.firstButton} onClick={() => handleNextClick(FeeOption.Subscription)}>
            14 day FREE trial
            <br />
            {budgetRangeMonthlySubscriptionFee} per month for unlimited leads
          </SingleClickButton>
          <div className="ion-text-center ion-padding">
            Or
          </div>
          <SingleClickButton expand='block' className={Styles.secondButton} fill='outline' onClick={() => handleNextClick(FeeOption.PerLead)}>
            Single lead package {budgetRangeLeadFee}
          </SingleClickButton>
        </div>
        {weaverFlags['FAQ'] && <FAQ />}
      </div>
    </WeaverIonContent>
  )
}

const PaymentPlanPickerPage: React.FC<PaymentPlanPickerPageProps> = (props) => {

  return (
    <WeaverIonPage id='PaymentPlanPickerPage' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Payment method' navElement={<NavBackButton />} />
      </WeaverIonHeader>
      <_PlanPicker2 {...props} />
    </WeaverIonPage>
  )
}

export default PaymentPlanPickerPage
