import { Money } from "../../graphql/generated"
import { moneyAmountAsNumber, MoneyRange } from "./currency"

export const moneyAsTextBySignificantFigures = (money: Money): string | undefined => {
  const amountInPence = moneyAmountAsNumber(money)
  if (amountInPence == null) return undefined

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: money.currency,
    maximumSignificantDigits: 2,
    notation: 'compact',
  }).format(amountInPence / 100)
}

export const flexibleBudgetTextToSignificantFigures = (moneyOrMoneyRange: Money | MoneyRange): string | undefined => {
  if ('currency' in moneyOrMoneyRange) {
    return moneyAsTextBySignificantFigures(moneyOrMoneyRange)
  } else {
    const { rangeBottom, rangeTop } = moneyOrMoneyRange
    if (rangeBottom === rangeTop) {
      return `up to ${moneyAsTextBySignificantFigures(rangeBottom)}`
    } else {
      return `${moneyAsTextBySignificantFigures(rangeBottom)} - ${moneyAsTextBySignificantFigures(rangeTop)}`
    }
  }
}
