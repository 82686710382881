import React, { ComponentProps } from 'react'
import ErrorBlock from './ErrorBlock'
import WeaverIonPage from '../WeaverIonWrappers/WeaverIonPage'
import ErrorBlockContent from './ErrorBlockContent'

const ErrorBlockPage: React.FC<ComponentProps<typeof ErrorBlock>> = (props) =>
  <WeaverIonPage id={`ErrorBlockPage.${props.name}`} disableDirectChildStructureChecks={true}>
    <ErrorBlockContent {...props} />
  </WeaverIonPage>

export default ErrorBlockPage
