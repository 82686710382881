import {  IonItem, useIonRouter } from '@ionic/react'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Styles from './AchievementToast.module.scss'
import AchievementGif from '../../../assets/images/achievement.gif'
import AchievementStillGif from '../../../assets/images/achievement-loading.gif'
import { isPlatform } from '@ionic/core'
import classNames from 'classnames'
import { v4 as uuidv4 } from "uuid"
import { matchPath } from 'react-router'
import { pageConfig_ChatRoom } from '../../../routes'
import { DateTime, Duration } from 'luxon'

type ToastOptions = {
  header?: string,
  message: string,
  duration?: number,
}
type AchievementToastContextValue = {
  /** Present an achievement Toast */
  present: (options: ToastOptions) => void,
}

type ToastState = ToastOptions & {id: string, state: "enter"|"present"|"exit"}

const AchievementToastContext = React.createContext<AchievementToastContextValue | undefined>(undefined)
AchievementToastContext.displayName = "AchievementToastContext"

export const useAchievementToast = () => {
  const value = useContext(AchievementToastContext)
  if (!value) throw new Error("Missing required context: AchievementToastContext")
  return value
}

const DEFAULT_DURATION = Duration.fromObject({ second: 2.8 }).toMillis()

type ResettableGifProps = {
  /** an id to trigger the reset process */
  resetKey: string,
  src: string,
  /** image file to display as an intermediate between image resets. Consider using the first frame of the gif to prevent flashing */
  loadingSrc: string,
  alt: string,
}
/**
 * Hacky component that can have it's gif animation restarted on change resetKey
 *
 * Gifs do not reset their animations (even via react `key` prop) unless the src is set
 * */
const ResettableGif: React.FC<ResettableGifProps> = ({ resetKey, src, loadingSrc, alt }) => {
  const imgRef = useRef<HTMLImageElement | null>(null)
  const first = useRef<boolean>(true)

  useEffect(() => {
    if (imgRef.current) {
      if (!first.current) return
      setImmediate(() => {
        if (!imgRef.current) return
        imgRef.current.src = src
        first.current = false

      })
    }
  }, [ resetKey, imgRef.current ])

  return <img src={loadingSrc} ref={imgRef} alt={alt} />
}

export const AchievementToastProvider: React.FC = ({ children }) => {
  const isTabletOrDesktop = isPlatform('tablet') || isPlatform('desktop')
  const [ toast, setToast ] = useState<ToastState | undefined>()
  const timeout = useRef<NodeJS.Timeout | undefined>()

  // some routes, like chat, have extra ui elements at the bottom of the screen
  // so we can detect them and add additional padding
  const router = useIonRouter()
  const isChatroom = matchPath(router.routeInfo.pathname, { path: pageConfig_ChatRoom.path })

  // During "present" state, use a timeout to hold for the duration before initiating the exit animation
  useEffect(() => {
    if (toast?.state === "present") {
      timeout.current = setTimeout(() => {
        setToast({
          ...toast,
          state: "exit",
        })
      }, toast.duration || DEFAULT_DURATION)
      return () => {
        if (timeout.current) {
          window.clearTimeout(timeout.current)
          timeout.current = undefined
        }
      }
    }
  }, [ toast?.state ])

  // At the end of the transition animations, update the state
  const handleAnimationEnd: React.AnimationEventHandler<HTMLIonItemElement>  = (event) => {
    if (!toast) return

    if (event.animationName === Styles.toastEnter) {
      setToast({
        ...toast,
        state: "present",
      })

    } else if (event.animationName === Styles.toastExit) {
      setToast(undefined)
    }
  }

  const present = useCallback((options: ToastOptions) => {
    if (toast) {
      console.warn("[AchievementToastProvider] toast interrupted", toast)
      setToast(undefined)
    }
    const nextToast: ToastState = {
      ...options,
      state: "enter",
      id: uuidv4(),
    }

    setToast(nextToast)
    console.debug("[AchievementToastProvider] presenting toast: ", nextToast)
  }, [ setToast ])

  const context = useMemo(() => ({
    present,
  }), [ present ])

  return <AchievementToastContext.Provider value={context}>
    {children}
    {
      toast?.id
        ? <div className={classNames([ Styles.customToast, "ion-no-padding" ], { [Styles.chatroomPadding]: isChatroom })}>
          <IonItem className={classNames({ [Styles.desktop]: isTabletOrDesktop, [Styles.enter]: toast.state === "enter", [Styles.exit]: toast.state === "exit" })} lines='none' onAnimationEnd={handleAnimationEnd}>
            <div className={Styles.imgContainer} slot='start'>
              <ResettableGif resetKey={toast.id} src={AchievementGif} loadingSrc={AchievementStillGif} alt="Achievement" />
            </div>
            <div className="ion-text-wrap">
              <span className={Styles.header}>{toast.header || "Task Complete"}</span>
              <div className={Styles.message}>{toast.message}</div>
            </div>
          </IonItem>
        </div>
        : null
    }
  </AchievementToastContext.Provider>
}
