import { IonBackdrop, IonButton, IonContent, IonIcon } from '@ionic/react'
import React, { useContext } from 'react'
import { SuspensionContext } from './SuspensionProvider'
import Styles from './SuspensionWarningGuard.module.scss'
import { TeamStatus } from '../../../graphql/generated'
import { useWeaverFlags } from '../../thirdParty/launchDarkly/useWeaverFlags'
import { alertCircleOutline } from 'ionicons/icons'
import { showIntercom } from '../../../common/utils/intercom'

const SuspensionWarningGuard: React.FC = ({ children }) => {
  const weaverFlags = useWeaverFlags()
  const suspensionContext = useContext(SuspensionContext)

  return (
    suspensionContext === TeamStatus.Suspended && weaverFlags['MW-2328-suspension-guard']
      ? <>
        <IonBackdrop visible={true} className={Styles.ionBackDrop}></IonBackdrop>
        <div className={'ion-page'}>
          <IonContent>
          </IonContent>
        </div>
        <div className={`${Styles.customAlert} ${Styles.supportDarkMode} ion-padding`}>
          <div className={Styles.customAlertContent}>
            <IonIcon icon={alertCircleOutline} />
            <h1 className={Styles.title}>Account Locked</h1>
            <p className={`${Styles.description} ion-padding-start ion-text-center ion-text-wrap`}>You need an active subscription to access this page</p>
            <IonButton onClick={showIntercom}>Update my billing</IonButton>
          </div>
        </div>
      </>
      : <>{children}</>
  )
}

export default SuspensionWarningGuard
