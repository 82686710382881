import { isSomething } from "../../../common/utils"

const removeUpperCaseLastWords = [ 'LIMITED', 'LTD' ]

export const prettifyCompanyName = (companyName: string): string =>
  companyName
    // Normalise
    .toUpperCase()
    // Process each word (accounting for excess whitespace)
    .trim()
    .split(/\s+/)
    // Remove the last word if it's to be filtered out
    .map((value, index, array) => {
      return index === array.length - 1 && removeUpperCaseLastWords.includes(value)
        ? undefined
        : value
    })
    .filter(isSomething)
    // Make each work title case
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    // Join it again
    .join(' ')
