import { alwaysArray, isSomething } from "."
import { objectEntries } from "./objects"

export const getEnumValueFromString = <T extends object>(type: T, value: string): T[keyof T] | undefined => {
  for (const [ typeKey, typeValue ] of objectEntries(type)) {
    if (typeValue === value) {
      return type[typeKey]
    }
  }

  return undefined
}

/**
* Takes an array of enums, maps them to a label value
* and then creates a comma separated list as a string.
* @param labelRecord: Record<Enum, string>
* @param enumArray: Enum[]
* return string
*/
export const getLabelsFromEnumList = <T, K extends keyof T> (labelRecord:T, enumArray:K[]) =>
  alwaysArray(enumArray).map(each => labelRecord[each]).filter(isSomething).join(', ' )

