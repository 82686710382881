import React, { ComponentProps, ReactNode } from 'react'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'
import { IonModal } from '@ionic/react'
import WorkHistoryForReference from './WorkHistoryForReference'

type WorkHistoryForReferenceModalProps = ComponentProps<typeof WorkHistoryForReference> & ComponentProps<typeof IonModal> & { header?: () => ReactNode }

const WorkHistoryForReferenceModal: React.FC<WorkHistoryForReferenceModalProps> = (props) => {
  // Split props into `workHistoryForReferenceProps` and `ionButtonProps`
  const { contractorProfile, workHistoryItem, reference, ...ionModalProps } = props
  const workHistoryForReferenceProps = { contractorProfile, workHistoryItem, reference }

  return (
    <WeaverIonModal id='WorkHistoryForReferenceModal' disableDirectChildStructureChecks={true} {...ionModalProps}>
      {props.header && props.header()}
      <WorkHistoryForReference {...workHistoryForReferenceProps} />
    </WeaverIonModal>
  )
}

export default WorkHistoryForReferenceModal
